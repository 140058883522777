/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum AISimilarProductFeature {
  PREMIUM = 'PREMIUM',
  SEE_MORE = 'SEE_MORE',
}
export enum AISimilarProductStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type SimilarAIProduct = {
  id?: string | null;
  title?: string | null;
  link?: string | null;
  thumbnail?: string | null;
  source?: string | null;
  source_icon?: string | null;
  price?: number | null;
};

export type AISimilarProductStatusResponse = {
  status: AISimilarProductStatus;
  message?: string | null;
  object_id: string;
};

export type AIDesignLabel = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  coordinates?: Array<Coordinates | null> | null;
  is_purchased?: boolean | null;
};

export type SimilarAIProductList = {
  count: number;
  results: [SimilarAIProduct];
};

export type SimilarAIProductsFilter = {
  object_id?: string | null;
  vendor_id?: string | null;
  feature?: AISimilarProductFeature;
};

export type AIDesignLabelList = {
  count: number;
  results: Array<AIDesignLabel | null>;
};

export type ServiceInqueryMessageOutput = {
  __typename: 'ServiceInqueryMessageOutput';
  userId: string;
  has_unread_messages: boolean;
};
enum TimeUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  TIMEOUT = 'TIMEOUT',
  UNKNOWN = 'UNKNOWN',
}

export type PaymentStatusResponse = {
  __typename: 'PaymentStatusResponse';
  checkout_id: string;
  package_id: string;
  status: PaymentStatus;
  message?: string | null;
};

export type ProjectInput = {
  title: string;
  visibility: string;
  room_type_id?: string | null;
  description?: string | null;
};

export type Project = {
  __typename: 'Project';
  id?: string | null;
  room_type?: RoomType | null;
  is_default?: boolean | null;
  title: string;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  visibility: string;
  client_id?: string | null;
  client?: Client | null;
  discussions_count?: number | null;
  ideas_count?: number | null;
  likes_count?: number | null;
  shares_count?: number | null;
  questions_count?: number | null;
  description?: string | null;
  ideas?: Array<Idea | null> | null;
  ideasList?: IdeaList | null;
  is_liked?: boolean | null;
  collaborators?: ClientList | null;
  default_idea?: Idea | null;
  questions?: QuestionList | null;
  entities?: EntityList | null;
};

export type RoomType = {
  __typename: 'RoomType';
  id: string;
  title?: string | null;
  arabic_title?: string | null;
  english_title?: string | null;
  category?: Category | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  photo?: string | null;
};

export type Category = {
  __typename: 'Category';
  id: string;
  title?: string | null;
  english_title?: string | null;
  description?: string | null;
  parent?: Category | null;
  photo?: string | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  filters?: string | null;
};

export type Client = {
  __typename: 'Client';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  mobile?: string | null;
  country_code?: string | null;
  gender?: string | null;
  type?: string | null;
  about_me?: string | null;
  my_fav_style?: string | null;
  my_next_style?: string | null;
  city_id?: string | null;
  country_id?: string | null;
  city?: City | null;
  country?: Country | null;
  zip?: string | null;
  state?: string | null;
  following_count?: number | null;
  followers_count?: number | null;
  followees_count?: number | null;
  is_followed?: boolean | null;
  total_review?: number | null;
  discussions_count?: number | null;
  comments_count?: number | null;
  is_verified?: boolean | null;
  is_add_gigs_tutorial_showed?: boolean | null;
  last_login?: string | null;
  profile_image?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  blog?: string | null;
  project_role?: string | null;
  client_awards?: Array<ClientAward | null> | null;
  client_badges?: Array<ClientBadge | null> | null;
  projects?: ProjectList | null;
  addresses?: ClientAddressList | null;
  bankcards?: ClientBankCardList | null;
  related_professional?: Professional | null;
  sendbird_access_token?: string | null;
  apns_token?: string | null;
  balance?: number | null;
  is_purchased?: boolean | null;
  referral_url?: string | null;
  can_create_free_rfq?: boolean | null;
  draft_service_inquiry?: ServiceInquiry | null;
};

export type City = {
  __typename: 'City';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  name?: string | null;
  region_id?: string | null;
  country_id?: string | null;
};

export type Country = {
  __typename: 'Country';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  name?: string | null;
  country_flag?: string | null;
  is_default?: boolean | null;
};

export type ClientAward = {
  __typename: 'ClientAward';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  title?: string | null;
  code?: string | null;
  image?: string | null;
};

export type ClientBadge = {
  __typename: 'ClientBadge';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  key?: string | null;
  title?: string | null;
  value?: number | null;
  image?: string | null;
  description?: string | null;
  client_progress?: number | null;
  is_claimed?: boolean | null;
};

export type ProjectList = {
  __typename: 'ProjectList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Project | null>;
};

export type ResultConnection = {
  __typename: 'ResultConnection';
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type CategoryList = {
  __typename: 'CategoryList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Category | null>;
};

export type RoomTypeList = {
  __typename: 'RoomTypeList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<RoomType | null>;
};

export type ClientAddressList = {
  __typename: 'ClientAddressList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<ClientAddress | null>;
};

export type ClientAddress = {
  __typename: 'ClientAddress';
  id?: string | null;
  name?: string | null;
  city?: City | null;
  country?: Country | null;
  neighborhood?: Neighborhood | null;
  description?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  phone_number?: string | null;
  email?: string | null;
  is_default?: boolean | null;
};

export type Neighborhood = {
  __typename: 'Neighborhood';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  name?: string | null;
  city_id?: string | null;
};

export type ClientBankCardList = {
  __typename: 'ClientBankCardList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<BankCard | null> | null;
};

export type BankCard = {
  __typename: 'BankCard';
  id?: string | null;
  card_type?: string | null;
  number?: string | null;
  name?: string | null;
  expiry_date?: string | null;
  partner_reference?: string | null;
  payment_method?: PaymentMethod | null;
};

export type PaymentMethod = {
  __typename: 'PaymentMethod';
  id?: number | null;
  name?: string | null;
  logo?: string | null;
  brand?: string | null;
};

export type GalleryPhotoList = {
  __typename: 'GalleryPhotoList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<GalleryPhoto | null>;
};

export type GalleryPhoto = {
  __typename: 'GalleryPhoto';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  photo?: string | null;
};

export type VideoList = {
  __typename: 'VideoList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Video | null>;
};

export type Video = {
  __typename: 'Video';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  video?: string | null;
};

export type ServiceList = {
  __typename: 'ServiceList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Service | null>;
};

export type Service = {
  __typename: 'Service';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  title?: string | null;
  image?: string | null;
  short_description?: string | null;
  long_description?: string | null;
};

export type QuestionList = {
  __typename: 'QuestionList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Question | null>;
};

export type Question = {
  __typename: 'Question';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  title?: string | null;
  description?: string | null;
  idea_id?: string | null;
  idea?: Idea | null;
  client_id?: string | null;
  client?: Client | null;
  likes_count?: number | null;
  comments_count?: number | null;
  is_liked?: boolean | null;
};

export type Idea = {
  __typename: 'Idea';
  id: string;
  tag?: string | null;
  created_at: string;
  updated_at: string;
  title: string;
  photo?: string | null;
  status?: string | null;
  source: string;
  description?: string | null;
  project_id?: string | null;
  project?: Project | null;
  client?: Client | null;
  client_id?: string | null;
  questions_count?: number | null;
  shares_count?: number | null;
  likes_count?: number | null;
  is_liked?: boolean | null;
  is_photo_uploaded?: boolean | null;
  questions?: QuestionList | null;
  google_vision_result?: string | null;
  paint_colors?: IdeaPaintColorsList | null;
  ideaLabels?: IdeaLabelList | null;
};

export type IdeaPaintColorsList = {
  __typename: 'IdeaPaintColorsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<IdeaPaintColor | null>;
};

export type IdeaPaintColor = {
  __typename: 'IdeaPaintColor';
  color?: PaintColor | null;
};

export type PaintColor = {
  __typename: 'PaintColor';
  id?: string | null;
  title?: string | null;
  rgb?: string | null;
  color_company_meta_data?: PaintColorCompanyMetaData | null;
};

export type PaintColorCompanyMetaData = {
  __typename: 'PaintColorCompanyMetaData';
  code?: string | null;
  external_link?: string | null;
  company?: PaintCompany | null;
};

export type PaintCompany = {
  __typename: 'PaintCompany';
  id?: string | null;
  title?: string | null;
  logo?: string | null;
};

export type IdeaLabelList = {
  __typename: 'IdeaLabelList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<IdeaLabel | null>;
};

export type IdeaLabel = {
  __typename: 'IdeaLabel';
  id?: string | null;
  coordinates?: Array<Coordinates | null> | null;
  name?: string | null;
  related_stockrecords_count?: number | null;
};

export type Coordinates = {
  __typename: 'Coordinates';
  x?: number | null;
  y?: number | null;
};

export type IdeaList = {
  __typename: 'IdeaList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Idea | null>;
};

export type ClientList = {
  __typename: 'ClientList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Client | null>;
};

export type ProfessionalList = {
  __typename: 'ProfessionalList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Professional | null>;
};

export type Professional = {
  __typename: 'Professional';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  client?: Client | null;
  address?: string | null;
  categories?: Array<Category | null> | null;
  services?: Array<Service | null> | null;
  locations?: Array<City | null> | null;
  is_profile_completed?: boolean | null;
  reviews_total?: number | null;
  reviews_count?: number | null;
  gallery_photos?: Array<string | null> | null;
  photos?: GalleryPhotoList | null;
  is_reviewable?: boolean | null;
  projects_count?: number | null;
  videos?: VideoList | null;
  contacts?: ContactInfoList | null;
  professional_type?: ProfessionalType | null;
  company_name?: string | null;
  company_logo?: string | null;
  company_registration_ref?: string | null;
  personal_freelance_license?: string | null;
  is_verified?: boolean | null;
  is_gig_professional?: boolean | null;
  gigs_count?: number | null;
  score?: number | null;
  quotations?: QuotationList | null;
  public_profile_deep_link?: string | null;
};

export type ContactInfoList = {
  __typename: 'ContactInfoList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<ContactInfo | null>;
};

export type ContactInfo = {
  __typename: 'ContactInfo';
  contact_type?: ContactType | null;
  value?: string | null;
};

export enum ContactType {
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
}

export enum ProfessionalType {
  COMPANY = 'COMPANY',
  PERSONAL = 'PERSONAL',
}

export type QuotationList = {
  __typename: 'QuotationList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<Quotation | null> | null;
};

export type QuotationPhoto = {
  photo: string;
};

export type QuotationPhotoList = {
  count: number;
  next: string | null;
  previous: string | null;
  results: [QuotationPhoto];
};
export type Quotation = {
  __typename: 'Quotation';
  id?: string | null;
  service_inquiry?: ServiceInquiry | null;
  professional?: Professional | null;
  created_at?: string | null;
  status?: QuotationStatus | null;
  budget_limits?: string | null;
  execution_time?: string | null;
  sendbird_channel_name?: string | null;
  sendbird_channel_url?: string | null;
  description?: string | null;
  time_unit?: TimeUnit | null;
  photos?: QuotationPhotoList | null;
};

export type ServiceInquiry = {
  __typename?: 'ServiceInquiry';
  id?: string | null;
  number?: string | null;
  status?: ServiceInquiryStatus | null;
  created_at?: string | null;
  sender?: Client | null;
  professional?: Professional | null;
  categories?: CategoryList | null;
  services?: ServiceList | null;
  description?: string | null;
  budget_limits?: string | null;
  phone_number?: string | null;
  whatsapp_number?: string | null;
  photos?: ServiceInquiryPhotosList | null;
  city?: City | null;
  country?: Country | null;
  gig_service_id?: string | null;
  gig_service_title?: string | null;
  gig_service_price?: number | null;
  gig_service_description?: string | null;
  sendbird_channel_name?: string | null;
  sendbird_channel_url?: string | null;
  reviews_total?: number | null;
  reviews_count?: number | null;
  quotations?: QuotationList | null;
  type?: ServiceInquiryType | null;
  quotations_count?: number | null;
  has_quotation?: boolean | null;
};

export enum ServiceInquiryStatus {
  OPENED = 'OPENED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  WAITING_RESPONSE = 'WAITING_RESPONSE',
  DELETED = 'DELETED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  CONTRACTED = 'CONTRACTED',
  DRAFT = 'DRAFT',
  CLOSED = 'CLOSED',
}

export enum ServiceInquirySection {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type ServiceInquiryPhotosList = {
  __typename: 'ServiceInquiryPhotosList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<ServiceInquiryPhoto | null> | null;
};

export type ServiceInquiryPhoto = {
  __typename: 'ServiceInquiryPhoto';
  photo?: string | null;
};

export enum ServiceInquiryType {
  CtoP = 'CtoP',
  Gig = 'Gig',
  RFQ = 'RFQ',
}

export enum QuotationStatus {
  WAITING_RESPONSE = 'WAITING_RESPONSE',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
}

export type StockRecordList = {
  __typename: 'StockRecordList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<StockRecord | null>;
};

export type StockRecord = {
  __typename: 'StockRecord';
  id?: string | null;
  currency?: string | null;
  price?: number | null;
  original_price?: number | null;
  discount_value?: number | null;
  available_number_in_stock?: number | null;
  product?: Product | null;
  partner?: Partner | null;
  reviews_total?: number | null;
  reviews_count?: number | null;
  partner_sku?: string | null;
  is_reviewable?: boolean | null;
  is_purchased?: boolean | null;
  vendor_variants?: Array<StockRecord | null> | null;
};

export type Product = {
  __typename: 'Product';
  id?: string | null;
  title?: string | null;
  description?: string | null;
  slug?: string | null;
  upc?: string | null;
  photo_url?: string | null;
  labels?: Array<Label | null> | null;
  manufactory?: Manufactory | null;
  images?: Array<Image | null> | null;
  attributes?: Array<ProductAttribute | null> | null;
  breadcrumbs?: Array<breadcrumb | null> | null;
  offer?: Offer | null;
  section?: Department | null;
  categories?: Array<ProductCategory | null> | null;
  color?: string | null;
  dimensions?: string | null;
};

export type Label = {
  __typename: 'Label';
  id?: string | null;
  title?: string | null;
  description?: string | null;
  color?: string | null;
  is_extra?: boolean | null;
};

export type Manufactory = {
  __typename: 'Manufactory';
  id?: string | null;
  name?: string | null;
  website_url?: string | null;
};

export type Image = {
  __typename: 'Image';
  id?: string | null;
  original?: string | null;
  is_default?: boolean | null;
  display_order?: number | null;
};

export type ProductAttribute = {
  __typename: 'ProductAttribute';
  name?: string | null;
  value?: string | null;
};

export type breadcrumb = {
  __typename: 'breadcrumb';
  title?: string | null;
  slug?: string | null;
};

export type Offer = {
  __typename: 'Offer';
  type?: string | null;
  value?: string | null;
};

export type Department = {
  __typename: 'Department';
  id?: string | null;
  title?: string | null;
  description?: string | null;
  slug?: string | null;
  photo_url?: string | null;
  products_count?: number | null;
  filters?: string | null;
  breadcrumbs?: Array<breadcrumb | null> | null;
  sub_departments?: Array<Department | null> | null;
  children_count?: string | null;
};

export type ProductCategory = {
  __typename: 'ProductCategory';
  title?: string | null;
  slug?: string | null;
};

export type Partner = {
  __typename: 'Partner';
  id?: string | null;
  name?: string | null;
  code?: string | null;
  logo?: string | null;
  address?: string | null;
  email?: string | null;
  reviews_total?: number | null;
  reviews_count?: number | null;
  is_reviewable?: boolean | null;
  mobile?: string | null;
  blog?: string | null;
  policy?: string | null;
};

export type CityList = {
  __typename: 'CityList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<City | null> | null;
};

export type CountryList = {
  __typename: 'CountryList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Country | null>;
};

export type NeighborhoodList = {
  __typename: 'NeighborhoodList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Neighborhood | null>;
};

export type RegionList = {
  __typename: 'RegionList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<Region | null> | null;
};

export type Region = {
  __typename: 'Region';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  name?: string | null;
};

export type TopicList = {
  __typename: 'TopicList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Topic | null>;
};

export type Topic = {
  __typename: 'Topic';
  id: string;
  paren_id?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  title?: string | null;
  description?: string | null;
  is_interested?: boolean | null;
};

export type DiscussionList = {
  __typename: 'DiscussionList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Discussion | null>;
};

export type Discussion = {
  __typename: 'Discussion';
  id: string;
  tag?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  title?: string | null;
  description?: string | null;
  client_id?: string | null;
  client?: Client | null;
  youtube_url?: string | null;
  photo?: string | null;
  web_url?: string | null;
  likes_count?: number | null;
  comments_count?: number | null;
  topics?: Array<Topic | null> | null;
  polls?: Array<Poll | null> | null;
  is_liked?: boolean | null;
  latest_two_commenters?: Array<Client | null> | null;
};

export type Poll = {
  __typename: 'Poll';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  title?: string | null;
  photo_url?: string | null;
};

export type ShareList = {
  __typename: 'ShareList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Share | null>;
};

export type Share = {
  __typename: 'Share';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  client?: Client | null;
};

export type SpamReasonList = {
  __typename: 'SpamReasonList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<SpamReason | null>;
};

export type SpamReason = {
  __typename: 'SpamReason';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  title?: string | null;
  description?: string | null;
};

export type TVList = {
  __typename: 'TVList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<TV | null>;
};

export type TV = {
  __typename: 'TV';
  id: string;
  video_url: string;
  photo: string;
  page: string;
  likes_count?: number | null;
  is_liked?: boolean | null;
  comments_count?: number | null;
  title?: string | null;
  meta_description?: string | null;
  categories?: Array<Category | null> | null;
  created_at?: string | null;
  video_length?: string | null;
  client?: Client | null;
};

export type MagazineList = {
  __typename: 'MagazineList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Magazine | null>;
};

export type Magazine = {
  __typename: 'Magazine';
  id: string;
  page: string;
  photo: string;
  likes_count?: number | null;
  comments_count?: number | null;
  title?: string | null;
  meta_description?: string | null;
  is_liked?: boolean | null;
  categories?: Array<Category | null> | null;
  created_at?: string | null;
  client?: Client | null;
};

export type NotificationTypeList = {
  __typename: 'NotificationTypeList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<NotificationType | null>;
};

export type NotificationType = {
  __typename: 'NotificationType';
  verb: string;
  options?: Array<NotificationValue | null> | null;
};

export type NotificationValue = {
  __typename: 'NotificationValue';
  id: string;
  value: string;
};

export type NotificationSettingResult = {
  __typename: 'NotificationSettingResult';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<NotificationSettingObject | null> | null;
};

export type NotificationSettingObject = {
  __typename: 'NotificationSettingObject';
  text?: string | null;
  settings?: Array<NotificationSettingTypeObject | null> | null;
};

export type NotificationSettingTypeObject = {
  __typename: 'NotificationSettingTypeObject';
  id?: string | null;
  title?: string | null;
  status?: NotificationStatus | null;
  blocked?: boolean | null;
};

export enum NotificationStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export type NotificationList = {
  __typename: 'NotificationList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<Notification | null> | null;
};

export type Notification = {
  __typename: 'Notification';
  id?: string | null;
  title?: string | null;
  body?: string | null;
  created_at?: string | null;
  unread?: boolean | null;
  notification_class?: string | null;
  sender?: Client | null;
  notification_payload?: NotificationPayload | null;
};

export type NotificationPayload = {
  __typename: 'NotificationPayload';
  page?: string | null;
  section?: string | null;
  id?: string | null;
  url?: string | null;
  icon?: string | null;
  image?: string | null;
};

export type DepartmentList = {
  __typename: 'DepartmentList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Department | null>;
};

export type ProductsList = {
  __typename: 'ProductsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Product | null>;
};

export type ProductSliderList = {
  __typename: 'ProductSliderList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<ProductSlider | null>;
};

export type ProductSlider = {
  __typename: 'ProductSlider';
  stock_records?: Array<StockRecord | null> | null;
  title?: string | null;
  show_all_url?: string | null;
};

export type ProductReviewList = {
  __typename: 'ProductReviewList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<ProductReview | null>;
};

export type ProductReview = {
  __typename: 'ProductReview';
  id?: string | null;
  stockrecord?: string | null;
  client?: Client | null;
  body?: string | null;
  general_score?: number | null;
  client_bought_product?: boolean | null;
  created_at?: string | null;
  status?: string | null;
  detailed_review_scores?: Array<DetailedReviewScore | null> | null;
  review_images?: Array<ReviewImage | null> | null;
  likes_count?: number | null;
  is_liked?: boolean | null;
};

export type DetailedReviewScore = {
  __typename: 'DetailedReviewScore';
  id: string;
  title?: string | null;
  status?: string | null;
  score?: number | null;
  detailed_score?: DetailedScore | null;
};

export type DetailedScore = {
  __typename: 'DetailedScore';
  id: string;
  title: string;
  status?: string | null;
};

export type ReviewImage = {
  __typename: 'ReviewImage';
  id?: string | null;
  image?: string | null;
  status?: string | null;
};

export type DetailedScoreList = {
  __typename: 'DetailedScoreList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<DetailedScore | null>;
};

export type FAQsList = {
  __typename: 'FAQsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<FAQ | null>;
};

export type FAQ = {
  __typename: 'FAQ';
  id?: string | null;
  title?: string | null;
  description?: string | null;
};

export type ClientFollowersList = {
  __typename: 'ClientFollowersList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Client | null>;
};

export type BrandsList = {
  __typename: 'BrandsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<Brand | null> | null;
};

export type Brand = {
  __typename: 'Brand';
  id: string;
  arabic_title?: string | null;
  english_title?: string | null;
  title?: string | null;
  arabic_description?: string | null;
  english_description?: string | null;
  description?: string | null;
  website?: string | null;
  logo?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  type?: BrandType | null;
  rate?: number | null;
  rates_count?: number | null;
  rank?: number | null;
  tags?: BrandTagsList | null;
  images?: BrandImagesList | null;
  is_reviewable?: boolean | null;
};

export enum BrandType {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
}

export type BrandTagsList = {
  __typename: 'BrandTagsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<BrandTag | null> | null;
};

export type BrandTag = {
  __typename: 'BrandTag';
  english_title?: string | null;
  arabic_title?: string | null;
  title?: string | null;
};

export type BrandImagesList = {
  __typename: 'BrandImagesList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<BrandImage | null> | null;
};

export type BrandImage = {
  __typename: 'BrandImage';
  photo?: string | null;
};

export type ClientBadgesList = {
  __typename: 'ClientBadgesList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<ClientBadge | null> | null;
};

export type ClientAwardsList = {
  __typename: 'ClientAwardsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<ClientAward | null>;
};

export type EntityList = {
  __typename: 'EntityList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Entity | null>;
};

export type Entity = {
  __typename: 'Entity';
  created_at: string;
  updated_at: string;
  status?: string | null;
  photo?: string | null;
  description?: string | null;
  tag?: string | null;
  client_id?: string | null;
  project_id?: string | null;
  entity_id?: string | null;
  title?: string | null;
  is_main_entity?: boolean | null;
};

export type UpdateProjectInput = {
  id: string;
  title: string;
  visibility: string;
  description?: string | null;
  room_type_id?: string | null;
};

export type ResultOutput = {
  __typename: 'ResultOutput';
  message: string;
  status?: string | null;
};

export type setProjectDefaultIdeaInput = {
  project: string;
  idea: string;
};

export type SaveEntityInput = {
  project_id: string;
  id: string;
  description?: string | null;
};

export type MoveEntityInput = {
  source_project_id: string;
  destination_project_id: string;
  ids: Array<string | null>;
};

export type DeleteEntityInput = {
  project_id: string;
  ids: Array<string | null>;
};

export type CopyEntityInput = {
  source_project_id: string;
  destination_project_id: string;
  ids: Array<string | null>;
  description?: string | null;
};

export type AddNoteToProjectEntityInput = {
  project_id: string;
  id: string;
  description?: string | null;
};

export type SetAsMainEntityInput = {
  project_id: string;
  id: string;
};

export type IdeaInput = {
  project_id: string;
  title: string;
  photo?: string | null;
  source: string;
};

export type EditClientProfileInput = {
  first_name?: string | null;
  last_name?: string | null;
  about_me?: string | null;
  mobile?: string | null;
  my_fav_style?: string | null;
  my_next_style?: string | null;
  country_id?: string | null;
  city_id?: string | null;
  state?: string | null;
  zip?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  blog?: string | null;
  profile_image?: string | null;
  language?: string | null;
};

export type NotificationSettingsInput = {
  notification_settings?: Array<NotificationSettingInput | null> | null;
};

export type NotificationSettingInput = {
  id: string;
  status: NotificationStatus;
};

export type InviteCollaboratorsInput = {
  project_id: string;
  collaborators: Array<CollaboratorsWithroleInput | null>;
};

export type CollaboratorsWithroleInput = {
  id: string;
  role: string;
};

export type DeleteCollaboratorsInput = {
  project_id: string;
  collaborators: Array<CollaboratorsInput | null>;
};

export type CollaboratorsInput = {
  id: string;
};

export type CompleteProfessionalProfileInput = {
  first_name: string;
  last_name?: string | null;
  about_me: string;
  address: string;
  locations: Array<string | null>;
  categories?: Array<string | null> | null;
  services: Array<string | null>;
  photos?: Array<string | null> | null;
  mobile?: string | null;
  country_code?: string | null;
  country?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
  blog?: string | null;
  contacts_info?: Array<ContactInfoInput | null> | null;
  professional_type?: ProfessionalType | null;
  company_name?: string | null;
  company_logo?: string | null;
  company_registration_ref?: string | null;
  personal_freelance_license?: string | null;
};

export type ContactInfoInput = {
  contact_type?: ContactType | null;
  value?: string | null;
};

export type UploadProfessionalPhotosInput = {
  photos?: Array<string | null> | null;
};

export type UploadProfessionalVideosInput = {
  videos?: Array<string | null> | null;
};

export type FollowUserInput = {
  followee: string;
};

export type UpdateFcmTokenInput = {
  fcm_token: string;
  apns_token?: string | null;
};

export type CompleteRegistrationInput = {
  user_type: UserType;
  referral_code: String;
};

export enum UserType {
  HOMEOWNER = 'HOMEOWNER',
  PROFESSIONAL = 'PROFESSIONAL',
}

export type QuestionInput = {
  idea: string;
  title: string;
  description?: string | null;
};

export type QuestionToProjectInput = {
  project: string;
  idea?: string | null;
  title: string;
  description?: string | null;
};

export type CommentInput = {
  id?: string | null;
  resourceId: string;
  content: string;
};

export type Comment = {
  __typename: 'Comment';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  status?: string | null;
  content?: string | null;
  client?: Client | null;
  client_id?: string | null;
  likes_count?: number | null;
  is_liked?: boolean | null;
};

export type DeleteCommentInput = {
  id?: string | null;
  resourceId: string;
};

export type ShareInput = {
  id?: string | null;
  resourceId: string;
};

export type LikeInput = {
  id?: string | null;
  resourceId: string;
};

export type ReviewInput = {
  id?: string | null;
  resourceId: string;
  content: string;
  rating: number;
};

export type Review = {
  __typename: 'Review';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  content?: string | null;
  rating?: number | null;
  client?: Client | null;
};

export type ReportSpamInput = {
  resourceId: string;
  id?: string | null;
  reason_id: string;
  description?: string | null;
};

export type DiscussionInput = {
  title: string;
  description: string;
  topics: Array<string | null>;
  youtube_url?: string | null;
  web_url?: string | null;
  photo?: string | null;
  polls?: string | null;
};

export type SaveInterestedTopicInput = {
  topics: Array<string | null>;
};

export type ContactMeInput = {
  professional_id: string;
  name: string;
  email: string;
  mobile?: string | null;
  project_zip_code?: string | null;
  message: string;
};

export type ContactUsInput = {
  name: string;
  email: string;
  message: string;
};

export type SendEmailInput = {
  subject: string;
  email: string;
  body: string;
};

export type ProductReviewInput = {
  id?: string | null;
  resourceId: string;
  stockrecord: string;
  body?: string | null;
  client_bought_product?: boolean | null;
  general_score: number;
  detailed_review_scores?: Array<DetailedReviewScoreInput | null> | null;
  review_images?: Array<string | null> | null;
};

export type DetailedReviewScoreInput = {
  detailed_score: string;
  score?: number | null;
};

export type ClientAddressInput = {
  id?: string | null;
  name: string;
  city_id: string;
  country_id: string;
  neighborhood_id: string;
  description?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  is_default?: boolean | null;
};

export type AddProductToBasketInput = {
  resourceId?: number | null;
  quantity?: number | null;
};

export type BasketLine = {
  __typename: 'BasketLine';
  id?: number | null;
  product?: Product | null;
  quantity?: number | null;
  stockrecord_id?: number | null;
  basket_id?: number | null;
  price_currency?: string | null;
  price_excl_tax?: string | null;
  price_incl_tax?: string | null;
  price_incl_tax_excl_discounts?: string | null;
  price_excl_tax_excl_discounts?: string | null;
  original_price_incl_tax?: string | null;
  original_price_excl_tax?: string | null;
  stockrecord?: StockRecord | null;
};

export type BasketLineInput = {
  id?: number | null;
  resourceId?: number | null;
  quantity?: number | null;
  product_id?: number | null;
  stockrecord_id?: number | null;
};

export type CheckoutInput = {
  shipping_address?: ShippingAddressInput | null;
  basket_id: number;
  total: number;
  shipping_method_code: string;
  save_card?: SaveCardOptions | null;
};

export type ShippingAddressInput = {
  client_address_id?: string | null;
  country: string;
  city: string;
  neighborhood: string;
  name: string;
  first_name?: string | null;
  last_name?: string | null;
  phone_number?: string | null;
  email?: string | null;
  note?: string | null;
};

export enum SaveCardOptions {
  True = 'True',
  False = 'False',
}

export type OrderDetails = {
  __typename: 'OrderDetails';
  id?: number | null;
  number?: string | null;
  currency?: string | null;
  total_incl_tax?: string | null;
  total_excl_tax?: string | null;
  total_discount_incl_tax?: number | null;
  total_discount_excl_tax?: number | null;
  shipping_incl_tax?: string | null;
  shipping_excl_tax?: string | null;
  status?: string | null;
  date_placed?: string | null;
  shipping_address?: ClientAddress | null;
  num_items?: number | null;
  products_price?: number | null;
  total_tax?: number | null;
  discount_price?: number | null;
  discount_amount?: number | null;
  payment_method?: PaymentMethod | null;
  payment_card?: BankCard | null;
  lines?: OrderLinesList | null;
};

export type OrderLinesList = {
  __typename: 'OrderLinesList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<OrderLine | null> | null;
};

export type OrderLine = {
  __typename: 'OrderLine';
  id?: number | null;
  product?: Product | null;
  quantity?: number | null;
  stockrecord?: StockRecord | null;
  price_currency?: string | null;
  price_excl_tax?: string | null;
  price_incl_tax?: string | null;
  price_incl_tax_excl_discounts?: string | null;
  price_excl_tax_excl_discounts?: string | null;
  original_price_incl_tax?: string | null;
  original_price_excl_tax?: string | null;
  status?: string | null;
  product_review?: ProductReview | null;
};

export type CancelOrderInput = {
  order_id: string;
};

export type BankCardInput = {
  id?: string | null;
  card_type: string;
  number: string;
  name: string;
  expiry_date: string;
  partner_reference?: string | null;
};

export type PartnerInput = {
  name: string;
  store_name: string;
  email: string;
  mobile: string;
  instagram_account?: string | null;
  website?: string | null;
};

export type PaymentCheckoutIdForOrderInfoInput = {
  basket?: CheckoutBasketInput | null;
  shipping_address?: ShippingAddressInput | null;
  payment_method_id: string;
};

export type CheckoutBasketInput = {
  id: number;
  status?: string | null;
  total_incl_tax: string;
  total_quantity: string;
  currency: string;
  lines: Array<CheckoutBasketLineInput | null>;
};

export type CheckoutBasketLineInput = {
  id: number;
  product_id: number;
  quantity: number;
  stockrecord_id: number;
  price_currency: string;
  price_incl_tax: string;
};

export type PaymentCheckoutIdForOrderInfoOutput = {
  __typename: 'PaymentCheckoutIdForOrderInfoOutput';
  result?: PaymentCheckoutIdResultObject | null;
  order_number?: string | null;
  checkout_id?: string | null;
  id?: string | null;
  updated_basket?: Basket | null;
};

export type PaymentCheckoutIdResultObject = {
  __typename: 'PaymentCheckoutIdResultObject';
  code?: string | null;
  description?: string | null;
};

export type Basket = {
  __typename: 'Basket';
  id?: number | null;
  status?: string | null;
  total_excl_tax?: string | null;
  total_excl_tax_excl_discounts?: string | null;
  total_incl_tax?: string | null;
  total_incl_tax_excl_discounts?: string | null;
  total_original_excl_tax?: string | null;
  total_original_incl_tax?: string | null;
  total_quantity?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  discount_price?: number | null;
  discount_amount?: number | null;
  lines?: Array<BasketLine | null> | null;
};

export type InputRefundOrderLine = {
  order_line_id: string;
  refund_reason_ids: Array<string | null>;
  refund_note?: string | null;
};

export type MarkNotificationAsReadInput = {
  notification_ids?: Array<string | null> | null;
  all?: boolean | null;
};

export type GigServiceInput = {
  id?: string | null;
  title: string;
  description?: string | null;
  services: Array<string>;
  cities: Array<string>;
  price: number;
  photos: Array<string>;
  is_enabled: boolean;
};

export type GigService = {
  __typename: 'GigService';
  id: string;
  title: string;
  description?: string | null;
  services: Array<Service>;
  cities: Array<City>;
  price: number;
  photos: Array<string>;
  is_enabled: boolean;
  professional: Professional;
};

export type PaymentCheckoutIdForManzilikAiInput = {
  package_id: string;
  payment_method_id: string;
};

export type PaymentCheckoutIdForManzilikAiOutput = {
  __typename: 'PaymentCheckoutIdForManzilikAiOutput';
  checkout_id?: string | null;
};

export type SendBirdChatGroupInput = {
  service_inquiry_id?: string | null;
};

export type ChatGroup = {
  __typename: 'ChatGroup';
  sendbird_access_token?: string | null;
  chat_url?: string | null;
  chat_name?: string | null;
};

export type GenerateAIDesignInput = {
  imageURL: string;
  styleSlug: string;
  roomTypeSlug: string;
  visibility: string;
  processingType: string;
  advancedOptions?: Array<AIOptionInput | null> | null;
  text?: string | null;
};

export type AIOptionInput = {
  optionNameSlug?: string | null;
  optionValueSlug?: string | null;
};

export type AIDesignObject = {
  __typename: 'AIDesignObject';
  id: string;
  created_at?: string | null;
  updated_at?: string | null;
  sourceImageUrl: string;
  selectedImageIndex?: number | null;
  processedImagesPath?: Array<string | null> | null;
  visibility: string;
  processingType: string;
  roomType?: AIDesignRoomType | null;
  style?: AIDesignStyle | null;
  status?: AIDesignStatus | null;
  text?: string | null;
  objects: AIDesignLabelList;
};

export type AIDesignRoomType = {
  __typename: 'AIDesignRoomType';
  id: string;
  name: string;
  slug: string;
  image?: string | null;
};

export type AIDesignStyle = {
  __typename: 'AIDesignStyle';
  id: string;
  name: string;
  slug: string;
  image?: string | null;
  template_prompt?: string | null;
};

export enum AIDesignStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  REFUNDED = 'REFUNDED',
  DELETED = 'DELETED',
  CANCELLED = 'CANCELLED',
}

export type CancelAIDesignResponse = {
  __typename: 'CancelAIDesignResponse';
  message?: string | null;
};

export type RateAIDesignInput = {
  imageId: string;
  rating: number;
  comments?: string | null;
};

export type SucessAIResponse = {
  __typename: 'SucessAIResponse';
  message?: string | null;
};

export type ImageNotificationResponse = {
  __typename: 'ImageNotificationResponse';
  outstandingId?: string | null;
  recommendedImages?: Array<string | null> | null;
  error?: string | null;
};

export type ServiceInquiryInput = {
  professional_id: string;
  services: Array<string | null>;
  categories?: Array<string | null> | null;
  photos?: Array<string | null> | null;
  description: string;
  budget_limits?: string | null;
  phone_number: string;
  whatsapp_number?: string | null;
  city?: string | null;
  gig_input?: GigInput | null;
};

export type GigInput = {
  id?: string | null;
  title?: string | null;
  price?: number | null;
  description?: string | null;
};

export type RespondServiceInquiryInput = {
  id: string;
  response: ServiceInquiryResponse;
  rejection_reasons?: Array<string | null> | null;
  rejection_note?: string | null;
};

export enum ServiceInquiryResponse {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export type CompleteServiceInquiryInput = {
  id: string;
  completed_by: string;
};

export type CancelServiceInquiryInput = {
  id: string;
  canceled_by: string;
  note: string;
};

export type SendRFQInput = {
  services: Array<string | null>;
  categories?: Array<string | null> | null;
  photos?: Array<string | null> | null;
  description: string;
  budget_limits?: string | null;
  phone_number: string;
  whatsapp_number?: string | null;
  city?: string | null;
};

export type SendQuotationInput = {
  service_inquiry_id: string;
  budget_limits?: string | null;
  execution_time?: string | null;
  description: string;
  photos?: Array<string | null> | null;
};

export type AcceptQuotationInput = {
  id: string;
};

export type PaymentPagination = {
  limit?: number | null;
  offset?: number | null;
};

export type PackageList = {
  __typename: 'PackageList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Package | null>;
};

export type Package = {
  __typename: 'Package';
  id: string;
  name: string;
  title?: string | null;
  credit_amount?: number | null;
  price?: number | null;
  discount?: number | null;
};

export type Pagination = {
  limit?: number | null;
  offset?: number | null;
  id?: string | null;
  resourceId?: string | null;
  date_type?: string | null;
  tab?: string | null;
  platform?: string | null;
  sort?: string | null;
  query?: string | null;
  extra?: string | null;
};

export type CategoryWithSubCategoryResult = {
  __typename: 'CategoryWithSubCategoryResult';
  result?: Array<CategoryWithSubCategory | null> | null;
};

export type CategoryWithSubCategory = {
  __typename: 'CategoryWithSubCategory';
  parent?: Category | null;
  children?: Array<Category | null> | null;
};

export type PresignedUrlInput = {
  content_type: string;
  file_name: string;
  destination: string;
};

export type PresignedUrl = {
  __typename: 'PresignedUrl';
  result: string;
};

export type FeedsResult = {
  __typename: 'FeedsResult';
  count: number;
  results: Array<FeedsData | null>;
};

export type FeedsData = {
  __typename: 'FeedsData';
  data?: string | null;
};

export type TotalResultOutput = {
  __typename: 'TotalResultOutput';
  total: number;
};

export type CommentList = {
  __typename: 'CommentList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Comment | null>;
};

export type ReviewsList = {
  __typename: 'ReviewsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Review | null>;
};

export type FilterJSON = {
  __typename: 'FilterJSON';
  filters?: string | null;
};

export type PlatformInput = {
  platform?: Platform | null;
};

export enum Platform {
  Mobile = 'Mobile',
  Web = 'Web',
}

export type SearchInput = {
  q?: string | null;
  f?: string | null;
  c?: string | null;
  i?: string | null;
  l?: string | null;
  o?: string | null;
  m?: string | null;
  p?: string | null;
  s?: string | null;
};

export type SearchResult = {
  __typename: 'SearchResult';
  count: number;
  results: Array<SearchData | null>;
  suggestions?: Array<SuggestionData | null> | null;
  filters?: string | null;
};

export type SearchData = {
  __typename: 'SearchData';
  index: string;
  data?: string | null;
};

export type SuggestionData = {
  __typename: 'SuggestionData';
  original?: string | null;
  suggestion?: string | null;
};

export type FindRelated = {
  id?: string | null;
  search_by?: string | null;
};

export type AutoCompleteInput = {
  text?: string | null;
  search_by?: string | null;
  l?: string | null;
  o?: string | null;
};

export type AutoCompleteList = {
  __typename: 'AutoCompleteList';
  count: number;
  results: AutoCompleteResult;
};

export type AutoCompleteResult = {
  __typename: 'AutoCompleteResult';
  projects?: Array<string | null> | null;
  ideas?: Array<string | null> | null;
  magazines?: Array<string | null> | null;
  tvs?: Array<string | null> | null;
  discussions?: Array<string | null> | null;
  professionals?: Array<string | null> | null;
  brands?: Array<string | null> | null;
  stockrecords?: Array<string | null> | null;
};

export type AutoCompleteObjectList = {
  __typename: 'AutoCompleteObjectList';
  count: number;
  results: AutoCompleteObjectsResult;
};

export type AutoCompleteObjectsResult = {
  __typename: 'AutoCompleteObjectsResult';
  projects?: Array<AutoCompleteObject | null> | null;
  magazines?: Array<AutoCompleteObject | null> | null;
  tvs?: Array<AutoCompleteObject | null> | null;
  discussions?: Array<AutoCompleteObject | null> | null;
  professionals?: Array<AutoCompleteObject | null> | null;
  brands?: Array<AutoCompleteObject | null> | null;
};

export type AutoCompleteObject = {
  __typename: 'AutoCompleteObject';
  id: string;
  title: string;
  key_id?: string | null;
  key?: string | null;
};

export type Menu = {
  __typename: 'Menu';
  data?: string | null;
};

export type ShoppingMenu = {
  __typename: 'ShoppingMenu';
  departments?: string | null;
};

export type HomePageSlider = {
  __typename: 'HomePageSlider';
  result?: string | null;
};

export type BreadcrumbList = {
  __typename: 'BreadcrumbList';
  breadcrumbs?: Array<breadcrumb | null> | null;
};

export type StockRecordBasketLineInput = {
  resourceId: string;
  basket: string;
};

export type StockRecordReviewsAggregationResult = {
  __typename: 'StockRecordReviewsAggregationResult';
  rates_aggregation?: Array<StockRecordReviewsAggregationObject | null> | null;
};

export type StockRecordReviewsAggregationObject = {
  __typename: 'StockRecordReviewsAggregationObject';
  rate?: number | null;
  count?: number | null;
};

export enum BannerSlug {
  IDEA_BANNER = 'IDEA_BANNER',
  MAGAZINE_BANNER = 'MAGAZINE_BANNER',
  DISCUSSION_BANNER = 'DISCUSSION_BANNER',
  PROFESSIONALS_BANNER = 'PROFESSIONALS_BANNER',
}

export type Banner = {
  __typename: 'Banner';
  title?: string | null;
  sub_title?: string | null;
  description?: string | null;
  photo_url?: string | null;
  slug?: string | null;
  button_title?: string | null;
  responsive_photo_url?: string | null;
  url?: string | null;
};

export type BasketLineList = {
  __typename: 'BasketLineList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<BasketLine | null>;
};

export type BasketLinesGroupedByPartnerResult = {
  __typename: 'BasketLinesGroupedByPartnerResult';
  result?: Array<BasketLinesGroupedByPartnerObject | null> | null;
};

export type BasketLinesGroupedByPartnerObject = {
  __typename: 'BasketLinesGroupedByPartnerObject';
  partner?: Partner | null;
  lines?: Array<BasketLine | null> | null;
};

export type OrderListInput = {
  limit?: number | null;
  offset?: number | null;
  status?: string | null;
  order_number?: string | null;
};

export type OrderList = {
  __typename: 'OrderList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Order | null>;
};

export type Order = {
  __typename: 'Order';
  id?: number | null;
  number?: string | null;
  currency?: string | null;
  total_incl_tax?: string | null;
  total_excl_tax?: string | null;
  shipping_incl_tax?: string | null;
  shipping_excl_tax?: string | null;
  status?: string | null;
  date_placed?: string | null;
  shipping_address?: ClientAddress | null;
  num_items?: number | null;
};

export type OrderStatusPipelineResult = {
  __typename: 'OrderStatusPipelineResult';
  status_pipeline?: Array<string | null> | null;
};

export type OrderLinesGroupedByPartnerResult = {
  __typename: 'OrderLinesGroupedByPartnerResult';
  result?: Array<OrderLinesGroupedByPartnerObject | null> | null;
};

export type OrderLinesGroupedByPartnerObject = {
  __typename: 'OrderLinesGroupedByPartnerObject';
  partner?: Partner | null;
  lines?: Array<OrderLine | null> | null;
};

export type MyOrdersCountStatusResult = {
  __typename: 'MyOrdersCountStatusResult';
  my_orders_statuses?: Array<MyOrdersCountStatusResultObject | null> | null;
};

export type MyOrdersCountStatusResultObject = {
  __typename: 'MyOrdersCountStatusResultObject';
  status?: string | null;
  count?: number | null;
};

export type PaymentMethodList = {
  __typename: 'PaymentMethodList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<PaymentMethod | null>;
};

export type PaymentCheckoutIdInput = {
  payment_method_id: string;
  capture?: SaveCardOptions | null;
  card_token?: string | null;
};

export type PaymentCheckoutIdOutput = {
  __typename: 'PaymentCheckoutIdOutput';
  result?: PaymentCheckoutIdResultObject | null;
  order_number?: string | null;
  id?: string | null;
};

export type PaymentStatusInput = {
  hp_checkout_id: string;
  source?: string | null;
  is_mada?: SaveCardOptions | null;
};

export type PaymentStatusOutput = {
  __typename: 'PaymentStatusOutput';
  payment_status?: string | null;
  description?: string | null;
};

export type WebPagesList = {
  __typename: 'WebPagesList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<WebPage | null>;
};

export type WebPage = {
  __typename: 'WebPage';
  id?: string | null;
  title?: string | null;
  description?: string | null;
};

export type ListOnBoardingScreensInput = {
  slug: OnBoardingScreenSlug;
  limit?: number | null;
  offset?: number | null;
};

export enum OnBoardingScreenSlug {
  HOME_PAGE = 'HOME_PAGE',
}

export type OnBoardingScreenList = {
  __typename: 'OnBoardingScreenList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<OnBoardingScreen | null>;
};

export type OnBoardingScreen = {
  __typename: 'OnBoardingScreen';
  id: string;
  title: string;
  description: string;
  order: number;
  image: string;
};

export type GeneralSettings = {
  __typename: 'GeneralSettings';
  enableEcommerce: boolean;
  enableRegistrationByMobile: boolean;
  enableSocialLogin: boolean;
  forceUpdateAndroidVersion: string;
  forceUpdateIOSVersion: string;
  PriceFilterMinValue: number;
  PriceFilterMaxValue: number;
  showProfessionalReviews: boolean;
  hyperPayPaymentUrl?: string | null;
  localizationUpdatingDate?: string | null;
  localizationArabicFilePath?: string | null;
  localizationEnglishFilePath?: string | null;
  ManzilikConfigurationFilePath?: string | null;
};

export type RefundReasons = {
  __typename: 'RefundReasons';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<RefundReason | null> | null;
};

export type RefundReason = {
  __typename: 'RefundReason';
  id: string;
  title?: string | null;
  is_note_required?: boolean | null;
};

export type GetOptionInput = {
  option_name: string;
};

export type OptionResults = {
  __typename: 'OptionResults';
  results?: Array<Option | null> | null;
};

export type Option = {
  __typename: 'Option';
  name_ar?: string | null;
  name_en?: string | null;
  metadata?: string | null;
  icon?: string | null;
};

export type FeatureFlag = {
  __typename: 'FeatureFlag';
  title?: string | null;
  platform?: Platform | null;
  enabled?: boolean | null;
};

export type ProfessionalGigsList = {
  __typename: 'ProfessionalGigsList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<GigService | null> | null;
};

export type GigSuggestionInput = {
  query?: string | null;
  service?: string | null;
};

export type DirectoryGigService = {
  __typename: 'DirectoryGigService';
  id: string;
  title: string;
  description: string;
  service: Service;
  price: number;
};

export type ListGigServiceInput = {
  limit?: number | null;
  offset?: number | null;
  service?: string | null;
  city?: string | null;
  section?: ManzilikModule | null;
  sort_by?: GigSorting | null;
  price_sorting?: PriceSorting | null;
};

export enum ManzilikModule {
  MANZILIK_AI = 'MANZILIK_AI',
}

export enum GigSorting {
  MOST_REQUESTED = 'MOST_REQUESTED',
}

export enum PriceSorting {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ListSimilarInput = {
  limit?: number | null;
  offset?: number | null;
  id: string;
  city_id?: string | null;
  type?: Indices | null;
};

export enum Indices {
  PROJECT = 'PROJECT',
  PROFESSIONAL = 'PROFESSIONAL',
  DISCUSSION = 'DISCUSSION',
  MAGAZINE = 'MAGAZINE',
  TV = 'TV',
  GIGSERVICES = 'GIGSERVICES',
}

export type SearchPagination = {
  limit?: number | null;
  offset?: number | null;
};

export enum SearchSortBy {
  Relevance = 'Relevance',
  MostRecent = 'MostRecent',
  MostLiked = 'MostLiked',
}

export type SearchFilterInput = {
  name?: string | null;
  values?: Array<string | null> | null;
};

export type ProjectSearchResult = {
  __typename: 'ProjectSearchResult';
  count: number;
  results: Array<Project | null>;
  filters?: Array<ProjectSearchFilterName | null> | null;
};

export type ProjectSearchFilterName = {
  __typename: 'ProjectSearchFilterName';
  title?: string | null;
  results?: Array<ProjectSearchFilterValue | null> | null;
};

export type ProjectSearchFilterValue = {
  __typename: 'ProjectSearchFilterValue';
  title?: string | null;
  count?: number | null;
};

export enum CompletionSearchBy {
  Projects = 'Projects',
}

export type CompletionResults = {
  __typename: 'CompletionResults';
  completions?: Array<string | null> | null;
  suggestions?: Array<SpillingSuggestion | null> | null;
};

export type SpillingSuggestion = {
  __typename: 'SpillingSuggestion';
  text?: string | null;
  suggest?: string | null;
};

export enum ProfessionalSearchSortBy {
  HIGHER_RATE = 'HIGHER_RATE',
  RECENTLY_JOINED = 'RECENTLY_JOINED',
  VERIFIED = 'VERIFIED',
  HIGH_SCORE = 'HIGH_SCORE',
}

export type ProfessionalSearchResult = {
  __typename: 'ProfessionalSearchResult';
  count: number;
  results: Array<Professional | null>;
};

export enum DiscussionSearchSortBy {
  VERIFIED = 'VERIFIED',
}

export type DiscussionSearchResult = {
  __typename: 'DiscussionSearchResult';
  count: number;
  results: Array<Discussion | null>;
};

export enum MagazineSearchSortBy {
  VERIFIED = 'VERIFIED',
}

export type MagazineSearchResult = {
  __typename: 'MagazineSearchResult';
  count: number;
  results: Array<Magazine | null>;
};

export type AIPresignedUrlInput = {
  content_type: string;
  file_name: string;
};

export type AIPagination = {
  limit?: number | null;
  offset?: number | null;
};

export type AIDesignObjectList = {
  __typename: 'AIDesignObjectList';
  count: number;
  results: Array<AIDesignObject | null>;
};

export type AIDesignRoomTypeList = {
  __typename: 'AIDesignRoomTypeList';
  count: number;
  results?: Array<AIDesignRoomType | null> | null;
};

export type AIDesignStyleList = {
  __typename: 'AIDesignStyleList';
  count?: number | null;
  results?: Array<AIDesignStyle | null> | null;
};

export type AIOptions = {
  __typename: 'AIOptions';
  name?: string | null;
  slug?: string | null;
  values?: Array<AIOption | null> | null;
};

export type AIOption = {
  __typename: 'AIOption';
  name?: string | null;
  slug?: string | null;
  image?: string | null;
};

export type AIDesignRating = {
  __typename: 'AIDesignRating';
  averageRating?: number | null;
  numberOfRatings?: number | null;
};

export type RequestCreditCost = {
  __typename: 'RequestCreditCost';
  value?: number | null;
};

export type ServiceInquiryRejectionReasons = {
  __typename: 'ServiceInquiryRejectionReasons';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<ServiceInquiryRejectionReason | null> | null;
};

export type ServiceInquiryRejectionReason = {
  __typename: 'ServiceInquiryRejectionReason';
  id: string;
  title?: string | null;
  is_note_required?: boolean | null;
};

export type ServiceInquiryList = {
  __typename: 'ServiceInquiryList';
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: Array<ServiceInquiry | null> | null;
};

export type ListQuotationInput = {
  limit?: number | null;
  offset?: number | null;
  services?: Array<string | null> | null;
  cities?: Array<string | null> | null;
};

export type ListRFQInput = {
  limit?: number | null;
  offset?: number | null;
  services?: Array<string | null> | null;
  cities?: Array<string | null> | null;
};

export type AddServiceInqueryMessageMutationVariables = {
  userId: string;
};

export type AddServiceInqueryMessageMutation = {
  addServiceInqueryMessage?: {
    __typename: 'ServiceInqueryMessageOutput';
    userId: string;
    has_unread_messages: boolean;
  } | null;
};

export type UpdatePaymentStatusMutationVariables = {
  checkout_id: string;
  package_id: string;
  status: PaymentStatus;
  message?: string | null;
};

export type UpdatePaymentStatusMutation = {
  updatePaymentStatus?: {
    __typename: 'PaymentStatusResponse';
    checkout_id: string;
    package_id: string;
    status: PaymentStatus;
    message?: string | null;
  } | null;
};

export type AddProjectMutationVariables = {
  input: ProjectInput;
};

export type AddProjectMutation = {
  addProject?: {
    __typename: 'Project';
    id?: string | null;
    room_type?: {
      __typename: 'RoomType';
      id: string;
      title?: string | null;
      arabic_title?: string | null;
      english_title?: string | null;
      category?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null;
    is_default?: boolean | null;
    title: string;
    status?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    visibility: string;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    discussions_count?: number | null;
    ideas_count?: number | null;
    likes_count?: number | null;
    shares_count?: number | null;
    questions_count?: number | null;
    description?: string | null;
    ideas?: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null> | null;
    ideasList?: {
      __typename: 'IdeaList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    is_liked?: boolean | null;
    collaborators?: {
      __typename: 'ClientList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null>;
    } | null;
    default_idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    entities?: {
      __typename: 'EntityList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Entity';
        created_at: string;
        updated_at: string;
        status?: string | null;
        photo?: string | null;
        description?: string | null;
        tag?: string | null;
        client_id?: string | null;
        project_id?: string | null;
        entity_id?: string | null;
        title?: string | null;
        is_main_entity?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput;
};

export type UpdateProjectMutation = {
  updateProject?: {
    __typename: 'Project';
    id?: string | null;
    room_type?: {
      __typename: 'RoomType';
      id: string;
      title?: string | null;
      arabic_title?: string | null;
      english_title?: string | null;
      category?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null;
    is_default?: boolean | null;
    title: string;
    status?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    visibility: string;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    discussions_count?: number | null;
    ideas_count?: number | null;
    likes_count?: number | null;
    shares_count?: number | null;
    questions_count?: number | null;
    description?: string | null;
    ideas?: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null> | null;
    ideasList?: {
      __typename: 'IdeaList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    is_liked?: boolean | null;
    collaborators?: {
      __typename: 'ClientList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null>;
    } | null;
    default_idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    entities?: {
      __typename: 'EntityList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Entity';
        created_at: string;
        updated_at: string;
        status?: string | null;
        photo?: string | null;
        description?: string | null;
        tag?: string | null;
        client_id?: string | null;
        project_id?: string | null;
        entity_id?: string | null;
        title?: string | null;
        is_main_entity?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export type DeleteProjectMutationVariables = {
  id: string;
};

export type DeleteProjectMutation = {
  deleteProject?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type SetProjectDefaultIdeaMutationVariables = {
  input?: setProjectDefaultIdeaInput | null;
};

export type SetProjectDefaultIdeaMutation = {
  setProjectDefaultIdea?: {
    __typename: 'Project';
    id?: string | null;
    room_type?: {
      __typename: 'RoomType';
      id: string;
      title?: string | null;
      arabic_title?: string | null;
      english_title?: string | null;
      category?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null;
    is_default?: boolean | null;
    title: string;
    status?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    visibility: string;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    discussions_count?: number | null;
    ideas_count?: number | null;
    likes_count?: number | null;
    shares_count?: number | null;
    questions_count?: number | null;
    description?: string | null;
    ideas?: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null> | null;
    ideasList?: {
      __typename: 'IdeaList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    is_liked?: boolean | null;
    collaborators?: {
      __typename: 'ClientList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null>;
    } | null;
    default_idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    entities?: {
      __typename: 'EntityList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Entity';
        created_at: string;
        updated_at: string;
        status?: string | null;
        photo?: string | null;
        description?: string | null;
        tag?: string | null;
        client_id?: string | null;
        project_id?: string | null;
        entity_id?: string | null;
        title?: string | null;
        is_main_entity?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export type SaveEntityToProjectMutationVariables = {
  input: SaveEntityInput;
};

export type SaveEntityToProjectMutation = {
  saveEntityToProject?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type MoveEntityFromProjectMutationVariables = {
  input: MoveEntityInput;
};

export type MoveEntityFromProjectMutation = {
  moveEntityFromProject?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type DeleteEntityFromProjectMutationVariables = {
  input: DeleteEntityInput;
};

export type DeleteEntityFromProjectMutation = {
  deleteEntityFromProject?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type CopyEntityToProjectMutationVariables = {
  input: CopyEntityInput;
};

export type CopyEntityToProjectMutation = {
  copyEntityToProject?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddNoteToProjectEntityMutationVariables = {
  input: AddNoteToProjectEntityInput;
};

export type AddNoteToProjectEntityMutation = {
  addNoteToProjectEntity?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type SetAsMainEntityMutationVariables = {
  input: SetAsMainEntityInput;
};

export type SetAsMainEntityMutation = {
  setAsMainEntity?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddIdeaMutationVariables = {
  input: IdeaInput;
};

export type AddIdeaMutation = {
  addIdea?: {
    __typename: 'Idea';
    id: string;
    tag?: string | null;
    created_at: string;
    updated_at: string;
    title: string;
    photo?: string | null;
    status?: string | null;
    source: string;
    description?: string | null;
    project_id?: string | null;
    project?: {
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    client_id?: string | null;
    questions_count?: number | null;
    shares_count?: number | null;
    likes_count?: number | null;
    is_liked?: boolean | null;
    is_photo_uploaded?: boolean | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    google_vision_result?: string | null;
    paint_colors?: {
      __typename: 'IdeaPaintColorsList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'IdeaPaintColor';
        color?: {
          __typename: 'PaintColor';
          id?: string | null;
          title?: string | null;
          rgb?: string | null;
        } | null;
      } | null>;
    } | null;
    ideaLabels?: {
      __typename: 'IdeaLabelList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'IdeaLabel';
        id?: string | null;
        coordinates?: Array<{
          __typename: 'Coordinates';
          x?: number | null;
          y?: number | null;
        } | null> | null;
        name?: string | null;
        related_stockrecords_count?: number | null;
      } | null>;
    } | null;
  } | null;
};

export type EditProfileMutationVariables = {
  input: EditClientProfileInput;
};

export type EditProfileMutation = {
  editProfile?: {
    __typename: 'Client';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    mobile?: string | null;
    country_code?: string | null;
    gender?: string | null;
    type?: string | null;
    about_me?: string | null;
    my_fav_style?: string | null;
    my_next_style?: string | null;
    city_id?: string | null;
    country_id?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    zip?: string | null;
    state?: string | null;
    following_count?: number | null;
    followers_count?: number | null;
    followees_count?: number | null;
    is_followed?: boolean | null;
    total_review?: number | null;
    discussions_count?: number | null;
    comments_count?: number | null;
    is_verified?: boolean | null;
    is_add_gigs_tutorial_showed?: boolean | null;
    last_login?: string | null;
    profile_image?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    blog?: string | null;
    project_role?: string | null;
    client_awards?: Array<{
      __typename: 'ClientAward';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      code?: string | null;
      image?: string | null;
    } | null> | null;
    client_badges?: Array<{
      __typename: 'ClientBadge';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      key?: string | null;
      title?: string | null;
      value?: number | null;
      image?: string | null;
      description?: string | null;
      client_progress?: number | null;
      is_claimed?: boolean | null;
    } | null> | null;
    projects?: {
      __typename: 'ProjectList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    addresses?: {
      __typename: 'ClientAddressList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ClientAddress';
        id?: string | null;
        name?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        neighborhood?: {
          __typename: 'Neighborhood';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          city_id?: string | null;
        } | null;
        description?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string | null;
        is_default?: boolean | null;
      } | null>;
    } | null;
    bankcards?: {
      __typename: 'ClientBankCardList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BankCard';
        id?: string | null;
        card_type?: string | null;
        number?: string | null;
        name?: string | null;
        expiry_date?: string | null;
        partner_reference?: string | null;
        payment_method?: {
          __typename: 'PaymentMethod';
          id?: number | null;
          name?: string | null;
          logo?: string | null;
          brand?: string | null;
        } | null;
      } | null> | null;
    } | null;
    related_professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    sendbird_access_token?: string | null;
    apns_token?: string | null;
    balance?: number | null;
    is_purchased?: boolean | null;
    referral_url?: string | null;
  } | null;
};

export type UpdateNotificationSettingsMutationVariables = {
  input: NotificationSettingsInput;
};

export type UpdateNotificationSettingsMutation = {
  updateNotificationSettings?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type InviteCollaboratorsMutationVariables = {
  input: InviteCollaboratorsInput;
};

export type InviteCollaboratorsMutation = {
  inviteCollaborators?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type DeleteCollaboratorsMutationVariables = {
  input: DeleteCollaboratorsInput;
};

export type DeleteCollaboratorsMutation = {
  deleteCollaborators?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type CompleteProfessionalProfileMutationVariables = {
  input: CompleteProfessionalProfileInput;
};

export type CompleteProfessionalProfileMutation = {
  completeProfessionalProfile?: {
    __typename: 'Professional';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    address?: string | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    } | null> | null;
    locations?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
    is_profile_completed?: boolean | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    gallery_photos?: Array<string | null> | null;
    photos?: {
      __typename: 'GalleryPhotoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'GalleryPhoto';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null>;
    } | null;
    is_reviewable?: boolean | null;
    projects_count?: number | null;
    videos?: {
      __typename: 'VideoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Video';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        video?: string | null;
      } | null>;
    } | null;
    contacts?: {
      __typename: 'ContactInfoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ContactInfo';
        contact_type?: ContactType | null;
        value?: string | null;
      } | null>;
    } | null;
    professional_type?: ProfessionalType | null;
    company_name?: string | null;
    company_logo?: string | null;
    company_registration_ref?: string | null;
    personal_freelance_license?: string | null;
    is_verified?: boolean | null;
    is_gig_professional?: boolean | null;
    gigs_count?: number | null;
    score?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UploadProfessionalPhotosMutationVariables = {
  input: UploadProfessionalPhotosInput;
};

export type UploadProfessionalPhotosMutation = {
  uploadProfessionalPhotos?: {
    __typename: 'Professional';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    address?: string | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    } | null> | null;
    locations?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
    is_profile_completed?: boolean | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    gallery_photos?: Array<string | null> | null;
    photos?: {
      __typename: 'GalleryPhotoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'GalleryPhoto';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null>;
    } | null;
    is_reviewable?: boolean | null;
    projects_count?: number | null;
    videos?: {
      __typename: 'VideoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Video';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        video?: string | null;
      } | null>;
    } | null;
    contacts?: {
      __typename: 'ContactInfoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ContactInfo';
        contact_type?: ContactType | null;
        value?: string | null;
      } | null>;
    } | null;
    professional_type?: ProfessionalType | null;
    company_name?: string | null;
    company_logo?: string | null;
    company_registration_ref?: string | null;
    personal_freelance_license?: string | null;
    is_verified?: boolean | null;
    is_gig_professional?: boolean | null;
    gigs_count?: number | null;
    score?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UploadProfessionalVideosMutationVariables = {
  input: UploadProfessionalVideosInput;
};

export type UploadProfessionalVideosMutation = {
  uploadProfessionalVideos?: {
    __typename: 'Professional';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    address?: string | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    } | null> | null;
    locations?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
    is_profile_completed?: boolean | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    gallery_photos?: Array<string | null> | null;
    photos?: {
      __typename: 'GalleryPhotoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'GalleryPhoto';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null>;
    } | null;
    is_reviewable?: boolean | null;
    projects_count?: number | null;
    videos?: {
      __typename: 'VideoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Video';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        video?: string | null;
      } | null>;
    } | null;
    contacts?: {
      __typename: 'ContactInfoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ContactInfo';
        contact_type?: ContactType | null;
        value?: string | null;
      } | null>;
    } | null;
    professional_type?: ProfessionalType | null;
    company_name?: string | null;
    company_logo?: string | null;
    company_registration_ref?: string | null;
    personal_freelance_license?: string | null;
    is_verified?: boolean | null;
    is_gig_professional?: boolean | null;
    gigs_count?: number | null;
    score?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteProfessionalGalleryPhotoMutationVariables = {
  id: string;
};

export type DeleteProfessionalGalleryPhotoMutation = {
  deleteProfessionalGalleryPhoto?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type DeleteProfessionalVideoMutationVariables = {
  id: string;
};

export type DeleteProfessionalVideoMutation = {
  deleteProfessionalVideo?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type FollowClientMutationVariables = {
  input?: FollowUserInput | null;
};

export type FollowClientMutation = {
  followClient?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type UnfollowClientMutationVariables = {
  input?: FollowUserInput | null;
};

export type UnfollowClientMutation = {
  unfollowClient?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type UpdateFcmTokenMutationVariables = {
  input?: UpdateFcmTokenInput | null;
};

export type UpdateFcmTokenMutation = {
  updateFcmToken?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type CompleteRegistrationMutationVariables = {
  input: CompleteRegistrationInput;
};

export type CompleteRegistrationMutation = {
  completeRegistration?: {
    __typename: 'Client';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    mobile?: string | null;
    country_code?: string | null;
    gender?: string | null;
    type?: string | null;
    about_me?: string | null;
    my_fav_style?: string | null;
    my_next_style?: string | null;
    city_id?: string | null;
    country_id?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    zip?: string | null;
    state?: string | null;
    following_count?: number | null;
    followers_count?: number | null;
    followees_count?: number | null;
    is_followed?: boolean | null;
    total_review?: number | null;
    discussions_count?: number | null;
    comments_count?: number | null;
    is_verified?: boolean | null;
    is_add_gigs_tutorial_showed?: boolean | null;
    last_login?: string | null;
    profile_image?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    blog?: string | null;
    project_role?: string | null;
    client_awards?: Array<{
      __typename: 'ClientAward';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      code?: string | null;
      image?: string | null;
    } | null> | null;
    client_badges?: Array<{
      __typename: 'ClientBadge';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      key?: string | null;
      title?: string | null;
      value?: number | null;
      image?: string | null;
      description?: string | null;
      client_progress?: number | null;
      is_claimed?: boolean | null;
    } | null> | null;
    projects?: {
      __typename: 'ProjectList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    addresses?: {
      __typename: 'ClientAddressList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ClientAddress';
        id?: string | null;
        name?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        neighborhood?: {
          __typename: 'Neighborhood';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          city_id?: string | null;
        } | null;
        description?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string | null;
        is_default?: boolean | null;
      } | null>;
    } | null;
    bankcards?: {
      __typename: 'ClientBankCardList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BankCard';
        id?: string | null;
        card_type?: string | null;
        number?: string | null;
        name?: string | null;
        expiry_date?: string | null;
        partner_reference?: string | null;
        payment_method?: {
          __typename: 'PaymentMethod';
          id?: number | null;
          name?: string | null;
          logo?: string | null;
          brand?: string | null;
        } | null;
      } | null> | null;
    } | null;
    related_professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    sendbird_access_token?: string | null;
    apns_token?: string | null;
    balance?: number | null;
    is_purchased?: boolean | null;
    referral_url?: string | null;
  } | null;
};

export type ShowAddGigsTutorialMutation = {
  showAddGigsTutorial?: {
    __typename: 'Client';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    mobile?: string | null;
    country_code?: string | null;
    gender?: string | null;
    type?: string | null;
    about_me?: string | null;
    my_fav_style?: string | null;
    my_next_style?: string | null;
    city_id?: string | null;
    country_id?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    zip?: string | null;
    state?: string | null;
    following_count?: number | null;
    followers_count?: number | null;
    followees_count?: number | null;
    is_followed?: boolean | null;
    total_review?: number | null;
    discussions_count?: number | null;
    comments_count?: number | null;
    is_verified?: boolean | null;
    is_add_gigs_tutorial_showed?: boolean | null;
    last_login?: string | null;
    profile_image?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    blog?: string | null;
    project_role?: string | null;
    client_awards?: Array<{
      __typename: 'ClientAward';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      code?: string | null;
      image?: string | null;
    } | null> | null;
    client_badges?: Array<{
      __typename: 'ClientBadge';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      key?: string | null;
      title?: string | null;
      value?: number | null;
      image?: string | null;
      description?: string | null;
      client_progress?: number | null;
      is_claimed?: boolean | null;
    } | null> | null;
    projects?: {
      __typename: 'ProjectList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    addresses?: {
      __typename: 'ClientAddressList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ClientAddress';
        id?: string | null;
        name?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        neighborhood?: {
          __typename: 'Neighborhood';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          city_id?: string | null;
        } | null;
        description?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string | null;
        is_default?: boolean | null;
      } | null>;
    } | null;
    bankcards?: {
      __typename: 'ClientBankCardList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BankCard';
        id?: string | null;
        card_type?: string | null;
        number?: string | null;
        name?: string | null;
        expiry_date?: string | null;
        partner_reference?: string | null;
        payment_method?: {
          __typename: 'PaymentMethod';
          id?: number | null;
          name?: string | null;
          logo?: string | null;
          brand?: string | null;
        } | null;
      } | null> | null;
    } | null;
    related_professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    sendbird_access_token?: string | null;
    apns_token?: string | null;
    balance?: number | null;
    is_purchased?: boolean | null;
    referral_url?: string | null;
  } | null;
};

export type RequestAccountDeletionMutation = {
  requestAccountDeletion?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddQuestionMutationVariables = {
  input: QuestionInput;
};

export type AddQuestionMutation = {
  addQuestion?: {
    __typename: 'Question';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    title?: string | null;
    description?: string | null;
    idea_id?: string | null;
    idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    likes_count?: number | null;
    comments_count?: number | null;
    is_liked?: boolean | null;
  } | null;
};

export type AddQuestionToProjectMutationVariables = {
  input: QuestionToProjectInput;
};

export type AddQuestionToProjectMutation = {
  addQuestionToProject?: {
    __typename: 'Question';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    title?: string | null;
    description?: string | null;
    idea_id?: string | null;
    idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    likes_count?: number | null;
    comments_count?: number | null;
    is_liked?: boolean | null;
  } | null;
};

export type AddCommentMutationVariables = {
  input: CommentInput;
};

export type AddCommentMutation = {
  addComment?: {
    __typename: 'Comment';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    content?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    client_id?: string | null;
    likes_count?: number | null;
    is_liked?: boolean | null;
  } | null;
};

export type UpdateCommentMutationVariables = {
  input: CommentInput;
};

export type UpdateCommentMutation = {
  updateComment?: {
    __typename: 'Comment';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    content?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    client_id?: string | null;
    likes_count?: number | null;
    is_liked?: boolean | null;
  } | null;
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput;
};

export type DeleteCommentMutation = {
  deleteComment?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type ShareEntityMutationVariables = {
  input: ShareInput;
};

export type ShareEntityMutation = {
  shareEntity?: {
    __typename: 'Share';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
  } | null;
};

export type LikeEntityMutationVariables = {
  input: LikeInput;
};

export type LikeEntityMutation = {
  likeEntity?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type ReviewEntityMutationVariables = {
  input: ReviewInput;
};

export type ReviewEntityMutation = {
  reviewEntity?: {
    __typename: 'Review';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    content?: string | null;
    rating?: number | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
  } | null;
};

export type UpdateReviewEntityMutationVariables = {
  input: ReviewInput;
};

export type UpdateReviewEntityMutation = {
  updateReviewEntity?: {
    __typename: 'Review';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    content?: string | null;
    rating?: number | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
  } | null;
};

export type ReportSpamMutationVariables = {
  input: ReportSpamInput;
};

export type ReportSpamMutation = {
  reportSpam?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddDiscussionMutationVariables = {
  input: DiscussionInput;
};

export type AddDiscussionMutation = {
  addDiscussion?: {
    __typename: 'Discussion';
    id: string;
    tag?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    title?: string | null;
    description?: string | null;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    youtube_url?: string | null;
    photo?: string | null;
    web_url?: string | null;
    likes_count?: number | null;
    comments_count?: number | null;
    topics?: Array<{
      __typename: 'Topic';
      id: string;
      paren_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      is_interested?: boolean | null;
    } | null> | null;
    polls?: Array<{
      __typename: 'Poll';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      photo_url?: string | null;
    } | null> | null;
    is_liked?: boolean | null;
    latest_two_commenters?: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteDiscussionMutationVariables = {
  id: string;
};

export type DeleteDiscussionMutation = {
  deleteDiscussion?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type SaveInterestedTopicMutationVariables = {
  input: SaveInterestedTopicInput;
};

export type SaveInterestedTopicMutation = {
  saveInterestedTopic?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type ContactMeMutationVariables = {
  input: ContactMeInput;
};

export type ContactMeMutation = {
  contactMe?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type ContactUsMutationVariables = {
  input: ContactUsInput;
};

export type ContactUsMutation = {
  contactUs?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type SendEmailMutationVariables = {
  input: SendEmailInput;
};

export type SendEmailMutation = {
  sendEmail?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddProductReviewMutationVariables = {
  input: ProductReviewInput;
};

export type AddProductReviewMutation = {
  addProductReview?: {
    __typename: 'ProductReview';
    id?: string | null;
    stockrecord?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    body?: string | null;
    general_score?: number | null;
    client_bought_product?: boolean | null;
    created_at?: string | null;
    status?: string | null;
    detailed_review_scores?: Array<{
      __typename: 'DetailedReviewScore';
      id: string;
      title?: string | null;
      status?: string | null;
      score?: number | null;
      detailed_score?: {
        __typename: 'DetailedScore';
        id: string;
        title: string;
        status?: string | null;
      } | null;
    } | null> | null;
    review_images?: Array<{
      __typename: 'ReviewImage';
      id?: string | null;
      image?: string | null;
      status?: string | null;
    } | null> | null;
    likes_count?: number | null;
    is_liked?: boolean | null;
  } | null;
};

export type UpdateProductReviewMutationVariables = {
  input: ProductReviewInput;
};

export type UpdateProductReviewMutation = {
  updateProductReview?: {
    __typename: 'ProductReview';
    id?: string | null;
    stockrecord?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    body?: string | null;
    general_score?: number | null;
    client_bought_product?: boolean | null;
    created_at?: string | null;
    status?: string | null;
    detailed_review_scores?: Array<{
      __typename: 'DetailedReviewScore';
      id: string;
      title?: string | null;
      status?: string | null;
      score?: number | null;
      detailed_score?: {
        __typename: 'DetailedScore';
        id: string;
        title: string;
        status?: string | null;
      } | null;
    } | null> | null;
    review_images?: Array<{
      __typename: 'ReviewImage';
      id?: string | null;
      image?: string | null;
      status?: string | null;
    } | null> | null;
    likes_count?: number | null;
    is_liked?: boolean | null;
  } | null;
};

export type DeleteProductReviewMutationVariables = {
  id: string;
};

export type DeleteProductReviewMutation = {
  deleteProductReview?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddAddressMutationVariables = {
  input: ClientAddressInput;
};

export type AddAddressMutation = {
  addAddress?: {
    __typename: 'ClientAddress';
    id?: string | null;
    name?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    neighborhood?: {
      __typename: 'Neighborhood';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      city_id?: string | null;
    } | null;
    description?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    phone_number?: string | null;
    email?: string | null;
    is_default?: boolean | null;
  } | null;
};

export type UpdateAddressMutationVariables = {
  input: ClientAddressInput;
};

export type UpdateAddressMutation = {
  updateAddress?: {
    __typename: 'ClientAddress';
    id?: string | null;
    name?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    neighborhood?: {
      __typename: 'Neighborhood';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      city_id?: string | null;
    } | null;
    description?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    phone_number?: string | null;
    email?: string | null;
    is_default?: boolean | null;
  } | null;
};

export type DeleteAddressMutationVariables = {
  id: string;
};

export type DeleteAddressMutation = {
  deleteAddress?: {
    __typename: 'ClientAddressList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'ClientAddress';
      id?: string | null;
      name?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      neighborhood?: {
        __typename: 'Neighborhood';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        city_id?: string | null;
      } | null;
      description?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      phone_number?: string | null;
      email?: string | null;
      is_default?: boolean | null;
    } | null>;
  } | null;
};

export type AddProductToBasketMutationVariables = {
  input: AddProductToBasketInput;
};

export type AddProductToBasketMutation = {
  addProductToBasket?: {
    __typename: 'BasketLine';
    id?: number | null;
    product?: {
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null;
    quantity?: number | null;
    stockrecord_id?: number | null;
    basket_id?: number | null;
    price_currency?: string | null;
    price_excl_tax?: string | null;
    price_incl_tax?: string | null;
    price_incl_tax_excl_discounts?: string | null;
    price_excl_tax_excl_discounts?: string | null;
    original_price_incl_tax?: string | null;
    original_price_excl_tax?: string | null;
    stockrecord?: {
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteProductLineFromBasketMutationVariables = {
  input?: BasketLineInput | null;
};

export type DeleteProductLineFromBasketMutation = {
  deleteProductLineFromBasket?: string | null;
};

export type EditBasketProductLineQuantityMutationVariables = {
  input?: BasketLineInput | null;
};

export type EditBasketProductLineQuantityMutation = {
  editBasketProductLineQuantity?: {
    __typename: 'BasketLine';
    id?: number | null;
    product?: {
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null;
    quantity?: number | null;
    stockrecord_id?: number | null;
    basket_id?: number | null;
    price_currency?: string | null;
    price_excl_tax?: string | null;
    price_incl_tax?: string | null;
    price_incl_tax_excl_discounts?: string | null;
    price_excl_tax_excl_discounts?: string | null;
    original_price_incl_tax?: string | null;
    original_price_excl_tax?: string | null;
    stockrecord?: {
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CheckoutMutationVariables = {
  input?: CheckoutInput | null;
};

export type CheckoutMutation = {
  checkout?: {
    __typename: 'OrderDetails';
    id?: number | null;
    number?: string | null;
    currency?: string | null;
    total_incl_tax?: string | null;
    total_excl_tax?: string | null;
    total_discount_incl_tax?: number | null;
    total_discount_excl_tax?: number | null;
    shipping_incl_tax?: string | null;
    shipping_excl_tax?: string | null;
    status?: string | null;
    date_placed?: string | null;
    shipping_address?: {
      __typename: 'ClientAddress';
      id?: string | null;
      name?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      neighborhood?: {
        __typename: 'Neighborhood';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        city_id?: string | null;
      } | null;
      description?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      phone_number?: string | null;
      email?: string | null;
      is_default?: boolean | null;
    } | null;
    num_items?: number | null;
    products_price?: number | null;
    total_tax?: number | null;
    discount_price?: number | null;
    discount_amount?: number | null;
    payment_method?: {
      __typename: 'PaymentMethod';
      id?: number | null;
      name?: string | null;
      logo?: string | null;
      brand?: string | null;
    } | null;
    payment_card?: {
      __typename: 'BankCard';
      id?: string | null;
      card_type?: string | null;
      number?: string | null;
      name?: string | null;
      expiry_date?: string | null;
      partner_reference?: string | null;
      payment_method?: {
        __typename: 'PaymentMethod';
        id?: number | null;
        name?: string | null;
        logo?: string | null;
        brand?: string | null;
      } | null;
    } | null;
    lines?: {
      __typename: 'OrderLinesList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'OrderLine';
        id?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        quantity?: number | null;
        stockrecord?: {
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null;
        price_currency?: string | null;
        price_excl_tax?: string | null;
        price_incl_tax?: string | null;
        price_incl_tax_excl_discounts?: string | null;
        price_excl_tax_excl_discounts?: string | null;
        original_price_incl_tax?: string | null;
        original_price_excl_tax?: string | null;
        status?: string | null;
        product_review?: {
          __typename: 'ProductReview';
          id?: string | null;
          stockrecord?: string | null;
          body?: string | null;
          general_score?: number | null;
          client_bought_product?: boolean | null;
          created_at?: string | null;
          status?: string | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CancelOrderMutationVariables = {
  input?: CancelOrderInput | null;
};

export type CancelOrderMutation = {
  cancelOrder?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddBankCardMutationVariables = {
  input: BankCardInput;
};

export type AddBankCardMutation = {
  addBankCard?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type UpdateBankCardMutationVariables = {
  input: BankCardInput;
};

export type UpdateBankCardMutation = {
  updateBankCard?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type DeleteBankCardMutationVariables = {
  id: string;
};

export type DeleteBankCardMutation = {
  deleteBankCard?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddPartnerMutationVariables = {
  input: PartnerInput;
};

export type AddPartnerMutation = {
  addPartner?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type GeneratePaymentCheckoutIdForOrderInfoMutationVariables = {
  input?: PaymentCheckoutIdForOrderInfoInput | null;
};

export type GeneratePaymentCheckoutIdForOrderInfoMutation = {
  generatePaymentCheckoutIdForOrderInfo?: {
    __typename: 'PaymentCheckoutIdForOrderInfoOutput';
    result?: {
      __typename: 'PaymentCheckoutIdResultObject';
      code?: string | null;
      description?: string | null;
    } | null;
    order_number?: string | null;
    checkout_id?: string | null;
    id?: string | null;
    updated_basket?: {
      __typename: 'Basket';
      id?: number | null;
      status?: string | null;
      total_excl_tax?: string | null;
      total_excl_tax_excl_discounts?: string | null;
      total_incl_tax?: string | null;
      total_incl_tax_excl_discounts?: string | null;
      total_original_excl_tax?: string | null;
      total_original_incl_tax?: string | null;
      total_quantity?: string | null;
      total_tax?: string | null;
      currency?: string | null;
      discount_price?: number | null;
      discount_amount?: number | null;
      lines?: Array<{
        __typename: 'BasketLine';
        id?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        quantity?: number | null;
        stockrecord_id?: number | null;
        basket_id?: number | null;
        price_currency?: string | null;
        price_excl_tax?: string | null;
        price_incl_tax?: string | null;
        price_incl_tax_excl_discounts?: string | null;
        price_excl_tax_excl_discounts?: string | null;
        original_price_incl_tax?: string | null;
        original_price_excl_tax?: string | null;
        stockrecord?: {
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type RefundOrderLineMutationVariables = {
  input?: InputRefundOrderLine | null;
};

export type RefundOrderLineMutation = {
  refundOrderLine?: {
    __typename: 'OrderLine';
    id?: number | null;
    product?: {
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null;
    quantity?: number | null;
    stockrecord?: {
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null;
    price_currency?: string | null;
    price_excl_tax?: string | null;
    price_incl_tax?: string | null;
    price_incl_tax_excl_discounts?: string | null;
    price_excl_tax_excl_discounts?: string | null;
    original_price_incl_tax?: string | null;
    original_price_excl_tax?: string | null;
    status?: string | null;
    product_review?: {
      __typename: 'ProductReview';
      id?: string | null;
      stockrecord?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      body?: string | null;
      general_score?: number | null;
      client_bought_product?: boolean | null;
      created_at?: string | null;
      status?: string | null;
      detailed_review_scores?: Array<{
        __typename: 'DetailedReviewScore';
        id: string;
        title?: string | null;
        status?: string | null;
        score?: number | null;
        detailed_score?: {
          __typename: 'DetailedScore';
          id: string;
          title: string;
          status?: string | null;
        } | null;
      } | null> | null;
      review_images?: Array<{
        __typename: 'ReviewImage';
        id?: string | null;
        image?: string | null;
        status?: string | null;
      } | null> | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
    } | null;
  } | null;
};

export type MarkNotificationAsReadMutationVariables = {
  input?: MarkNotificationAsReadInput | null;
};

export type MarkNotificationAsReadMutation = {
  markNotificationAsRead?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type AddGigServiceMutationVariables = {
  input?: GigServiceInput | null;
};

export type AddGigServiceMutation = {
  addGigService?: {
    __typename: 'GigService';
    id: string;
    title: string;
    description?: string | null;
    services: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    }>;
    cities: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    }>;
    price: number;
    photos: Array<string>;
    is_enabled: boolean;
    professional: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    };
  } | null;
};

export type EditGigServiceMutationVariables = {
  input?: GigServiceInput | null;
};

export type EditGigServiceMutation = {
  editGigService?: {
    __typename: 'GigService';
    id: string;
    title: string;
    description?: string | null;
    services: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    }>;
    cities: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    }>;
    price: number;
    photos: Array<string>;
    is_enabled: boolean;
    professional: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    };
  } | null;
};

export type GeneratePaymentCheckoutIdForManzilikAiMutationVariables = {
  input?: PaymentCheckoutIdForManzilikAiInput | null;
};

export type GeneratePaymentCheckoutIdForManzilikAiMutation = {
  generatePaymentCheckoutIdForManzilikAi?: {
    __typename: 'PaymentCheckoutIdForManzilikAiOutput';
    checkout_id?: string | null;
  } | null;
};

export type CreateSendBirdChatGroupMutationVariables = {
  input: SendBirdChatGroupInput;
};

export type CreateSendBirdChatGroupMutation = {
  createSendBirdChatGroup?: {
    __typename: 'ChatGroup';
    sendbird_access_token?: string | null;
    chat_url?: string | null;
    chat_name?: string | null;
  } | null;
};

export type GenerateAIDesignMutationVariables = {
  input: GenerateAIDesignInput;
};

export type GenerateAIDesignMutation = {
  generateAIDesign?: {
    __typename: 'AIDesignObject';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    sourceImageUrl: string;
    selectedImageIndex?: number | null;
    processedImagesPath?: Array<string | null> | null;
    visibility: string;
    processingType: string;
    roomType?: {
      __typename: 'AIDesignRoomType';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null;
    style?: {
      __typename: 'AIDesignStyle';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null;
    status?: AIDesignStatus | null;
  } | null;
};

export type CancelAIDesignGenerationMutationVariables = {
  id: string;
};

export type CancelAIDesignGenerationMutation = {
  cancelAIDesignGeneration?: {
    __typename: 'CancelAIDesignResponse';
    message?: string | null;
  } | null;
};

export type SelectAIDesignImageMutationVariables = {
  id: string;
  index: number;
};

export type SelectAIDesignImageMutation = {
  selectAIDesignImage?: {
    __typename: 'AIDesignObject';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    sourceImageUrl: string;
    selectedImageIndex?: number | null;
    processedImagesPath?: Array<string | null> | null;
    visibility: string;
    processingType: string;
    roomType?: {
      __typename: 'AIDesignRoomType';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null;
    style?: {
      __typename: 'AIDesignStyle';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null;
    status?: AIDesignStatus | null;
  } | null;
};

export type RateAIDesignMutationVariables = {
  input: RateAIDesignInput;
};

export type RateAIDesignMutation = {
  rateAIDesign?: {
    __typename: 'SucessAIResponse';
    message?: string | null;
  } | null;
};

export type NotifyUserOnRecommendedImagesMutationVariables = {
  outstandingId: string;
  recommendedImages?: Array<string | null> | null;
  error?: string | null;
};

export type NotifyUserOnRecommendedImagesMutation = {
  notifyUserOnRecommendedImages?: {
    __typename: 'ImageNotificationResponse';
    outstandingId?: string | null;
    recommendedImages?: Array<string | null> | null;
    error?: string | null;
  } | null;
};

export type SubmitServiceInquiryMutationVariables = {
  input?: ServiceInquiryInput | null;
};

export type SubmitServiceInquiryMutation = {
  submitServiceInquiry?: {
    __typename: 'ServiceInquiry';
    id?: string | null;
    number?: string | null;
    status?: ServiceInquiryStatus | null;
    created_at?: string | null;
    sender?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    categories?: {
      __typename: 'CategoryList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null>;
    } | null;
    services?: {
      __typename: 'ServiceList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null>;
    } | null;
    description?: string | null;
    budget_limits?: string | null;
    phone_number?: string | null;
    whatsapp_number?: string | null;
    photos?: {
      __typename: 'ServiceInquiryPhotosList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'ServiceInquiryPhoto';
        photo?: string | null;
      } | null> | null;
    } | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    gig_service_id?: string | null;
    gig_service_title?: string | null;
    gig_service_price?: number | null;
    gig_service_description?: string | null;
    sendbird_channel_name?: string | null;
    sendbird_channel_url?: string | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
    type?: ServiceInquiryType | null;
  } | null;
};

export type RespondServiceInquiryMutationVariables = {
  input?: RespondServiceInquiryInput | null;
};

export type RespondServiceInquiryMutation = {
  respondServiceInquiry?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type CompleteServiceInquiryMutationVariables = {
  input?: CompleteServiceInquiryInput | null;
};

export type CompleteServiceInquiryMutation = {
  completeServiceInquiry?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type CancelServiceInquiryMutationVariables = {
  input?: CancelServiceInquiryInput | null;
};

export type CancelServiceInquiryMutation = {
  cancelServiceInquiry?: {
    __typename: 'ResultOutput';
    message: string;
    status?: string | null;
  } | null;
};

export type SendRFQMutationVariables = {
  input?: SendRFQInput | null;
};

export type SendRFQMutation = {
  sendRFQ?: {
    __typename: 'ServiceInquiry';
    id?: string | null;
    number?: string | null;
    status?: ServiceInquiryStatus | null;
    created_at?: string | null;
    sender?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    categories?: {
      __typename: 'CategoryList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null>;
    } | null;
    services?: {
      __typename: 'ServiceList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null>;
    } | null;
    description?: string | null;
    budget_limits?: string | null;
    phone_number?: string | null;
    whatsapp_number?: string | null;
    photos?: {
      __typename: 'ServiceInquiryPhotosList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'ServiceInquiryPhoto';
        photo?: string | null;
      } | null> | null;
    } | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    gig_service_id?: string | null;
    gig_service_title?: string | null;
    gig_service_price?: number | null;
    gig_service_description?: string | null;
    sendbird_channel_name?: string | null;
    sendbird_channel_url?: string | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
    type?: ServiceInquiryType | null;
  } | null;
};

export type SendQuotationMutationVariables = {
  input?: SendQuotationInput | null;
};

export type SendQuotationMutation = {
  sendQuotation?: {
    __typename: 'Quotation';
    id?: string | null;
    service_inquiry?: {
      __typename: 'ServiceInquiry';
      id?: string | null;
      number?: string | null;
      status?: ServiceInquiryStatus | null;
      created_at?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      categories?: {
        __typename: 'CategoryList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null>;
      } | null;
      services?: {
        __typename: 'ServiceList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null>;
      } | null;
      description?: string | null;
      budget_limits?: string | null;
      phone_number?: string | null;
      whatsapp_number?: string | null;
      photos?: {
        __typename: 'ServiceInquiryPhotosList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'ServiceInquiryPhoto';
          photo?: string | null;
        } | null> | null;
      } | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      gig_service_id?: string | null;
      gig_service_title?: string | null;
      gig_service_price?: number | null;
      gig_service_description?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
      type?: ServiceInquiryType | null;
    } | null;
    professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    created_at?: string | null;
    status?: QuotationStatus | null;
    budget_limits?: string | null;
    execution_time?: string | null;
    sendbird_channel_name?: string | null;
    sendbird_channel_url?: string | null;
  } | null;
};

export type AcceptQuotationMutationVariables = {
  input?: AcceptQuotationInput | null;
};

export type AcceptQuotationMutation = {
  acceptQuotation?: {
    __typename: 'Quotation';
    id?: string | null;
    service_inquiry?: {
      __typename: 'ServiceInquiry';
      id?: string | null;
      number?: string | null;
      status?: ServiceInquiryStatus | null;
      created_at?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      categories?: {
        __typename: 'CategoryList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null>;
      } | null;
      services?: {
        __typename: 'ServiceList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null>;
      } | null;
      description?: string | null;
      budget_limits?: string | null;
      phone_number?: string | null;
      whatsapp_number?: string | null;
      photos?: {
        __typename: 'ServiceInquiryPhotosList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'ServiceInquiryPhoto';
          photo?: string | null;
        } | null> | null;
      } | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      gig_service_id?: string | null;
      gig_service_title?: string | null;
      gig_service_price?: number | null;
      gig_service_description?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
      type?: ServiceInquiryType | null;
    } | null;
    professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    created_at?: string | null;
    status?: QuotationStatus | null;
    budget_limits?: string | null;
    execution_time?: string | null;
    sendbird_channel_name?: string | null;
    sendbird_channel_url?: string | null;
  } | null;
};

export type ListPackagesQueryVariables = {
  input?: PaymentPagination | null;
};

export type ListPackagesQuery = {
  listPackages: {
    __typename: 'PackageList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Package';
      id: string;
      name: string;
      title?: string | null;
      credit_amount?: number | null;
      price?: number | null;
      discount?: number | null;
    } | null>;
  };
};

export type ListCategoriesQueryVariables = {
  input?: Pagination | null;
};

export type ListCategoriesQuery = {
  listCategories?: {
    __typename: 'CategoryList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null>;
  } | null;
};

export type ListCategoriesWithSubCategoriesQuery = {
  listCategoriesWithSubCategories?: {
    __typename: 'CategoryWithSubCategoryResult';
    result?: Array<{
      __typename: 'CategoryWithSubCategory';
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      children?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetCategoryQueryVariables = {
  id: string;
};

export type GetCategoryQuery = {
  getCategory?: {
    __typename: 'Category';
    id: string;
    title?: string | null;
    english_title?: string | null;
    description?: string | null;
    parent?: {
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null;
    photo?: string | null;
    status?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    filters?: string | null;
  } | null;
};

export type ListRoomTypesQueryVariables = {
  input?: Pagination | null;
};

export type ListRoomTypesQuery = {
  listRoomTypes: {
    __typename: 'RoomTypeList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'RoomType';
      id: string;
      title?: string | null;
      arabic_title?: string | null;
      english_title?: string | null;
      category?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null>;
  };
};

export type GetProjectQueryVariables = {
  id: string;
};

export type GetProjectQuery = {
  getProject: {
    __typename: 'Project';
    id?: string | null;
    room_type?: {
      __typename: 'RoomType';
      id: string;
      title?: string | null;
      arabic_title?: string | null;
      english_title?: string | null;
      category?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null;
    is_default?: boolean | null;
    title: string;
    status?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    visibility: string;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    discussions_count?: number | null;
    ideas_count?: number | null;
    likes_count?: number | null;
    shares_count?: number | null;
    questions_count?: number | null;
    description?: string | null;
    ideas?: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null> | null;
    ideasList?: {
      __typename: 'IdeaList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    is_liked?: boolean | null;
    collaborators?: {
      __typename: 'ClientList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null>;
    } | null;
    default_idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    entities?: {
      __typename: 'EntityList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Entity';
        created_at: string;
        updated_at: string;
        status?: string | null;
        photo?: string | null;
        description?: string | null;
        tag?: string | null;
        client_id?: string | null;
        project_id?: string | null;
        entity_id?: string | null;
        title?: string | null;
        is_main_entity?: boolean | null;
      } | null>;
    } | null;
  };
};

export type ListProjectsQueryVariables = {
  input?: Pagination | null;
};

export type ListProjectsQuery = {
  listProjects: {
    __typename: 'ProjectList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type ListHomePageProjectsQueryVariables = {
  input?: Pagination | null;
};

export type ListHomePageProjectsQuery = {
  listHomePageProjects: {
    __typename: 'ProjectList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type ListMyProjectsQueryVariables = {
  input?: Pagination | null;
};

export type ListMyProjectsQuery = {
  listMyProjects: {
    __typename: 'ProjectList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type ListSimilarProjectsQueryVariables = {
  input: Pagination;
};

export type ListSimilarProjectsQuery = {
  listSimilarProjects: {
    __typename: 'ProjectList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type ListProjectEntitiesQueryVariables = {
  input: Pagination;
};

export type ListProjectEntitiesQuery = {
  listProjectEntities: {
    __typename: 'EntityList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Entity';
      created_at: string;
      updated_at: string;
      status?: string | null;
      photo?: string | null;
      description?: string | null;
      tag?: string | null;
      client_id?: string | null;
      project_id?: string | null;
      entity_id?: string | null;
      title?: string | null;
      is_main_entity?: boolean | null;
    } | null>;
  };
};

export type ListProjectIdeasByIdeaIdQueryVariables = {
  input: Pagination;
};

export type ListProjectIdeasByIdeaIdQuery = {
  listProjectIdeasByIdeaId: {
    __typename: 'IdeaList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type GetIdeaQueryVariables = {
  id: string;
};

export type GetIdeaQuery = {
  getIdea?: {
    __typename: 'Idea';
    id: string;
    tag?: string | null;
    created_at: string;
    updated_at: string;
    title: string;
    photo?: string | null;
    status?: string | null;
    source: string;
    description?: string | null;
    project_id?: string | null;
    project?: {
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    client_id?: string | null;
    questions_count?: number | null;
    shares_count?: number | null;
    likes_count?: number | null;
    is_liked?: boolean | null;
    is_photo_uploaded?: boolean | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    google_vision_result?: string | null;
    paint_colors?: {
      __typename: 'IdeaPaintColorsList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'IdeaPaintColor';
        color?: {
          __typename: 'PaintColor';
          id?: string | null;
          title?: string | null;
          rgb?: string | null;
        } | null;
      } | null>;
    } | null;
    ideaLabels?: {
      __typename: 'IdeaLabelList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'IdeaLabel';
        id?: string | null;
        coordinates?: Array<{
          __typename: 'Coordinates';
          x?: number | null;
          y?: number | null;
        } | null> | null;
        name?: string | null;
        related_stockrecords_count?: number | null;
      } | null>;
    } | null;
  } | null;
};

export type ListMoreIdeasQueryVariables = {
  input: Pagination;
};

export type ListMoreIdeasQuery = {
  listMoreIdeas: {
    __typename: 'IdeaList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type GetPresignedUrlQueryVariables = {
  input: PresignedUrlInput;
};

export type GetPresignedUrlQuery = {
  getPresignedUrl: {
    __typename: 'PresignedUrl';
    result: string;
  };
};

export type ListClientIdeasByClientIdQueryVariables = {
  input: Pagination;
};

export type ListClientIdeasByClientIdQuery = {
  listClientIdeasByClientId: {
    __typename: 'IdeaList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null>;
  };
};

export type ListRelatedProfessionalsQueryVariables = {
  input: Pagination;
};

export type ListRelatedProfessionalsQuery = {
  listRelatedProfessionals: {
    __typename: 'ProfessionalList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  };
};

export type ListRelatedStockRecordsQueryVariables = {
  input: Pagination;
};

export type ListRelatedStockRecordsQuery = {
  listRelatedStockRecords: {
    __typename: 'StockRecordList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  };
};

export type GetAuthenticatedClientQuery = {
  getAuthenticatedClient?: {
    __typename: 'Client';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    mobile?: string | null;
    country_code?: string | null;
    gender?: string | null;
    type?: string | null;
    about_me?: string | null;
    my_fav_style?: string | null;
    my_next_style?: string | null;
    city_id?: string | null;
    country_id?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    zip?: string | null;
    state?: string | null;
    following_count?: number | null;
    followers_count?: number | null;
    followees_count?: number | null;
    is_followed?: boolean | null;
    total_review?: number | null;
    discussions_count?: number | null;
    comments_count?: number | null;
    is_verified?: boolean | null;
    is_add_gigs_tutorial_showed?: boolean | null;
    last_login?: string | null;
    profile_image?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    blog?: string | null;
    project_role?: string | null;
    client_awards?: Array<{
      __typename: 'ClientAward';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      code?: string | null;
      image?: string | null;
    } | null> | null;
    client_badges?: Array<{
      __typename: 'ClientBadge';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      key?: string | null;
      title?: string | null;
      value?: number | null;
      image?: string | null;
      description?: string | null;
      client_progress?: number | null;
      is_claimed?: boolean | null;
    } | null> | null;
    projects?: {
      __typename: 'ProjectList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    addresses?: {
      __typename: 'ClientAddressList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ClientAddress';
        id?: string | null;
        name?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        neighborhood?: {
          __typename: 'Neighborhood';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          city_id?: string | null;
        } | null;
        description?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string | null;
        is_default?: boolean | null;
      } | null>;
    } | null;
    bankcards?: {
      __typename: 'ClientBankCardList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BankCard';
        id?: string | null;
        card_type?: string | null;
        number?: string | null;
        name?: string | null;
        expiry_date?: string | null;
        partner_reference?: string | null;
        payment_method?: {
          __typename: 'PaymentMethod';
          id?: number | null;
          name?: string | null;
          logo?: string | null;
          brand?: string | null;
        } | null;
      } | null> | null;
    } | null;
    related_professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    sendbird_access_token?: string | null;
    apns_token?: string | null;
    balance?: number | null;
    is_purchased?: boolean | null;
    referral_url?: string | null;
  } | null;
};

export type GetClientQueryVariables = {
  id: string;
};

export type GetClientQuery = {
  getClient?: {
    __typename: 'Client';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    mobile?: string | null;
    country_code?: string | null;
    gender?: string | null;
    type?: string | null;
    about_me?: string | null;
    my_fav_style?: string | null;
    my_next_style?: string | null;
    city_id?: string | null;
    country_id?: string | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null;
    zip?: string | null;
    state?: string | null;
    following_count?: number | null;
    followers_count?: number | null;
    followees_count?: number | null;
    is_followed?: boolean | null;
    total_review?: number | null;
    discussions_count?: number | null;
    comments_count?: number | null;
    is_verified?: boolean | null;
    is_add_gigs_tutorial_showed?: boolean | null;
    last_login?: string | null;
    profile_image?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    blog?: string | null;
    project_role?: string | null;
    client_awards?: Array<{
      __typename: 'ClientAward';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      code?: string | null;
      image?: string | null;
    } | null> | null;
    client_badges?: Array<{
      __typename: 'ClientBadge';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      key?: string | null;
      title?: string | null;
      value?: number | null;
      image?: string | null;
      description?: string | null;
      client_progress?: number | null;
      is_claimed?: boolean | null;
    } | null> | null;
    projects?: {
      __typename: 'ProjectList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    addresses?: {
      __typename: 'ClientAddressList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ClientAddress';
        id?: string | null;
        name?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        neighborhood?: {
          __typename: 'Neighborhood';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          city_id?: string | null;
        } | null;
        description?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string | null;
        is_default?: boolean | null;
      } | null>;
    } | null;
    bankcards?: {
      __typename: 'ClientBankCardList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BankCard';
        id?: string | null;
        card_type?: string | null;
        number?: string | null;
        name?: string | null;
        expiry_date?: string | null;
        partner_reference?: string | null;
        payment_method?: {
          __typename: 'PaymentMethod';
          id?: number | null;
          name?: string | null;
          logo?: string | null;
          brand?: string | null;
        } | null;
      } | null> | null;
    } | null;
    related_professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    sendbird_access_token?: string | null;
    apns_token?: string | null;
    balance?: number | null;
    is_purchased?: boolean | null;
    referral_url?: string | null;
  } | null;
};

export type ListCollaboratorsByProjectQueryVariables = {
  input?: Pagination | null;
};

export type ListCollaboratorsByProjectQuery = {
  listCollaboratorsByProject?: {
    __typename: 'ClientList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null>;
  } | null;
};

export type ListSuggestedCollaboratorsQueryVariables = {
  input?: Pagination | null;
};

export type ListSuggestedCollaboratorsQuery = {
  listSuggestedCollaborators?: {
    __typename: 'ClientList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null>;
  } | null;
};

export type ListFeedsQueryVariables = {
  input?: Pagination | null;
};

export type ListFeedsQuery = {
  listFeeds: {
    __typename: 'FeedsResult';
    count: number;
    results: Array<{
      __typename: 'FeedsData';
      data?: string | null;
    } | null>;
  };
};

export type ListCitiesQueryVariables = {
  input?: Pagination | null;
};

export type ListCitiesQuery = {
  listCities?: {
    __typename: 'CityList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
  } | null;
};

export type ListLocationsQueryVariables = {
  input?: Pagination | null;
};

export type ListLocationsQuery = {
  listLocations?: {
    __typename: 'CityList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
  } | null;
};

export type ListCountriesQueryVariables = {
  input?: Pagination | null;
};

export type ListCountriesQuery = {
  listCountries?: {
    __typename: 'CountryList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Country';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      country_flag?: string | null;
      is_default?: boolean | null;
    } | null>;
  } | null;
};

export type ListNeighborhoodsQueryVariables = {
  input?: Pagination | null;
};

export type ListNeighborhoodsQuery = {
  listNeighborhoods?: {
    __typename: 'NeighborhoodList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Neighborhood';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      city_id?: string | null;
    } | null>;
  } | null;
};

export type ListCitiesByRegionQueryVariables = {
  input?: Pagination | null;
};

export type ListCitiesByRegionQuery = {
  listCitiesByRegion?: {
    __typename: 'CityList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
  } | null;
};

export type ListRegionsQueryVariables = {
  input?: Pagination | null;
};

export type ListRegionsQuery = {
  listRegions?: {
    __typename: 'RegionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'Region';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type ListQuestionsByIdeaQueryVariables = {
  input: Pagination;
};

export type ListQuestionsByIdeaQuery = {
  listQuestionsByIdea: {
    __typename: 'QuestionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Question';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      idea_id?: string | null;
      idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      likes_count?: number | null;
      comments_count?: number | null;
      is_liked?: boolean | null;
    } | null>;
  };
};

export type ListTopicsQueryVariables = {
  input?: Pagination | null;
};

export type ListTopicsQuery = {
  listTopics: {
    __typename: 'TopicList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Topic';
      id: string;
      paren_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      is_interested?: boolean | null;
    } | null>;
  };
};

export type ListMainTopicsQueryVariables = {
  input?: Pagination | null;
};

export type ListMainTopicsQuery = {
  listMainTopics: {
    __typename: 'TopicList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Topic';
      id: string;
      paren_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      is_interested?: boolean | null;
    } | null>;
  };
};

export type ListTopicsInterestedFlagByClientQueryVariables = {
  input?: Pagination | null;
};

export type ListTopicsInterestedFlagByClientQuery = {
  listTopicsInterestedFlagByClient: {
    __typename: 'TopicList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Topic';
      id: string;
      paren_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      is_interested?: boolean | null;
    } | null>;
  };
};

export type ListInterestedTopicsByClientQueryVariables = {
  input?: Pagination | null;
};

export type ListInterestedTopicsByClientQuery = {
  listInterestedTopicsByClient: {
    __typename: 'TopicList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Topic';
      id: string;
      paren_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      is_interested?: boolean | null;
    } | null>;
  };
};

export type GetDiscussionQueryVariables = {
  id: string;
};

export type GetDiscussionQuery = {
  getDiscussion: {
    __typename: 'Discussion';
    id: string;
    tag?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    status?: string | null;
    title?: string | null;
    description?: string | null;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    youtube_url?: string | null;
    photo?: string | null;
    web_url?: string | null;
    likes_count?: number | null;
    comments_count?: number | null;
    topics?: Array<{
      __typename: 'Topic';
      id: string;
      paren_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      is_interested?: boolean | null;
    } | null> | null;
    polls?: Array<{
      __typename: 'Poll';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      photo_url?: string | null;
    } | null> | null;
    is_liked?: boolean | null;
    latest_two_commenters?: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null> | null;
  };
};

export type ListDiscussionsQueryVariables = {
  input?: Pagination | null;
};

export type ListDiscussionsQuery = {
  listDiscussions: {
    __typename: 'DiscussionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Discussion';
      id: string;
      tag?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      youtube_url?: string | null;
      photo?: string | null;
      web_url?: string | null;
      likes_count?: number | null;
      comments_count?: number | null;
      topics?: Array<{
        __typename: 'Topic';
        id: string;
        paren_id?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        is_interested?: boolean | null;
      } | null> | null;
      polls?: Array<{
        __typename: 'Poll';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        photo_url?: string | null;
      } | null> | null;
      is_liked?: boolean | null;
      latest_two_commenters?: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type ListMostRecentDiscussionsQueryVariables = {
  input?: Pagination | null;
};

export type ListMostRecentDiscussionsQuery = {
  listMostRecentDiscussions: {
    __typename: 'DiscussionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Discussion';
      id: string;
      tag?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      youtube_url?: string | null;
      photo?: string | null;
      web_url?: string | null;
      likes_count?: number | null;
      comments_count?: number | null;
      topics?: Array<{
        __typename: 'Topic';
        id: string;
        paren_id?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        is_interested?: boolean | null;
      } | null> | null;
      polls?: Array<{
        __typename: 'Poll';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        photo_url?: string | null;
      } | null> | null;
      is_liked?: boolean | null;
      latest_two_commenters?: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type ListSameTopicsDiscussionsQueryVariables = {
  input: Pagination;
};

export type ListSameTopicsDiscussionsQuery = {
  listSameTopicsDiscussions: {
    __typename: 'DiscussionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Discussion';
      id: string;
      tag?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      youtube_url?: string | null;
      photo?: string | null;
      web_url?: string | null;
      likes_count?: number | null;
      comments_count?: number | null;
      topics?: Array<{
        __typename: 'Topic';
        id: string;
        paren_id?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        is_interested?: boolean | null;
      } | null> | null;
      polls?: Array<{
        __typename: 'Poll';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        photo_url?: string | null;
      } | null> | null;
      is_liked?: boolean | null;
      latest_two_commenters?: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type ListUserDiscussionsQueryVariables = {
  input: Pagination;
};

export type ListUserDiscussionsQuery = {
  listUserDiscussions: {
    __typename: 'DiscussionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Discussion';
      id: string;
      tag?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      youtube_url?: string | null;
      photo?: string | null;
      web_url?: string | null;
      likes_count?: number | null;
      comments_count?: number | null;
      topics?: Array<{
        __typename: 'Topic';
        id: string;
        paren_id?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        is_interested?: boolean | null;
      } | null> | null;
      polls?: Array<{
        __typename: 'Poll';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        photo_url?: string | null;
      } | null> | null;
      is_liked?: boolean | null;
      latest_two_commenters?: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type ListDiscussionsWhereUserInteractedQueryVariables = {
  input: Pagination;
};

export type ListDiscussionsWhereUserInteractedQuery = {
  listDiscussionsWhereUserInteracted: {
    __typename: 'DiscussionList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Discussion';
      id: string;
      tag?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      youtube_url?: string | null;
      photo?: string | null;
      web_url?: string | null;
      likes_count?: number | null;
      comments_count?: number | null;
      topics?: Array<{
        __typename: 'Topic';
        id: string;
        paren_id?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        is_interested?: boolean | null;
      } | null> | null;
      polls?: Array<{
        __typename: 'Poll';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        photo_url?: string | null;
      } | null> | null;
      is_liked?: boolean | null;
      latest_two_commenters?: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type ListTopCommentersQueryVariables = {
  input?: Pagination | null;
};

export type ListTopCommentersQuery = {
  listTopCommenters: {
    __typename: 'ClientList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null>;
  };
};

export type GetUserDiscussionsCommentsCountQuery = {
  getUserDiscussionsCommentsCount: {
    __typename: 'TotalResultOutput';
    total: number;
  };
};

export type GetUserDiscussionsCountQuery = {
  getUserDiscussionsCount: {
    __typename: 'TotalResultOutput';
    total: number;
  };
};

export type ListCommentsQueryVariables = {
  input: Pagination;
};

export type ListCommentsQuery = {
  listComments: {
    __typename: 'CommentList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Comment';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      content?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
    } | null>;
  };
};

export type ListLikesQueryVariables = {
  input: Pagination;
};

export type ListLikesQuery = {
  listLikes: {
    __typename: 'ClientList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null>;
  };
};

export type ListSharesQueryVariables = {
  input: Pagination;
};

export type ListSharesQuery = {
  listShares: {
    __typename: 'ShareList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Share';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
    } | null>;
  };
};

export type ListReviewsQueryVariables = {
  input: Pagination;
};

export type ListReviewsQuery = {
  listReviews: {
    __typename: 'ReviewsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Review';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      content?: string | null;
      rating?: number | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
    } | null>;
  };
};

export type GetMyReviewForProfessionalQueryVariables = {
  input: Pagination;
};

export type GetMyReviewForProfessionalQuery = {
  getMyReviewForProfessional: {
    __typename: 'Review';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    content?: string | null;
    rating?: number | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
  };
};

export type ListSpamReasonsQuery = {
  listSpamReasons?: {
    __typename: 'SpamReasonList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'SpamReason';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
    } | null>;
  } | null;
};

export type ListFiltersQuery = {
  listFilters: {
    __typename: 'FilterJSON';
    filters?: string | null;
  };
};

export type GetTVQueryVariables = {
  id: string;
};

export type GetTVQuery = {
  getTV?: {
    __typename: 'TV';
    id: string;
    video_url: string;
    photo: string;
    page: string;
    likes_count?: number | null;
    is_liked?: boolean | null;
    comments_count?: number | null;
    title?: string | null;
    meta_description?: string | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    created_at?: string | null;
    video_length?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
  } | null;
};

export type ListTVsQueryVariables = {
  input?: Pagination | null;
};

export type ListTVsQuery = {
  listTVs?: {
    __typename: 'TVList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'TV';
      id: string;
      video_url: string;
      photo: string;
      page: string;
      likes_count?: number | null;
      is_liked?: boolean | null;
      comments_count?: number | null;
      title?: string | null;
      meta_description?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      created_at?: string | null;
      video_length?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetMagazineQueryVariables = {
  id: string;
};

export type GetMagazineQuery = {
  getMagazine?: {
    __typename: 'Magazine';
    id: string;
    page: string;
    photo: string;
    likes_count?: number | null;
    comments_count?: number | null;
    title?: string | null;
    meta_description?: string | null;
    is_liked?: boolean | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    created_at?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
  } | null;
};

export type ListMagazinesQueryVariables = {
  input?: Pagination | null;
};

export type ListMagazinesQuery = {
  listMagazines?: {
    __typename: 'MagazineList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Magazine';
      id: string;
      page: string;
      photo: string;
      likes_count?: number | null;
      comments_count?: number | null;
      title?: string | null;
      meta_description?: string | null;
      is_liked?: boolean | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      created_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ListRecentMagazinesQueryVariables = {
  input?: Pagination | null;
};

export type ListRecentMagazinesQuery = {
  listRecentMagazines?: {
    __typename: 'MagazineList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Magazine';
      id: string;
      page: string;
      photo: string;
      likes_count?: number | null;
      comments_count?: number | null;
      title?: string | null;
      meta_description?: string | null;
      is_liked?: boolean | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      created_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ListNotificationTypesQueryVariables = {
  input?: Pagination | null;
};

export type ListNotificationTypesQuery = {
  listNotificationTypes?: {
    __typename: 'NotificationTypeList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'NotificationType';
      verb: string;
      options?: Array<{
        __typename: 'NotificationValue';
        id: string;
        value: string;
      } | null> | null;
    } | null>;
  } | null;
};

export type ListNotificationSettingsQueryVariables = {
  input?: PlatformInput | null;
};

export type ListNotificationSettingsQuery = {
  listNotificationSettings?: {
    __typename: 'NotificationSettingResult';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'NotificationSettingObject';
      text?: string | null;
      settings?: Array<{
        __typename: 'NotificationSettingTypeObject';
        id?: string | null;
        title?: string | null;
        status?: NotificationStatus | null;
        blocked?: boolean | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ListNotificationsQueryVariables = {
  input?: Pagination | null;
};

export type ListNotificationsQuery = {
  listNotifications?: {
    __typename: 'NotificationList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'Notification';
      id?: string | null;
      title?: string | null;
      body?: string | null;
      created_at?: string | null;
      unread?: boolean | null;
      notification_class?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      notification_payload?: {
        __typename: 'NotificationPayload';
        page?: string | null;
        section?: string | null;
        id?: string | null;
        url?: string | null;
        icon?: string | null;
        image?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUserUnreadNotificationsCountQuery = {
  getUserUnreadNotificationsCount?: {
    __typename: 'TotalResultOutput';
    total: number;
  } | null;
};

export type SearchQueryVariables = {
  input?: SearchInput | null;
};

export type SearchQuery = {
  search: {
    __typename: 'SearchResult';
    count: number;
    results: Array<{
      __typename: 'SearchData';
      index: string;
      data?: string | null;
    } | null>;
    suggestions?: Array<{
      __typename: 'SuggestionData';
      original?: string | null;
      suggestion?: string | null;
    } | null> | null;
    filters?: string | null;
  };
};

export type FindRelatedQueryVariables = {
  input?: FindRelated | null;
};

export type FindRelatedQuery = {
  findRelated: {
    __typename: 'SearchResult';
    count: number;
    results: Array<{
      __typename: 'SearchData';
      index: string;
      data?: string | null;
    } | null>;
    suggestions?: Array<{
      __typename: 'SuggestionData';
      original?: string | null;
      suggestion?: string | null;
    } | null> | null;
    filters?: string | null;
  };
};

export type AutoCompleteQueryVariables = {
  input?: AutoCompleteInput | null;
};

export type AutoCompleteQuery = {
  autoComplete: {
    __typename: 'AutoCompleteList';
    count: number;
    results: {
      __typename: 'AutoCompleteResult';
      projects?: Array<string | null> | null;
      ideas?: Array<string | null> | null;
      magazines?: Array<string | null> | null;
      tvs?: Array<string | null> | null;
      discussions?: Array<string | null> | null;
      professionals?: Array<string | null> | null;
      brands?: Array<string | null> | null;
      stockrecords?: Array<string | null> | null;
    };
  };
};

export type AutoCompleteObjectsQueryVariables = {
  input?: AutoCompleteInput | null;
};

export type AutoCompleteObjectsQuery = {
  autoCompleteObjects: {
    __typename: 'AutoCompleteObjectList';
    count: number;
    results: {
      __typename: 'AutoCompleteObjectsResult';
      projects?: Array<{
        __typename: 'AutoCompleteObject';
        id: string;
        title: string;
        key_id?: string | null;
        key?: string | null;
      } | null> | null;
      magazines?: Array<{
        __typename: 'AutoCompleteObject';
        id: string;
        title: string;
        key_id?: string | null;
        key?: string | null;
      } | null> | null;
      tvs?: Array<{
        __typename: 'AutoCompleteObject';
        id: string;
        title: string;
        key_id?: string | null;
        key?: string | null;
      } | null> | null;
      discussions?: Array<{
        __typename: 'AutoCompleteObject';
        id: string;
        title: string;
        key_id?: string | null;
        key?: string | null;
      } | null> | null;
      professionals?: Array<{
        __typename: 'AutoCompleteObject';
        id: string;
        title: string;
        key_id?: string | null;
        key?: string | null;
      } | null> | null;
      brands?: Array<{
        __typename: 'AutoCompleteObject';
        id: string;
        title: string;
        key_id?: string | null;
        key?: string | null;
      } | null> | null;
    };
  };
};

export type GetMenuQuery = {
  getMenu?: {
    __typename: 'Menu';
    data?: string | null;
  } | null;
};

export type GetShoppingMenuQuery = {
  getShoppingMenu?: {
    __typename: 'ShoppingMenu';
    departments?: string | null;
  } | null;
};

export type ListProfessionalsQueryVariables = {
  input?: Pagination | null;
};

export type ListProfessionalsQuery = {
  listProfessionals: {
    __typename: 'ProfessionalList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  };
};

export type GetProfessionalQueryVariables = {
  id: string;
};

export type GetProfessionalQuery = {
  getProfessional: {
    __typename: 'Professional';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    address?: string | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    } | null> | null;
    locations?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
    is_profile_completed?: boolean | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    gallery_photos?: Array<string | null> | null;
    photos?: {
      __typename: 'GalleryPhotoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'GalleryPhoto';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null>;
    } | null;
    is_reviewable?: boolean | null;
    projects_count?: number | null;
    videos?: {
      __typename: 'VideoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Video';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        video?: string | null;
      } | null>;
    } | null;
    contacts?: {
      __typename: 'ContactInfoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ContactInfo';
        contact_type?: ContactType | null;
        value?: string | null;
      } | null>;
    } | null;
    professional_type?: ProfessionalType | null;
    company_name?: string | null;
    company_logo?: string | null;
    company_registration_ref?: string | null;
    personal_freelance_license?: string | null;
    is_verified?: boolean | null;
    is_gig_professional?: boolean | null;
    gigs_count?: number | null;
    score?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
  };
};

export type GetAuthenticatedProfessionalQuery = {
  getAuthenticatedProfessional: {
    __typename: 'Professional';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    address?: string | null;
    categories?: Array<{
      __typename: 'Category';
      id: string;
      title?: string | null;
      english_title?: string | null;
      description?: string | null;
      parent?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      photo?: string | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      filters?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    } | null> | null;
    locations?: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null> | null;
    is_profile_completed?: boolean | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    gallery_photos?: Array<string | null> | null;
    photos?: {
      __typename: 'GalleryPhotoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'GalleryPhoto';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null>;
    } | null;
    is_reviewable?: boolean | null;
    projects_count?: number | null;
    videos?: {
      __typename: 'VideoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Video';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        video?: string | null;
      } | null>;
    } | null;
    contacts?: {
      __typename: 'ContactInfoList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'ContactInfo';
        contact_type?: ContactType | null;
        value?: string | null;
      } | null>;
    } | null;
    professional_type?: ProfessionalType | null;
    company_name?: string | null;
    company_logo?: string | null;
    company_registration_ref?: string | null;
    personal_freelance_license?: string | null;
    is_verified?: boolean | null;
    is_gig_professional?: boolean | null;
    gigs_count?: number | null;
    score?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
  };
};

export type ListGalleryPhotosQueryVariables = {
  input?: Pagination | null;
};

export type ListGalleryPhotosQuery = {
  listGalleryPhotos?: {
    __typename: 'GalleryPhotoList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'GalleryPhoto';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null>;
  } | null;
};

export type ListVideosQueryVariables = {
  input?: Pagination | null;
};

export type ListVideosQuery = {
  listVideos?: {
    __typename: 'VideoList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Video';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      video?: string | null;
    } | null>;
  } | null;
};

export type ListServicesQueryVariables = {
  input?: Pagination | null;
};

export type ListServicesQuery = {
  listServices?: {
    __typename: 'ServiceList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    } | null>;
  } | null;
};

export type GetServiceQueryVariables = {
  id: string;
};

export type GetServiceQuery = {
  getService?: {
    __typename: 'Service';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    title?: string | null;
    image?: string | null;
    short_description?: string | null;
    long_description?: string | null;
  } | null;
};

export type ListDepartmentsQueryVariables = {
  input?: Pagination | null;
};

export type ListDepartmentsQuery = {
  listDepartments?: {
    __typename: 'DepartmentList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Department';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      photo_url?: string | null;
      products_count?: number | null;
      filters?: string | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      sub_departments?: Array<{
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null> | null;
      children_count?: string | null;
    } | null>;
  } | null;
};

export type ListDepartmentsBySlugQueryVariables = {
  input?: Pagination | null;
};

export type ListDepartmentsBySlugQuery = {
  listDepartmentsBySlug?: {
    __typename: 'DepartmentList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Department';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      photo_url?: string | null;
      products_count?: number | null;
      filters?: string | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      sub_departments?: Array<{
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null> | null;
      children_count?: string | null;
    } | null>;
  } | null;
};

export type GetDepartmentQueryVariables = {
  id: string;
};

export type GetDepartmentQuery = {
  getDepartment?: {
    __typename: 'Department';
    id?: string | null;
    title?: string | null;
    description?: string | null;
    slug?: string | null;
    photo_url?: string | null;
    products_count?: number | null;
    filters?: string | null;
    breadcrumbs?: Array<{
      __typename: 'breadcrumb';
      title?: string | null;
      slug?: string | null;
    } | null> | null;
    sub_departments?: Array<{
      __typename: 'Department';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      photo_url?: string | null;
      products_count?: number | null;
      filters?: string | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      sub_departments?: Array<{
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null> | null;
      children_count?: string | null;
    } | null> | null;
    children_count?: string | null;
  } | null;
};

export type GetHomePageSliderQuery = {
  getHomePageSlider?: {
    __typename: 'HomePageSlider';
    result?: string | null;
  } | null;
};

export type GetBreadcrumbQueryVariables = {
  slug: string;
  model: string;
};

export type GetBreadcrumbQuery = {
  getBreadcrumb?: {
    __typename: 'BreadcrumbList';
    breadcrumbs?: Array<{
      __typename: 'breadcrumb';
      title?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null;
};

export type ListHomePageSpecialProductsQueryVariables = {
  input?: Pagination | null;
};

export type ListHomePageSpecialProductsQuery = {
  listHomePageSpecialProducts: {
    __typename: 'ProductsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null>;
  };
};

export type ListHomePageSpecialStockRecordsQueryVariables = {
  input?: Pagination | null;
};

export type ListHomePageSpecialStockRecordsQuery = {
  listHomePageSpecialStockRecords: {
    __typename: 'StockRecordList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  };
};

export type ListDepartmentProductsQueryVariables = {
  input?: Pagination | null;
};

export type ListDepartmentProductsQuery = {
  listDepartmentProducts: {
    __typename: 'ProductsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null>;
  };
};

export type ListDepartmentStockRecordsQueryVariables = {
  input?: Pagination | null;
};

export type ListDepartmentStockRecordsQuery = {
  listDepartmentStockRecords: {
    __typename: 'StockRecordList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  };
};

export type ListProductSlidersQueryVariables = {
  input?: Pagination | null;
};

export type ListProductSlidersQuery = {
  listProductSliders: {
    __typename: 'ProductSliderList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'ProductSlider';
      stock_records?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
      title?: string | null;
      show_all_url?: string | null;
    } | null>;
  };
};

export type GetProductQueryVariables = {
  id: string;
};

export type GetProductQuery = {
  getProduct: {
    __typename: 'Product';
    id?: string | null;
    title?: string | null;
    description?: string | null;
    slug?: string | null;
    upc?: string | null;
    photo_url?: string | null;
    labels?: Array<{
      __typename: 'Label';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      color?: string | null;
      is_extra?: boolean | null;
    } | null> | null;
    manufactory?: {
      __typename: 'Manufactory';
      id?: string | null;
      name?: string | null;
      website_url?: string | null;
    } | null;
    images?: Array<{
      __typename: 'Image';
      id?: string | null;
      original?: string | null;
      is_default?: boolean | null;
      display_order?: number | null;
    } | null> | null;
    attributes?: Array<{
      __typename: 'ProductAttribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
    breadcrumbs?: Array<{
      __typename: 'breadcrumb';
      title?: string | null;
      slug?: string | null;
    } | null> | null;
    offer?: {
      __typename: 'Offer';
      type?: string | null;
      value?: string | null;
    } | null;
    section?: {
      __typename: 'Department';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      photo_url?: string | null;
      products_count?: number | null;
      filters?: string | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      sub_departments?: Array<{
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null> | null;
      children_count?: string | null;
    } | null;
    categories?: Array<{
      __typename: 'ProductCategory';
      title?: string | null;
      slug?: string | null;
    } | null> | null;
    color?: string | null;
    dimensions?: string | null;
  };
};

export type GetStockRecordQueryVariables = {
  id: string;
};

export type GetStockRecordQuery = {
  getStockRecord: {
    __typename: 'StockRecord';
    id?: string | null;
    currency?: string | null;
    price?: number | null;
    original_price?: number | null;
    discount_value?: number | null;
    available_number_in_stock?: number | null;
    product?: {
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null;
    partner?: {
      __typename: 'Partner';
      id?: string | null;
      name?: string | null;
      code?: string | null;
      logo?: string | null;
      address?: string | null;
      email?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      is_reviewable?: boolean | null;
      mobile?: string | null;
      blog?: string | null;
      policy?: string | null;
    } | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    partner_sku?: string | null;
    is_reviewable?: boolean | null;
    is_purchased?: boolean | null;
    vendor_variants?: Array<{
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  };
};

export type GetBasketLineByStockrecordQueryVariables = {
  input?: StockRecordBasketLineInput | null;
};

export type GetBasketLineByStockrecordQuery = {
  getBasketLineByStockrecord: {
    __typename: 'BasketLine';
    id?: number | null;
    product?: {
      __typename: 'Product';
      id?: string | null;
      title?: string | null;
      description?: string | null;
      slug?: string | null;
      upc?: string | null;
      photo_url?: string | null;
      labels?: Array<{
        __typename: 'Label';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        color?: string | null;
        is_extra?: boolean | null;
      } | null> | null;
      manufactory?: {
        __typename: 'Manufactory';
        id?: string | null;
        name?: string | null;
        website_url?: string | null;
      } | null;
      images?: Array<{
        __typename: 'Image';
        id?: string | null;
        original?: string | null;
        is_default?: boolean | null;
        display_order?: number | null;
      } | null> | null;
      attributes?: Array<{
        __typename: 'ProductAttribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
      breadcrumbs?: Array<{
        __typename: 'breadcrumb';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      offer?: {
        __typename: 'Offer';
        type?: string | null;
        value?: string | null;
      } | null;
      section?: {
        __typename: 'Department';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        photo_url?: string | null;
        products_count?: number | null;
        filters?: string | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        sub_departments?: Array<{
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null> | null;
        children_count?: string | null;
      } | null;
      categories?: Array<{
        __typename: 'ProductCategory';
        title?: string | null;
        slug?: string | null;
      } | null> | null;
      color?: string | null;
      dimensions?: string | null;
    } | null;
    quantity?: number | null;
    stockrecord_id?: number | null;
    basket_id?: number | null;
    price_currency?: string | null;
    price_excl_tax?: string | null;
    price_incl_tax?: string | null;
    price_incl_tax_excl_discounts?: string | null;
    price_excl_tax_excl_discounts?: string | null;
    original_price_incl_tax?: string | null;
    original_price_excl_tax?: string | null;
    stockrecord?: {
      __typename: 'StockRecord';
      id?: string | null;
      currency?: string | null;
      price?: number | null;
      original_price?: number | null;
      discount_value?: number | null;
      available_number_in_stock?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      partner_sku?: string | null;
      is_reviewable?: boolean | null;
      is_purchased?: boolean | null;
      vendor_variants?: Array<{
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null;
  };
};

export type GetPartnerQueryVariables = {
  id: string;
};

export type GetPartnerQuery = {
  getPartner: {
    __typename: 'Partner';
    id?: string | null;
    name?: string | null;
    code?: string | null;
    logo?: string | null;
    address?: string | null;
    email?: string | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    is_reviewable?: boolean | null;
    mobile?: string | null;
    blog?: string | null;
    policy?: string | null;
  };
};

export type ListProductReviewsByStockRecordIdQueryVariables = {
  input?: Pagination | null;
};

export type ListProductReviewsByStockRecordIdQuery = {
  listProductReviewsByStockRecordId: {
    __typename: 'ProductReviewList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'ProductReview';
      id?: string | null;
      stockrecord?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      body?: string | null;
      general_score?: number | null;
      client_bought_product?: boolean | null;
      created_at?: string | null;
      status?: string | null;
      detailed_review_scores?: Array<{
        __typename: 'DetailedReviewScore';
        id: string;
        title?: string | null;
        status?: string | null;
        score?: number | null;
        detailed_score?: {
          __typename: 'DetailedScore';
          id: string;
          title: string;
          status?: string | null;
        } | null;
      } | null> | null;
      review_images?: Array<{
        __typename: 'ReviewImage';
        id?: string | null;
        image?: string | null;
        status?: string | null;
      } | null> | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
    } | null>;
  };
};

export type ListDetailedScoreQueryVariables = {
  input?: Pagination | null;
};

export type ListDetailedScoreQuery = {
  listDetailedScore: {
    __typename: 'DetailedScoreList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'DetailedScore';
      id: string;
      title: string;
      status?: string | null;
    } | null>;
  };
};

export type List_stockrecord_reviews_aggregationQueryVariables = {
  input?: Pagination | null;
};

export type List_stockrecord_reviews_aggregationQuery = {
  list_stockrecord_reviews_aggregation?: {
    __typename: 'StockRecordReviewsAggregationResult';
    rates_aggregation?: Array<{
      __typename: 'StockRecordReviewsAggregationObject';
      rate?: number | null;
      count?: number | null;
    } | null> | null;
  } | null;
};

export type GetBannerDetailsQueryVariables = {
  slug: BannerSlug;
};

export type GetBannerDetailsQuery = {
  getBannerDetails?: {
    __typename: 'Banner';
    title?: string | null;
    sub_title?: string | null;
    description?: string | null;
    photo_url?: string | null;
    slug?: string | null;
    button_title?: string | null;
    responsive_photo_url?: string | null;
    url?: string | null;
  } | null;
};

export type ListFAQsQueryVariables = {
  input?: Pagination | null;
};

export type ListFAQsQuery = {
  listFAQs: {
    __typename: 'FAQsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'FAQ';
      id?: string | null;
      title?: string | null;
      description?: string | null;
    } | null>;
  };
};

export type ListFollowersByClientIdQueryVariables = {
  input?: Pagination | null;
};

export type ListFollowersByClientIdQuery = {
  listFollowersByClientId?: {
    __typename: 'ClientFollowersList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null>;
  } | null;
};

export type ListFolloweesByClientIdQueryVariables = {
  input?: Pagination | null;
};

export type ListFolloweesByClientIdQuery = {
  listFolloweesByClientId?: {
    __typename: 'ClientFollowersList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null>;
  } | null;
};

export type FetchImagesFromUrlQueryVariables = {
  url: string;
};

export type FetchImagesFromUrlQuery = {
  fetchImagesFromUrl?: Array<string | null> | null;
};

export type ListBrandsQueryVariables = {
  input?: Pagination | null;
};

export type ListBrandsQuery = {
  listBrands?: {
    __typename: 'BrandsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'Brand';
      id: string;
      arabic_title?: string | null;
      english_title?: string | null;
      title?: string | null;
      arabic_description?: string | null;
      english_description?: string | null;
      description?: string | null;
      website?: string | null;
      logo?: string | null;
      email?: string | null;
      phone?: string | null;
      address?: string | null;
      type?: BrandType | null;
      rate?: number | null;
      rates_count?: number | null;
      rank?: number | null;
      tags?: {
        __typename: 'BrandTagsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BrandTag';
          english_title?: string | null;
          arabic_title?: string | null;
          title?: string | null;
        } | null> | null;
      } | null;
      images?: {
        __typename: 'BrandImagesList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BrandImage';
          photo?: string | null;
        } | null> | null;
      } | null;
      is_reviewable?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetBrandQueryVariables = {
  id: string;
};

export type GetBrandQuery = {
  getBrand?: {
    __typename: 'Brand';
    id: string;
    arabic_title?: string | null;
    english_title?: string | null;
    title?: string | null;
    arabic_description?: string | null;
    english_description?: string | null;
    description?: string | null;
    website?: string | null;
    logo?: string | null;
    email?: string | null;
    phone?: string | null;
    address?: string | null;
    type?: BrandType | null;
    rate?: number | null;
    rates_count?: number | null;
    rank?: number | null;
    tags?: {
      __typename: 'BrandTagsList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BrandTag';
        english_title?: string | null;
        arabic_title?: string | null;
        title?: string | null;
      } | null> | null;
    } | null;
    images?: {
      __typename: 'BrandImagesList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'BrandImage';
        photo?: string | null;
      } | null> | null;
    } | null;
    is_reviewable?: boolean | null;
  } | null;
};

export type GetAuthenticatedClientBasketQuery = {
  getAuthenticatedClientBasket?: {
    __typename: 'Basket';
    id?: number | null;
    status?: string | null;
    total_excl_tax?: string | null;
    total_excl_tax_excl_discounts?: string | null;
    total_incl_tax?: string | null;
    total_incl_tax_excl_discounts?: string | null;
    total_original_excl_tax?: string | null;
    total_original_incl_tax?: string | null;
    total_quantity?: string | null;
    total_tax?: string | null;
    currency?: string | null;
    discount_price?: number | null;
    discount_amount?: number | null;
    lines?: Array<{
      __typename: 'BasketLine';
      id?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      quantity?: number | null;
      stockrecord_id?: number | null;
      basket_id?: number | null;
      price_currency?: string | null;
      price_excl_tax?: string | null;
      price_incl_tax?: string | null;
      price_incl_tax_excl_discounts?: string | null;
      price_excl_tax_excl_discounts?: string | null;
      original_price_incl_tax?: string | null;
      original_price_excl_tax?: string | null;
      stockrecord?: {
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListBasketProductsQueryVariables = {
  input?: Pagination | null;
};

export type ListBasketProductsQuery = {
  listBasketProducts?: {
    __typename: 'BasketLineList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'BasketLine';
      id?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      quantity?: number | null;
      stockrecord_id?: number | null;
      basket_id?: number | null;
      price_currency?: string | null;
      price_excl_tax?: string | null;
      price_incl_tax?: string | null;
      price_incl_tax_excl_discounts?: string | null;
      price_excl_tax_excl_discounts?: string | null;
      original_price_incl_tax?: string | null;
      original_price_excl_tax?: string | null;
      stockrecord?: {
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ListBasketProductsGroupedByPartnerQueryVariables = {
  input?: Pagination | null;
};

export type ListBasketProductsGroupedByPartnerQuery = {
  listBasketProductsGroupedByPartner?: {
    __typename: 'BasketLinesGroupedByPartnerResult';
    result?: Array<{
      __typename: 'BasketLinesGroupedByPartnerObject';
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      lines?: Array<{
        __typename: 'BasketLine';
        id?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        quantity?: number | null;
        stockrecord_id?: number | null;
        basket_id?: number | null;
        price_currency?: string | null;
        price_excl_tax?: string | null;
        price_incl_tax?: string | null;
        price_incl_tax_excl_discounts?: string | null;
        price_excl_tax_excl_discounts?: string | null;
        original_price_incl_tax?: string | null;
        original_price_excl_tax?: string | null;
        stockrecord?: {
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ListMyOrdersQueryVariables = {
  input?: OrderListInput | null;
};

export type ListMyOrdersQuery = {
  listMyOrders?: {
    __typename: 'OrderList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'Order';
      id?: number | null;
      number?: string | null;
      currency?: string | null;
      total_incl_tax?: string | null;
      total_excl_tax?: string | null;
      shipping_incl_tax?: string | null;
      shipping_excl_tax?: string | null;
      status?: string | null;
      date_placed?: string | null;
      shipping_address?: {
        __typename: 'ClientAddress';
        id?: string | null;
        name?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        neighborhood?: {
          __typename: 'Neighborhood';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          city_id?: string | null;
        } | null;
        description?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string | null;
        is_default?: boolean | null;
      } | null;
      num_items?: number | null;
    } | null>;
  } | null;
};

export type GetOrderByIdQueryVariables = {
  id: number;
};

export type GetOrderByIdQuery = {
  getOrderById?: {
    __typename: 'OrderDetails';
    id?: number | null;
    number?: string | null;
    currency?: string | null;
    total_incl_tax?: string | null;
    total_excl_tax?: string | null;
    total_discount_incl_tax?: number | null;
    total_discount_excl_tax?: number | null;
    shipping_incl_tax?: string | null;
    shipping_excl_tax?: string | null;
    status?: string | null;
    date_placed?: string | null;
    shipping_address?: {
      __typename: 'ClientAddress';
      id?: string | null;
      name?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      neighborhood?: {
        __typename: 'Neighborhood';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        city_id?: string | null;
      } | null;
      description?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      phone_number?: string | null;
      email?: string | null;
      is_default?: boolean | null;
    } | null;
    num_items?: number | null;
    products_price?: number | null;
    total_tax?: number | null;
    discount_price?: number | null;
    discount_amount?: number | null;
    payment_method?: {
      __typename: 'PaymentMethod';
      id?: number | null;
      name?: string | null;
      logo?: string | null;
      brand?: string | null;
    } | null;
    payment_card?: {
      __typename: 'BankCard';
      id?: string | null;
      card_type?: string | null;
      number?: string | null;
      name?: string | null;
      expiry_date?: string | null;
      partner_reference?: string | null;
      payment_method?: {
        __typename: 'PaymentMethod';
        id?: number | null;
        name?: string | null;
        logo?: string | null;
        brand?: string | null;
      } | null;
    } | null;
    lines?: {
      __typename: 'OrderLinesList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'OrderLine';
        id?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        quantity?: number | null;
        stockrecord?: {
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null;
        price_currency?: string | null;
        price_excl_tax?: string | null;
        price_incl_tax?: string | null;
        price_incl_tax_excl_discounts?: string | null;
        price_excl_tax_excl_discounts?: string | null;
        original_price_incl_tax?: string | null;
        original_price_excl_tax?: string | null;
        status?: string | null;
        product_review?: {
          __typename: 'ProductReview';
          id?: string | null;
          stockrecord?: string | null;
          body?: string | null;
          general_score?: number | null;
          client_bought_product?: boolean | null;
          created_at?: string | null;
          status?: string | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetOrderByNumberQueryVariables = {
  id: string;
};

export type GetOrderByNumberQuery = {
  getOrderByNumber?: {
    __typename: 'OrderDetails';
    id?: number | null;
    number?: string | null;
    currency?: string | null;
    total_incl_tax?: string | null;
    total_excl_tax?: string | null;
    total_discount_incl_tax?: number | null;
    total_discount_excl_tax?: number | null;
    shipping_incl_tax?: string | null;
    shipping_excl_tax?: string | null;
    status?: string | null;
    date_placed?: string | null;
    shipping_address?: {
      __typename: 'ClientAddress';
      id?: string | null;
      name?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      neighborhood?: {
        __typename: 'Neighborhood';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        city_id?: string | null;
      } | null;
      description?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      phone_number?: string | null;
      email?: string | null;
      is_default?: boolean | null;
    } | null;
    num_items?: number | null;
    products_price?: number | null;
    total_tax?: number | null;
    discount_price?: number | null;
    discount_amount?: number | null;
    payment_method?: {
      __typename: 'PaymentMethod';
      id?: number | null;
      name?: string | null;
      logo?: string | null;
      brand?: string | null;
    } | null;
    payment_card?: {
      __typename: 'BankCard';
      id?: string | null;
      card_type?: string | null;
      number?: string | null;
      name?: string | null;
      expiry_date?: string | null;
      partner_reference?: string | null;
      payment_method?: {
        __typename: 'PaymentMethod';
        id?: number | null;
        name?: string | null;
        logo?: string | null;
        brand?: string | null;
      } | null;
    } | null;
    lines?: {
      __typename: 'OrderLinesList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'OrderLine';
        id?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        quantity?: number | null;
        stockrecord?: {
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null;
        price_currency?: string | null;
        price_excl_tax?: string | null;
        price_incl_tax?: string | null;
        price_incl_tax_excl_discounts?: string | null;
        price_excl_tax_excl_discounts?: string | null;
        original_price_incl_tax?: string | null;
        original_price_excl_tax?: string | null;
        status?: string | null;
        product_review?: {
          __typename: 'ProductReview';
          id?: string | null;
          stockrecord?: string | null;
          body?: string | null;
          general_score?: number | null;
          client_bought_product?: boolean | null;
          created_at?: string | null;
          status?: string | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetOrderStatusPipelineQuery = {
  getOrderStatusPipeline?: {
    __typename: 'OrderStatusPipelineResult';
    status_pipeline?: Array<string | null> | null;
  } | null;
};

export type ListOrderProductsQueryVariables = {
  input?: Pagination | null;
};

export type ListOrderProductsQuery = {
  listOrderProducts?: {
    __typename: 'OrderLinesList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'OrderLine';
      id?: number | null;
      product?: {
        __typename: 'Product';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        upc?: string | null;
        photo_url?: string | null;
        labels?: Array<{
          __typename: 'Label';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          color?: string | null;
          is_extra?: boolean | null;
        } | null> | null;
        manufactory?: {
          __typename: 'Manufactory';
          id?: string | null;
          name?: string | null;
          website_url?: string | null;
        } | null;
        images?: Array<{
          __typename: 'Image';
          id?: string | null;
          original?: string | null;
          is_default?: boolean | null;
          display_order?: number | null;
        } | null> | null;
        attributes?: Array<{
          __typename: 'ProductAttribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
        breadcrumbs?: Array<{
          __typename: 'breadcrumb';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        offer?: {
          __typename: 'Offer';
          type?: string | null;
          value?: string | null;
        } | null;
        section?: {
          __typename: 'Department';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          photo_url?: string | null;
          products_count?: number | null;
          filters?: string | null;
          children_count?: string | null;
        } | null;
        categories?: Array<{
          __typename: 'ProductCategory';
          title?: string | null;
          slug?: string | null;
        } | null> | null;
        color?: string | null;
        dimensions?: string | null;
      } | null;
      quantity?: number | null;
      stockrecord?: {
        __typename: 'StockRecord';
        id?: string | null;
        currency?: string | null;
        price?: number | null;
        original_price?: number | null;
        discount_value?: number | null;
        available_number_in_stock?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        partner?: {
          __typename: 'Partner';
          id?: string | null;
          name?: string | null;
          code?: string | null;
          logo?: string | null;
          address?: string | null;
          email?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          is_reviewable?: boolean | null;
          mobile?: string | null;
          blog?: string | null;
          policy?: string | null;
        } | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        partner_sku?: string | null;
        is_reviewable?: boolean | null;
        is_purchased?: boolean | null;
        vendor_variants?: Array<{
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null> | null;
      } | null;
      price_currency?: string | null;
      price_excl_tax?: string | null;
      price_incl_tax?: string | null;
      price_incl_tax_excl_discounts?: string | null;
      price_excl_tax_excl_discounts?: string | null;
      original_price_incl_tax?: string | null;
      original_price_excl_tax?: string | null;
      status?: string | null;
      product_review?: {
        __typename: 'ProductReview';
        id?: string | null;
        stockrecord?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        body?: string | null;
        general_score?: number | null;
        client_bought_product?: boolean | null;
        created_at?: string | null;
        status?: string | null;
        detailed_review_scores?: Array<{
          __typename: 'DetailedReviewScore';
          id: string;
          title?: string | null;
          status?: string | null;
          score?: number | null;
        } | null> | null;
        review_images?: Array<{
          __typename: 'ReviewImage';
          id?: string | null;
          image?: string | null;
          status?: string | null;
        } | null> | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListOrderProductsGroupedByPartnerQueryVariables = {
  input?: Pagination | null;
};

export type ListOrderProductsGroupedByPartnerQuery = {
  listOrderProductsGroupedByPartner?: {
    __typename: 'OrderLinesGroupedByPartnerResult';
    result?: Array<{
      __typename: 'OrderLinesGroupedByPartnerObject';
      partner?: {
        __typename: 'Partner';
        id?: string | null;
        name?: string | null;
        code?: string | null;
        logo?: string | null;
        address?: string | null;
        email?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        is_reviewable?: boolean | null;
        mobile?: string | null;
        blog?: string | null;
        policy?: string | null;
      } | null;
      lines?: Array<{
        __typename: 'OrderLine';
        id?: number | null;
        product?: {
          __typename: 'Product';
          id?: string | null;
          title?: string | null;
          description?: string | null;
          slug?: string | null;
          upc?: string | null;
          photo_url?: string | null;
          color?: string | null;
          dimensions?: string | null;
        } | null;
        quantity?: number | null;
        stockrecord?: {
          __typename: 'StockRecord';
          id?: string | null;
          currency?: string | null;
          price?: number | null;
          original_price?: number | null;
          discount_value?: number | null;
          available_number_in_stock?: number | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          partner_sku?: string | null;
          is_reviewable?: boolean | null;
          is_purchased?: boolean | null;
        } | null;
        price_currency?: string | null;
        price_excl_tax?: string | null;
        price_incl_tax?: string | null;
        price_incl_tax_excl_discounts?: string | null;
        price_excl_tax_excl_discounts?: string | null;
        original_price_incl_tax?: string | null;
        original_price_excl_tax?: string | null;
        status?: string | null;
        product_review?: {
          __typename: 'ProductReview';
          id?: string | null;
          stockrecord?: string | null;
          body?: string | null;
          general_score?: number | null;
          client_bought_product?: boolean | null;
          created_at?: string | null;
          status?: string | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ListMyOrdersCountByStatusQuery = {
  listMyOrdersCountByStatus?: {
    __typename: 'MyOrdersCountStatusResult';
    my_orders_statuses?: Array<{
      __typename: 'MyOrdersCountStatusResultObject';
      status?: string | null;
      count?: number | null;
    } | null> | null;
  } | null;
};

export type ListPaymentMethodsQueryVariables = {
  input?: Pagination | null;
};

export type ListPaymentMethodsQuery = {
  listPaymentMethods?: {
    __typename: 'PaymentMethodList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'PaymentMethod';
      id?: number | null;
      name?: string | null;
      logo?: string | null;
      brand?: string | null;
    } | null>;
  } | null;
};

export type GetPaymentCheckoutIdQueryVariables = {
  input?: PaymentCheckoutIdInput | null;
};

export type GetPaymentCheckoutIdQuery = {
  getPaymentCheckoutId?: {
    __typename: 'PaymentCheckoutIdOutput';
    result?: {
      __typename: 'PaymentCheckoutIdResultObject';
      code?: string | null;
      description?: string | null;
    } | null;
    order_number?: string | null;
    id?: string | null;
  } | null;
};

export type GetPaymentStatusQueryVariables = {
  input?: PaymentStatusInput | null;
};

export type GetPaymentStatusQuery = {
  getPaymentStatus?: {
    __typename: 'PaymentStatusOutput';
    payment_status?: string | null;
    description?: string | null;
  } | null;
};

export type ListWebPagesQueryVariables = {
  input?: Pagination | null;
};

export type ListWebPagesQuery = {
  listWebPages?: {
    __typename: 'WebPagesList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'WebPage';
      id?: string | null;
      title?: string | null;
      description?: string | null;
    } | null>;
  } | null;
};

export type GetWebPageMetaDataQueryVariables = {
  id: string;
};

export type GetWebPageMetaDataQuery = {
  getWebPageMetaData?: {
    __typename: 'WebPage';
    id?: string | null;
    title?: string | null;
    description?: string | null;
  } | null;
};

export type ListOnBoardingScreensQueryVariables = {
  input?: ListOnBoardingScreensInput | null;
};

export type ListOnBoardingScreensQuery = {
  listOnBoardingScreens?: {
    __typename: 'OnBoardingScreenList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<{
      __typename: 'OnBoardingScreen';
      id: string;
      title: string;
      description: string;
      order: number;
      image: string;
    } | null>;
  } | null;
};

export type GetGeneralSettingsQuery = {
  getGeneralSettings?: {
    __typename: 'GeneralSettings';
    enableEcommerce: boolean;
    enableRegistrationByMobile: boolean;
    enableSocialLogin: boolean;
    forceUpdateAndroidVersion: string;
    forceUpdateIOSVersion: string;
    PriceFilterMinValue: number;
    PriceFilterMaxValue: number;
    showProfessionalReviews: boolean;
    hyperPayPaymentUrl?: string | null;
    localizationUpdatingDate?: string | null;
    localizationArabicFilePath?: string | null;
    localizationEnglishFilePath?: string | null;
    ManzilikConfigurationFilePath?: string | null;
  } | null;
};

export type ListRefundReasonsQuery = {
  listRefundReasons?: {
    __typename: 'RefundReasons';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'RefundReason';
      id: string;
      title?: string | null;
      is_note_required?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetOptionQueryVariables = {
  input?: GetOptionInput | null;
};

export type GetOptionQuery = {
  getOption?: {
    __typename: 'OptionResults';
    results?: Array<{
      __typename: 'Option';
      name_ar?: string | null;
      name_en?: string | null;
      metadata?: string | null;
      icon?: string | null;
    } | null> | null;
  } | null;
};

export type ListBadgesQueryVariables = {
  input?: Pagination | null;
};

export type ListBadgesQuery = {
  listBadges?: {
    __typename: 'ClientBadgesList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'ClientBadge';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      key?: string | null;
      title?: string | null;
      value?: number | null;
      image?: string | null;
      description?: string | null;
      client_progress?: number | null;
      is_claimed?: boolean | null;
    } | null> | null;
  } | null;
};

export type ListFeatureFlagsQueryVariables = {
  input?: PlatformInput | null;
};

export type ListFeatureFlagsQuery = {
  listFeatureFlags?: Array<{
    __typename: 'FeatureFlag';
    title?: string | null;
    platform?: Platform | null;
    enabled?: boolean | null;
  } | null> | null;
};

export type GetGigServiceDetailsQueryVariables = {
  id: string;
};

export type GetGigServiceDetailsQuery = {
  getGigServiceDetails?: {
    __typename: 'GigService';
    id: string;
    title: string;
    description?: string | null;
    services: Array<{
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    }>;
    cities: Array<{
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    }>;
    price: number;
    photos: Array<string>;
    is_enabled: boolean;
    professional: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    };
  } | null;
};

export type ListProfessionalGigsQueryVariables = {
  input?: Pagination | null;
};

export type ListProfessionalGigsQuery = {
  ListProfessionalGigs?: {
    __typename: 'ProfessionalGigsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'GigService';
      id: string;
      title: string;
      description?: string | null;
      services: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      }>;
      cities: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      }>;
      price: number;
      photos: Array<string>;
      is_enabled: boolean;
      professional: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      };
    } | null> | null;
  } | null;
};

export type ListGigsTitleSuggestionsQueryVariables = {
  input?: GigSuggestionInput | null;
};

export type ListGigsTitleSuggestionsQuery = {
  listGigsTitleSuggestions?: Array<{
    __typename: 'DirectoryGigService';
    id: string;
    title: string;
    description: string;
    service: {
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    };
    price: number;
  } | null> | null;
};

export type GetDirectoryGigDetailsQueryVariables = {
  id: string;
};

export type GetDirectoryGigDetailsQuery = {
  getDirectoryGigDetails: {
    __typename: 'DirectoryGigService';
    id: string;
    title: string;
    description: string;
    service: {
      __typename: 'Service';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      title?: string | null;
      image?: string | null;
      short_description?: string | null;
      long_description?: string | null;
    };
    price: number;
  };
};

export type ListGigServicesQueryVariables = {
  input: ListGigServiceInput;
};

export type ListGigServicesQuery = {
  ListGigServices?: {
    __typename: 'ProfessionalGigsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'GigService';
      id: string;
      title: string;
      description?: string | null;
      services: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      }>;
      cities: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      }>;
      price: number;
      photos: Array<string>;
      is_enabled: boolean;
      professional: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      };
    } | null> | null;
  } | null;
};

export type ListSimilarGigServicesQueryVariables = {
  input: ListSimilarInput;
};

export type ListSimilarGigServicesQuery = {
  ListSimilarGigServices?: {
    __typename: 'ProfessionalGigsList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'GigService';
      id: string;
      title: string;
      description?: string | null;
      services: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      }>;
      cities: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      }>;
      price: number;
      photos: Array<string>;
      is_enabled: boolean;
      professional: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      };
    } | null> | null;
  } | null;
};

export type SearchProjectsQueryVariables = {
  text?: string | null;
  pagination?: SearchPagination | null;
  sortBy?: SearchSortBy | null;
  filters?: Array<SearchFilterInput | null> | null;
  categories?: Array<string | null> | null;
};

export type SearchProjectsQuery = {
  searchProjects: {
    __typename: 'ProjectSearchResult';
    count: number;
    results: Array<{
      __typename: 'Project';
      id?: string | null;
      room_type?: {
        __typename: 'RoomType';
        id: string;
        title?: string | null;
        arabic_title?: string | null;
        english_title?: string | null;
        category?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        photo?: string | null;
      } | null;
      is_default?: boolean | null;
      title: string;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      visibility: string;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      discussions_count?: number | null;
      ideas_count?: number | null;
      likes_count?: number | null;
      shares_count?: number | null;
      questions_count?: number | null;
      description?: string | null;
      ideas?: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null> | null;
      ideasList?: {
        __typename: 'IdeaList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null>;
      } | null;
      is_liked?: boolean | null;
      collaborators?: {
        __typename: 'ClientList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null>;
      } | null;
      default_idea?: {
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      entities?: {
        __typename: 'EntityList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Entity';
          created_at: string;
          updated_at: string;
          status?: string | null;
          photo?: string | null;
          description?: string | null;
          tag?: string | null;
          client_id?: string | null;
          project_id?: string | null;
          entity_id?: string | null;
          title?: string | null;
          is_main_entity?: boolean | null;
        } | null>;
      } | null;
    } | null>;
    filters?: Array<{
      __typename: 'ProjectSearchFilterName';
      title?: string | null;
      results?: Array<{
        __typename: 'ProjectSearchFilterValue';
        title?: string | null;
        count?: number | null;
      } | null> | null;
    } | null> | null;
  };
};

export type SearchCompletionQueryVariables = {
  text: string;
  searchBy?: CompletionSearchBy | null;
};

export type SearchCompletionQuery = {
  searchCompletion?: {
    __typename: 'CompletionResults';
    completions?: Array<string | null> | null;
    suggestions?: Array<{
      __typename: 'SpillingSuggestion';
      text?: string | null;
      suggest?: string | null;
    } | null> | null;
  } | null;
};

export type SearchProfessionalsQueryVariables = {
  text?: string | null;
  pagination?: SearchPagination | null;
  sortBy?: ProfessionalSearchSortBy | null;
  services?: Array<string | null> | null;
  cities?: Array<string | null> | null;
  regions?: Array<string | null> | null;
  is_verified?: boolean | null;
};

export type SearchProfessionalsQuery = {
  searchProfessionals: {
    __typename: 'ProfessionalSearchResult';
    count: number;
    results: Array<{
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null>;
  };
};

export type SearchDiscussionsQueryVariables = {
  text?: string | null;
  pagination?: SearchPagination | null;
  sortBy?: DiscussionSearchSortBy | null;
  topics?: Array<string | null> | null;
};

export type SearchDiscussionsQuery = {
  searchDiscussions: {
    __typename: 'DiscussionSearchResult';
    count: number;
    results: Array<{
      __typename: 'Discussion';
      id: string;
      tag?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      title?: string | null;
      description?: string | null;
      client_id?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      youtube_url?: string | null;
      photo?: string | null;
      web_url?: string | null;
      likes_count?: number | null;
      comments_count?: number | null;
      topics?: Array<{
        __typename: 'Topic';
        id: string;
        paren_id?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        is_interested?: boolean | null;
      } | null> | null;
      polls?: Array<{
        __typename: 'Poll';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        photo_url?: string | null;
      } | null> | null;
      is_liked?: boolean | null;
      latest_two_commenters?: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type SearchMagazinesQueryVariables = {
  text?: string | null;
  pagination?: SearchPagination | null;
  sortBy?: MagazineSearchSortBy | null;
  categories?: Array<string | null> | null;
};

export type SearchMagazinesQuery = {
  searchMagazines: {
    __typename: 'MagazineSearchResult';
    count: number;
    results: Array<{
      __typename: 'Magazine';
      id: string;
      page: string;
      photo: string;
      likes_count?: number | null;
      comments_count?: number | null;
      title?: string | null;
      meta_description?: string | null;
      is_liked?: boolean | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      created_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
    } | null>;
  };
};

export type GetPresignedUrlForImageAIDesignQueryVariables = {
  input: AIPresignedUrlInput;
};

export type GetPresignedUrlForImageAIDesignQuery = {
  getPresignedUrlForImageAIDesign: {
    __typename: 'PresignedUrl';
    result: string;
  };
};

export type ListPublishedAIDesignsQueryVariables = {
  input: AIPagination;
  styleSlug?: string | null;
};

export type ListPublishedAIDesignsQuery = {
  listPublishedAIDesigns: {
    __typename: 'AIDesignObjectList';
    count: number;
    results: Array<{
      __typename: 'AIDesignObject';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      sourceImageUrl: string;
      selectedImageIndex?: number | null;
      processedImagesPath?: Array<string | null> | null;
      visibility: string;
      processingType: string;
      roomType?: {
        __typename: 'AIDesignRoomType';
        id: string;
        name: string;
        slug: string;
        image?: string | null;
      } | null;
      style?: {
        __typename: 'AIDesignStyle';
        id: string;
        name: string;
        slug: string;
        image?: string | null;
      } | null;
      status?: AIDesignStatus | null;
    } | null>;
  };
};

export type ListMyAIDesignsQueryVariables = {
  input: AIPagination;
};

export type ListMyAIDesignsQuery = {
  listMyAIDesigns: {
    __typename: 'AIDesignObjectList';
    count: number;
    results: Array<{
      __typename: 'AIDesignObject';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      sourceImageUrl: string;
      selectedImageIndex?: number | null;
      processedImagesPath?: Array<string | null> | null;
      visibility: string;
      processingType: string;
      roomType?: {
        __typename: 'AIDesignRoomType';
        id: string;
        name: string;
        slug: string;
        image?: string | null;
      } | null;
      style?: {
        __typename: 'AIDesignStyle';
        id: string;
        name: string;
        slug: string;
        image?: string | null;
      } | null;
      status?: AIDesignStatus | null;
    } | null>;
  };
};

export type GetAIDesignDetailsQueryVariables = {
  id: string;
};

export type GetAIDesignDetailsQuery = {
  getAIDesignDetails: {
    __typename: 'AIDesignObject';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    sourceImageUrl: string;
    selectedImageIndex?: number | null;
    processedImagesPath?: Array<string | null> | null;
    visibility: string;
    processingType: string;
    roomType?: {
      __typename: 'AIDesignRoomType';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null;
    style?: {
      __typename: 'AIDesignStyle';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null;
    status?: AIDesignStatus | null;
  };
};

export type ListAIRoomTypesQueryVariables = {
  input: AIPagination;
};

export type ListAIRoomTypesQuery = {
  listAIRoomTypes: {
    __typename: 'AIDesignRoomTypeList';
    count: number;
    results?: Array<{
      __typename: 'AIDesignRoomType';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null> | null;
  };
};

export type ListAIStylesQueryVariables = {
  input: AIPagination;
};

export type ListAIStylesQuery = {
  listAIStyles: {
    __typename: 'AIDesignStyleList';
    count?: number | null;
    results?: Array<{
      __typename: 'AIDesignStyle';
      id: string;
      name: string;
      slug: string;
      image?: string | null;
    } | null> | null;
  };
};

export type GetAIOptionsQuery = {
  getAIOptions?: Array<{
    __typename: 'AIOptions';
    name?: string | null;
    slug?: string | null;
    values?: Array<{
      __typename: 'AIOption';
      name?: string | null;
      slug?: string | null;
      image?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetAIDesignRatingQueryVariables = {
  designId: string;
};

export type GetAIDesignRatingQuery = {
  getAIDesignRating?: {
    __typename: 'AIDesignRating';
    averageRating?: number | null;
    numberOfRatings?: number | null;
  } | null;
};

export type GetRequestCreditCostQuery = {
  getRequestCreditCost?: {
    __typename: 'RequestCreditCost';
    value?: number | null;
  } | null;
};

export type ListServiceInquiryRejectionReasonQueryVariables = {
  input?: Pagination | null;
};

export type ListServiceInquiryRejectionReasonQuery = {
  listServiceInquiryRejectionReason?: {
    __typename: 'ServiceInquiryRejectionReasons';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'ServiceInquiryRejectionReason';
      id: string;
      title?: string | null;
      is_note_required?: boolean | null;
    } | null> | null;
  } | null;
};

export type ListSentServiceInquiriesQueryVariables = {
  input?: Pagination | null;
};

export type ListSentServiceInquiriesQuery = {
  listSentServiceInquiries?: {
    __typename: 'ServiceInquiryList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'ServiceInquiry';
      id?: string | null;
      number?: string | null;
      status?: ServiceInquiryStatus | null;
      created_at?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      categories?: {
        __typename: 'CategoryList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null>;
      } | null;
      services?: {
        __typename: 'ServiceList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null>;
      } | null;
      description?: string | null;
      budget_limits?: string | null;
      phone_number?: string | null;
      whatsapp_number?: string | null;
      photos?: {
        __typename: 'ServiceInquiryPhotosList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'ServiceInquiryPhoto';
          photo?: string | null;
        } | null> | null;
      } | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      gig_service_id?: string | null;
      gig_service_title?: string | null;
      gig_service_price?: number | null;
      gig_service_description?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
      type?: ServiceInquiryType | null;
    } | null> | null;
  } | null;
};

export type ListReceivedServiceInquiriesQueryVariables = {
  input?: Pagination | null;
};

export type ListReceivedServiceInquiriesQuery = {
  listReceivedServiceInquiries?: {
    __typename: 'ServiceInquiryList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'ServiceInquiry';
      id?: string | null;
      number?: string | null;
      status?: ServiceInquiryStatus | null;
      created_at?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      categories?: {
        __typename: 'CategoryList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null>;
      } | null;
      services?: {
        __typename: 'ServiceList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null>;
      } | null;
      description?: string | null;
      budget_limits?: string | null;
      phone_number?: string | null;
      whatsapp_number?: string | null;
      photos?: {
        __typename: 'ServiceInquiryPhotosList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'ServiceInquiryPhoto';
          photo?: string | null;
        } | null> | null;
      } | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      gig_service_id?: string | null;
      gig_service_title?: string | null;
      gig_service_price?: number | null;
      gig_service_description?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
      type?: ServiceInquiryType | null;
    } | null> | null;
  } | null;
};

export type GetServiceInquiryQueryVariables = {
  id: string;
};

export type GetServiceInquiryQuery = {
  getServiceInquiry?: {
    __typename: 'ServiceInquiry';
    id?: string | null;
    number?: string | null;
    status?: ServiceInquiryStatus | null;
    created_at?: string | null;
    sender?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    categories?: {
      __typename: 'CategoryList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null>;
    } | null;
    services?: {
      __typename: 'ServiceList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null>;
    } | null;
    description?: string | null;
    budget_limits?: string | null;
    phone_number?: string | null;
    whatsapp_number?: string | null;
    photos?: {
      __typename: 'ServiceInquiryPhotosList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'ServiceInquiryPhoto';
        photo?: string | null;
      } | null> | null;
    } | null;
    city?: {
      __typename: 'City';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      name?: string | null;
      region_id?: string | null;
      country_id?: string | null;
    } | null;
    gig_service_id?: string | null;
    gig_service_title?: string | null;
    gig_service_price?: number | null;
    gig_service_description?: string | null;
    sendbird_channel_name?: string | null;
    sendbird_channel_url?: string | null;
    reviews_total?: number | null;
    reviews_count?: number | null;
    quotations?: {
      __typename: 'QuotationList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results?: Array<{
        __typename: 'Quotation';
        id?: string | null;
        service_inquiry?: {
          __typename: 'ServiceInquiry';
          id?: string | null;
          number?: string | null;
          status?: ServiceInquiryStatus | null;
          created_at?: string | null;
          description?: string | null;
          budget_limits?: string | null;
          phone_number?: string | null;
          whatsapp_number?: string | null;
          gig_service_id?: string | null;
          gig_service_title?: string | null;
          gig_service_price?: number | null;
          gig_service_description?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          type?: ServiceInquiryType | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        created_at?: string | null;
        status?: QuotationStatus | null;
        budget_limits?: string | null;
        execution_time?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
      } | null> | null;
    } | null;
    type?: ServiceInquiryType | null;
  } | null;
};

export type GetQuotationQueryVariables = {
  id: string;
};

export type GetQuotationQuery = {
  getQuotation?: {
    __typename: 'Quotation';
    id?: string | null;
    service_inquiry?: {
      __typename: 'ServiceInquiry';
      id?: string | null;
      number?: string | null;
      status?: ServiceInquiryStatus | null;
      created_at?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      categories?: {
        __typename: 'CategoryList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null>;
      } | null;
      services?: {
        __typename: 'ServiceList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null>;
      } | null;
      description?: string | null;
      budget_limits?: string | null;
      phone_number?: string | null;
      whatsapp_number?: string | null;
      photos?: {
        __typename: 'ServiceInquiryPhotosList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'ServiceInquiryPhoto';
          photo?: string | null;
        } | null> | null;
      } | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      gig_service_id?: string | null;
      gig_service_title?: string | null;
      gig_service_price?: number | null;
      gig_service_description?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
      type?: ServiceInquiryType | null;
    } | null;
    professional?: {
      __typename: 'Professional';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      address?: string | null;
      categories?: Array<{
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: 'Service';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        title?: string | null;
        image?: string | null;
        short_description?: string | null;
        long_description?: string | null;
      } | null> | null;
      locations?: Array<{
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null> | null;
      is_profile_completed?: boolean | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      gallery_photos?: Array<string | null> | null;
      photos?: {
        __typename: 'GalleryPhotoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'GalleryPhoto';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null>;
      } | null;
      is_reviewable?: boolean | null;
      projects_count?: number | null;
      videos?: {
        __typename: 'VideoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Video';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          video?: string | null;
        } | null>;
      } | null;
      contacts?: {
        __typename: 'ContactInfoList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ContactInfo';
          contact_type?: ContactType | null;
          value?: string | null;
        } | null>;
      } | null;
      professional_type?: ProfessionalType | null;
      company_name?: string | null;
      company_logo?: string | null;
      company_registration_ref?: string | null;
      personal_freelance_license?: string | null;
      is_verified?: boolean | null;
      is_gig_professional?: boolean | null;
      gigs_count?: number | null;
      score?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
    } | null;
    created_at?: string | null;
    status?: QuotationStatus | null;
    budget_limits?: string | null;
    execution_time?: string | null;
    sendbird_channel_name?: string | null;
    sendbird_channel_url?: string | null;
  } | null;
};

export type ListMyQuotationsQueryVariables = {
  input: ListQuotationInput;
};

export type ListMyQuotationsQuery = {
  ListMyQuotations?: {
    __typename: 'QuotationList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'Quotation';
      id?: string | null;
      service_inquiry?: {
        __typename: 'ServiceInquiry';
        id?: string | null;
        number?: string | null;
        status?: ServiceInquiryStatus | null;
        created_at?: string | null;
        sender?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        categories?: {
          __typename: 'CategoryList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        services?: {
          __typename: 'ServiceList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        description?: string | null;
        budget_limits?: string | null;
        phone_number?: string | null;
        whatsapp_number?: string | null;
        photos?: {
          __typename: 'ServiceInquiryPhotosList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        gig_service_id?: string | null;
        gig_service_title?: string | null;
        gig_service_price?: number | null;
        gig_service_description?: string | null;
        sendbird_channel_name?: string | null;
        sendbird_channel_url?: string | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        type?: ServiceInquiryType | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      created_at?: string | null;
      status?: QuotationStatus | null;
      budget_limits?: string | null;
      execution_time?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
    } | null> | null;
  } | null;
};

export type ListRFQQueryVariables = {
  input: ListRFQInput;
};

export type ListRFQQuery = {
  ListRFQ?: {
    __typename: 'ServiceInquiryList';
    count: number;
    next?: string | null;
    previous?: string | null;
    results?: Array<{
      __typename: 'ServiceInquiry';
      id?: string | null;
      number?: string | null;
      status?: ServiceInquiryStatus | null;
      created_at?: string | null;
      sender?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      categories?: {
        __typename: 'CategoryList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null>;
      } | null;
      services?: {
        __typename: 'ServiceList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null>;
      } | null;
      description?: string | null;
      budget_limits?: string | null;
      phone_number?: string | null;
      whatsapp_number?: string | null;
      photos?: {
        __typename: 'ServiceInquiryPhotosList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'ServiceInquiryPhoto';
          photo?: string | null;
        } | null> | null;
      } | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      gig_service_id?: string | null;
      gig_service_title?: string | null;
      gig_service_price?: number | null;
      gig_service_description?: string | null;
      sendbird_channel_name?: string | null;
      sendbird_channel_url?: string | null;
      reviews_total?: number | null;
      reviews_count?: number | null;
      quotations?: {
        __typename: 'QuotationList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'Quotation';
          id?: string | null;
          created_at?: string | null;
          status?: QuotationStatus | null;
          budget_limits?: string | null;
          execution_time?: string | null;
          sendbird_channel_name?: string | null;
          sendbird_channel_url?: string | null;
        } | null> | null;
      } | null;
      type?: ServiceInquiryType | null;
    } | null> | null;
  } | null;
};

export type ProjectAddedSubscription = {
  projectAdded?: {
    __typename: 'Project';
    id?: string | null;
    room_type?: {
      __typename: 'RoomType';
      id: string;
      title?: string | null;
      arabic_title?: string | null;
      english_title?: string | null;
      category?: {
        __typename: 'Category';
        id: string;
        title?: string | null;
        english_title?: string | null;
        description?: string | null;
        parent?: {
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null;
        photo?: string | null;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        filters?: string | null;
      } | null;
      status?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      photo?: string | null;
    } | null;
    is_default?: boolean | null;
    title: string;
    status?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    visibility: string;
    client_id?: string | null;
    client?: {
      __typename: 'Client';
      id: string;
      created_at?: string | null;
      updated_at?: string | null;
      status?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      mobile?: string | null;
      country_code?: string | null;
      gender?: string | null;
      type?: string | null;
      about_me?: string | null;
      my_fav_style?: string | null;
      my_next_style?: string | null;
      city_id?: string | null;
      country_id?: string | null;
      city?: {
        __typename: 'City';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        region_id?: string | null;
        country_id?: string | null;
      } | null;
      country?: {
        __typename: 'Country';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        name?: string | null;
        country_flag?: string | null;
        is_default?: boolean | null;
      } | null;
      zip?: string | null;
      state?: string | null;
      following_count?: number | null;
      followers_count?: number | null;
      followees_count?: number | null;
      is_followed?: boolean | null;
      total_review?: number | null;
      discussions_count?: number | null;
      comments_count?: number | null;
      is_verified?: boolean | null;
      is_add_gigs_tutorial_showed?: boolean | null;
      last_login?: string | null;
      profile_image?: string | null;
      facebook?: string | null;
      twitter?: string | null;
      linkedin?: string | null;
      blog?: string | null;
      project_role?: string | null;
      client_awards?: Array<{
        __typename: 'ClientAward';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        code?: string | null;
        image?: string | null;
      } | null> | null;
      client_badges?: Array<{
        __typename: 'ClientBadge';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        key?: string | null;
        title?: string | null;
        value?: number | null;
        image?: string | null;
        description?: string | null;
        client_progress?: number | null;
        is_claimed?: boolean | null;
      } | null> | null;
      projects?: {
        __typename: 'ProjectList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      addresses?: {
        __typename: 'ClientAddressList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'ClientAddress';
          id?: string | null;
          name?: string | null;
          description?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          phone_number?: string | null;
          email?: string | null;
          is_default?: boolean | null;
        } | null>;
      } | null;
      bankcards?: {
        __typename: 'ClientBankCardList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results?: Array<{
          __typename: 'BankCard';
          id?: string | null;
          card_type?: string | null;
          number?: string | null;
          name?: string | null;
          expiry_date?: string | null;
          partner_reference?: string | null;
        } | null> | null;
      } | null;
      related_professional?: {
        __typename: 'Professional';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        address?: string | null;
        categories?: Array<{
          __typename: 'Category';
          id: string;
          title?: string | null;
          english_title?: string | null;
          description?: string | null;
          photo?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          filters?: string | null;
        } | null> | null;
        services?: Array<{
          __typename: 'Service';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          title?: string | null;
          image?: string | null;
          short_description?: string | null;
          long_description?: string | null;
        } | null> | null;
        locations?: Array<{
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null> | null;
        is_profile_completed?: boolean | null;
        reviews_total?: number | null;
        reviews_count?: number | null;
        gallery_photos?: Array<string | null> | null;
        photos?: {
          __typename: 'GalleryPhotoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_reviewable?: boolean | null;
        projects_count?: number | null;
        videos?: {
          __typename: 'VideoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        contacts?: {
          __typename: 'ContactInfoList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        professional_type?: ProfessionalType | null;
        company_name?: string | null;
        company_logo?: string | null;
        company_registration_ref?: string | null;
        personal_freelance_license?: string | null;
        is_verified?: boolean | null;
        is_gig_professional?: boolean | null;
        gigs_count?: number | null;
        score?: number | null;
        quotations?: {
          __typename: 'QuotationList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      sendbird_access_token?: string | null;
      apns_token?: string | null;
      balance?: number | null;
      is_purchased?: boolean | null;
      referral_url?: string | null;
    } | null;
    discussions_count?: number | null;
    ideas_count?: number | null;
    likes_count?: number | null;
    shares_count?: number | null;
    questions_count?: number | null;
    description?: string | null;
    ideas?: Array<{
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null> | null;
    ideasList?: {
      __typename: 'IdeaList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Idea';
        id: string;
        tag?: string | null;
        created_at: string;
        updated_at: string;
        title: string;
        photo?: string | null;
        status?: string | null;
        source: string;
        description?: string | null;
        project_id?: string | null;
        project?: {
          __typename: 'Project';
          id?: string | null;
          is_default?: boolean | null;
          title: string;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          visibility: string;
          client_id?: string | null;
          discussions_count?: number | null;
          ideas_count?: number | null;
          likes_count?: number | null;
          shares_count?: number | null;
          questions_count?: number | null;
          description?: string | null;
          is_liked?: boolean | null;
        } | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        client_id?: string | null;
        questions_count?: number | null;
        shares_count?: number | null;
        likes_count?: number | null;
        is_liked?: boolean | null;
        is_photo_uploaded?: boolean | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        google_vision_result?: string | null;
        paint_colors?: {
          __typename: 'IdeaPaintColorsList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        ideaLabels?: {
          __typename: 'IdeaLabelList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null>;
    } | null;
    is_liked?: boolean | null;
    collaborators?: {
      __typename: 'ClientList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null>;
    } | null;
    default_idea?: {
      __typename: 'Idea';
      id: string;
      tag?: string | null;
      created_at: string;
      updated_at: string;
      title: string;
      photo?: string | null;
      status?: string | null;
      source: string;
      description?: string | null;
      project_id?: string | null;
      project?: {
        __typename: 'Project';
        id?: string | null;
        room_type?: {
          __typename: 'RoomType';
          id: string;
          title?: string | null;
          arabic_title?: string | null;
          english_title?: string | null;
          status?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          photo?: string | null;
        } | null;
        is_default?: boolean | null;
        title: string;
        status?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        visibility: string;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        discussions_count?: number | null;
        ideas_count?: number | null;
        likes_count?: number | null;
        shares_count?: number | null;
        questions_count?: number | null;
        description?: string | null;
        ideas?: Array<{
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null> | null;
        ideasList?: {
          __typename: 'IdeaList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        is_liked?: boolean | null;
        collaborators?: {
          __typename: 'ClientList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        default_idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        questions?: {
          __typename: 'QuestionList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        entities?: {
          __typename: 'EntityList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
      } | null;
      client?: {
        __typename: 'Client';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        mobile?: string | null;
        country_code?: string | null;
        gender?: string | null;
        type?: string | null;
        about_me?: string | null;
        my_fav_style?: string | null;
        my_next_style?: string | null;
        city_id?: string | null;
        country_id?: string | null;
        city?: {
          __typename: 'City';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          region_id?: string | null;
          country_id?: string | null;
        } | null;
        country?: {
          __typename: 'Country';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          name?: string | null;
          country_flag?: string | null;
          is_default?: boolean | null;
        } | null;
        zip?: string | null;
        state?: string | null;
        following_count?: number | null;
        followers_count?: number | null;
        followees_count?: number | null;
        is_followed?: boolean | null;
        total_review?: number | null;
        discussions_count?: number | null;
        comments_count?: number | null;
        is_verified?: boolean | null;
        is_add_gigs_tutorial_showed?: boolean | null;
        last_login?: string | null;
        profile_image?: string | null;
        facebook?: string | null;
        twitter?: string | null;
        linkedin?: string | null;
        blog?: string | null;
        project_role?: string | null;
        client_awards?: Array<{
          __typename: 'ClientAward';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          code?: string | null;
          image?: string | null;
        } | null> | null;
        client_badges?: Array<{
          __typename: 'ClientBadge';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          key?: string | null;
          title?: string | null;
          value?: number | null;
          image?: string | null;
          description?: string | null;
          client_progress?: number | null;
          is_claimed?: boolean | null;
        } | null> | null;
        projects?: {
          __typename: 'ProjectList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        addresses?: {
          __typename: 'ClientAddressList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        bankcards?: {
          __typename: 'ClientBankCardList';
          count: number;
          next?: string | null;
          previous?: string | null;
        } | null;
        related_professional?: {
          __typename: 'Professional';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          address?: string | null;
          is_profile_completed?: boolean | null;
          reviews_total?: number | null;
          reviews_count?: number | null;
          gallery_photos?: Array<string | null> | null;
          is_reviewable?: boolean | null;
          projects_count?: number | null;
          professional_type?: ProfessionalType | null;
          company_name?: string | null;
          company_logo?: string | null;
          company_registration_ref?: string | null;
          personal_freelance_license?: string | null;
          is_verified?: boolean | null;
          is_gig_professional?: boolean | null;
          gigs_count?: number | null;
          score?: number | null;
        } | null;
        sendbird_access_token?: string | null;
        apns_token?: string | null;
        balance?: number | null;
        is_purchased?: boolean | null;
        referral_url?: string | null;
      } | null;
      client_id?: string | null;
      questions_count?: number | null;
      shares_count?: number | null;
      likes_count?: number | null;
      is_liked?: boolean | null;
      is_photo_uploaded?: boolean | null;
      questions?: {
        __typename: 'QuestionList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'Question';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          title?: string | null;
          description?: string | null;
          idea_id?: string | null;
          client_id?: string | null;
          likes_count?: number | null;
          comments_count?: number | null;
          is_liked?: boolean | null;
        } | null>;
      } | null;
      google_vision_result?: string | null;
      paint_colors?: {
        __typename: 'IdeaPaintColorsList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaPaintColor';
        } | null>;
      } | null;
      ideaLabels?: {
        __typename: 'IdeaLabelList';
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<{
          __typename: 'IdeaLabel';
          id?: string | null;
          name?: string | null;
          related_stockrecords_count?: number | null;
        } | null>;
      } | null;
    } | null;
    questions?: {
      __typename: 'QuestionList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Question';
        id: string;
        created_at?: string | null;
        updated_at?: string | null;
        status?: string | null;
        title?: string | null;
        description?: string | null;
        idea_id?: string | null;
        idea?: {
          __typename: 'Idea';
          id: string;
          tag?: string | null;
          created_at: string;
          updated_at: string;
          title: string;
          photo?: string | null;
          status?: string | null;
          source: string;
          description?: string | null;
          project_id?: string | null;
          client_id?: string | null;
          questions_count?: number | null;
          shares_count?: number | null;
          likes_count?: number | null;
          is_liked?: boolean | null;
          is_photo_uploaded?: boolean | null;
          google_vision_result?: string | null;
        } | null;
        client_id?: string | null;
        client?: {
          __typename: 'Client';
          id: string;
          created_at?: string | null;
          updated_at?: string | null;
          status?: string | null;
          first_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          mobile?: string | null;
          country_code?: string | null;
          gender?: string | null;
          type?: string | null;
          about_me?: string | null;
          my_fav_style?: string | null;
          my_next_style?: string | null;
          city_id?: string | null;
          country_id?: string | null;
          zip?: string | null;
          state?: string | null;
          following_count?: number | null;
          followers_count?: number | null;
          followees_count?: number | null;
          is_followed?: boolean | null;
          total_review?: number | null;
          discussions_count?: number | null;
          comments_count?: number | null;
          is_verified?: boolean | null;
          is_add_gigs_tutorial_showed?: boolean | null;
          last_login?: string | null;
          profile_image?: string | null;
          facebook?: string | null;
          twitter?: string | null;
          linkedin?: string | null;
          blog?: string | null;
          project_role?: string | null;
          sendbird_access_token?: string | null;
          apns_token?: string | null;
          balance?: number | null;
          is_purchased?: boolean | null;
          referral_url?: string | null;
        } | null;
        likes_count?: number | null;
        comments_count?: number | null;
        is_liked?: boolean | null;
      } | null>;
    } | null;
    entities?: {
      __typename: 'EntityList';
      count: number;
      next?: string | null;
      previous?: string | null;
      results: Array<{
        __typename: 'Entity';
        created_at: string;
        updated_at: string;
        status?: string | null;
        photo?: string | null;
        description?: string | null;
        tag?: string | null;
        client_id?: string | null;
        project_id?: string | null;
        entity_id?: string | null;
        title?: string | null;
        is_main_entity?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export type ServiceInqueryMessageAddedSubscriptionVariables = {
  userId: string;
};

export type ServiceInqueryMessageAddedSubscription = {
  serviceInqueryMessageAdded?: {
    __typename: 'ServiceInqueryMessageOutput';
    userId: string;
    has_unread_messages: boolean;
  } | null;
};

export type PaymentStatusUpdatedSubscriptionVariables = {
  checkout_id: string;
};

export type PaymentStatusUpdatedSubscription = {
  paymentStatusUpdated?: {
    __typename: 'PaymentStatusResponse';
    checkout_id: string;
    package_id: string;
    status: PaymentStatus;
    message?: string | null;
  } | null;
};

export type ImageProcessedSubscriptionVariables = {
  outstandingId: string;
};

export type ImageProcessedSubscription = {
  imageProcessed?: {
    __typename: 'ImageNotificationResponse';
    outstandingId?: string | null;
    recommendedImages?: Array<string | null> | null;
    error?: string | null;
  } | null;
};

export type ServiceInquiryFilter = {
  inquiry_status?: ServiceInquiryStatus;
  inquiry_section?: ServiceInquirySection;
  inquiry_number?: string | null;
};

export type AIVendorsFilter = {
  is_premium?: boolean | null;
  object_id?: string | null;
};

export type Vendors = {
  id: string | null;
  name: string | null;
  photo: string | null;
  products_count: number | null;
  is_premium: boolean | null;
};

export type AIProductViewCreditResult = {
  object_id: string | null;
  outstanding_id: string | null;
};
