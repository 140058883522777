/* eslint-disable @typescript-eslint/naming-convention */
export const LOGIN_ROUTE = '/login';
export const SIGN_UP_ROUTE = '/signup';
export const CHANGE_PASSWORD_ROUTE = '/change-password';
export const SET_PASSWORD_ROUTE = '/set-password';
export const FEDERATED_LOGIN_ROUTE = '/federated-login';
export const PROJECTS_ROUTE = '/projects';
export const PROFILE_ROUTE = '/edit-profile';
export const MY_ORDERS_ROUTE = '/edit-profile/my-orders';
export const PROJECT_ROUTE = '/project/:id';
export const RESET_PASSWORD_ROUTE = '/reset-new-password/username=:username';
export const IDEA_ROUTE = '/idea/:name?/:projectId/:ideaId?';
export const ARTICLE_ROUTE = '/magazine/:name?/:id';
export const FORGET_PASSWORD_ROUTE = '/forget-password';
export const DISCUSSION_ROUTE = '/discussion';
export const DISCUSSIONS_ROUTE = '/discussions/:id?';
export const GENERIC_DISCUSSIONS_ROUTE = '/discussions';
export const MAGAZINE_ROUTE = '/magazines/:id?';
export const GENERIC_MAGAZINE_ROUTE = '/magazine';
export const MAGAZINES_ROUTE = '/magazines';
export const TV_ROUTE = '/tv';
export const PROFESSIONALS_ROUTE = '/professionals';
export const REQUEST_PROFESSIONAL_SERVICE_ROUTE = '/request-professional-service';
export const REQUEST_QUOTATION_SERVICE_ROUTE = '/request-quotation-service';
export const PROFESSIONAL_SERVICE_REQUESTS_LIST_ROUTE = '/edit-profile/service-requests';
export const PROFESSIONAL_REQUESTS_WORKS_ON = '/edit-profile/requests-works-on';
export const PROFESSIONAL_RFQ_ROUTE = '/edit-profile/quotations-requests/:id?';
export const BRANDS_ROUTE = '/brands';
export const BRAND_ROUTE = '/brand/:id';
export const COMPLETE_PROFILE_ROUTE = '/complete-profile';
export const PRODUCTS_MAIN_ROUTE = '/products';
export const PRODUCTS_ROUTE = '/products/*';
export const PRODUCT_ROUTE = '/product/:id?';
export const PRODUCTS_SEARCH_ROUTE = '/products/search';
export const DEPARTMENTS_PAGE = '/departments';
export const PRIVACY_POLICY = '/privacy-policy';
export const ADD_REVIEW = '/:stockRecordId/add-review';
export const UPDATE_REVIEW = '/:stockRecordId/add-review/:reviewId';
export const IDEAS_ROUTE = '/ideas';
export const WHO_WE_ARE_ROUTE = '/about';
export const CONTACT_US_ROUT = '/contact-us';
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions';
export const PUBLIC_PROFILE_ROUTE = '/client/:id';
export const CLIENTS_LANDING_PAGE_ROUTE = '/client';
export const PROFESSIONALS_LANDING_PAGE_ROUTE = '/professional';
export const PROFESSIONAL_PUBLIC_ROUTE = '/professional/:id';
export const VENDOR_ROUTE = '/vendor';
export const CHECKOUT_ROUTE = '/checkout';
export const COMING_SOON_ROUTE = '/coming-soon';
export const NOT_FOUND_ROUTE = '/404';
export const MY_GIGS_LIST_ROUTE = '/edit-profile/my-gigs';
export const REQUEST_GIG_SERVICE_ROUTE = '/request-gig-service';
export const MESSENGER_ROUTE = '/messenger/:id?';
export const MESSENGER_MOBILE_ROUTE = '/messenger-mobile/:id?';
export const MANZILIK_AI_ROUTE = '/manzilik-ai';
export const MANZILIK_AI_SUGGESTED_PRODUCTS_ROUTE = '/manzilik-ai/suggested-products';
export const MANZILIK_AI_DESIGN_DETAILS = '/ai-design-details';
export const AI_CHECKOUT_ROUTE = '/ai-checkout';
export const HOW_MANZILIK_AI_ROUTE = '/how-manzilik-ai-works';
export const AI_RESULTS_ROUTE = '/ai-results/:id?';
export const AI_CHECKOUT_REDIRECT_ROUTE = '/payment-status/redirect';
export const CLIENT_TYPE_PAGE = '/client-type';
export const REQUESTS_FOR_QUOTATIONS = '/requests-for-quotations';
export const TEST_PAGE = '/test';
