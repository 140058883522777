/* eslint-disable @typescript-eslint/naming-convention */
export const SAR = 'SAR';
export const DISCOUNT = 'DISCOUNT';
export const EMAIL = 'EMAIL';
export const JOKER = 'JOKER';
export const LOGIN = 'LOGIN';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const LOGOUT = 'LOGOUT';
export const PASSWORD = 'PASSWORD';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_WITH_EMAIL = 'SIGNUP_WITH_EMAIL';
export const CONTINUE_WITH_GOOGLE = 'CONTINUE_WITH_GOOGLE';
export const CONTINUE_WITH_FACEBOOK = 'CONTINUE_WITH_FACEBOOK';
export const OR = 'OR';
export const PLEASE_WRITE_VALID_EMAILS = 'PLEASE_WRITE_VALID_EMAILS';
export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';
export const EMAIL_REQUIRED_MESSAGE = 'EMAIL_REQUIRED_MESSAGE';
export const PASSWORD_REQUIRED_MESSAGE = 'PASSWORD_REQUIRED_MESSAGE';
export const REGEX_DOESNT_MATCH_MSG = 'REGEX_DOESNT_MATCH_MSG';
export const INVALID_CARD_HOLDER = 'INVALID_CARD_HOLDER';
export const MISSING_FIRST_NAME = 'MISSING_FIRST_NAME';
export const MISSING_LAST_NAME = 'MISSING_LAST_NAME';
export const HOME_PAGE_HEADING = 'HOME_PAGE_HEADING';
export const HOME_PAGE_DESCRIPTION = 'HOME_PAGE_DESCRIPTION';
export const CONFIRM = 'CONFIRM';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const RESEND_CONFIRMATION = 'RESEND_CONFIRMATION';
export const FIND_IDEAS = 'FIND_IDEAS';
export const CONFIRM_CODE_TITLE = 'CONFIRM_CODE_TITLE';
export const SIGNUP_WITH = 'SIGNUP_WITH';
export const LOGIN_BY_EMAIL = 'LOGIN_BY_EMAIL';
export const BACK_TO_LOGIN = 'BACK_TO_LOGIN';
export const USERNAME = 'USERNAME';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const KEEP_SIGNED_IN = 'KEEP_SIGNED_IN';
export const SIGNIN = 'SIGNIN';
export const SIGN_IN_POLICY = 'SIGN_IN_POLICY';
export const COMMON_QUESTIONS = 'COMMON_QUESTIONS';
export const COMMON_QUESTION_1 = 'COMMON_QUESTION_1';
export const COMMON_QUESTION_2 = 'COMMON_QUESTION_2';
export const COMMON_QUESTION_3 = 'COMMON_QUESTION_3';
export const COMMON_QUESTION_4 = 'COMMON_QUESTION_4';
export const COMMON_QUESTION_5 = 'COMMON_QUESTION_5';
export const COMMON_QUESTION_6 = 'COMMON_QUESTION_6';
export const LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK';
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE';
export const LOGIN_BY_SOCIAL_MEDIA = 'LOGIN_BY_SOCIAL_MEDIA';
export const MANZIL_LOGIN = 'MANZIL_LOGIN';
export const FORGET_YOUR_PASSWORD = 'FORGET_YOUR_PASSWORD';
export const RESET_YOUR_PASSWORD = 'RESET_YOUR_PASSWORD';
export const FORGET_PASSWORD_DESCRIPTION = 'FORGET_PASSWORD_DESCRIPTION';
export const HELLO = 'HELLO';
export const RE_ENTER_PASSWORD = 'RE_ENTER_PASSWORD';
export const SUBMIT = 'SUBMIT';
export const NEW_PASSWORD_TERMS = 'NEW_PASSWORD_TERMS';
export const CONTINUE = 'CONTINUE';
export const PASSWORD_DONT_MATCH = 'PASSWORD_DONT_MATCH';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const CODE_MESSAGE = 'CODE_MESSAGE';
export const CODE_TEXT = 'CODE_TEXT';
export const RESET_PASSWORD_SUCCESS_MSG = 'RESET_PASSWORD_SUCCESS_MSG';
export const MOBILE = 'MOBILE';
export const MAIL = 'MAIL';
export const HAVE_ACCOUNT = 'HAVE_ACCOUNT';
export const SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE';
export const SAVE_PASSWORD = 'SAVE_PASSWORD';
//GENERIC
export const GENERIC = 'GENERIC';
export const CANCEL = 'CANCEL';
export const DELETE = 'DELETE';
export const ARABIC = 'ARABIC';
export const ENGLISH = 'ENGLISH';
export const SEND = 'SEND';
export const WEBSITE = 'WEBSITE';
//Header Component
export const GET_IDEAS = 'GET_IDEAS';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const PROJECTS = 'PROJECTS';
export const MORE = 'MORE';

// CREATE MODAL COMPONANT
export const PROJECT = 'PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const PROJECT_NAME = 'PROJECT_NAME';
export const ENTER_PROJECT_NAME = 'ENTER_PROJECT_NAME';
export const PRIVATE = 'PRIVATE';
export const PUBLIC = 'PUBLIC';
export const CHOOSE_SPACE = 'CHOOSE_SPACE';
export const OPTIONAL = 'OPTIONAL';
export const CREATE = 'CREATE';
export const LOADING = 'LOADING';
// MOVE IDEA MODAL
export const MOVE_IDEA = 'MOVE_IDEA';
// VALIDATION MESSAGES
export const REQUIRED = 'REQUIRED';
// USERBOX COMPONENT
export const VIEW_PROFILE = 'VIEW_PROFILE';
export const VIEW_ALL = 'VIEW_ALL';
export const SEARCH_IDEAS = 'SEARCH_IDEAS';

// PROJECT PAGE
export const INVITE_FRIEND = 'INVITE_FRIEND';
export const FILTER_BY = 'FILTER_BY';
export const MY_IDEAS = 'MY_IDEAS';
export const MORE_IDEAS_FROM_MANZEL = 'MORE_IDEAS_FROM_MANZEL';
export const ADD_NOTE = 'ADD_NOTE';
export const MOVE = 'MOVE';
export const COPY = 'COPY';
export const REMOVE = 'REMOVE';
export const SELECT_ALL = 'SELECT_ALL';
export const ORGANIZE = 'ORGANIZE';
export const CHANGE_TO_GALLERY_VIEW = 'CHANGE_TO_GALLERY_VIEW';
export const ADD_NEW_IDEA = 'ADD_NEW_IDEA';
export const EXPORT = 'EXPORT';
// PROJECTS LIST PAGE
export const ACTIVITY = 'ACTIVITY';
// SEND MESSAGE FORM
export const SEND_BY_EMAIL = 'SEND_BY_EMAIL';
export const ENTER_EMAIL_RECIPIENTS = 'ENTER_EMAIL_RECIPIENTS';
export const MESSAGE_SUBJECT = 'MESSAGE_SUBJECT';
export const MESSAGE = 'MESSAGE';
export const MESSAGE_INPUT_PLACE_HOLDER = 'MESSAGE_INPUT_PLACE_HOLDER';
export const MESSAGE_IS_SENT = 'MESSAGE_IS_SENT';
export const LINK_COPIED = 'LINK_COPIED';
export const TO = 'TO';
export const IMPORT_GMAIL_CONTACT = 'IMPORT_GMAIL_CONTACT';
export const PREVIEW = 'PREVIEW';
export const SEE_THIS_PROJECT_ON_MANZILIK = 'SEE_THIS_PROJECT_ON_MANZILIK';
// ADD IDEA FROM
export const UPLOAD_TERMS = 'UPLOAD_TERMS';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPLOAD_PHOTOS = 'UPLOAD_PHOTOS';
export const UPLOADED_IMAGES_SUCCESSFULLY = 'UPLOADED_IMAGES_SUCCESSFULLY';
export const SAVE_FROM_WEB = 'SAVE_FROM_WEB';
export const UPLOAD = 'UPLOAD';
export const ADD_PHOTO = 'ADD_PHOTO';
export const EDIT_PROJECT_INFO = 'EDIT_PROJECT_INFO';
export const TITLE = 'TITLE';
export const DESCRIPTION = 'DESCRIPTION';
export const EDIT = 'EDIT';
export const WRITE_DESCRIPTION = 'WRITE_DESCRIPTION';
export const ENTER_PROJECT_DESCRIPTION = 'ENTER_PROJECT_DESCRIPTION';
export const ENTER_PROJECT_STATUS = 'ENTER_PROJECT_STATUS';
export const ENTER_PROJECT_CATEGORY = 'ENTER_PROJECT_CATEGORY';
export const PUBLISH_STATUS = 'PUBLISH_STATUS';
export const PUBLIC_WORD = 'PUBLIC_WORD';
export const PRIVATE_WORD = 'PRIVATE_WORD';
export const CATEGORY_TYPE = 'CATEGORY_TYPE';

// ----------------------- Footer ------------------------------ //
export const FOOTER_CREDITS = 'FOOTER_CREDITS';
export const COMPANY = 'COMPANY';
export const ABOUT_MANZEL = 'ABOUT_MANZEL';
export const PRIVACY_AND_POLICY = 'PRIVACY_AND_POLICY';
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
export const COOKIE_POLICY = 'COOKIE_POLICY';
export const GET_HELP = 'GET_HELP';
export const GET_INSPIRED = 'GET_INSPIRED';
export const MANZEL_SUPPORT = 'MANZEL_SUPPORT';
export const FACEBOOK = 'FACEBOOK';
export const TWITTER = 'TWITTER';
export const INSTAGRAM = 'INSTAGRAM';
export const YOUTUBE = 'YOUTUBE';
export const RSS = 'RSS';
export const CONTACT = 'CONTACT';
export const CONNECT_WITH_US = 'CONNECT_WITH_US';
export const DOWNLOAD_APPLICATION = 'DOWNLOAD_APPLICATION';
export const FOLLOW_US = 'FOLLOW_US';
export const AVAILABLE_APPLE = 'AVAILABLE_APPLE';
export const APP_STORE = 'APP_STORE';
export const GET_APPLICATION = 'GET_APPLICATION';
export const GOOGLE_PLAY = 'GOOGLE_PLAY';
export const ALL_RIGHTS = 'ALL_RIGHTS';

export const UPDATE = 'UPDATE';
export const ARE_YOU_SURE_DELETE_MSG = 'ARE_YOU_SURE_DELETE_MSG';
export const ARE_YOU_SURE_DELETE_IDEA = 'ARE_YOU_SURE_DELETE_IDEA';
export const YES = 'YES';
export const NO = 'NO';
export const DROP_FILE_HERE = 'DROP_FILE_HERE';
// invite friend modal
export const INVITE = 'INVITE';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const CAN_EDIT = 'CAN_EDIT';
export const CAN_VIEW = 'CAN_VIEW';
export const WHO_HAS_ACCESS = 'WHO_HAS_ACCESS';
export const CURRENTLY_SHARE_WITH = 'CURRENTLY_SHARE_WITH';
export const INVITE_BY_EMAIL_OR_NAME = 'INVITE_BY_EMAIL_OR_NAME';
export const INVITE_BY_EMAIL_OR_NAME_PLACEHOLDER = 'INVITE_BY_EMAIL_OR_NAME_PLACEHOLDER';
export const DELETE_FRIEND = 'DELETE_FRIEND';
export const YOU = 'YOU';

// --------------------------------- edit profile page ----------------------------

export const EDIT_PROFILE = 'EDIT_PROFILE';
export const ACCOUNT = 'ACCOUNT';
export const ACCOUNT_INFORMATION = 'ACCOUNT_INFORMATION';
export const PROFILE_INFO = 'PROFILE_INFO';
export const CONTACT_INFO = 'CONTACT_INFO';
export const SOCIAL_MEDIA_SETTINGS = 'SOCIAL_MEDIA_SETTINGS';
export const ADVANCE_SETTINGS = 'ADVANCE_SETTINGS';
export const PRIVACY_SETTINGS = 'PRIVACY_SETTINGS';
export const PROFILE_INFORMATION = 'PROFILE_INFORMATION';
export const FIRST_NAME = 'FIRST_NAME';
export const COMPANY_NAME = 'COMPANY_NAME';
export const COMPANY_LOGO = 'COMPANY_LOGO';
export const COMPANY_REGISTERATION_REF = 'COMPANY_REGISTERATION_REF';
export const PERSONAL_FREELANCE_LICENSE = 'PERSONAL_FREELANCE_LICENSE';
export const SELECT_ACCOUNT_TYPE = 'ACCOUNT_TYPE';
export const PERSONAL_ACCOUNT_TYPE = 'PERSONAL_ACCOUNT_TYPE';
export const ERROR_ACCOUNT_TYPE_NOT_SELECTED = 'ERROR_ACCOUNT_TYPE_NOT_SELECTED';
export const COMPANY_ACCOUNT_TYPE = 'COMPANY_ACCOUNT_TYPE';
export const LAST_NAME = 'LAST_NAME';
export const PUBLICLY_DISPLAYED = 'PUBLICLY_DISPLAYED';
export const ABOUT_ME = 'ABOUT_ME';
export const MY_FAVORITE_STYLE = 'MY_FAVORITE_STYLE';
export const MY_NEXT_MANZILIK_PROJECT = 'MY_NEXT_MANZILIK_PROJECT';
export const USER_NAME = 'USER_NAME';
export const BY = 'BY';
export const PROFILE_DETAILS_UPDATED_SUCCESSFULLY = 'PROFILE_DETAILS_UPDATED_SUCCESSFULLY';
export const PROCEED_TO = 'PROCEED_TO';
export const YOUR_MANZILIK_PROJECTS = 'YOUR_MANZILIK_PROJECTS';
export const PREVIEW_YOUR = 'PREVIEW_YOUR';
export const PUBLIC_PROFILE = 'PUBLIC_PROFILE';
export const YOU_CAN_ONLY_UPLOAD_JPG = 'YOU_CAN_ONLY_UPLOAD_JPG';
export const OLD_PASSWORD = 'OLD_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD';
export const CONTACT_INFORMATION = 'CONTACT_INFORMATION';
export const COUNTRY = 'COUNTRY';
export const CITY = 'CITY';
export const STATE = 'STATE';
export const ZIP = 'ZIP';
export const SAVED_TO = 'SAVED_TO';
export const UPDATED_NOTIFICATIONS_SUCCESS = 'UPDATED_NOTIFICATIONS_SUCCESS';
export const ABOUT_SHOULD_BE_MORE_THAN_FIFTY_CHARACTER = 'ABOUT_SHOULD_BE_MORE_THAN_FIFTY_CHARACTER';
export const ADDRESSES = 'ADDRESSES';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADDRESS_TITLE = 'ADDRESS_TITLE';
export const NEIGHBORHOOD = 'NEIGHBORHOOD';
export const ADDRESS_DETAILS = 'ADDRESS_DETAILS';
export const UPDATE_ADDRESS_TITLE = 'UPDATE_ADDRESS_TITLE';
export const SAVE_UPDATES = 'SAVE_UPDATES';
export const NEW_ADDRESS = 'NEW_ADDRESS';
// --------------------------------- edit profile video page -----------------------------------------
export const PROFILE_VIDEOS = 'THE_VIDEOS';
export const VIDEO_EXAMPLE = 'VIDEO_EXAMPLE';
export const ADD_YOUTUBE_LINK = 'ADD_YOUTUBE_LINK';
export const ADD_VALID_YOUTUBE_LINK = 'ADD_VALID_YOUTUBE_LINK';
// --------------------------------- idea  page ----------------------------

export const SAVE = 'SAVE';
export const QUESTION_SUBMIT = 'QUESTION_SUBMIT';
// --------------------------------- edit profile photo  page ----------------------------

export const OTHER_PHOTO_IN = 'OTHER_PHOTO_IN';
export const SEE_MORE_PHOTOS = 'SEE_MORE_PHOTOS';
export const SEE_MORE_VIDEOS = 'SEE_MORE_VIDEOS';
export const SAVE_IDEA = 'SAVE_IDEA';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const WHAT_DO_YOU_LIKE_ABOUT_THIS_IDEA = 'WHAT_DO_YOU_LIKE_ABOUT_THIS_IDEA';
export const FOLLOW = 'FOLLOW';
export const UN_FOLLOW = 'UN_FOLLOW';
export const WRITE_COMMENT = 'WRITE_COMMENT';
export const QUESTION_ABOUT_THIS_PHOTO = 'QUESTION_ABOUT_THIS_PHOTO';
export const SHOW = 'SHOW';
export const MORE_QUESTIONS = 'MORE_QUESTIONS';
export const MORE_COMMENTS = 'MORE_COMMENTS';
export const ASK_QUESTION = 'ASK_QUESTION';
export const I_HAVE_ANOTHER_QUESTION = 'I_HAVE_ANOTHER_QUESTION';
export const ASK_QUESTION_ABOUT_THIS_PHOTO = 'ASK_QUESTION_ABOUT_THIS_PHOTO';
export const TELL_US_THE_DETAILS = 'TELL_US_THE_DETAILS';
export const YOUR_QUESTION = 'YOUR_QUESTION';
export const OK = 'OK';
export const PEOPLE_WHO_LIKE_THIS = 'PEOPLE_WHO_LIKE_THIS';
export const ARE_YOU_SURE_DELETE_COMMENT_MSG = 'ARE_YOU_SURE_DELETE_COMMENT_MSG';

//-------------------------------- edit profile -social media --------------------------

export const LINKEDIN = 'LINKEDIN';
export const WEBSITE_OR_BLOG = 'WEBSITE_OR_BLOG';
export const YOUR_PROFILE_PHOTO = 'YOUR_PROFILE_PHOTO';
export const DRAG_THE_CORNER_OF_THE_BOX_TO_CHANGE_POSITION_AND_SIZE_OR_UPLOAD_A_NEW_IMAGE =
  'DRAG_THE_CORNER_OF_THE_BOX_TO_CHANGE_POSITION_AND_SIZE_OR_UPLOAD_A_NEW_IMAGE';
export const CLICK_OR_DRAG_DROP_FILES_HERE_TO_UPLOAD = 'CLICK_OR_DRAG_DROP_FILES_HERE_TO_UPLOAD';
export const YOU_CAN_UPLOAD_A_JPG_GIF_OR_PNG_FILE = 'YOU_CAN_UPLOAD_A_JPG_GIF_OR_PNG_FILE';

// --------------------------------- privacy settings page ----------------------------

export const YOUR_PRIVACY_SETTINGS = 'YOUR_PRIVACY_SETTINGS';
export const SMS_OFFERS = 'SMS_OFFERS';
export const SEND_ME_SMS_FROM_MANZILIK_WITH_TIPS = 'SEND_ME_SMS_FROM_MANZILIK_WITH_TIPS';
export const IT_MAY_TAKE_UP_TO_3_DAYS_FOR_US_TO_FULLY_PROCESS = 'IT_MAY_TAKE_UP_TO_3_DAYS_FOR_US_TO_FULLY_PROCESS';
export const DISCLOSE_MY_INFO_FOR_COMMERCIAL_PURPOSES = 'DISCLOSE_MY_INFO_FOR_COMMERCIAL_PURPOSES';
export const UNDER_CALIFORNIA_LAW_LEARN_MORE_HERE = 'UNDER_CALIFORNIA_LAW_LEARN_MORE_HERE';
export const MARKETING_AND_EMAIL_COMMUNICATIONS = 'MARKETING_AND_EMAIL_COMMUNICATIONS';
export const MANAGE_YOUR_EMAIL_SETTINGS = 'MANAGE_YOUR_EMAIL_SETTINGS';
export const ADDRESS_BOOK = 'ADDRESS_BOOK';
export const DELETE_MY_ADDRESS_BOOK_CONTACT = 'DELETE_MY_ADDRESS_BOOK_CONTACT';
export const DOWNLOAD_A_COPY_OF_YOUR_DATA = 'DOWNLOAD_A_COPY_OF_YOUR_DATA';

//-------------------------------- idea search page --------------------------
export const SEARCH = 'SEARCH';
export const IN_ALL_PHOTOS = 'IN_ALL_PHOTOS';
export const ALL_FILTERS = 'ALL_FILTERS';
export const STYLE = 'STYLE';
export const SIZE = 'SIZE';
export const COLOR = 'COLOR';
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export const LIVING_ROOM = 'LIVING_ROOM';
export const KITCHEN_IN_PHOTOS = 'KITCHEN_IN_PHOTOS';
export const BATHROOM_IN_PHOTOS = 'BATHROOM_IN_PHOTOS';
export const SEND_EMAIL = 'SEND_EMAIL';
export const REFINE_BY = 'REFINE_BY';
export const SORT_BY = 'SORT_BY';
export const OF = 'OF';
export const LOCATION = 'LOCATION';
export const RELEVANCE = 'RELEVANCE';
export const CLEAR_ALL = 'CLEAR_ALL';
export const BUDGET = 'BUDGET';
export const TYPE = 'TYPE';
export const SEARCH_IDEA = 'SEARCH_IDEA';
export const MOST_SEARCHED = 'MOST_SEARCHED';
// --------------------------------- advanced settings page ----------------------------

export const ADVANCED_SETTINGS = 'ADVANCED_SETTINGS';
export const NEWSLETTER_EDITION = 'NEWSLETTER_EDITION';
export const EMAIL_PREFERENCES = 'EMAIL_PREFERENCES';
export const RECEIVE_EMAILS_ABOUT_MY_FOLLOWERS = 'RECEIVE_ONLY_EMAILS_ABOUT_MY_FOLLOWERS';
export const RECEIVE_ONLY_EMAILS_ABOUT_NEW_FOLLOWERS = 'RECEIVE_ONLY_EMAILS_ABOUT_NEW_FOLLOWERS';
export const CUSTOMIZE_MY_EMAIL_SUBSCRIPTIONS_BELOW = 'CUSTOMIZE_MY_EMAIL_SUBSCRIPTIONS_BELOW';
export const DAILY_NOTIFICATIONS = 'DAILY_NOTIFICATIONS';
export const WEBSITE_NOTIFICATIONS = 'WEBSITE_NOTIFICATIONS';
export const EMAIL_SUBSCRIPTIONS = 'EMAIL_SUBSCRIPTIONS';
export const MANZILIK_MAGAZINE_HOME_TOURS = 'MANZILIK_MAGAZINE_HOME_TOURS';
export const MANZILIK_SHOP_TRENDING_PRODUCTS_AND_SALES = 'MANZILIK_SHOP_TRENDING_PRODUCTS_AND_SALES';
export const PRODUCT_ALERTS_PRICE_DROPS = 'PRODUCT_ALERTS_PRICE_DROPS';
export const PERSONALIZED_RECOMMENDATIONS = 'PERSONALIZED_RECOMMENDATIONS';
export const MANZILIK_TV_SURVEYS_AND_OTHER_UPDATES = 'MANZILIK_TV_SURVEYS_AND_OTHER_UPDATES';
export const MANZILIK_REFERRAL_COUPONS = 'MANZILIK_REFERRAL_COUPONS';
export const MANZILIK_UPDATES_NEAR_ME = 'MANZILIK_UPDATES_NEAR_ME';
export const TIPS_GUIDES_AND_NEW_FEATURE_ANNOUNCEMENTS = 'TIPS_GUIDES_AND_NEW_FEATURE_ANNOUNCEMENTS';
export const USER_ACTIVITY_NOTIFICATIONS = 'USER_ACTIVITY_NOTIFICATIONS';
export const SEND_ME_AN_EMAIL_IF = 'SEND_ME_AN_EMAIL_IF';
export const THERE_IS_AN_ISSUE_WITH_MY_ACCOUNT_OR_LOGIN = 'THERE_IS_AN_ISSUE_WITH_MY_ACCOUNT_OR_LOGIN';
export const SOMEONE_FOLLOWS_ME = 'SOMEONE_FOLLOWS_ME';
export const SOMEONE_MESSAGES_ME = 'SOMEONE_MESSAGES_ME';
export const SOMEONE_COMMENTS_ON_MY_IDEABOOK = 'SOMEONE_COMMENTS_ON_MY_IDEABOOK';
export const SOMEONE_COMMENTS_ON_MY_POST = 'SOMEONE_COMMENTS_ON_MY_POST';
export const NOTIFICATION_CENTER = 'NOTIFICATION_CENTER';
export const SHOW_ACTIVITIES_FROM_USERS_I_AM_FOLLOWING = 'SHOW_ACTIVITIES_FROM_USERS_I_AM_FOLLOWING';
export const MY_PROFILE_PAGES = 'MY_PROFILE_PAGES';
export const THE_FOLLOWING_WILL_BE_VISIBLE_ON_MY_PUBLIC_PROFILE = 'THE_FOLLOWING_WILL_BE_VISIBLE_ON_MY_PUBLIC_PROFILE';
export const MY_PUBLIC_IDEABOOKS = 'MY_PUBLIC_IDEABOOKS';
export const MY_UPLOADED_PHOTOS_AND_PROJECTS = 'MY_UPLOADED_PHOTOS_AND_PROJECTS';
export const MY_DISCUSSIONS = 'MY_DISCUSSIONS';
export const MY_SOCIAL_MEDIA_PROFILES = 'MY_SOCIAL_MEDIA_PROFILES';
export const PEOPLE_WHO_FOLLOW_ME = 'PEOPLE_WHO_FOLLOW_ME';
export const PEOPLE_I_FOLLOW = 'PEOPLE_I_FOLLOW';
export const DEFAULT_PHOTO_ORDER_IN_MY_IDEABOOKS = 'DEFAULT_PHOTO_ORDER_IN_MY_IDEABOOKS';
export const CLIMATE_ZONE_FOR_GARDEN_FORUMS = 'CLIMATE_ZONE_FOR_GARDEN_FORUMS';
export const MY_GARDEN_WEB_TRADING_LIST = 'MY_GARDEN_WEB_TRADING_LIST';
export const ALLOW_USERS_TO_FOLLOW_MY_PROFILE = 'ALLOW_USERS_TO_FOLLOW_MY_PROFILE';
export const NOTE_IF_THIS_IS_UNCHECKED = 'NOTE_IF_THIS_IS_UNCHECKED';
export const WHO_CAN_MESSAGE_ME = 'WHO_CAN_MESSAGE_ME';
export const ENABLE_ADD_TO_IDEABOOK_CONFIRMATION = 'ENABLE_ADD_TO_IDEABOOK_CONFIRMATION';
export const ALLOW_COMMENTS_ON_MY_PUBLIC_PHOTOS = 'ALLOW_COMMENTS_ON_MY_PUBLIC_PHOTOS';
export const PUBLIC_PROFILE_PAGE = 'PUBLIC_PROFILE_PAGE';
export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT';
export const EVERY_TIME = 'EVERY_TIME';
export const NEVER = 'NEVER';
export const LIMIT_1_PER_HOUR_POST = 'LIMIT_1_PER_HOUR_POST';
export const LIMIT_3_PER_HOUR_POST = 'LIMIT_3_PER_HOUR_POST';
export const LIMIT_1_PER_DAY = 'LIMIT_1_PER_DAY';
export const LIMIT_2_PER_DAY = 'LIMIT_2_PER_DAY';
export const LIMIT_5_PER_DAY = 'LIMIT_5_PER_DAY';
export const DISPLAY_NEWS_FIRST = 'DISPLAY_NEWS_FIRST';
export const DISPLAY_OLDEST_FIRST = 'DISPLAY_OLDEST_FIRST';
export const DONT_ALLOW_USERS_TO_MESSAGE_ME = 'DONT_ALLOW_USERS_TO_MESSAGE_ME';
export const ALLOW_USERS_I_FOLLOW_TO_MESSAGE_ME = 'ALLOW_USERS_I_FOLLOW_TO_MESSAGE_ME';
export const ALLOW_ALL_USERS_TO_MESSAGE_ME = 'ALLOW_ALL_USERS_TO_MESSAGE_ME';
export const YOU_NEE_TO_LOGIN = 'YOU_NEE_TO_LOGIN';
export const YOU_DONT_HAVE_EMAIL = 'YOU_DONT_HAVE_EMAIL';

// --------------------------------- discussions page ----------------------------

export const WHAT_ARE_YOU_WORKING_ON = 'WHAT_ARE_YOU_WORKING_ON';
export const GET_HELP_FOR_YOUR_PROJECTS = 'GET_HELP_FOR_YOUR_PROJECTS';
export const START_A_DISCUSSION = 'START_A_DISCUSSION';
export const LATEST = 'LATEST';
export const FEATURED = 'FEATURED';
export const POPULAR = 'POPULAR';
export const UNANSWERED = 'UNANSWERED';
export const POST = 'POST';
export const POSTS = 'POST_plural';
export const POST_0 = 'POST_0';
export const POST_1 = 'POST_1';
export const POST_2 = 'POST_2';
export const POST_3 = 'POST_3';
export const POST_4 = 'POST_4';
export const POSTED_BY = 'POSTED_BY';
export const YOUR_POSTS = 'YOUR_POSTS';
export const YOUR_COMMENTS = 'YOUR_COMMENTS';
export const YOUR_TOPICS = 'YOUR_TOPICS';
export const EXPLORE_DISCUSSIONS = 'EXPLORE_DISCUSSIONS';
export const EXPLORE_ALL_DISCUSSIONS = 'EXPLORE_ALL_DISCUSSIONS';
export const FEATURED_HOME_DISCUSSIONS = 'FEATURED_HOME_DISCUSSIONS';
export const FEATURED_GARDEN_DISCUSSIONS = 'FEATURED_GARDEN_DISCUSSIONS';
export const TOP_COMMENTERS = 'TOP_COMMENTERS';
export const TODAY = 'TODAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const PREV = 'PREV';
export const NEXT = 'NEXT';
export const UPDATED_AT = 'UPDATED_AT';
export const HI = 'HI';
export const ADD_PHOTO_FOR_BETTER_ANSWERS = 'ADD_PHOTO_FOR_BETTER_ANSWERS';
export const POLL_OPTIONS = 'POLL_OPTIONS';
export const YOU_CAN_ENTER_TEXT_LINK_PRODUCT_OR_UPLOAD_AN_IMAGE = 'YOU_CAN_ENTER_TEXT_LINK_PRODUCT_OR_UPLOAD_AN_IMAGE';
export const ENTER_TEXT_OR_PASTE_MANZILIK_LINK = 'ENTER_TEXT_OR_PASTE_MANZILIK_LINK';
export const OPTION = 'OPTION';
export const ADD_OPTION = 'ADD_OPTION';
export const ADD_POLL = 'ADD_POLL';
export const CHOOSE_TOPIC = 'CHOOSE_TOPIC';
export const ADD_POST = 'ADD_POST';
export const ADD_LINK = 'ADD_LINK';
export const ADD_VIDEO = 'ADD_VIDEO';
export const TEXT = 'TEXT';
export const LINK = 'LINK';
export const SORTED_BY = 'SORTED_BY';
export const OLDEST = 'OLDEST';
export const RELATED_DISCUSSIONS = 'RELATED_DISCUSSIONS';
export const MORE_DISCUSSIONS = 'MORE_DISCUSSIONS';
export const FEATURED_ANSWER = 'FEATURED_ANSWER';
export const VOTE = 'VOTE';
export const COMMENT = 'COMMENT';
export const COMMENTS = 'COMMENT_plural';
export const COMMENT_0 = 'COMMENT_0';
export const COMMENT_1 = 'COMMENT_1';
export const COMMENT_2 = 'COMMENT_2';
export const COMMENT_3 = 'COMMENT_3';
export const COMMENT_4 = 'COMMENT_4';
export const REMOVE_POLL = 'REMOVE_POLL';
export const POLL_MUST_HAVE_TWO = 'POLL_MUST_HAVE_TWO';
export const TITLE_AND_DESC_NEEDS_TO_BE_15 = 'TITLE_AND_DESC_NEEDS_TO_BE_15';
export const TITLE_INVALID_CHARS = 'TITLE_INVALID_CHARS ';
export const WELCOME_TO_MANZILIK_ADVICE = 'WELCOME_TO_MANZILIK_ADVICE';
export const SEE_DISCUSSION_FROM_DESIGNERS_AND_EXPERT = 'SEE_DISCUSSION_FROM_DESIGNERS_AND_EXPERT';
export const GET_STARTED = 'GET_STARTED';
export const SELECTED = 'SELECTED';
export const DONE = 'DONE';
export const FOLLOW_AT_LEAST_ONE_TOPIC = 'FOLLOW_AT_LEAST_ONE_TOPIC';
export const FIND_HOME_AND_GARDEN_TOPICS = 'FIND_HOME_AND_GARDEN_TOPICS';
export const WHAT_ARE_YOUR_INTERESTS = 'WHAT_ARE_YOUR_INTERESTS';
export const NO_COMMENTS = 'NO_COMMENTS';

// --------------------------------- magazine page ----------------------------
export const STORIES_FROM_MANZILIK = 'STORIES_FROM_MANZILIK';
export const LATEST_FROM_MANZILIK = 'LATEST_FROM_MANZILIK';
export const FULL_STORY = 'FULL_STORY';
export const WATCH_NOW = 'WATCH_NOW';
export const MOST_POPULAR = 'MOST_POPULAR';
export const NEW_THIS_WEEK = 'NEW_THIS_WEEK';
export const MANZILIK_TOURS = 'MANZILIK_TOURS';
export const MANZILIK_TV = 'MANZILIK_TV';
export const PREV_PAGE = 'PREV_PAGE';
export const NEW_TO_MANZILIK = 'NEW_TO_MANZILIK';
export const READ_RELATED_STORIES = 'READ_RELATED_STORIES';
export const READ_THE_FULL_STORY = 'READ_THE_FULL_STORY';
export const SEE_MORE_COMMENTS = 'SEE_MORE_COMMENTS';
export const MANZILIK_DISCUSSIONS = 'MANZILIK_DISCUSSIONS';

// --------------------------------- magazine details page ----------------------------
export const PEOPLE_WHO_LIKED_THIS_STORY = 'PEOPLE_WHO_LIKED_THIS_STORY';
export const MORE_ON_MANZILIK = 'MORE_ON_MANZILIK';
export const EXPLORE_RELATED_TOPICS = 'EXPLORE_RELATED_TOPICS';
export const PRINT = 'PRINT';
export const EMBED = 'EMBED';
export const READ_MORE = 'READ_MORE';
export const SHOW_LESS = 'SHOW_LESS';
export const LIKE = 'LIKE';
export const LIKES = 'LIKE_plural';
export const LIKE_0 = 'LIKE_0';
export const LIKE_1 = 'LIKE_1';
export const LIKE_2 = 'LIKE_2';
export const LIKE_3 = 'LIKE_3';
export const LIKE_4 = 'LIKE_4';
export const SAVE_DISCUSSION = 'SAVE_DISCUSSION';
export const SAVED_SUCCESSFULLY = 'SAVED_SUCCESSFULLY';
export const SAUDI_ARABIA = 'SAUDI_ARABIA';
export const PHOTO = 'PHOTO';
export const PHOTOS = 'PHOTO_plural';
export const PHOTO_0 = 'PHOTO_0';
export const PHOTO_1 = 'PHOTO_1';
export const PHOTO_2 = 'PHOTO_2';
export const PHOTO_3 = 'PHOTO_3';
export const PHOTO_4 = 'PHOTO_4';
export const DISCUSSION = 'DISCUSSION';
export const DISCUSSIONS = 'DISCUSSION_plural';
export const DISCUSSION_0 = 'DISCUSSION_0';
export const DISCUSSION_1 = 'DISCUSSION_1';
export const DISCUSSION_2 = 'DISCUSSION_2';
export const DISCUSSION_3 = 'DISCUSSION_3';
export const DISCUSSION_4 = 'DISCUSSION_4';
export const COMMENT_IS_EMPTY = 'COMMENT_IS_EMPTY';
export const REPORT_SPAM = 'REPORT_SPAM';
export const MAGAZINE = 'MAGAZINE';
export const IDEA = 'IDEA';
export const IDEAS = 'IDEA_plural';
export const IDEA_0 = 'IDEA_0';
export const IDEA_1 = 'IDEA_1';
export const IDEA_2 = 'IDEA_2';
export const IDEA_3 = 'IDEA_3';
export const IDEA_4 = 'IDEA_4';
export const IDEA_TAG = 'IDEA_TAG';
export const PAGE_404 = 'PAGE_404';
export const SORRY_PAGE_NOT_FOUND = 'SORRY_PAGE_NOT_FOUND';
export const BACK_HOME = 'BACK_HOME';
export const TV = 'TV';
export const NEW_VIDEO_ON_MANZILIK = 'NEW_VIDEO_ON_MANZILIK';
export const THERE_ARE_NO_DATA = 'THERE_ARE_NO_DATA';
export const FIND_PROFESSIONAL = 'FIND_PROFESSIONAL';
export const FIND_PRODUCTS = 'FIND_PRODUCTS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const PROFESSIONAL_ROOM = 'PROFESSIONAL_ROOM';
export const REVIEW = 'REVIEW';
export const REVIEWS = 'REVIEW_plural';
export const REVIEW_0 = 'REVIEW_0';
export const REVIEW_1 = 'REVIEW_1';
export const REVIEW_2 = 'REVIEW_2';
export const REVIEW_3 = 'REVIEW_3';
export const REVIEW_4 = 'REVIEW_4';
export const NO_REVIEWS = 'NO_REVIEWS';
export const EMBED_A_WIDGET = 'EMBED_A_WIDGET';

//------------------------------------- Professional --------------------------------------//

export const CATEGORIES = 'CATEGORIES';
export const SERVICES = 'SERVICES';
export const VERIFICATION = 'VERIFICATION';
export const VERIFIED = 'VERIFIED';
export const UNVERIFIED = 'UNVERIFIED';
export const MAX_SIZE_FILE = 'MAX_SIZE_FILE';
export const UNDER_REVIEW = 'UNDER_REVIEW';
export const COMPLETE_PROFILE = 'COMPLETE_PROFILE';
export const PROFESSIONAL = 'PROFESSIONAL';
export const PROFESSIONALS = 'PROFESSIONALS';
export const HOMEOWNER = 'HOMEOWNER';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_SERVICE = 'SELECT_SERVICE';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const HOW_DO_YOU_RATE_THIS_PROFESSIONAL = 'HOW_DO_YOU_RATE_THIS_PROFESSIONAL';
export const DO_YOU_WANT_TO_SHARE_MORE = 'DO_YOU_WANT_TO_SHARE_MORE';
export const WRITE_REVIEW = 'WRITE_REVIEW';
export const CONTACT_THIS_PRO = 'CONTACT_THIS_PRO';
export const NAME = 'NAME';
export const MOBILE_NUMBER = 'MOBILE_NUMBER';
export const PROJECT_ZIP_CODE = 'PROJECT_ZIP_CODE';
export const SAVE_YOUR_MESSAGE = 'SAVE_YOUR_MESSAGE';
export const TELL_YOUR_PROFESSIONAL = 'TELL_YOUR_PROFESSIONAL';
export const FOR = 'FOR';
export const ACTIVATE_MY_ACCOUNT = 'ACTIVATE_MY_ACCOUNT';
export const ADDRESS = 'ADDRESS';
export const PROF_PROFILE_REVIEW_NOTE = 'PROF_PROFILE_REVIEW_NOTE';
export const PLEASE_FILL_REQUIRED_FIELDS = 'PLEASE_FILL_REQUIRED_FIELDS';
export const LOAD_NEXT_PHOTOS = 'LOAD_NEXT_PHOTOS';
export const EDIT_REVIEW = 'EDIT_REVIEW';
export const PLEASE_ENTER_VALID_PHONE_NUMBER = 'PLEASE_ENTER_VALID_PHONE_NUMBER';
export const PLEASE_ENTER_VALID_PHONE_NUMBER_EX = 'PLEASE_ENTER_VALID_PHONE_NUMBER_EX';
export const BE_FIRST_TO_COMMENT = 'BE_FIRST_TO_COMMENT';
export const SPAM_DONE = 'SPAM_DONE';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO = 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VIDEO = 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VIDEO';
export const MORE_REVIEWS = 'MORE_REVIEWS';
export const MORE_REVIEWS_PLURAL = 'MORE_REVIEWS_plural';
export const MORE_REVIEWS_0 = 'MORE_REVIEWS_0';
export const MORE_REVIEWS_1 = 'MORE_REVIEWS_1';
export const MORE_REVIEWS_2 = 'MORE_REVIEWS_2';
export const MORE_REVIEWS_3 = 'MORE_REVIEWS_3';
export const MORE_REVIEWS_4 = 'MORE_REVIEWS_4';
export const PERSONAL_INFORMATION = 'PERSONAL_INFORMATION';
export const PHOTOS_GALLERY = 'PHOTOS_GALLERY';
export const VIDEOS = 'VIDEOS';
export const REGION = 'REGION';

// -------------------------- cognito messages ------------------------------------

export const USER_DOESNT_EXIST = 'USER_DOESNT_EXIST';
export const INCORRECT_USERNAME_OR_PASSWORD = 'INCORRECT_USERNAME_OR_PASSWORD';
export const USER_EXIST = 'USER_EXIST';
export const INVALID_CODE = 'INVALID_CODE';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const USER_NOT_CONFIRMED = 'USER_NOT_CONFIRMED';
export const MUST_RESET_PASSWORD = 'MUST_RESET_PASSWORD';
export const CAPTCHA_IS_MISSING = 'CAPTCHA_IS_MISSING';

export const SAVE_MAGAZINE = 'SAVE_MAGAZINE';
export const SAVE_TV = 'SAVE_TV';

// ---------------------- auto complete --------------------------------
export const MAGAZINES = 'MAGAZINES';
export const TVS = 'TVS';
export const DISCUSSIONS_SEARCH = 'DISCUSSIONS';
export const IN = 'IN';

// ----------------------- Departments Page ----------------------
export const PRODUCT = 'PRODUCT';
export const PRODUCTS = 'PRODUCTS';
export const EXPLORE_PRODUCTS = 'EXPLORE_PRODUCTS';
export const EXPLORE_MORE_FAV_PRODUCTS = 'EXPLORE_MORE_FAV_PRODUCTS';
export const BUY_ALL = 'BUY_ALL';
export const FURNITURE = 'FURNITURE';
// -------------------------- products page ------------------------------------
export const HIGHTEST_PRICE = 'HIGHTEST_PRICE';
export const LOWEST_PRICE = 'LOWEST_PRICE';
export const HIGHEST_RATE = 'HIGHEST_RATE';
export const LOWEST_RATE = 'LOWEST_RATE';
export const SHOPPING = 'SHOPPING';
export const ALL_PRODUCTS = 'ALL_PRODUCTS';
export const EXTRA_SIZES = 'EXTRA_SIZES';
export const EXTRA_COLORS = 'EXTRA_COLORS';
export const SHOW_ALL_RESULTS = 'SHOW_ALL_RESULTS';
export const ATTR_DIMENSIONS = 'Dimensions';
export const ATTR_COLOR = 'Color';

// ------------------------ product page ----------------------------------------
export const ADD_TO_CART = 'ADD_TO_CART';
export const ITEMS_LEFT = 'ITEMS_LEFT';
export const READY_TO_SHIP_IN = 'READY_TO_SHIPPING_IN';
export const DAYS = 'DAYS';
export const PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION';
export const PRODUCT_SPECIFICATION = 'PRODUCT_SPECIFICATION';
export const PRODUCT_SHIPPING = 'PRODUCT_SHIPPING';
export const QUANTITY = 'QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const ENTER_NEW_QUANTITY = 'ENTER_NEW_QUANTITY';
// ------------------------ prodcut details page ----------------------------------------
export const ITEM_COLOR = 'ITEM_COLOR';
export const ITEM_DIMENSIONS = 'ITEM_DIMENSIONS';
export const BE_FIRST_REVIEWER = 'BE_FIRST_REVIEWER';
export const NO_BODY_REVIEWED_THIS_PRODUCT = 'NO_BODY_REVIEWED_THIS_PRODUCT';
export const CUSTOMER_REVIEWS = 'CUSTOMER_REVIEWS';
export const REVIEW_PRODUCT = 'REVIEW_PRODUCT';
export const REVIEWED_PRODUCT_SUCCESSFULLY = 'REVIEWED_PRODUCT_SUCCESSFULLY';
export const WHAT_IS_YOUR_REVIEW_FOR_PRODUCT = 'WHAT_IS_YOUR_REVIEW_FOR_PRODUCT';
export const TRANSFER_YOUR_EXPERIENCE_OF_USING_THE_PRODUCT = 'TRANSFER_YOUR_EXPERIENCE_OF_USING_THE_PRODUCT';
export const REASONABLE_PRICE = 'REASONABLE_PRICE';
export const PRODUCT_MATCHES_DESCRIPTION = 'PRODUCT_MATCHES';
export const PRODUCT_QUALITY = 'PRODUCT_QUALITY';
export const SHIPPING_SPEED = 'SHIPPING_SPEED';
export const ADD_PRODUCT_IMAGES = 'ADD_PRODUCT_IMAGES';
export const ADD_PRODUCT_COMMENT = 'ADD_PRODUCT_COMMENT';
export const ADD_YOUR_COMMENT_HERE = 'ADD_YOUR_COMMENT_HERE';
export const PRODUCT_NUMBER = 'PRODUCT_NUMBER';
export const MANUFACTORY = 'MANUFACTORY';
export const SOLD_BY = 'SOLD_BY';
export const SIZE_OR_WEIGHT = 'SIZE_OR_WEIGHT';
export const CATEGORY = 'CATEGORY';
// -------------------------- who we are ------------------------------------
export const WHO_WE_ARE = 'WHO_WE_ARE';
// ---------------------------- Main Page ------------------------------//
export const MAIN = 'MAIN';
export const MOST_RECENT = 'MOST_RECENT';
export const MANZILIK = 'MANZILIK';
export const BOUGHT_THIS_PRODUCT = 'BOUGHT_THIS_PRODUCT';
export const COMMENT_LIKE = 'COMMENT_LIKE';
export const DID_THIS_COMMENT_HELP_YOU = 'DID_THIS_COMMENT_HELP_YOU';
export const REVIEW_THIS_PRODUCT = 'REVIEW_THIS_PRODUCT';
export const GENERAL_REVIEW_MANDATORY = 'GENERAL_REVIEW_MANDATORY';
export const EDIT_PRODUCT_REVIEW = 'EDIT_PRODUCT_REVIEW';
export const YOU_HAVE_TO_LOGIN_FOR_REVIEW = 'YOU_HAVE_TO_LOGIN_FOR_REVIEW';
export const GO_TO_LOGIN = 'GO_TO_LOGIN';
export const SHOW_ALL = 'SHOW_ALL';
export const CONTACT_US = 'CONTACT_US';
export const FREQUENTLY_ASKED_QUESTIONS = 'FREQUENTLY_ASKED_QUESTIONS';
export const HOW_CAN_WE_HELP = 'HOW_CAN_WE_HELP';
export const FULL_NAME = 'FULL_NAME';
export const EMAIL_HERE = 'EMAIL_HERE';
export const WRITE_FULL_NAME = 'WRITE_FULL_NAME';
export const MESSAGE_CONTENT = 'MESSAGE_CONTENT';
export const MESSAGE_CONTENT_HERE = 'MESSAGE_CONTENT_HERE';
export const LETTER = 'LETTER';
export const SEND_NOW = 'SEND_NOW';
export const SIGN_IN_NOW = 'SIGN_IN_NOW';
export const SUBSCRIBE_TO_NEWSLETTER = 'SUBSCRIBE_TO_NEWSLETTER';
export const MUST_AGREE_TERMS = 'MUST_AGREE_TERMS';
export const ARE_YOY_LOOKING_FOR_IDEAS = 'ARE_YOY_LOOKING_FOR_IDEAS';

//------------------------- Contact Us Page ----------------------------//
export const QUANTITY_VALIDATION_MESSAGE = 'QUANTITY_VALIDATION_MESSAGE';
export const HOME_PAGE = 'HOME_PAGE';
export const REQUEST_SUCCESSFULLY_RECEIVED = 'REQUEST_SUCCESSFULLY_RECEIVED';
export const REQUEST_WILL_BE_FOLLOWED_UP = 'REQUEST_WILL_BE_FOLLOWED_UP';
// ---------------------------- public profile ------------------------------//
export const AWARDS = 'AWARDS';
export const WON = 'WON';
export const BADGES = 'BADGES';
export const GENERAL_IDEA_ABOUT_ME = 'GENERAL_IDEA_ABOUT_ME';
export const PROFILE_IDEAS = 'PROFILE_IDEAS';
export const UNLIMITED_CREATIVITY_IDEAS = 'UNLIMITED_CREATIVITY_IDEAS';
export const NO_IDEAS = 'NO_IDEAS';
export const FOLLOWING = 'FOLLOWING';
export const FOLLOWERS = 'FOLLOWERS';
//------------------------- Landing Pages ----------------------------//
export const JOINT_US_NOW = 'JOINT_US_NOW';
export const WITH_MANZILIK = 'WITH_MANZILIK';
export const INSPIRE_YOURSELF = 'INSPIRE_YOURSELF';
export const OR_SIGH_UP_WITH = 'OR_SIGH_UP_WITH';
export const EQUIPMENT_YOU_APARTMENT = 'EQUIPMENT_YOU_APARTMENT';
export const WE_BRING_PRACTICAL_IDEAS = 'WE_BRING_PRACTICAL_IDEAS';
export const MANY_NEW_DECORATING_IDEAS = 'MANY_NEW_DECORATING_IDEAS';
export const WANT_TO_CHANGE_YOUR_HOME_DECORE = 'WANT_TO_CHANGE_YOUR_HOME_DECORE';
export const WE_PROVIDE_COMPLETE_FEED_OF_IDEAS = 'WE_PROVIDE_COMPLETE_FEED_OF_IDEAS';
export const DO_YOU_WANT_TO_EXPAND_CUSTOMER_INCREASE = 'DO_YOU_WANT_TO_EXPAND_CUSTOMER_INCREASE';
export const WE_PROVIDE_PLATFORM_TO_DISPLAY_YOUR_BUSINESS = 'WE_PROVIDE_PLATFORM_TO_DISPLAY_YOUR_BUSINESS';
export const ARE_YOU_AN_INTERIOR_DESIGN = 'ARE_YOU_AN_INTERIOR_DESIGN';
export const YOUR_GOAL_IS_TO_INCREASE_CLIENT_PROJECTS = 'YOUR_GOAL_IS_TO_INCREASE_CLIENT_PROJECTS';
export const CONFIRMATION_CODE = 'CONFIRMATION_CODE';

//----------------------- SEO -------------------------------//
export const HOME_PAGE_SEO_DESCRIPTION = 'HOME_PAGE_SEO_DESCRIPTION';
export const LOGIN_SEO_DESCRIPTION = 'LOGIN_SEO_DESCRIPTION';
export const DISCUSSION_SEO_DESCRIPTION = 'DISCUSSION_SEO_DESCRIPTION';
export const IDEAS_FILTER_SEO_DESCRIPTION = 'IDEAS_FILTER_SEO_DESCRIPTION';
export const ARTICLE_SEO_DESCRIPTION = 'ARTICLE_SEO_DESCRIPTION';
export const MAGAZINE_HOME_SEO_DESCRIPTION = 'MAGAZINE_HOME_SEO_DESCRIPTION';
export const TV_DETAILS_SEO_DESCRIPTION = 'TV_DETAILS_SEO_DESCRIPTION';
export const TV_LIST_SEO_DESCRIPTION = 'TV_LIST_SEO_DESCRIPTION';
export const PROFESSIONALS_SEO_DESCRIPTION = 'PROFESSIONALS_SEO_DESCRIPTION';
export const PROJECTS_SEO_DESCRIPTION = 'PROJECTS_SEO_DESCRIPTION';
export const STORES_SEO_DESCRIPTION = 'STORES_SEO_DESCRIPTION';
export const DISCUSSIONS_TITLE = 'DISCUSSION_TITLE';
export const MAGAZINES_TITLE = 'MAGAZINES_TITLE';
export const ABOUT_DESCRIPTION = 'ABOUT_DESCRIPTION';
export const TERMS_DESCRIPTION = 'TERMS_DESCRIPTION';
export const PRIVACY_DESCRIPTION = 'PRIVACY_DESCRIPTION';
export const HOME = 'HOME';

//--------------------------Add idea from web -------------------//
export const ADD_WEB_PAGE_URL = 'ADD_WEB_PAGE_URL';
export const FIELD_SHOULD_BE_URL = 'FIELD_SHOULD_BE_URL';
//------------------------------------- Idea page --------------------------------------//
export const CONTACT_PROFESSIONAL = 'CONTACT_PROFESSIONAL';
export const RELATED_PROFESSIONALS = 'RELATED_PROFESSIONALS';
export const MY_PROJECTS = 'MY_PROJECTS';
export const ALL = 'All';

//--------------------------------------- BRANDS --------------------//
export const BRANDS = 'BRANDS';
export const CHOOSE_BRAND_TYPE = 'CHOOSE_BRAND_TYPE';
export const FIND_STORE = 'FIND_STORE';
export const STYLE_TYPES = 'STYLE_TYPES';
export const HOW_DO_YOU_RATE_THIS_STORE = 'HOW_DO_YOU_RATE_THIS_STORE';
export const SHARE = 'SHARE';
//---------------------------------------- EXTENDED FOOTER------------//
export const THE_TV = 'THE_TV';
export const THE_MAGAZINE = 'THE_MAGAZINE';
export const THE_DISCUSSIONS = 'THE_DISCUSSIONS';
export const PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES';
export const ROOM_TYPES = 'ROOM_TYPES';

//------------------------Partner------------------------//
export const PROVIDER = 'PROVIDER';
export const HOW_DO_YOU_RATE_THIS_PARTNER = 'HOW_DO_YOU_RATE_THIS_PARTNER';

//------------------------checkout------------------------//
export const SHIPPING_NO_ADDRESS_SELECTED = 'SHIPPING_NO_ADDRESS_SELECTED';
export const BASKET_NO_ITEMS_ADDED = 'BASKET_NO_ITEMS_ADDED';
export const THE_PRODUCT = 'THE_PRODUCT';
export const PRICE = 'THE_PRICE';
export const OPERATION = 'OPERATION';
export const BASKET = 'BASKET';
export const DELIVERY = 'DELIVERY';
export const PAYMENT = 'PAYMENT';
export const SUCCESSFULL = 'SUCCESSFUL';
export const PENDING_PAYMENT = 'PENDING_PAYMENT';
export const REJECTED = 'REJECTED';
export const PAYMENT_REVIEW = 'PAYMENT_REVIEW';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SECURE_PAYMENT_LINE = 'SECURE_PAYMENT_LINE';
export const EDIT_ADDRESS_LINE = 'EDIT_ADDRESS_LINE';
export const LAST_STEP_PURCHASE = 'LAST_STEP_PURCHASE';
export const PAY_NOW = 'PAY_NOW';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const ORDER_REVIEW = 'ORDER_REVIEW';
export const TRACK = 'TRACK';
export const FAST_DELIVERY = 'FAST_DELIVERY';
export const SAFE_GATEWAY = 'SAFE_GATEWAY';
export const SUPPORT = 'SUPPORT';
export const CHECK = 'CHECK';
export const DO_YOU_HAVE_COPOUN = 'DO_YOU_HAVE_COPOUN';
export const PRICE_BEFORE_TAX = 'PRICE_BEFORE_TAX';
export const PRICE_AFTER_TAX = 'PRICE_AFTER_TAX';
export const TOTAL_TAX = 'TOTAL_TAX';
export const STEP_CONTINUE = 'STEP_CONTINUE';
export const SHOPPING_BASKET = 'SHOPPING_BASKET';
export const ENTER_COPON_CODE = 'ENTER_COPON_CODE';
export const GO_TO_BASKET = 'GO_TO_BASKET';
export const CONTINUE_SHOPPING = 'CONTINUE_SHOPPING';
export const PRODUCT_WAS_ADDED_TO_BASKET = 'PRODUCT_WAS_ADDED_TO_BASKET';
export const CONFIRM_PAYMENT_PROCCESS = 'CONFIRM_PAYMENT_PROCCESS';
export const SHOPPING_DATA_NOT_COMPLETED = 'SHOPPING_DATA_NOT_COMPLETED';
export const BASKET_IS_EMPTY = 'BASKET_IS_EMPTY';
export const START_ADDING_PRODUCT_TO_BASKET = 'START_ADDING_PRODUCT_TO_BASKET';
export const MANZILIK_STORE = 'MANZILIK_STORE';
export const SHIPPING_ADDRESSES = 'SHIPPING_ADDRESSES';
export const NO_ADDED_ADDRESSES = 'NO_ADDED_ADDRESSES';
export const START_SAVING_YOUR_ADDRESS_TO_DELIVER_THE_PRODUCTS = 'START_SAVING_YOUR_ADDRESS_TO_DELIVER_THE_PRODUCTS';
export const SAVED_CARDS = 'SAVED_CARDS';
export const ADD_NEW_CARD = 'ADD_NEW_CARD';
export const SAVE_CARD_IN_SAFE_WAY_TO_PAY_QUICKLY_IN_FUTURE = 'SAVE_CARD_IN_SAFE_WAY_TO_PAY_QUICKLY_IN_FUTURE';
export const THE_TOTAL_PRICE_HAS_BEEN_PAID_AND_WILL_WORKING_ON_PREPARING_THE_ORDER =
  'THE_TOTAL_PRICE_HAS_BEEN_PAID_AND_WILL_WORKING_ON_PREPARING_THE_ORDER';
export const TRACK_THE_ORDER = 'TRACK_THE_ORDER';
export const REVIEW_PROCESS = 'REVIEW_PROCESS';
export const PREPARE_THE_ORDER = 'PREPARE_THE_ORDER';
export const DELIVERY_PROCESS = 'DELIVERY_PROCESS';
export const THE_ORDER_HAS_BEEN_DELIVERED_SUCCESSFULLY = 'THE_ORDER_HAS_BEEN_DELIVERED_SUCCESSFULLY';
export const MY_ORDERS = 'MY_ORDERS';
export const CANCEL_THE_ORDER = 'CANCEL_THE_ORDER';
export const CLOSE = 'CLOSE';
export const THE_ORDER_HAS_BEEN_DONE_SUCCESSFULLY = 'THE_ORDER_HAS_BEEN_DONE_SUCCESSFULLY';
export const SHOW_CONTACT_INFO = 'SHOW_CONTACT_INFO';

//------------------------my orders------------------------//
export const SEARCH_BY_ORDER_NUMBER = 'SEARCH_BY_ORDER_NUMBER';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const UNDER_PROCESSING = 'UNDER_PROCESSING';
export const CANCELED = 'CANCELED';
export const DELIVERED = 'DELIVERED';
export const PENDING = 'PENDING';
export const ORDER_NUMBER = 'ORDER_NUMBER';
export const TOTAL_PRICE = 'TOTAL_PRICE';
export const ORDER_STATUS = 'ORDER_STATUS';
export const ORDER_DATE = 'ORDER_DATE';
export const CANCEL_PRODUCT = 'CANCEL_PRODUCT';
export const CONFIRM_CANCEL_PRODUCT = 'CONFIRM_CANCEL_PRODUCT';
export const REFUND_REQUEST_DESC = 'REFUND_REQUEST_DESC';
export const REFUND_REQUEST_NOTE = 'REFUND_REQUEST_NOTE';
export const REFUND_REQUEST_SUCCESS = 'REFUND_REQUEST_SUCCESS';
export const REFUND_REQUEST_SUCCESS_NOTE = 'REFUND_REQUEST_SUCCESS_NOTE';
export const DELIVERY_ADDRESS = 'DELIVERY_ADDRESS';
export const DETAILS = 'DETAILS';
export const THE_FOLLOWING_INFO_IS_ONLY_FOR_US_IT_WONT_PREVENT_YOU_FROM_CANCELING_YOUR_ORDER =
  'THE_FOLLOWING_INFO_IS_ONLY_FOR_US_IT_WONT_PREVENT_YOU_FROM_CANCELING_YOUR_ORDER';
export const SHIPPING_PROBLEM = 'SHIPPING_PROBLEM';
export const OTHER = 'OTHER';
export const ORDER_IS_DUPLICATED = 'ORDER_IS_DUPLICATED';
export const CHANGE_THE_ORDER = 'CHANGE_THE_ORDER';
export const REASON_OF_CANCELING_THE_ORDER = 'REASON_OF_CANCELING_THE_ORDER';
export const ADD_DESCRIPTION = 'ADD_DESCRIPTION';
export const CONFIRM_ORDER_CANCELATION = 'CONFIRM_ORDER_CANCELATION';
export const NEW = 'NEW';
export const SHIPPING_COST = 'SHIPPING_COST';
export const DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT';

// ------------------------- coming soon ------------------------- //

export const STORE_NAME = 'STORE_NAME';
export const INESTEGRAM_ACCOUNT = 'INESTEGRAM_ACCOUNT';
export const SEND_INFORMATION = 'SEND_INFORMATION';
export const ENTER = 'ENTER';
export const THANK_FOR_YOUR_INTEREST_TO_JOIN_MANZILIK_PLANTFORM = 'THANK_FOR_YOUR_INTEREST_TO_JOIN_MANZILIK_PLANTFORM';
export const OPERATION_TEAM_WILL_REACH_YOU_SOON = 'OPERATION_TEAM_WILL_REACH_YOU_SOON_TO';
export const COMING_SOON = 'COMING_SOON';
export const MANZILIK_STORE_SOON = 'MANZILIK_STORE_SOON';
export const WE_HELP_PEOPLE_TO_ACHEVE_THEIR_GOAL = 'WE_HELP_PEOPLE_TO_ACHEVE_THEIR_GOAL';
export const JOIN_OUR_VENDORS = 'JOIN_OUR_VENDORS';
export const EXPLORE_MANZILIK = 'EXPLORE_MANZILIK';

// ------------------------- else ------------------------- //
export const CARD_NUMBER_MISMATCH_BRAND_TYPE = 'CARD_NUMBER_MISMATCH_BRAND_TYPE';
export const INVALID_REGISTERATION_REFERENCE = 'INVALID_REGISTERATION_REFERENCE';
export const INVALID_FREELANCE_NUMBER = 'INVALID_FREELANCE_NUMBER';
export const NO_NAME = 'NO_NAME';

export const DOWNLOAD_MANZILIK_APP = 'DOWNLOAD_MANZILIK_APP';
export const DOWNLOAD_MANZILIK_APP_DESC = 'DOWNLOAD_MANZILIK_APP_DESC';
export const DOWNLOAD = 'DOWNLOAD';
export const SHOP_SEARCH = 'SHOP_SEARCH';
export const SHOP_SEARCH_HEADING = 'SHOP_SEARCH_HEADING';
export const SHOP_SEARCH_SUBHEADING = 'SHOP_SEARCH_SUBHEADING';
export const SHOWING_RESULTS_FOR = 'SHOWING_RESULTS_FOR';
export const MINUTE = 'MINUTE';
export const MINUTES = 'MINUTES';
export const HOUR = 'HOUR';
export const HOURS = 'HOURS';
export const DAY = 'DAY';
export const ELAPSED = 'ELAPSED';
export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const NO_NOTIFICATIONS = 'NO_NOTIFICATIONS';
export const YOU_CAN_BROWSE = 'YOU_CAN_BROWSE ';
export const SEND_REQUEST_SERVICE = 'SEND_REQUEST_SERVICE ';
export const BADGES_TITLE = 'BADGES_TITLE';
export const BADGES_ACHIEVED = 'BADGES_ACHIEVED';
export const BADGES_REMINDING = 'BADGES_REMINDING';
export const CAll_NOW = 'CAll_NOW';
export const BROWSE_WEBSITE = 'BROWSE_WEBSITE';
export const SEND_SERVICE_REQUEST = 'SEND_SERVICE_REQUEST';
export const SEND_SERVICE_REQUEST_NOW = 'SEND_SERVICE_REQUEST_NOW';
export const SERVICE_INFO = 'SERVICE_INFO';
export const SERVICE_DESCRIPTION = 'SERVICE_DESCRIPTION';
export const BUDGET_EXAMPLE = 'BUDGET_EXAMPLE';
export const IMAGES = 'IMAGES';
export const CONTACT_DETAILS = 'CONTACT_DETAILS';
export const TELEPHONE_NUMBER = 'TELEPHONE_NUMBER';
export const WHATSAPP_NUMBER = 'WHATSAPP_NUMBER';
export const REQUEST_NUMBER = 'REQUEST_NUMBER';
export const SELECT_IMAGES_TO_UPLOAD = 'SELECT_IMAGES_TO_UPLOAD';
export const SERVICE_REQUEST_WAS_SENT = 'SERVICE_REQUEST_WAS_SENT';
export const SERVICE_REQUEST_UNDER_REVIEW = 'SERVICE_REQUEST_UNDER_REVIEW';
export const SERVICE_REQUESTS_LIST = 'SERVICE_REQUESTS_LIST';
export const SERVICE_REQUEST_ERROR = 'SERVICE_REQUEST_ERROR';
export const RETRY_AGAIN = 'RETRY_AGAIN';
export const SERVICE_REQUESTS = 'SERVICE_REQUESTS';
export const NO_SERVICE_REQUESTS = 'NO_SERVICE_REQUESTS';
export const REVIEW_PROFESSIONAlS_TO_REQUEST_SERVICE = 'REVIEW_PROFESSIONAlS_TO_REQUEST_SERVICE';
export const SERVICE_TYPE = 'SERVICE_TYPE';
export const HOME_OWNER = 'HOME_OWNER';
export const ISSUE_DATE = 'ISSUE_DATE';
export const ADD_PROJECTS_AND_SERVICES_TO_IMPROVE_YOUR_CHANCE = 'ADD_PROJECTS_AND_SERVICES_TO_IMPROVE_YOUR_CHANCE';
export const RECIEVED_REQUESTS = 'RECIEVED_REQUESTS';
export const SENT_REQUESTS = 'SENT_REQUESTS';
export const ACCEPTED_REQUEST = 'ACCEPTED_REQUEST';
export const REJECTED_REQUEST = 'REJECTED_REQUEST';
export const WAITING_RESPONSE_REQUEST = 'WAITING_RESPONSE_REQUEST';
export const DELETED_REQUEST = 'DELETED_REQUEST';
export const PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION';
export const EXTRA_INFO = 'EXTRA_INFO';
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';
export const REJECT = 'REJECT';
export const REQUEST_DETAILS = 'REQUEST_DETAILS';
export const REQUEST_ACCEPTED_CONTACT_OWNER = 'REQUEST_ACCEPTED_CONTACT_OWNER';
export const REJECT_SERVICE_REASONS = 'REJECT_SERVICE_REASONS';
export const REQUESTED_SERVICE_NOT_AVAILABLE = 'REQUESTED_SERVICE_NOT_AVAILABLE';
export const COULDNT_REACH_AN_AGREEMENT = 'COULDNT_REACH_AN_AGREEMENT';
export const CLIENT_MISBEHAVIOUR = 'CLIENT_MISSBEHAVIOUR';
export const PLEASE_ADD_REJECTION_DETAILS = 'PLEASE_ADD_REJECTION_DETAILS';
export const CONFIRM_SERVICE_REJECTION = 'CONFIRM_SERVICE_REJECTION';
export const UNDO = 'UNDO';
export const REQUIRED_INFO_WILL_NOT_PREVENT_REJECTION = 'REQUIRED_INFO_WILL_NOT_PREVENT_REJECTION';
export const M0 = 'M0';
export const M1 = 'M1';
export const M2 = 'M2';
export const M3 = 'M3';
export const M4 = 'M4';
export const M5 = 'M5';
export const M6 = 'M6';
export const M7 = 'M7';
export const M8 = 'M8';
export const M9 = 'M9';
export const M10 = 'M10';
export const M11 = 'M11';
export const REQUEST_STATUS = 'REQUEST_STATUS';
export const FIND_REQUEST_BY_NUMBER = 'FIND_REQUEST_BY_NUMBER';
export const SERVICE_REQUEST_WAS_REJECTED = 'SERVICE_REQUEST_WAS_REJECTED';
export const YOU_CAN_CONTINUE_VIEWING_SERVICE_REQUEST = 'YOU_CAN_CONTINUE_VIEWING_SERVICE_REQUEST';
export const ERROR_INVALID_TEL_5XXXXXXXX = 'ERROR_INVALID_TEL_5XXXXXXXX';
export const LANG = 'LANG';
export const VERIFIED_FIRST = 'VERIFIED_FIRST';
export const JOIN_DATE = 'JOIN_DATE';
export const HIGHER_RATING = 'HIGHER_RATING';
export const SELECT_SORT_METHOD = 'SELECT_SORT_METHOD';
export const RESULTS = 'RESULTS';
export const VIEW_AS = 'VIEW_AS';
export const ENTER_FULL_NAME = 'ENTER_FULL_NAME';
export const NAME_VERIFICATION_STEP = 'NAME_VERIFICATION_STEP';
export const SAVE_AND_CONTINUE = 'SAVE_AND_CONTINUE';
export const PLEASE_ENTER_VALID_FIRST_NAME = 'PLEASE_ENTER_VALID_FIRST_NAME';
export const PLEASE_ENTER_VALID_LAST_NAME = 'PLEASE_ENTER_VALID_LAST_NAME';
export const PLEASE_SELECT_COUNTRY = 'PLEASE_SELECT_COUNTRY';
export const PLEASE_SELECT_CITY = 'PLEASE_SELECT_CITY';
export const MANAGE_GIGS = 'MANAGE_GIGS';
export const ADD_NEW_SERVICE = 'ADD_NEW_SERVICE';
export const NO_FAST_SERVICES = 'NO_FAST_SERVICES';
export const START_NOW_GIGS = 'START_NOW_GIGS';
export const IMAGE = 'IMAGE';
export const MAIN_SERVICE = 'MAIN_SERVICE';
export const SERVICE_PRICE = 'SERVICE_PRICE';
export const SERVICE_LOCATION = 'SERVICE_LOCATION';
export const SERVICE_STATUS = 'SERVICE_STATUS';
export const SERVICE_TITLE = 'SERVICE_TITLE';
export const ACTIVATED = 'ACTIVATED';
export const NOT_AVAILABLE = 'NOT_AVAILABLE';
export const AVAILABLE = 'AVAILABLE';
export const SET_AS_MAIN = 'SET_AS_MAIN';
export const SERVICE_DETAILS = 'SERVICE_DETAILS';
export const REQUEST_SERVICE = 'REQUEST_SERVICE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const BACK = 'BACK';
export const SELECT_SERVICE_TYPE = 'SELECT_SERVICE_TYPE';
export const SERVICE_ADDRESS = 'SERVICE_ADDRESS';
export const SERVICE_DESC_ONE = 'SERVICE_DESC_ONE';
export const SERVICE_DESC_TWO = 'SERVICE_DESC_TWO';
export const SERVICE_CITIES = 'SERVICE_CITIES';
export const SAVE_SERVICE = 'SAVE_SERVICE';
export const PLEASE_ENTER_VALID_YOUTUBE_LINK = 'PLEASE_ENTER_VALID_YOUTUBE_LINK';
export const UNREAD_MESSAGES = 'UNREAD_MESSAGES';
export const START_CONTACT = 'START_CONTACT';
export const CONTACT_NOW = 'CONTACT_NOW';
export const VIEW_CHAT = 'VIEW_CHAT';
export const KNOW_YOUR_NEEDS = 'KNOW_YOUR_NEEDS';
export const KNOW_YOUR_NEEDS2 = 'KNOW_YOUR_NEEDS2';
export const DO_YOU_NEED_HELP = 'DO_YOU_NEED_HELP';
export const PROVIDE_YOU_HELP = 'PROVIDE_YOU_HELP';
export const PROVIDE_YOU_HELP2 = 'PROVIDE_YOU_HELP2';
export const FIND_A_PRO = 'FIND_A_PRO';
export const SELECT_CITY = 'SELECT_CITY';
export const SEARCH_CITY = 'SEARCH_CITY';
export const CONFIRM_CITY = 'CONFIRM_CITY';
export const VIEW_MORE = 'VIEW_MORE';
export const VIEW_LESS = 'VIEW_LESS';
export const RATING = 'RATING';
export const AVAILABLE_IN = 'AVAILABLE_IN';
export const SIMILAR_SERVICES = 'SIMILAR_SERVICES';
export const MORE_INFO = 'MORE_INFO';
export const CONNECT_WITH_SERVICE_PROVIDER = 'CONNECT_WITH_SERVICE_PROVIDER';
export const COVERED_CITIES = 'COVERED_CITIES';
export const SERVICES_PACKAGE = 'SERVICES_PACKAGE';
export const PROVIDE_SERVICES_PACKAGE = 'PROVIDE_SERVICES_PACKAGE';
export const REQUEST_SERVICE_NOW = 'REQUEST_SERVICE_NOW';
export const VIEW = 'VIEW';
export const DISAPPEAR = 'DISAPPEAR';
export const REQUEST_SERVICE_INFO = 'REQUEST_SERVICE_INFO';
export const ADDITIONAL_INFO = 'ADDITIONAL_INFO';
export const SELECT_CITY_FIRST = 'SELECT_CITY_FIRST';
export const NO_GIGS = 'NO_GIGS';
export const GIGS = 'GIGS';
export const EXAMPLE_LICENSE = 'EXAMPLE_LICENSE ';
export const REQUEST_COMPLETED = 'REQUEST_COMPLETED';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const SERVICE_PROVIDER = 'SERVICE_PROVIDER';
export const COMPLETE_BTN = 'COMPLETE_BTN';
export const COMPLETE_NOTIFICATION = 'COMPLETE_NOTIFICATION';
export const RELATED_GIGS = 'RELATED_GIGS';
export const COMPLETED_REQUEST = 'COMPLETED_REQUEST';
export const CANCELLED_REQUEST = 'CANCELLED_REQUEST';
export const THANKS_FOR_COMPLETE = 'THANKS_FOR_COMPLETE';
export const MANZILIK_AI_TAB = 'MANZILIK_AI_TAB';
export const DESIGN_YOUR_HOME_NOW = 'DESIGN_YOUR_HOME_NOW';
export const USE_AI_TO_DESIGN_YOUR_HOME = 'USE_AI_TO_DESIGN_YOUR_HOME';
export const START_THE_EXPERIENCE_NOW = 'START_THE_EXPERIENCE_NOW';
export const USE_AI_TO_DESIGN_YOUR_HOME2 = 'USE_AI_TO_DESIGN_YOUR_HOME2';
export const ADD = 'ADD';
export const POINT = 'POINT';
export const NEWLY_PUBLISHED = 'NEWLY_PUBLISHED';
export const MY_DESIGNS = 'MY_DESIGNS';
export const WE_DO_THE_WORK = 'WE_DO_THE_WORK';
export const STUCK = 'STUCK';
export const UPLOAD_PHOTO_TO_START = 'UPLOAD_PHOTO_TO_START';
export const START_UPLOAD_PHOTO = 'START_UPLOAD_PHOTO';
export const HOW_AI_WORKS = 'HOW_AI_WORKS';
export const THE_STYLE = 'THE_STYLE';
export const ROOM_TYPE = 'ROOM_TYPE';
export const MORE_DETAILS = 'MORE_DETAILS';
export const PRIVACY = 'PRIVACY';
export const EXPORT_SPEED = 'EXPORT_SPEED';
export const START_NOW = 'START_NOW';
export const YOUR_CREDIT_NOT_ENOUGH = 'YOUR_CREDIT_NOT_ENOUGH';
export const ADD_CREDITS = 'ADD_CREDITS';
export const PRIVACY_TOOLTIP = 'PRIVACY_TOOLTIP';
export const PRIVATE_TEXT = 'PRIVATE_TEXT';
export const PUBLIC_TEXT = 'PUBLIC_TEXT';
export const TWO_POINTS = 'TWO_POINTS';
export const TWO_POINTS_NOR_PAID = 'TWO_POINTS_NOR_PAID';
export const SLOW = 'SLOW';
export const FAST = 'FAST';
export const ONE_POINT = 'ONE_POINT';
export const FREE = 'FREE';
export const SHARE_AI = 'SHARE_AI';
export const NEED_DESIGNER = 'NEED_DESIGNER';
export const NEED_DESIGNER_TEXT = 'NEED_DESIGNER_TEXT';
export const SEND_TO_MANZILIK = 'SEND_TO_MANZILIK';
export const WHY_MANZILIK = 'WHY_MANZILIK';
export const WHY_MANZILIK_TEXT = 'WHY_MANZILIK_TEXT';
export const SAVE_TIME_EFFORT = 'SAVE_TIME_EFFORT';
export const SAVE_TIME_EFFORT_TEXT = 'SAVE_TIME_EFFORT_TEXT';
export const IMPROVE_DESIGN = 'IMPROVE_DESIGN';
export const IMPROVE_DESIGN_TEXT = 'IMPROVE_DESIGN_TEXT';
export const PREDICT_FUTURE = 'PREDICT_FUTURE';
export const PREDICT_FUTURE_TEXT = 'PREDICT_FUTURE_TEXT';
export const CUSTOMIZE_DESIGN = 'CUSTOMIZE_DESIGN';
export const CUSTOMIZE_DESIGN_TEXT = 'CUSTOMIZE_DESIGN_TEXT';
export const ADD_ACCOUNT_POINTS = 'ADD_ACCOUNT_POINTS';
export const ALL_OPTIONS_AVAILABLE = 'ALL_OPTIONS_AVAILABLE';
export const DOWNLOAD_IMAGES = 'DOWNLOAD_IMAGES';
export const CREATE_DESIGNS = 'CREATE_DESIGNS';
export const REMOVE_WATER_MARK = 'REMOVE_WATER_MARK';
export const A_LOT_OF_DESIGNS = 'A_LOT_OF_DESIGNS';
export const MOST_REQUESTED = 'MOST_REQUESTED';
export const GET_IT_NOW = 'GET_IT_NOW';
export const BEFORE = 'BEFORE';
export const AFTER = 'AFTER';
export const SMART_DESIGN = 'SMART_DESIGN';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const CONTINUE_BTN = 'CONTINUE_BTN';
export const ORIGINAL = 'ORIGINAL';
export const EXPERIMENTAL = 'EXPERIMENTAL';
export const THANKS_FOR_RATE = 'THANKS_FOR_RATE';
export const TOTAL_PAYMENT = 'TOTAL_PAYMENT';
export const START_SMART_DESIGN = 'START_SMART_DESIGN';
export const TYR_ANOTHER_TIME = 'TYR_ANOTHER_TIME';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const DESIGN_IN_PROGRESS = 'DESIGN_IN_PROGRESS';
export const ALL_PRICES = 'ALL_PRICES';
export const RIGHT_DESCRIPTION = 'RIGHT_DESCRIPTION';
export const SPECIFY_ACCOUNT_TYPE = 'SPECIFY_ACCOUNT_TYPE';
export const CONTINUE_HOME_OWNER = 'CONTINUE_HOME_OWNER';
export const CONTINUE_PROFESSIONAL = 'CONTINUE_PROFESSIONAL';
export const CLIENT_TYPE_TEXT = 'CLIENT_TYPE_TEXT';
export const PROF_TYPE_TEXT = 'PROF_TYPE_TEXT';
export const SERVICE_PROVIDER2 = 'SERVICE_PROVIDER2';
export const INVITE_FRIENDS = 'INVITE_FRIENDS';
export const GET_POINTS = 'GET_POINTS';
export const COPY_DONE = 'COPY_DONE';
export const SHARE_LINK = 'SHARE_LINK';
export const REGISTER_FOR_MANZILIK = 'REGISTER_FOR_MANZILIK';
export const TRY_AI = 'TRY_AI';
export const GOT_POINTS = 'GOT_POINTS';
export const SHARE_WITH_FRIENDS = 'SHARE_WITH_FRIENDS';
export const WE_NEED_YOUR_PHONE = 'WE_NEED_YOUR_PHONE';
export const AI_ERROR_B104 = 'AI_ERROR_B104';
export const DELETE_DESIGN = 'DELETE_DESIGN';
export const SURE_DELETE = 'SURE_DELETE';
export const CONFIRM_DELETE = 'CONFIRM_DELETE';
export const REQUEST_FOR_QUOTATION = 'REQUEST_FOR_QUOTATION';
export const REQUEST_FOR_QUOTATION_TEXT = 'REQUEST_FOR_QUOTATION_TEXT';
export const POST_A_QUOTE = 'POST_A_QUOTE';
export const POST_NOW = 'POST_NOW';
export const POST_A_QUOTE_TEXT = 'POST_A_QUOTE_TEXT';
export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const POST_IT_NOW = 'POST_IT_NOW';
export const PRICE_QUOTE_SEND_SUCCESS = 'PRICE_QUOTE_SEND_SUCCESS';
export const PRICE_QUOTE_SEND_SUCCESS_TEXT = 'PRICE_QUOTE_SEND_SUCCESS_TEXT';
export const PRICE_QUOTE_DETAILS = 'PRICE_QUOTE_DETAILS';
export const CREATE_A_PROJECT = 'CREATE_A_PROJECT';
export const CREATE_A_PROJECT_TEXT = 'CREATE_A_PROJECT_TEXT';
export const DETERMINE_THE_REQUIRED_SKILLS = 'DETERMINE_THE_REQUIRED_SKILLS';
export const DETERMINE_THE_REQUIRED_SKILLS_TEXT = 'DETERMINE_THE_REQUIRED_SKILLS_TEXT';
export const DETERMINE_THE_BUDGET = 'DETERMINE_THE_BUDGET';
export const DETERMINE_THE_BUDGET_TEXT = 'DETERMINE_THE_BUDGET_TEXT';
export const PUBLISH_THE_PROJECT = 'PUBLISH_THE_PROJECT';
export const PUBLISH_THE_PROJECT_TEXT = 'PUBLISH_THE_PROJECT_TEXT';
export const REVIEW_OFFERS = 'REVIEW_OFFERS';
export const REVIEW_OFFERS_TEXT = 'REVIEW_OFFERS_TEXT';
export const DISCUSS_DETAILS = 'DISCUSS_DETAILS';
export const DISCUSS_DETAILS_TEXT = 'DISCUSS_DETAILS_TEXT';
export const CONTRACTING = 'CONTRACTING';
export const CONTRACTING_TEXT = 'CONTRACTING_TEXT';
export const FOLLOW_THE_PROGRESS = 'FOLLOW_THE_PROGRESS';
export const FOLLOW_THE_PROGRESS_TEXT = 'FOLLOW_THE_PROGRESS_TEXT';
export const SUBMIT_A_PRICE_REQUEST = 'SUBMIT_A_PRICE_REQUEST';
export const ACCURACY = 'ACCURACY';
export const WALLS = 'WALLS';
export const FLOOR = 'FLOOR';
export const CEILING = 'CEILING';
export const COLOR_PALETS = 'COLOR_PALETS';
export const DESIGN_DESC = 'DESIGN_DESC';
export const AI_INPUT_PLACEHOLDER = 'AI_INPUT_PLACEHOLDER';
export const ADDITIONAL_OPTIONS = 'ADDITIONAL_OPTIONS';
export const NOT_SELECTED = 'NOT_SELECTED';
export const QUOTATION_DETAILS = 'QUOTATION_DETAILS';
export const APPLY_TO_QUOTATIONS = 'APPLY_TO_QUOTATIONS';
export const QUOTATIONS = 'QUOTATIONS';
export const OPENED_REQUEST = 'OPENED_REQUEST';
export const CLOSED_REQUEST = 'CLOSED_REQUEST';
export const DURATION = 'DURATION';
export const REQUESTS_WORKS_ON = 'REQUESTS_WORKS_ON';
export const QUOTATIONS_REQUESTS = 'QUOTATIONS_REQUESTS';
export const CONTRACTED_REQUEST = 'CONTRACTED_REQUEST';
export const BUSINESS_YOU_LIKE = 'BUSINESS_YOU_LIKE';
export const BROWSE_JOBS = 'BROWSE_JOBS';
export const APPLICANTS = 'APPLICANTS';
export const PHOTOS_ATTACHED = 'PHOTOS_ATTACHED';
export const MAKE_AN_OFFER = 'MAKE_AN_OFFER';
export const OFFER_PRICE = 'OFFER_PRICE';
export const PROJECT_DURATION = 'PROJECT_DURATION';
export const MESSAGE_TO_SERVICE_REQUESTER = 'MESSAGE_TO_SERVICE_REQUESTER';
export const ATTACH_YOUR_WORK = 'ATTACH_YOUR_WORK';
export const UPLOAD_YOUR_WORK = 'UPLOAD_YOUR_WORK';
export const ATTACH_UP_TO = 'ATTACH_UP_TO';
export const TO_SUBMIT_RFQ = 'TO_SUBMIT_RFQ';
export const UPGRADE_SERVICES = 'UPGRADE_SERVICES';
export const THE_TYPE = 'THE_TYPE';
export const QUOTATION_REQUEST = 'QUOTATION_REQUEST';
export const ARE_U_SURE_TO_SUBMIT_QUOTATION = 'ARE_U_SURE_TO_SUBMIT_QUOTATION';
export const QUOTATION_LAST_STEP = 'QUOTATION_LAST_STEP';
export const CONFIRM_QUOTATION = 'CONFIRM_QUOTATION';
export const QUOTATION_SENT = 'QUOTATION_SENT';
export const MONTHS = 'MONTHS';
export const YEARS = 'YEARS';
export const START_TRIP = 'START_TRIP';
export const YOU_CAN_APPLY = 'YOU_CAN_APPLY';
export const PRESS_MY_ACCOUNT = 'PRESS_MY_ACCOUNT';
export const SERVICE_DETAILS_CONT = 'SERVICE_DETAILS_CONT';
export const TYR_TO_ADD_SERVICE = 'TYR_TO_ADD_SERVICE';
export const THEN_CLICK_GIGS_MANAGEMENT = 'THEN_CLICK_GIGS_MANAGEMENT';
export const THEN_CLICK_ADD_GIG = 'THEN_CLICK_ADD_GIG';
export const TO_CONTINUE_FOLLOW_DETAILS = 'TO_CONTINUE_FOLLOW_DETAILS';
export const YOUR_REVIEW = 'YOUR_REVIEW';
export const REVIEW_SERVICE = 'REVIEW_SERVICE';
export const REVIEW_SERVICE_TEXT = 'REVIEW_SERVICE_TEXT';
export const START_RATING = 'START_RATING';
export const ADD_RATE = 'ADD_RATE';
export const PRICE_NOT_ADDED = 'PRICE_NOT_ADDED';
export const ORDER_ACCEPTED = 'ORDER_ACCEPTED';
export const CONTACT_CS = 'CONTACT_CS';
export const DESC_ABOUT_ME = 'DESC_ABOUT_ME';
export const NO_BADGES = 'NO_BADGES';
export const HOW_TO_GET = 'HOW_TO_GET';
export const HOW_TO_GET_BAGDES = 'HOW_TO_GET_BAGDES';
export const HEADING_HOME_CLIENT1 = 'HEADING_HOME_CLIENT1';
export const HEADING_HOME_CLIENT2 = 'HEADING_HOME_CLIENT2';
export const START_SEARCH = 'START_SEARCH';
export const EXPLORE_YOUR_REQUESTS = 'EXPLORE_YOUR_REQUESTS';
export const NO_PROJECTS = 'NO_PROJECTS';
export const ADD_NEW_PROJECTS = 'ADD_NEW_PROJECTS';
export const NEXT_STEP = 'NEXT_STEP';

export const SERVICE_FORM = 'SERVICE_FORM';
export const PAY_SERVICE_VALUE = 'PAY_SERVICE_VALUE';
export const VAT_INCLUDED = 'VAT_INCLUDED';
export const WILL_ADDED = 'WILL_ADDED';
export const FIVE_POINTS = 'FIVE_POINTS';
export const TO_USE_IN_AI = 'TO_USE_IN_AI';
export const INCLUDED_FEES = 'INCLUDED_FEES';
export const REFUND2 = 'REFUND2';
export const ACTIVE_REPUBLISH = 'ACTIVE_REPUBLISH';
export const YOUR_MANZILIK_ORDER = 'YOUR_MANZILIK_ORDER';
export const CONGRATS = 'CONGRATS';
export const ARE_YOU_SURE_DELETE_ORDER = 'ARE_YOU_SURE_DELETE_ORDER';
export const ACCEPTED_OFFER = 'ACCEPTED_OFFER';
export const CANCELED_OFFER = 'CANCELED_OFFER';
export const NOW_CAN_COMPLETE_CONTRACT = 'NOW_CAN_COMPLETE_CONTRACT';
export const ORDER_CANCELED = 'ORDER_CANCELED';
export const REFUND_MESSAGE = 'REFUND_MESSAGE';
export const CONFIRM_CANCEL_ORDER = 'CONFIRM_CANCEL_ORDER';
export const MESSAGES = 'MESSAGES';
export const OFFER_ACCEPTED = 'OFFER_ACCEPTED';
export const YOU_CAN_PUBLISH = 'YOU_CAN_PUBLISH';
export const RE_PUBLISH = 'RE_PUBLISH';
export const NO_QUOTATIONS = 'NO_QUOTATIONS';
export const DRAFT_REQUEST = 'DRAFT_REQUEST';
export const DESCRIBE_SERVICE = 'DESCRIBE_SERVICE';
export const DESCRIPTION_HELPS_YOU = 'DESCRIPTION_HELPS_YOU';
export const DESCRIPTION_EXAMPLE = 'DESCRIPTION_EXAMPLE';
export const WE_NEED_YOUR_PHONE_TO_ASSIST = 'WE_NEED_YOUR_PHONE_TO_ASSIST';
export const REGISTER_NOW = 'REGISTER_NOW';
export const CONTINUE_PAYMENT = 'CONTINUE_PAYMENT';
export const CONTACT_SUPPORT = 'CONTACT_SUPPORT';
export const SHOW_CLOSED_COMPLETED_REQUESTS = 'SHOW_CLOSED_REQUESTS';
export const INPROGRESS_REQUESTS = 'INPROGRESS_REQUESTS';
export const NEW_REQUESTS = 'NEW_REQUESTS';
export const COMPLETED_CANCELED_REQUESTS = 'COMPLETED_CANCELED_REQUESTS';
export const APPLICANT = 'APPLICANT';
export const SOME_GUIDES = 'SOME_GUIDES';
export const AI_GUIDE_1 = 'AI_GUIDE_1';
export const AI_GUIDE_2 = 'AI_GUIDE_2';
export const AI_GUIDE_3 = 'AI_GUIDE_3';
export const AI_GUIDE_4 = 'AI_GUIDE_4';
export const AI_GUIDE_5 = 'AI_GUIDE_5';
export const AI_GUIDE_6 = 'AI_GUIDE_6';
export const AI_GUIDE_7 = 'AI_GUIDE_7';
export const ATTACHED_PHOTOS = 'ATTACHED_PHOTOS';
export const GUIDES = 'GUIDES';
export const SMART_TEXT_AREA = 'SMART_TEXT_AREA';
export const SMART_GENERATION = 'SMART_GENERATION';
export const RE_GENERATE = 'RE_GENERATE';
export const SELECT_STYLE = 'SELECT_STYLE';
export const SELECT_ROOM_TYPE = 'SELECT_ROOM_TYPE';
export const RE_SELECT = 'RE_SELECT';
export const ADV_GENERATE_TOOLTIP = 'ADV_GENERATE_TOOLTIP';
export const NOT_EXIST = 'NOT_EXIST';
export const CLASSIC_VIEW = 'CLASSIC_VIEW';
export const BACK_HISTORY = 'BACK_HISTORY';
export const CLASSIFY_STORES = 'CLASSIFY_STORES';
export const FIND_VENDOR = 'FIND_VENDOR';
export const SIMILAR_PRODUCTS = 'SIMILAR_PRODUCTS';
export const ANALYZE_PRODUCTS = 'ANALYZE_PRODUCTS';
export const COUNTRY_CITY = 'COUNTRY_CITY';
export const MANAGE_IDEAS = 'MANAGE_IDEAS';
export const MANAGE_VIDEOS = 'MANAGE_VIDEOS';
export const SHARE_PROFILE = 'SHARE_PROFILE';
export const SHARE_PROFILE_WITH_PEOPLE = 'SHARE_PROFILE_WITH_PEOPLE';
export const MANAGE_REQUESTS = 'MANAGE_REQUESTS';
export const TRENDING = 'TRENDING';
export const JOBS = 'JOBS';
export const APPLIED_FOR_JOB = 'APPLIED_FOR_JOB';
export const BE_FIRST_ONE = 'BE_FIRST_ONE';
export const APPLY = 'APPLY';
export const NO_PRODUCTS_EXIST = 'NO_PRODUCTS_EXIST';
export const VIEW_SELLERS = 'VIEW_SELLERS';
export const VIEW_PRODUCTS = 'VIEW_PRODUCTS';
export const PRESS_TO_VIEW_PRODUCTS = 'PRESS_TO_VIEW_PRODUCTS';
export const VIEW_SELLERS_PRODUCTS = 'VIEW_SELLERS_PRODUCTS';
export const VIEW_SELLERS_PRODUCTS_WITHIN_SEARCH = 'VIEW_SELLERS_PRODUCTS_WITHIN_SEARCH';
export const BEST_SELLING_PRODUCTS = 'BEST_SELLING_PRODUCTS';
export const WILL_DEDUCT = 'WILL_DEDUCT';
export const POINTS_FROM_BALANCE = 'POINTS_FROM_BALANCE';
export const BEST_SELLERS_IN_KINGDOM = 'BEST_SELLERS_IN_KINGDOM';
export const INCOMPLETE_RFQ = 'INCOMPLETE_RFQ';
