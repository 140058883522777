/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addServiceInqueryMessage = /* GraphQL */ `mutation AddServiceInqueryMessage($userId: ID!) {
  addServiceInqueryMessage(userId: $userId) {
    userId
    has_unread_messages
    __typename
  }
}
` as GeneratedMutation<APITypes.AddServiceInqueryMessageMutationVariables, APITypes.AddServiceInqueryMessageMutation>;
export const updatePaymentStatus = /* GraphQL */ `mutation UpdatePaymentStatus(
  $checkout_id: String!
  $package_id: String!
  $status: PaymentStatus!
  $message: String
) {
  updatePaymentStatus(
    checkout_id: $checkout_id
    package_id: $package_id
    status: $status
    message: $message
  ) {
    checkout_id
    package_id
    status
    message
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdatePaymentStatusMutationVariables, APITypes.UpdatePaymentStatusMutation>;
export const addProject = /* GraphQL */ `mutation AddProject($input: ProjectInput!) {
  addProject(input: $input) {
    id
    room_type {
      id
      title
      arabic_title
      english_title
      category {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      status
      created_at
      updated_at
      photo
      __typename
    }
    is_default
    title
    status
    created_at
    updated_at
    visibility
    client_id
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    discussions_count
    ideas_count
    likes_count
    shares_count
    questions_count
    description
    ideas {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    ideasList {
      count
      next
      previous
      results {
        id
        tag
        created_at
        updated_at
        title
        photo
        status
        source
        description
        project_id
        project {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        client_id
        questions_count
        shares_count
        likes_count
        is_liked
        is_photo_uploaded
        questions {
          count
          next
          previous
          __typename
        }
        google_vision_result
        paint_colors {
          count
          next
          previous
          __typename
        }
        ideaLabels {
          count
          next
          previous
          __typename
        }
        __typename
      }
      __typename
    }
    is_liked
    collaborators {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      __typename
    }
    default_idea {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    questions {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        title
        description
        idea_id
        idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        likes_count
        comments_count
        is_liked
        __typename
      }
      __typename
    }
    entities {
      count
      next
      previous
      results {
        created_at
        updated_at
        status
        photo
        description
        tag
        client_id
        project_id
        entity_id
        title
        is_main_entity
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.AddProjectMutationVariables, APITypes.AddProjectMutation>;
export const updateProject = /* GraphQL */ `mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    id
    room_type {
      id
      title
      arabic_title
      english_title
      category {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      status
      created_at
      updated_at
      photo
      __typename
    }
    is_default
    title
    status
    created_at
    updated_at
    visibility
    client_id
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    discussions_count
    ideas_count
    likes_count
    shares_count
    questions_count
    description
    ideas {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    ideasList {
      count
      next
      previous
      results {
        id
        tag
        created_at
        updated_at
        title
        photo
        status
        source
        description
        project_id
        project {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        client_id
        questions_count
        shares_count
        likes_count
        is_liked
        is_photo_uploaded
        questions {
          count
          next
          previous
          __typename
        }
        google_vision_result
        paint_colors {
          count
          next
          previous
          __typename
        }
        ideaLabels {
          count
          next
          previous
          __typename
        }
        __typename
      }
      __typename
    }
    is_liked
    collaborators {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      __typename
    }
    default_idea {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    questions {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        title
        description
        idea_id
        idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        likes_count
        comments_count
        is_liked
        __typename
      }
      __typename
    }
    entities {
      count
      next
      previous
      results {
        created_at
        updated_at
        status
        photo
        description
        tag
        client_id
        project_id
        entity_id
        title
        is_main_entity
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateProjectMutationVariables, APITypes.UpdateProjectMutation>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject($id: ID!) {
  deleteProject(id: $id) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteProjectMutationVariables, APITypes.DeleteProjectMutation>;
export const setProjectDefaultIdea = /* GraphQL */ `mutation SetProjectDefaultIdea($input: setProjectDefaultIdeaInput) {
  setProjectDefaultIdea(input: $input) {
    id
    room_type {
      id
      title
      arabic_title
      english_title
      category {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      status
      created_at
      updated_at
      photo
      __typename
    }
    is_default
    title
    status
    created_at
    updated_at
    visibility
    client_id
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    discussions_count
    ideas_count
    likes_count
    shares_count
    questions_count
    description
    ideas {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    ideasList {
      count
      next
      previous
      results {
        id
        tag
        created_at
        updated_at
        title
        photo
        status
        source
        description
        project_id
        project {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        client_id
        questions_count
        shares_count
        likes_count
        is_liked
        is_photo_uploaded
        questions {
          count
          next
          previous
          __typename
        }
        google_vision_result
        paint_colors {
          count
          next
          previous
          __typename
        }
        ideaLabels {
          count
          next
          previous
          __typename
        }
        __typename
      }
      __typename
    }
    is_liked
    collaborators {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      __typename
    }
    default_idea {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    questions {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        title
        description
        idea_id
        idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        likes_count
        comments_count
        is_liked
        __typename
      }
      __typename
    }
    entities {
      count
      next
      previous
      results {
        created_at
        updated_at
        status
        photo
        description
        tag
        client_id
        project_id
        entity_id
        title
        is_main_entity
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.SetProjectDefaultIdeaMutationVariables, APITypes.SetProjectDefaultIdeaMutation>;
export const saveEntityToProject = /* GraphQL */ `mutation SaveEntityToProject($input: SaveEntityInput!) {
  saveEntityToProject(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.SaveEntityToProjectMutationVariables, APITypes.SaveEntityToProjectMutation>;
export const moveEntityFromProject = /* GraphQL */ `mutation MoveEntityFromProject($input: MoveEntityInput!) {
  moveEntityFromProject(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.MoveEntityFromProjectMutationVariables, APITypes.MoveEntityFromProjectMutation>;
export const deleteEntityFromProject = /* GraphQL */ `mutation DeleteEntityFromProject($input: DeleteEntityInput!) {
  deleteEntityFromProject(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteEntityFromProjectMutationVariables, APITypes.DeleteEntityFromProjectMutation>;
export const copyEntityToProject = /* GraphQL */ `mutation CopyEntityToProject($input: CopyEntityInput!) {
  copyEntityToProject(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.CopyEntityToProjectMutationVariables, APITypes.CopyEntityToProjectMutation>;
export const addNoteToProjectEntity =
  /* GraphQL */ `mutation AddNoteToProjectEntity($input: AddNoteToProjectEntityInput!) {
  addNoteToProjectEntity(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.AddNoteToProjectEntityMutationVariables, APITypes.AddNoteToProjectEntityMutation>;
export const setAsMainEntity = /* GraphQL */ `mutation SetAsMainEntity($input: SetAsMainEntityInput!) {
  setAsMainEntity(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.SetAsMainEntityMutationVariables, APITypes.SetAsMainEntityMutation>;
export const addIdea = /* GraphQL */ `mutation AddIdea($input: IdeaInput!) {
  addIdea(input: $input) {
    id
    tag
    created_at
    updated_at
    title
    photo
    status
    source
    description
    project_id
    project {
      id
      room_type {
        id
        title
        arabic_title
        english_title
        category {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        status
        created_at
        updated_at
        photo
        __typename
      }
      is_default
      title
      status
      created_at
      updated_at
      visibility
      client_id
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      discussions_count
      ideas_count
      likes_count
      shares_count
      questions_count
      description
      ideas {
        id
        tag
        created_at
        updated_at
        title
        photo
        status
        source
        description
        project_id
        project {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        client_id
        questions_count
        shares_count
        likes_count
        is_liked
        is_photo_uploaded
        questions {
          count
          next
          previous
          __typename
        }
        google_vision_result
        paint_colors {
          count
          next
          previous
          __typename
        }
        ideaLabels {
          count
          next
          previous
          __typename
        }
        __typename
      }
      ideasList {
        count
        next
        previous
        results {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        __typename
      }
      is_liked
      collaborators {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        __typename
      }
      default_idea {
        id
        tag
        created_at
        updated_at
        title
        photo
        status
        source
        description
        project_id
        project {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        client_id
        questions_count
        shares_count
        likes_count
        is_liked
        is_photo_uploaded
        questions {
          count
          next
          previous
          __typename
        }
        google_vision_result
        paint_colors {
          count
          next
          previous
          __typename
        }
        ideaLabels {
          count
          next
          previous
          __typename
        }
        __typename
      }
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      entities {
        count
        next
        previous
        results {
          created_at
          updated_at
          status
          photo
          description
          tag
          client_id
          project_id
          entity_id
          title
          is_main_entity
          __typename
        }
        __typename
      }
      __typename
    }
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    client_id
    questions_count
    shares_count
    likes_count
    is_liked
    is_photo_uploaded
    questions {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        status
        title
        description
        idea_id
        idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        likes_count
        comments_count
        is_liked
        __typename
      }
      __typename
    }
    google_vision_result
    paint_colors {
      count
      next
      previous
      results {
        color {
          id
          title
          rgb
          __typename
        }
        __typename
      }
      __typename
    }
    ideaLabels {
      count
      next
      previous
      results {
        id
        coordinates {
          x
          y
          __typename
        }
        name
        related_stockrecords_count
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.AddIdeaMutationVariables, APITypes.AddIdeaMutation>;
export const editProfile = /* GraphQL */ `mutation EditProfile($input: EditClientProfileInput!) {
  editProfile(input: $input) {
    id
    created_at
    updated_at
    status
    first_name
    last_name
    email
    mobile
    country_code
    gender
    type
    about_me
    my_fav_style
    my_next_style
    city_id
    country_id
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    country {
      id
      created_at
      updated_at
      status
      name
      country_flag
      is_default
      __typename
    }
    zip
    state
    following_count
    followers_count
    followees_count
    is_followed
    total_review
    discussions_count
    comments_count
    is_verified
    is_add_gigs_tutorial_showed
    last_login
    profile_image
    facebook
    twitter
    linkedin
    blog
    project_role
    client_awards {
      id
      created_at
      updated_at
      status
      title
      code
      image
      __typename
    }
    client_badges {
      id
      created_at
      updated_at
      status
      key
      title
      value
      image
      description
      client_progress
      is_claimed
      __typename
    }
    projects {
      count
      next
      previous
      results {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      __typename
    }
    addresses {
      count
      next
      previous
      results {
        id
        name
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        neighborhood {
          id
          created_at
          updated_at
          status
          name
          city_id
          __typename
        }
        description
        first_name
        last_name
        phone_number
        email
        is_default
        __typename
      }
      __typename
    }
    bankcards {
      count
      next
      previous
      results {
        id
        card_type
        number
        name
        expiry_date
        partner_reference
        payment_method {
          id
          name
          logo
          brand
          __typename
        }
        __typename
      }
      __typename
    }
    related_professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    sendbird_access_token
    apns_token
    balance
    is_purchased
    referral_url
    __typename
  }
}
` as GeneratedMutation<APITypes.EditProfileMutationVariables, APITypes.EditProfileMutation>;
export const updateNotificationSettings =
  /* GraphQL */ `mutation UpdateNotificationSettings($input: NotificationSettingsInput!) {
  updateNotificationSettings(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateNotificationSettingsMutationVariables,
    APITypes.UpdateNotificationSettingsMutation
  >;
export const inviteCollaborators = /* GraphQL */ `mutation InviteCollaborators($input: InviteCollaboratorsInput!) {
  inviteCollaborators(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.InviteCollaboratorsMutationVariables, APITypes.InviteCollaboratorsMutation>;
export const deleteCollaborators = /* GraphQL */ `mutation DeleteCollaborators($input: DeleteCollaboratorsInput!) {
  deleteCollaborators(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteCollaboratorsMutationVariables, APITypes.DeleteCollaboratorsMutation>;
export const completeProfessionalProfile = /* GraphQL */ `mutation CompleteProfessionalProfile(
  $input: CompleteProfessionalProfileInput!
) {
  completeProfessionalProfile(input: $input) {
    id
    created_at
    updated_at
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    address
    categories {
      id
      title
      english_title
      description
      parent {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      photo
      status
      created_at
      updated_at
      filters
      __typename
    }
    services {
      id
      created_at
      updated_at
      title
      image
      short_description
      long_description
      __typename
    }
    locations {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    is_profile_completed
    reviews_total
    reviews_count
    gallery_photos
    photos {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        photo
        __typename
      }
      __typename
    }
    is_reviewable
    projects_count
    videos {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        video
        __typename
      }
      __typename
    }
    contacts {
      count
      next
      previous
      results {
        contact_type
        value
        __typename
      }
      __typename
    }
    professional_type
    company_name
    company_logo
    company_registration_ref
    personal_freelance_license
    is_verified
    is_gig_professional
    gigs_count
    score
    quotations {
      count
      next
      previous
      results {
        id
        service_inquiry {
          id
          number
          status
          created_at
          description
          budget_limits
          phone_number
          whatsapp_number
          gig_service_id
          gig_service_title
          gig_service_price
          gig_service_description
          sendbird_channel_name
          sendbird_channel_url
          reviews_total
          reviews_count
          type
          __typename
        }
        professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        created_at
        status
        budget_limits
        execution_time
        sendbird_channel_name
        sendbird_channel_url
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CompleteProfessionalProfileMutationVariables,
  APITypes.CompleteProfessionalProfileMutation
>;
export const uploadProfessionalPhotos =
  /* GraphQL */ `mutation UploadProfessionalPhotos($input: UploadProfessionalPhotosInput!) {
  uploadProfessionalPhotos(input: $input) {
    id
    created_at
    updated_at
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    address
    categories {
      id
      title
      english_title
      description
      parent {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      photo
      status
      created_at
      updated_at
      filters
      __typename
    }
    services {
      id
      created_at
      updated_at
      title
      image
      short_description
      long_description
      __typename
    }
    locations {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    is_profile_completed
    reviews_total
    reviews_count
    gallery_photos
    photos {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        photo
        __typename
      }
      __typename
    }
    is_reviewable
    projects_count
    videos {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        video
        __typename
      }
      __typename
    }
    contacts {
      count
      next
      previous
      results {
        contact_type
        value
        __typename
      }
      __typename
    }
    professional_type
    company_name
    company_logo
    company_registration_ref
    personal_freelance_license
    is_verified
    is_gig_professional
    gigs_count
    score
    quotations {
      count
      next
      previous
      results {
        id
        service_inquiry {
          id
          number
          status
          created_at
          description
          budget_limits
          phone_number
          whatsapp_number
          gig_service_id
          gig_service_title
          gig_service_price
          gig_service_description
          sendbird_channel_name
          sendbird_channel_url
          reviews_total
          reviews_count
          type
          __typename
        }
        professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        created_at
        status
        budget_limits
        execution_time
        sendbird_channel_name
        sendbird_channel_url
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.UploadProfessionalPhotosMutationVariables, APITypes.UploadProfessionalPhotosMutation>;
export const uploadProfessionalVideos =
  /* GraphQL */ `mutation UploadProfessionalVideos($input: UploadProfessionalVideosInput!) {
  uploadProfessionalVideos(input: $input) {
    id
    created_at
    updated_at
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    address
    categories {
      id
      title
      english_title
      description
      parent {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      photo
      status
      created_at
      updated_at
      filters
      __typename
    }
    services {
      id
      created_at
      updated_at
      title
      image
      short_description
      long_description
      __typename
    }
    locations {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    is_profile_completed
    reviews_total
    reviews_count
    gallery_photos
    photos {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        photo
        __typename
      }
      __typename
    }
    is_reviewable
    projects_count
    videos {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        video
        __typename
      }
      __typename
    }
    contacts {
      count
      next
      previous
      results {
        contact_type
        value
        __typename
      }
      __typename
    }
    professional_type
    company_name
    company_logo
    company_registration_ref
    personal_freelance_license
    is_verified
    is_gig_professional
    gigs_count
    score
    quotations {
      count
      next
      previous
      results {
        id
        service_inquiry {
          id
          number
          status
          created_at
          description
          budget_limits
          phone_number
          whatsapp_number
          gig_service_id
          gig_service_title
          gig_service_price
          gig_service_description
          sendbird_channel_name
          sendbird_channel_url
          reviews_total
          reviews_count
          type
          __typename
        }
        professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        created_at
        status
        budget_limits
        execution_time
        sendbird_channel_name
        sendbird_channel_url
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.UploadProfessionalVideosMutationVariables, APITypes.UploadProfessionalVideosMutation>;
export const deleteProfessionalGalleryPhoto = /* GraphQL */ `mutation DeleteProfessionalGalleryPhoto($id: ID!) {
  deleteProfessionalGalleryPhoto(id: $id) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfessionalGalleryPhotoMutationVariables,
  APITypes.DeleteProfessionalGalleryPhotoMutation
>;
export const deleteProfessionalVideo = /* GraphQL */ `mutation DeleteProfessionalVideo($id: ID!) {
  deleteProfessionalVideo(id: $id) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteProfessionalVideoMutationVariables, APITypes.DeleteProfessionalVideoMutation>;
export const followClient = /* GraphQL */ `mutation FollowClient($input: FollowUserInput) {
  followClient(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.FollowClientMutationVariables, APITypes.FollowClientMutation>;
export const unfollowClient = /* GraphQL */ `mutation UnfollowClient($input: FollowUserInput) {
  unfollowClient(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.UnfollowClientMutationVariables, APITypes.UnfollowClientMutation>;
export const updateFcmToken = /* GraphQL */ `mutation UpdateFcmToken($input: UpdateFcmTokenInput) {
  updateFcmToken(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateFcmTokenMutationVariables, APITypes.UpdateFcmTokenMutation>;
export const completeRegistration = /* GraphQL */ `mutation CompleteRegistration($input: CompleteRegistrationInput!) {
  completeRegistration(input: $input) {
    id
    created_at
    updated_at
    status
    first_name
    last_name
    email
    mobile
    country_code
    gender
    type
    about_me
    my_fav_style
    my_next_style
    city_id
    country_id
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    country {
      id
      created_at
      updated_at
      status
      name
      country_flag
      is_default
      __typename
    }
    zip
    state
    following_count
    followers_count
    followees_count
    is_followed
    total_review
    discussions_count
    comments_count
    is_verified
    is_add_gigs_tutorial_showed
    last_login
    profile_image
    facebook
    twitter
    linkedin
    blog
    project_role
    client_awards {
      id
      created_at
      updated_at
      status
      title
      code
      image
      __typename
    }
    client_badges {
      id
      created_at
      updated_at
      status
      key
      title
      value
      image
      description
      client_progress
      is_claimed
      __typename
    }
    projects {
      count
      next
      previous
      results {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      __typename
    }
    addresses {
      count
      next
      previous
      results {
        id
        name
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        neighborhood {
          id
          created_at
          updated_at
          status
          name
          city_id
          __typename
        }
        description
        first_name
        last_name
        phone_number
        email
        is_default
        __typename
      }
      __typename
    }
    bankcards {
      count
      next
      previous
      results {
        id
        card_type
        number
        name
        expiry_date
        partner_reference
        payment_method {
          id
          name
          logo
          brand
          __typename
        }
        __typename
      }
      __typename
    }
    related_professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    sendbird_access_token
    apns_token
    balance
    is_purchased
    referral_url
    __typename
  }
}
` as GeneratedMutation<APITypes.CompleteRegistrationMutationVariables, APITypes.CompleteRegistrationMutation>;
export const showAddGigsTutorial = /* GraphQL */ `mutation ShowAddGigsTutorial {
  showAddGigsTutorial {
    id
    created_at
    updated_at
    status
    first_name
    last_name
    email
    mobile
    country_code
    gender
    type
    about_me
    my_fav_style
    my_next_style
    city_id
    country_id
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    country {
      id
      created_at
      updated_at
      status
      name
      country_flag
      is_default
      __typename
    }
    zip
    state
    following_count
    followers_count
    followees_count
    is_followed
    total_review
    discussions_count
    comments_count
    is_verified
    is_add_gigs_tutorial_showed
    last_login
    profile_image
    facebook
    twitter
    linkedin
    blog
    project_role
    client_awards {
      id
      created_at
      updated_at
      status
      title
      code
      image
      __typename
    }
    client_badges {
      id
      created_at
      updated_at
      status
      key
      title
      value
      image
      description
      client_progress
      is_claimed
      __typename
    }
    projects {
      count
      next
      previous
      results {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      __typename
    }
    addresses {
      count
      next
      previous
      results {
        id
        name
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        neighborhood {
          id
          created_at
          updated_at
          status
          name
          city_id
          __typename
        }
        description
        first_name
        last_name
        phone_number
        email
        is_default
        __typename
      }
      __typename
    }
    bankcards {
      count
      next
      previous
      results {
        id
        card_type
        number
        name
        expiry_date
        partner_reference
        payment_method {
          id
          name
          logo
          brand
          __typename
        }
        __typename
      }
      __typename
    }
    related_professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    sendbird_access_token
    apns_token
    balance
    is_purchased
    referral_url
    __typename
  }
}
` as GeneratedMutation<APITypes.ShowAddGigsTutorialMutation, APITypes.ShowAddGigsTutorialMutation>;
export const requestAccountDeletion = /* GraphQL */ `mutation RequestAccountDeletion {
  requestAccountDeletion {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.RequestAccountDeletionMutation, APITypes.RequestAccountDeletionMutation>;
export const addQuestion = /* GraphQL */ `mutation AddQuestion($input: QuestionInput!) {
  addQuestion(input: $input) {
    id
    created_at
    updated_at
    status
    title
    description
    idea_id
    idea {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    client_id
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    likes_count
    comments_count
    is_liked
    __typename
  }
}
` as GeneratedMutation<APITypes.AddQuestionMutationVariables, APITypes.AddQuestionMutation>;
export const addQuestionToProject = /* GraphQL */ `mutation AddQuestionToProject($input: QuestionToProjectInput!) {
  addQuestionToProject(input: $input) {
    id
    created_at
    updated_at
    status
    title
    description
    idea_id
    idea {
      id
      tag
      created_at
      updated_at
      title
      photo
      status
      source
      description
      project_id
      project {
        id
        room_type {
          id
          title
          arabic_title
          english_title
          status
          created_at
          updated_at
          photo
          __typename
        }
        is_default
        title
        status
        created_at
        updated_at
        visibility
        client_id
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        discussions_count
        ideas_count
        likes_count
        shares_count
        questions_count
        description
        ideas {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        ideasList {
          count
          next
          previous
          __typename
        }
        is_liked
        collaborators {
          count
          next
          previous
          __typename
        }
        default_idea {
          id
          tag
          created_at
          updated_at
          title
          photo
          status
          source
          description
          project_id
          client_id
          questions_count
          shares_count
          likes_count
          is_liked
          is_photo_uploaded
          google_vision_result
          __typename
        }
        questions {
          count
          next
          previous
          __typename
        }
        entities {
          count
          next
          previous
          __typename
        }
        __typename
      }
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      client_id
      questions_count
      shares_count
      likes_count
      is_liked
      is_photo_uploaded
      questions {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          status
          title
          description
          idea_id
          client_id
          likes_count
          comments_count
          is_liked
          __typename
        }
        __typename
      }
      google_vision_result
      paint_colors {
        count
        next
        previous
        results {
          __typename
        }
        __typename
      }
      ideaLabels {
        count
        next
        previous
        results {
          id
          name
          related_stockrecords_count
          __typename
        }
        __typename
      }
      __typename
    }
    client_id
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    likes_count
    comments_count
    is_liked
    __typename
  }
}
` as GeneratedMutation<APITypes.AddQuestionToProjectMutationVariables, APITypes.AddQuestionToProjectMutation>;
export const addComment = /* GraphQL */ `mutation AddComment($input: CommentInput!) {
  addComment(input: $input) {
    id
    created_at
    updated_at
    status
    content
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    client_id
    likes_count
    is_liked
    __typename
  }
}
` as GeneratedMutation<APITypes.AddCommentMutationVariables, APITypes.AddCommentMutation>;
export const updateComment = /* GraphQL */ `mutation UpdateComment($input: CommentInput!) {
  updateComment(input: $input) {
    id
    created_at
    updated_at
    status
    content
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    client_id
    likes_count
    is_liked
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateCommentMutationVariables, APITypes.UpdateCommentMutation>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteCommentMutationVariables, APITypes.DeleteCommentMutation>;
export const shareEntity = /* GraphQL */ `mutation ShareEntity($input: ShareInput!) {
  shareEntity(input: $input) {
    id
    created_at
    updated_at
    status
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.ShareEntityMutationVariables, APITypes.ShareEntityMutation>;
export const likeEntity = /* GraphQL */ `mutation LikeEntity($input: LikeInput!) {
  likeEntity(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.LikeEntityMutationVariables, APITypes.LikeEntityMutation>;
export const reviewEntity = /* GraphQL */ `mutation ReviewEntity($input: ReviewInput!) {
  reviewEntity(input: $input) {
    id
    created_at
    updated_at
    content
    rating
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.ReviewEntityMutationVariables, APITypes.ReviewEntityMutation>;
export const updateReviewEntity = /* GraphQL */ `mutation UpdateReviewEntity($input: ReviewInput!) {
  updateReviewEntity(input: $input) {
    id
    created_at
    updated_at
    content
    rating
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateReviewEntityMutationVariables, APITypes.UpdateReviewEntityMutation>;
export const reportSpam = /* GraphQL */ `mutation ReportSpam($input: ReportSpamInput!) {
  reportSpam(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.ReportSpamMutationVariables, APITypes.ReportSpamMutation>;
export const addDiscussion = /* GraphQL */ `mutation AddDiscussion($input: DiscussionInput!) {
  addDiscussion(input: $input) {
    id
    tag
    created_at
    updated_at
    status
    title
    description
    client_id
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    youtube_url
    photo
    web_url
    likes_count
    comments_count
    topics {
      id
      paren_id
      created_at
      updated_at
      status
      title
      description
      is_interested
      __typename
    }
    polls {
      id
      created_at
      updated_at
      status
      title
      photo_url
      __typename
    }
    is_liked
    latest_two_commenters {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.AddDiscussionMutationVariables, APITypes.AddDiscussionMutation>;
export const deleteDiscussion = /* GraphQL */ `mutation DeleteDiscussion($id: ID!) {
  deleteDiscussion(id: $id) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteDiscussionMutationVariables, APITypes.DeleteDiscussionMutation>;
export const saveInterestedTopic = /* GraphQL */ `mutation SaveInterestedTopic($input: SaveInterestedTopicInput!) {
  saveInterestedTopic(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.SaveInterestedTopicMutationVariables, APITypes.SaveInterestedTopicMutation>;
export const contactMe = /* GraphQL */ `mutation ContactMe($input: ContactMeInput!) {
  contactMe(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.ContactMeMutationVariables, APITypes.ContactMeMutation>;
export const contactUs = /* GraphQL */ `mutation ContactUs($input: ContactUsInput!) {
  contactUs(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.ContactUsMutationVariables, APITypes.ContactUsMutation>;
export const sendEmail = /* GraphQL */ `mutation SendEmail($input: SendEmailInput!) {
  sendEmail(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.SendEmailMutationVariables, APITypes.SendEmailMutation>;
export const addProductReview = /* GraphQL */ `mutation AddProductReview($input: ProductReviewInput!) {
  addProductReview(input: $input) {
    id
    stockrecord
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    body
    general_score
    client_bought_product
    created_at
    status
    detailed_review_scores {
      id
      title
      status
      score
      detailed_score {
        id
        title
        status
        __typename
      }
      __typename
    }
    review_images {
      id
      image
      status
      __typename
    }
    likes_count
    is_liked
    __typename
  }
}
` as GeneratedMutation<APITypes.AddProductReviewMutationVariables, APITypes.AddProductReviewMutation>;
export const updateProductReview = /* GraphQL */ `mutation UpdateProductReview($input: ProductReviewInput!) {
  updateProductReview(input: $input) {
    id
    stockrecord
    client {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    body
    general_score
    client_bought_product
    created_at
    status
    detailed_review_scores {
      id
      title
      status
      score
      detailed_score {
        id
        title
        status
        __typename
      }
      __typename
    }
    review_images {
      id
      image
      status
      __typename
    }
    likes_count
    is_liked
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateProductReviewMutationVariables, APITypes.UpdateProductReviewMutation>;
export const deleteProductReview = /* GraphQL */ `mutation DeleteProductReview($id: ID!) {
  deleteProductReview(id: $id) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteProductReviewMutationVariables, APITypes.DeleteProductReviewMutation>;
export const addAddress = /* GraphQL */ `mutation AddAddress($input: ClientAddressInput!) {
  addAddress(input: $input) {
    id
    name
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    country {
      id
      created_at
      updated_at
      status
      name
      country_flag
      is_default
      __typename
    }
    neighborhood {
      id
      created_at
      updated_at
      status
      name
      city_id
      __typename
    }
    description
    first_name
    last_name
    phone_number
    email
    is_default
    __typename
  }
}
` as GeneratedMutation<APITypes.AddAddressMutationVariables, APITypes.AddAddressMutation>;
export const updateAddress = /* GraphQL */ `mutation UpdateAddress($input: ClientAddressInput!) {
  updateAddress(input: $input) {
    id
    name
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    country {
      id
      created_at
      updated_at
      status
      name
      country_flag
      is_default
      __typename
    }
    neighborhood {
      id
      created_at
      updated_at
      status
      name
      city_id
      __typename
    }
    description
    first_name
    last_name
    phone_number
    email
    is_default
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateAddressMutationVariables, APITypes.UpdateAddressMutation>;
export const deleteAddress = /* GraphQL */ `mutation DeleteAddress($id: ID!) {
  deleteAddress(id: $id) {
    count
    next
    previous
    results {
      id
      name
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      neighborhood {
        id
        created_at
        updated_at
        status
        name
        city_id
        __typename
      }
      description
      first_name
      last_name
      phone_number
      email
      is_default
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteAddressMutationVariables, APITypes.DeleteAddressMutation>;
export const addProductToBasket = /* GraphQL */ `mutation AddProductToBasket($input: AddProductToBasketInput!) {
  addProductToBasket(input: $input) {
    id
    product {
      id
      title
      description
      slug
      upc
      photo_url
      labels {
        id
        title
        description
        color
        is_extra
        __typename
      }
      manufactory {
        id
        name
        website_url
        __typename
      }
      images {
        id
        original
        is_default
        display_order
        __typename
      }
      attributes {
        name
        value
        __typename
      }
      breadcrumbs {
        title
        slug
        __typename
      }
      offer {
        type
        value
        __typename
      }
      section {
        id
        title
        description
        slug
        photo_url
        products_count
        filters
        breadcrumbs {
          title
          slug
          __typename
        }
        sub_departments {
          id
          title
          description
          slug
          photo_url
          products_count
          filters
          children_count
          __typename
        }
        children_count
        __typename
      }
      categories {
        title
        slug
        __typename
      }
      color
      dimensions
      __typename
    }
    quantity
    stockrecord_id
    basket_id
    price_currency
    price_excl_tax
    price_incl_tax
    price_incl_tax_excl_discounts
    price_excl_tax_excl_discounts
    original_price_incl_tax
    original_price_excl_tax
    stockrecord {
      id
      currency
      price
      original_price
      discount_value
      available_number_in_stock
      product {
        id
        title
        description
        slug
        upc
        photo_url
        labels {
          id
          title
          description
          color
          is_extra
          __typename
        }
        manufactory {
          id
          name
          website_url
          __typename
        }
        images {
          id
          original
          is_default
          display_order
          __typename
        }
        attributes {
          name
          value
          __typename
        }
        breadcrumbs {
          title
          slug
          __typename
        }
        offer {
          type
          value
          __typename
        }
        section {
          id
          title
          description
          slug
          photo_url
          products_count
          filters
          children_count
          __typename
        }
        categories {
          title
          slug
          __typename
        }
        color
        dimensions
        __typename
      }
      partner {
        id
        name
        code
        logo
        address
        email
        reviews_total
        reviews_count
        is_reviewable
        mobile
        blog
        policy
        __typename
      }
      reviews_total
      reviews_count
      partner_sku
      is_reviewable
      is_purchased
      vendor_variants {
        id
        currency
        price
        original_price
        discount_value
        available_number_in_stock
        product {
          id
          title
          description
          slug
          upc
          photo_url
          color
          dimensions
          __typename
        }
        partner {
          id
          name
          code
          logo
          address
          email
          reviews_total
          reviews_count
          is_reviewable
          mobile
          blog
          policy
          __typename
        }
        reviews_total
        reviews_count
        partner_sku
        is_reviewable
        is_purchased
        vendor_variants {
          id
          currency
          price
          original_price
          discount_value
          available_number_in_stock
          reviews_total
          reviews_count
          partner_sku
          is_reviewable
          is_purchased
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.AddProductToBasketMutationVariables, APITypes.AddProductToBasketMutation>;
export const deleteProductLineFromBasket =
  /* GraphQL */ `mutation DeleteProductLineFromBasket($input: BasketLineInput) {
  deleteProductLineFromBasket(input: $input)
}
` as GeneratedMutation<
    APITypes.DeleteProductLineFromBasketMutationVariables,
    APITypes.DeleteProductLineFromBasketMutation
  >;
export const editBasketProductLineQuantity =
  /* GraphQL */ `mutation EditBasketProductLineQuantity($input: BasketLineInput) {
  editBasketProductLineQuantity(input: $input) {
    id
    product {
      id
      title
      description
      slug
      upc
      photo_url
      labels {
        id
        title
        description
        color
        is_extra
        __typename
      }
      manufactory {
        id
        name
        website_url
        __typename
      }
      images {
        id
        original
        is_default
        display_order
        __typename
      }
      attributes {
        name
        value
        __typename
      }
      breadcrumbs {
        title
        slug
        __typename
      }
      offer {
        type
        value
        __typename
      }
      section {
        id
        title
        description
        slug
        photo_url
        products_count
        filters
        breadcrumbs {
          title
          slug
          __typename
        }
        sub_departments {
          id
          title
          description
          slug
          photo_url
          products_count
          filters
          children_count
          __typename
        }
        children_count
        __typename
      }
      categories {
        title
        slug
        __typename
      }
      color
      dimensions
      __typename
    }
    quantity
    stockrecord_id
    basket_id
    price_currency
    price_excl_tax
    price_incl_tax
    price_incl_tax_excl_discounts
    price_excl_tax_excl_discounts
    original_price_incl_tax
    original_price_excl_tax
    stockrecord {
      id
      currency
      price
      original_price
      discount_value
      available_number_in_stock
      product {
        id
        title
        description
        slug
        upc
        photo_url
        labels {
          id
          title
          description
          color
          is_extra
          __typename
        }
        manufactory {
          id
          name
          website_url
          __typename
        }
        images {
          id
          original
          is_default
          display_order
          __typename
        }
        attributes {
          name
          value
          __typename
        }
        breadcrumbs {
          title
          slug
          __typename
        }
        offer {
          type
          value
          __typename
        }
        section {
          id
          title
          description
          slug
          photo_url
          products_count
          filters
          children_count
          __typename
        }
        categories {
          title
          slug
          __typename
        }
        color
        dimensions
        __typename
      }
      partner {
        id
        name
        code
        logo
        address
        email
        reviews_total
        reviews_count
        is_reviewable
        mobile
        blog
        policy
        __typename
      }
      reviews_total
      reviews_count
      partner_sku
      is_reviewable
      is_purchased
      vendor_variants {
        id
        currency
        price
        original_price
        discount_value
        available_number_in_stock
        product {
          id
          title
          description
          slug
          upc
          photo_url
          color
          dimensions
          __typename
        }
        partner {
          id
          name
          code
          logo
          address
          email
          reviews_total
          reviews_count
          is_reviewable
          mobile
          blog
          policy
          __typename
        }
        reviews_total
        reviews_count
        partner_sku
        is_reviewable
        is_purchased
        vendor_variants {
          id
          currency
          price
          original_price
          discount_value
          available_number_in_stock
          reviews_total
          reviews_count
          partner_sku
          is_reviewable
          is_purchased
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.EditBasketProductLineQuantityMutationVariables,
    APITypes.EditBasketProductLineQuantityMutation
  >;
export const checkout = /* GraphQL */ `mutation Checkout($input: CheckoutInput) {
  checkout(input: $input) {
    id
    number
    currency
    total_incl_tax
    total_excl_tax
    total_discount_incl_tax
    total_discount_excl_tax
    shipping_incl_tax
    shipping_excl_tax
    status
    date_placed
    shipping_address {
      id
      name
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      neighborhood {
        id
        created_at
        updated_at
        status
        name
        city_id
        __typename
      }
      description
      first_name
      last_name
      phone_number
      email
      is_default
      __typename
    }
    num_items
    products_price
    total_tax
    discount_price
    discount_amount
    payment_method {
      id
      name
      logo
      brand
      __typename
    }
    payment_card {
      id
      card_type
      number
      name
      expiry_date
      partner_reference
      payment_method {
        id
        name
        logo
        brand
        __typename
      }
      __typename
    }
    lines {
      count
      next
      previous
      results {
        id
        product {
          id
          title
          description
          slug
          upc
          photo_url
          color
          dimensions
          __typename
        }
        quantity
        stockrecord {
          id
          currency
          price
          original_price
          discount_value
          available_number_in_stock
          reviews_total
          reviews_count
          partner_sku
          is_reviewable
          is_purchased
          __typename
        }
        price_currency
        price_excl_tax
        price_incl_tax
        price_incl_tax_excl_discounts
        price_excl_tax_excl_discounts
        original_price_incl_tax
        original_price_excl_tax
        status
        product_review {
          id
          stockrecord
          body
          general_score
          client_bought_product
          created_at
          status
          likes_count
          is_liked
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.CheckoutMutationVariables, APITypes.CheckoutMutation>;
export const cancelOrder = /* GraphQL */ `mutation CancelOrder($input: CancelOrderInput) {
  cancelOrder(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.CancelOrderMutationVariables, APITypes.CancelOrderMutation>;
export const addBankCard = /* GraphQL */ `mutation AddBankCard($input: BankCardInput!) {
  addBankCard(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.AddBankCardMutationVariables, APITypes.AddBankCardMutation>;
export const updateBankCard = /* GraphQL */ `mutation UpdateBankCard($input: BankCardInput!) {
  updateBankCard(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateBankCardMutationVariables, APITypes.UpdateBankCardMutation>;
export const deleteBankCard = /* GraphQL */ `mutation DeleteBankCard($id: ID!) {
  deleteBankCard(id: $id) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteBankCardMutationVariables, APITypes.DeleteBankCardMutation>;
export const addPartner = /* GraphQL */ `mutation AddPartner($input: PartnerInput!) {
  addPartner(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.AddPartnerMutationVariables, APITypes.AddPartnerMutation>;
export const generatePaymentCheckoutIdForOrderInfo = /* GraphQL */ `mutation GeneratePaymentCheckoutIdForOrderInfo(
  $input: PaymentCheckoutIdForOrderInfoInput
) {
  generatePaymentCheckoutIdForOrderInfo(input: $input) {
    result {
      code
      description
      __typename
    }
    order_number
    checkout_id
    id
    updated_basket {
      id
      status
      total_excl_tax
      total_excl_tax_excl_discounts
      total_incl_tax
      total_incl_tax_excl_discounts
      total_original_excl_tax
      total_original_incl_tax
      total_quantity
      total_tax
      currency
      discount_price
      discount_amount
      lines {
        id
        product {
          id
          title
          description
          slug
          upc
          photo_url
          color
          dimensions
          __typename
        }
        quantity
        stockrecord_id
        basket_id
        price_currency
        price_excl_tax
        price_incl_tax
        price_incl_tax_excl_discounts
        price_excl_tax_excl_discounts
        original_price_incl_tax
        original_price_excl_tax
        stockrecord {
          id
          currency
          price
          original_price
          discount_value
          available_number_in_stock
          reviews_total
          reviews_count
          partner_sku
          is_reviewable
          is_purchased
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GeneratePaymentCheckoutIdForOrderInfoMutationVariables,
  APITypes.GeneratePaymentCheckoutIdForOrderInfoMutation
>;
export const refundOrderLine = /* GraphQL */ `mutation RefundOrderLine($input: InputRefundOrderLine) {
  refundOrderLine(input: $input) {
    id
    product {
      id
      title
      description
      slug
      upc
      photo_url
      labels {
        id
        title
        description
        color
        is_extra
        __typename
      }
      manufactory {
        id
        name
        website_url
        __typename
      }
      images {
        id
        original
        is_default
        display_order
        __typename
      }
      attributes {
        name
        value
        __typename
      }
      breadcrumbs {
        title
        slug
        __typename
      }
      offer {
        type
        value
        __typename
      }
      section {
        id
        title
        description
        slug
        photo_url
        products_count
        filters
        breadcrumbs {
          title
          slug
          __typename
        }
        sub_departments {
          id
          title
          description
          slug
          photo_url
          products_count
          filters
          children_count
          __typename
        }
        children_count
        __typename
      }
      categories {
        title
        slug
        __typename
      }
      color
      dimensions
      __typename
    }
    quantity
    stockrecord {
      id
      currency
      price
      original_price
      discount_value
      available_number_in_stock
      product {
        id
        title
        description
        slug
        upc
        photo_url
        labels {
          id
          title
          description
          color
          is_extra
          __typename
        }
        manufactory {
          id
          name
          website_url
          __typename
        }
        images {
          id
          original
          is_default
          display_order
          __typename
        }
        attributes {
          name
          value
          __typename
        }
        breadcrumbs {
          title
          slug
          __typename
        }
        offer {
          type
          value
          __typename
        }
        section {
          id
          title
          description
          slug
          photo_url
          products_count
          filters
          children_count
          __typename
        }
        categories {
          title
          slug
          __typename
        }
        color
        dimensions
        __typename
      }
      partner {
        id
        name
        code
        logo
        address
        email
        reviews_total
        reviews_count
        is_reviewable
        mobile
        blog
        policy
        __typename
      }
      reviews_total
      reviews_count
      partner_sku
      is_reviewable
      is_purchased
      vendor_variants {
        id
        currency
        price
        original_price
        discount_value
        available_number_in_stock
        product {
          id
          title
          description
          slug
          upc
          photo_url
          color
          dimensions
          __typename
        }
        partner {
          id
          name
          code
          logo
          address
          email
          reviews_total
          reviews_count
          is_reviewable
          mobile
          blog
          policy
          __typename
        }
        reviews_total
        reviews_count
        partner_sku
        is_reviewable
        is_purchased
        vendor_variants {
          id
          currency
          price
          original_price
          discount_value
          available_number_in_stock
          reviews_total
          reviews_count
          partner_sku
          is_reviewable
          is_purchased
          __typename
        }
        __typename
      }
      __typename
    }
    price_currency
    price_excl_tax
    price_incl_tax
    price_incl_tax_excl_discounts
    price_excl_tax_excl_discounts
    original_price_incl_tax
    original_price_excl_tax
    status
    product_review {
      id
      stockrecord
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      body
      general_score
      client_bought_product
      created_at
      status
      detailed_review_scores {
        id
        title
        status
        score
        detailed_score {
          id
          title
          status
          __typename
        }
        __typename
      }
      review_images {
        id
        image
        status
        __typename
      }
      likes_count
      is_liked
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.RefundOrderLineMutationVariables, APITypes.RefundOrderLineMutation>;
export const markNotificationAsRead =
  /* GraphQL */ `mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput) {
  markNotificationAsRead(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.MarkNotificationAsReadMutationVariables, APITypes.MarkNotificationAsReadMutation>;
export const addGigService = /* GraphQL */ `mutation AddGigService($input: GigServiceInput) {
  addGigService(input: $input) {
    id
    title
    description
    services {
      id
      created_at
      updated_at
      title
      image
      short_description
      long_description
      __typename
    }
    cities {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    price
    photos
    is_enabled
    professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.AddGigServiceMutationVariables, APITypes.AddGigServiceMutation>;
export const editGigService = /* GraphQL */ `mutation EditGigService($input: GigServiceInput) {
  editGigService(input: $input) {
    id
    title
    description
    services {
      id
      created_at
      updated_at
      title
      image
      short_description
      long_description
      __typename
    }
    cities {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    price
    photos
    is_enabled
    professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.EditGigServiceMutationVariables, APITypes.EditGigServiceMutation>;
export const generatePaymentCheckoutIdForManzilikAi = /* GraphQL */ `mutation GeneratePaymentCheckoutIdForManzilikAi(
  $input: PaymentCheckoutIdForManzilikAiInput
) {
  generatePaymentCheckoutIdForManzilikAi(input: $input) {
    checkout_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GeneratePaymentCheckoutIdForManzilikAiMutationVariables,
  APITypes.GeneratePaymentCheckoutIdForManzilikAiMutation
>;
export const createSendBirdChatGroup =
  /* GraphQL */ `mutation CreateSendBirdChatGroup($input: SendBirdChatGroupInput!) {
  createSendBirdChatGroup(input: $input) {
    sendbird_access_token
    chat_url
    chat_name
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateSendBirdChatGroupMutationVariables, APITypes.CreateSendBirdChatGroupMutation>;
export const generateAIDesign = /* GraphQL */ `mutation GenerateAIDesign($input: GenerateAIDesignInput!) {
  generateAIDesign(input: $input) {
    id
    created_at
    updated_at
    sourceImageUrl
    selectedImageIndex
    processedImagesPath
    visibility
    processingType
    roomType {
      id
      name
      slug
      image
      __typename
    }
    style {
      id
      name
      slug
      image
      template_prompt
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.GenerateAIDesignMutationVariables, APITypes.GenerateAIDesignMutation>;
export const cancelAIDesignGeneration = /* GraphQL */ `mutation CancelAIDesignGeneration($id: ID!) {
  cancelAIDesignGeneration(id: $id) {
    message
    __typename
  }
}
` as GeneratedMutation<APITypes.CancelAIDesignGenerationMutationVariables, APITypes.CancelAIDesignGenerationMutation>;
export const selectAIDesignImage = /* GraphQL */ `mutation SelectAIDesignImage($id: ID!, $index: Int!) {
  selectAIDesignImage(id: $id, index: $index) {
    id
    created_at
    updated_at
    sourceImageUrl
    selectedImageIndex
    processedImagesPath
    visibility
    processingType
    roomType {
      id
      name
      slug
      image
      __typename
    }
    style {
      id
      name
      slug
      image
      template_prompt
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.SelectAIDesignImageMutationVariables, APITypes.SelectAIDesignImageMutation>;
export const rateAIDesign = /* GraphQL */ `mutation RateAIDesign($input: RateAIDesignInput!) {
  rateAIDesign(input: $input) {
    message
    __typename
  }
}
` as GeneratedMutation<APITypes.RateAIDesignMutationVariables, APITypes.RateAIDesignMutation>;
export const notifyUserOnRecommendedImages = /* GraphQL */ `mutation NotifyUserOnRecommendedImages(
  $outstandingId: ID!
  $recommendedImages: [String]
  $error: String
) {
  notifyUserOnRecommendedImages(
    outstandingId: $outstandingId
    recommendedImages: $recommendedImages
    error: $error
  ) {
    outstandingId
    recommendedImages
    error
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NotifyUserOnRecommendedImagesMutationVariables,
  APITypes.NotifyUserOnRecommendedImagesMutation
>;
export const submitServiceInquiry = /* GraphQL */ `mutation SubmitServiceInquiry($input: ServiceInquiryInput) {
  submitServiceInquiry(input: $input) {
    id
    number
    status
    created_at
    sender {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    categories {
      count
      next
      previous
      results {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      __typename
    }
    services {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      __typename
    }
    description
    budget_limits
    phone_number
    whatsapp_number
    photos {
      count
      next
      previous
      results {
        photo
        __typename
      }
      __typename
    }
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    gig_service_id
    gig_service_title
    gig_service_price
    gig_service_description
    sendbird_channel_name
    sendbird_channel_url
    reviews_total
    reviews_count
    quotations {
      count
      next
      previous
      results {
        id
        service_inquiry {
          id
          number
          status
          created_at
          description
          budget_limits
          phone_number
          whatsapp_number
          gig_service_id
          gig_service_title
          gig_service_price
          gig_service_description
          sendbird_channel_name
          sendbird_channel_url
          reviews_total
          reviews_count
          type
          __typename
        }
        professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        created_at
        status
        budget_limits
        execution_time
        sendbird_channel_name
        sendbird_channel_url
        __typename
      }
      __typename
    }
    type
    __typename
  }
}
` as GeneratedMutation<APITypes.SubmitServiceInquiryMutationVariables, APITypes.SubmitServiceInquiryMutation>;
export const respondServiceInquiry = /* GraphQL */ `mutation RespondServiceInquiry($input: RespondServiceInquiryInput) {
  respondServiceInquiry(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.RespondServiceInquiryMutationVariables, APITypes.RespondServiceInquiryMutation>;
export const completeServiceInquiry =
  /* GraphQL */ `mutation CompleteServiceInquiry($input: CompleteServiceInquiryInput) {
  completeServiceInquiry(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.CompleteServiceInquiryMutationVariables, APITypes.CompleteServiceInquiryMutation>;
export const cancelServiceInquiry = /* GraphQL */ `mutation CancelServiceInquiry($input: CancelServiceInquiryInput) {
  cancelServiceInquiry(input: $input) {
    message
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.CancelServiceInquiryMutationVariables, APITypes.CancelServiceInquiryMutation>;
export const sendRFQ = /* GraphQL */ `mutation SendRFQ($input: SendRFQInput) {
  sendRFQ(input: $input) {
    id
    number
    status
    created_at
    sender {
      id
      created_at
      updated_at
      status
      first_name
      last_name
      email
      mobile
      country_code
      gender
      type
      about_me
      my_fav_style
      my_next_style
      city_id
      country_id
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      country {
        id
        created_at
        updated_at
        status
        name
        country_flag
        is_default
        __typename
      }
      zip
      state
      following_count
      followers_count
      followees_count
      is_followed
      total_review
      discussions_count
      comments_count
      is_verified
      is_add_gigs_tutorial_showed
      last_login
      profile_image
      facebook
      twitter
      linkedin
      blog
      project_role
      client_awards {
        id
        created_at
        updated_at
        status
        title
        code
        image
        __typename
      }
      client_badges {
        id
        created_at
        updated_at
        status
        key
        title
        value
        image
        description
        client_progress
        is_claimed
        __typename
      }
      projects {
        count
        next
        previous
        results {
          id
          is_default
          title
          status
          created_at
          updated_at
          visibility
          client_id
          discussions_count
          ideas_count
          likes_count
          shares_count
          questions_count
          description
          is_liked
          __typename
        }
        __typename
      }
      addresses {
        count
        next
        previous
        results {
          id
          name
          description
          first_name
          last_name
          phone_number
          email
          is_default
          __typename
        }
        __typename
      }
      bankcards {
        count
        next
        previous
        results {
          id
          card_type
          number
          name
          expiry_date
          partner_reference
          __typename
        }
        __typename
      }
      related_professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      sendbird_access_token
      apns_token
      balance
      is_purchased
      referral_url
      __typename
    }
    professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    categories {
      count
      next
      previous
      results {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      __typename
    }
    services {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      __typename
    }
    description
    budget_limits
    phone_number
    whatsapp_number
    photos {
      count
      next
      previous
      results {
        photo
        __typename
      }
      __typename
    }
    city {
      id
      created_at
      updated_at
      status
      name
      region_id
      country_id
      __typename
    }
    gig_service_id
    gig_service_title
    gig_service_price
    gig_service_description
    sendbird_channel_name
    sendbird_channel_url
    reviews_total
    reviews_count
    quotations {
      count
      next
      previous
      results {
        id
        service_inquiry {
          id
          number
          status
          created_at
          description
          budget_limits
          phone_number
          whatsapp_number
          gig_service_id
          gig_service_title
          gig_service_price
          gig_service_description
          sendbird_channel_name
          sendbird_channel_url
          reviews_total
          reviews_count
          type
          __typename
        }
        professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        created_at
        status
        budget_limits
        execution_time
        sendbird_channel_name
        sendbird_channel_url
        __typename
      }
      __typename
    }
    type
    __typename
  }
}
` as GeneratedMutation<APITypes.SendRFQMutationVariables, APITypes.SendRFQMutation>;
export const sendQuotation = /* GraphQL */ `mutation SendQuotation($input: SendQuotationInput) {
  sendQuotation(input: $input) {
    id
    service_inquiry {
      id
      number
      status
      created_at
      sender {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      categories {
        count
        next
        previous
        results {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        __typename
      }
      services {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        __typename
      }
      description
      budget_limits
      phone_number
      whatsapp_number
      photos {
        count
        next
        previous
        results {
          photo
          __typename
        }
        __typename
      }
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      gig_service_id
      gig_service_title
      gig_service_price
      gig_service_description
      sendbird_channel_name
      sendbird_channel_url
      reviews_total
      reviews_count
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      type
      __typename
    }
    professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    created_at
    status
    budget_limits
    execution_time
    sendbird_channel_name
    sendbird_channel_url
    __typename
  }
}
` as GeneratedMutation<APITypes.SendQuotationMutationVariables, APITypes.SendQuotationMutation>;
export const acceptQuotation = /* GraphQL */ `mutation AcceptQuotation($input: AcceptQuotationInput) {
  acceptQuotation(input: $input) {
    id
    service_inquiry {
      id
      number
      status
      created_at
      sender {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      professional {
        id
        created_at
        updated_at
        client {
          id
          created_at
          updated_at
          status
          first_name
          last_name
          email
          mobile
          country_code
          gender
          type
          about_me
          my_fav_style
          my_next_style
          city_id
          country_id
          zip
          state
          following_count
          followers_count
          followees_count
          is_followed
          total_review
          discussions_count
          comments_count
          is_verified
          is_add_gigs_tutorial_showed
          last_login
          profile_image
          facebook
          twitter
          linkedin
          blog
          project_role
          sendbird_access_token
          apns_token
          balance
          is_purchased
          referral_url
          __typename
        }
        address
        categories {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        services {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        locations {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        is_profile_completed
        reviews_total
        reviews_count
        gallery_photos
        photos {
          count
          next
          previous
          __typename
        }
        is_reviewable
        projects_count
        videos {
          count
          next
          previous
          __typename
        }
        contacts {
          count
          next
          previous
          __typename
        }
        professional_type
        company_name
        company_logo
        company_registration_ref
        personal_freelance_license
        is_verified
        is_gig_professional
        gigs_count
        score
        quotations {
          count
          next
          previous
          __typename
        }
        __typename
      }
      categories {
        count
        next
        previous
        results {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        __typename
      }
      services {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          title
          image
          short_description
          long_description
          __typename
        }
        __typename
      }
      description
      budget_limits
      phone_number
      whatsapp_number
      photos {
        count
        next
        previous
        results {
          photo
          __typename
        }
        __typename
      }
      city {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      gig_service_id
      gig_service_title
      gig_service_price
      gig_service_description
      sendbird_channel_name
      sendbird_channel_url
      reviews_total
      reviews_count
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      type
      __typename
    }
    professional {
      id
      created_at
      updated_at
      client {
        id
        created_at
        updated_at
        status
        first_name
        last_name
        email
        mobile
        country_code
        gender
        type
        about_me
        my_fav_style
        my_next_style
        city_id
        country_id
        city {
          id
          created_at
          updated_at
          status
          name
          region_id
          country_id
          __typename
        }
        country {
          id
          created_at
          updated_at
          status
          name
          country_flag
          is_default
          __typename
        }
        zip
        state
        following_count
        followers_count
        followees_count
        is_followed
        total_review
        discussions_count
        comments_count
        is_verified
        is_add_gigs_tutorial_showed
        last_login
        profile_image
        facebook
        twitter
        linkedin
        blog
        project_role
        client_awards {
          id
          created_at
          updated_at
          status
          title
          code
          image
          __typename
        }
        client_badges {
          id
          created_at
          updated_at
          status
          key
          title
          value
          image
          description
          client_progress
          is_claimed
          __typename
        }
        projects {
          count
          next
          previous
          __typename
        }
        addresses {
          count
          next
          previous
          __typename
        }
        bankcards {
          count
          next
          previous
          __typename
        }
        related_professional {
          id
          created_at
          updated_at
          address
          is_profile_completed
          reviews_total
          reviews_count
          gallery_photos
          is_reviewable
          projects_count
          professional_type
          company_name
          company_logo
          company_registration_ref
          personal_freelance_license
          is_verified
          is_gig_professional
          gigs_count
          score
          __typename
        }
        sendbird_access_token
        apns_token
        balance
        is_purchased
        referral_url
        __typename
      }
      address
      categories {
        id
        title
        english_title
        description
        parent {
          id
          title
          english_title
          description
          photo
          status
          created_at
          updated_at
          filters
          __typename
        }
        photo
        status
        created_at
        updated_at
        filters
        __typename
      }
      services {
        id
        created_at
        updated_at
        title
        image
        short_description
        long_description
        __typename
      }
      locations {
        id
        created_at
        updated_at
        status
        name
        region_id
        country_id
        __typename
      }
      is_profile_completed
      reviews_total
      reviews_count
      gallery_photos
      photos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          photo
          __typename
        }
        __typename
      }
      is_reviewable
      projects_count
      videos {
        count
        next
        previous
        results {
          id
          created_at
          updated_at
          video
          __typename
        }
        __typename
      }
      contacts {
        count
        next
        previous
        results {
          contact_type
          value
          __typename
        }
        __typename
      }
      professional_type
      company_name
      company_logo
      company_registration_ref
      personal_freelance_license
      is_verified
      is_gig_professional
      gigs_count
      score
      quotations {
        count
        next
        previous
        results {
          id
          created_at
          status
          budget_limits
          execution_time
          sendbird_channel_name
          sendbird_channel_url
          __typename
        }
        __typename
      }
      __typename
    }
    created_at
    status
    budget_limits
    execution_time
    sendbird_channel_name
    sendbird_channel_url
    __typename
  }
}
` as GeneratedMutation<APITypes.AcceptQuotationMutationVariables, APITypes.AcceptQuotationMutation>;
