import { BrandType } from './../pages/stores/types';
import {
  AGREE_TO_TERMS,
  CONFIRM,
  CONFIRM_CODE_TITLE,
  CONTINUE_WITH_FACEBOOK,
  CONTINUE_WITH_GOOGLE,
  EMAIL,
  EMAIL_REQUIRED_MESSAGE,
  FIND_IDEAS,
  HOME_PAGE_HEADING,
  JOKER,
  LOGIN,
  OR,
  PASSWORD,
  PASSWORD_REQUIRED_MESSAGE,
  REGEX_DOESNT_MATCH_MSG,
  RESEND_CONFIRMATION,
  SIGNUP,
  SIGNUP_WITH,
  SIGNUP_WITH_EMAIL,
  LOGIN_BY_EMAIL,
  USERNAME,
  FORGET_PASSWORD,
  KEEP_SIGNED_IN,
  SIGNIN,
  SIGN_IN_POLICY,
  COMMON_QUESTIONS,
  COMMON_QUESTION_1,
  COMMON_QUESTION_2,
  COMMON_QUESTION_3,
  COMMON_QUESTION_4,
  COMMON_QUESTION_5,
  COMMON_QUESTION_6,
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_GOOGLE,
  LOGIN_BY_SOCIAL_MEDIA,
  MANZIL_LOGIN,
  FORGET_YOUR_PASSWORD,
  FORGET_PASSWORD_DESCRIPTION,
  HELLO,
  CONTINUE,
  RE_ENTER_PASSWORD,
  SUBMIT,
  NEW_PASSWORD_TERMS,
  PASSWORD_DONT_MATCH,
  CREATE_NEW_PASSWORD,
  CODE_MESSAGE,
  CODE_TEXT,
  CREATE_PROJECT,
  PROJECT_NAME,
  PRIVATE,
  ENTER_PROJECT_NAME,
  CHOOSE_SPACE,
  OPTIONAL,
  CREATE,
  GENERIC,
  CANCEL,
  LOADING,
  REQUIRED,
  RESET_PASSWORD_SUCCESS_MSG,
  DELETE,
  DISCUSSIONS,
  PHOTOS,
  INVITE_FRIEND,
  FILTER_BY,
  MY_IDEAS,
  MORE_IDEAS_FROM_MANZEL,
  ADD_NOTE,
  COPY,
  MOVE,
  REMOVE,
  ENTER_EMAIL_RECIPIENTS,
  MESSAGE_SUBJECT,
  MESSAGE,
  MESSAGE_INPUT_PLACE_HOLDER,
  MESSAGE_IS_SENT,
  LINK_COPIED,
  UPLOAD_TERMS,
  UPLOAD_PHOTO,
  SAVE_FROM_WEB,
  UPLOAD,
  ADD_PHOTO,
  EDIT_PROJECT_INFO,
  TITLE,
  DESCRIPTION,
  EDIT,
  WRITE_DESCRIPTION,
  ENGLISH,
  GET_IDEAS,
  NOTIFICATIONS,
  PROJECTS,
  FOOTER_CREDITS,
  COMPANY,
  ABOUT_MANZEL,
  PRIVACY_AND_POLICY,
  TERMS_AND_CONDITIONS,
  COOKIE_POLICY,
  GET_HELP,
  GET_INSPIRED,
  MANZEL_SUPPORT,
  CONTACT,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  YOUTUBE,
  RSS,
  CONNECT_WITH_US,
  DROP_FILE_HERE,
  YES,
  NO,
  ARE_YOU_SURE_DELETE_MSG,
  UPDATE,
  VIEW_PROFILE,
  VIEW_ALL,
  LOGOUT,
  SEND_BY_EMAIL,
  TO,
  IMPORT_GMAIL_CONTACT,
  ACTIVITY,
  BACK_TO_LOGIN,
  SEND,
  MOVE_IDEA,
  ARE_YOU_SURE_DELETE_IDEA,
  INVITE,
  SAVE_SETTINGS,
  CAN_EDIT,
  CAN_VIEW,
  WHO_HAS_ACCESS,
  CURRENTLY_SHARE_WITH,
  INVITE_BY_EMAIL_OR_NAME,
  INVITE_BY_EMAIL_OR_NAME_PLACEHOLDER,
  DELETE_FRIEND,
  YOU,
  ORGANIZE,
  SELECT_ALL,
  CHANGE_TO_GALLERY_VIEW,
  ADD_NEW_IDEA,
  EXPORT,
  EDIT_PROFILE,
  ACCOUNT,
  ACCOUNT_INFORMATION,
  PROFILE_INFO,
  CONTACT_INFO,
  SOCIAL_MEDIA_SETTINGS,
  ADVANCE_SETTINGS,
  PRIVACY_SETTINGS,
  PROFILE_INFORMATION,
  FIRST_NAME,
  LAST_NAME,
  PUBLICLY_DISPLAYED,
  ABOUT_ME,
  MY_FAVORITE_STYLE,
  MY_NEXT_MANZILIK_PROJECT,
  USER_NAME,
  BY,
  PROFILE_DETAILS_UPDATED_SUCCESSFULLY,
  PROCEED_TO,
  YOUR_MANZILIK_PROJECTS,
  PREVIEW_YOUR,
  PUBLIC_PROFILE,
  YOU_CAN_ONLY_UPLOAD_JPG,
  OLD_PASSWORD,
  NEW_PASSWORD,
  CONTACT_INFORMATION,
  COUNTRY,
  CITY,
  STATE,
  ZIP,
  SAVED_TO,
  SAVE,
  OTHER_PHOTO_IN,
  SEE_MORE_PHOTOS,
  SAVE_IDEA,
  SELECT_PROJECT,
  WHAT_DO_YOU_LIKE_ABOUT_THIS_IDEA,
  FOLLOW,
  WRITE_COMMENT,
  LINKEDIN,
  WEBSITE_OR_BLOG,
  CLICK_OR_DRAG_DROP_FILES_HERE_TO_UPLOAD,
  DRAG_THE_CORNER_OF_THE_BOX_TO_CHANGE_POSITION_AND_SIZE_OR_UPLOAD_A_NEW_IMAGE,
  YOU_CAN_UPLOAD_A_JPG_GIF_OR_PNG_FILE,
  YOUR_PROFILE_PHOTO,
  ADDRESS_BOOK,
  DELETE_MY_ADDRESS_BOOK_CONTACT,
  DISCLOSE_MY_INFO_FOR_COMMERCIAL_PURPOSES,
  DOWNLOAD_A_COPY_OF_YOUR_DATA,
  MANAGE_YOUR_EMAIL_SETTINGS,
  MARKETING_AND_EMAIL_COMMUNICATIONS,
  ADVANCED_SETTINGS,
  ALLOW_COMMENTS_ON_MY_PUBLIC_PHOTOS,
  ALLOW_USERS_TO_FOLLOW_MY_PROFILE,
  CLIMATE_ZONE_FOR_GARDEN_FORUMS,
  CUSTOMIZE_MY_EMAIL_SUBSCRIPTIONS_BELOW,
  DEACTIVATE_ACCOUNT,
  DEFAULT_PHOTO_ORDER_IN_MY_IDEABOOKS,
  EMAIL_PREFERENCES,
  EMAIL_SUBSCRIPTIONS,
  ENABLE_ADD_TO_IDEABOOK_CONFIRMATION,
  EVERY_TIME,
  MANZILIK_MAGAZINE_HOME_TOURS,
  MANZILIK_REFERRAL_COUPONS,
  MANZILIK_SHOP_TRENDING_PRODUCTS_AND_SALES,
  MANZILIK_TV_SURVEYS_AND_OTHER_UPDATES,
  MANZILIK_UPDATES_NEAR_ME,
  MY_DISCUSSIONS,
  MY_GARDEN_WEB_TRADING_LIST,
  MY_PROFILE_PAGES,
  MY_PUBLIC_IDEABOOKS,
  MY_SOCIAL_MEDIA_PROFILES,
  MY_UPLOADED_PHOTOS_AND_PROJECTS,
  NEWSLETTER_EDITION,
  NOTE_IF_THIS_IS_UNCHECKED,
  NOTIFICATION_CENTER,
  PEOPLE_I_FOLLOW,
  PEOPLE_WHO_FOLLOW_ME,
  PERSONALIZED_RECOMMENDATIONS,
  PRODUCT_ALERTS_PRICE_DROPS,
  PUBLIC_PROFILE_PAGE,
  SEND_ME_AN_EMAIL_IF,
  SHOW_ACTIVITIES_FROM_USERS_I_AM_FOLLOWING,
  SOMEONE_COMMENTS_ON_MY_IDEABOOK,
  SOMEONE_COMMENTS_ON_MY_POST,
  SOMEONE_FOLLOWS_ME,
  SOMEONE_MESSAGES_ME,
  THERE_IS_AN_ISSUE_WITH_MY_ACCOUNT_OR_LOGIN,
  THE_FOLLOWING_WILL_BE_VISIBLE_ON_MY_PUBLIC_PROFILE,
  TIPS_GUIDES_AND_NEW_FEATURE_ANNOUNCEMENTS,
  USER_ACTIVITY_NOTIFICATIONS,
  WHO_CAN_MESSAGE_ME,
  IT_MAY_TAKE_UP_TO_3_DAYS_FOR_US_TO_FULLY_PROCESS,
  SEND_ME_SMS_FROM_MANZILIK_WITH_TIPS,
  SMS_OFFERS,
  UNDER_CALIFORNIA_LAW_LEARN_MORE_HERE,
  YOUR_PRIVACY_SETTINGS,
  QUESTION_ABOUT_THIS_PHOTO,
  SHOW,
  MORE_QUESTIONS,
  MORE_COMMENTS,
  ASK_QUESTION,
  I_HAVE_ANOTHER_QUESTION,
  ASK_QUESTION_ABOUT_THIS_PHOTO,
  TELL_US_THE_DETAILS,
  YOUR_QUESTION,
  OK,
  PEOPLE_WHO_LIKE_THIS,
  PLEASE_WRITE_VALID_EMAILS,
  ARE_YOU_SURE_DELETE_COMMENT_MSG,
  SEARCH,
  IN_ALL_PHOTOS,
  ALL_FILTERS,
  STYLE,
  SIZE,
  COLOR,
  PREVIOUS_PAGE,
  LIVING_ROOM,
  KITCHEN_IN_PHOTOS,
  BATHROOM_IN_PHOTOS,
  SEND_EMAIL,
  REFINE_BY,
  SORT_BY,
  OF,
  LOCATION,
  RELEVANCE,
  PUBLIC,
  DONE,
  CLEAR_ALL,
  SELECTED,
  BUDGET,
  TYPE,
  LIMIT_1_PER_DAY,
  LIMIT_1_PER_HOUR_POST,
  LIMIT_2_PER_DAY,
  LIMIT_3_PER_HOUR_POST,
  LIMIT_5_PER_DAY,
  NEVER,
  DISPLAY_NEWS_FIRST,
  DISPLAY_OLDEST_FIRST,
  ALLOW_ALL_USERS_TO_MESSAGE_ME,
  ALLOW_USERS_I_FOLLOW_TO_MESSAGE_ME,
  DONT_ALLOW_USERS_TO_MESSAGE_ME,
  EXPLORE_ALL_DISCUSSIONS,
  EXPLORE_DISCUSSIONS,
  FEATURED,
  FEATURED_GARDEN_DISCUSSIONS,
  FEATURED_HOME_DISCUSSIONS,
  GET_HELP_FOR_YOUR_PROJECTS,
  LATEST,
  MONTH,
  NEXT,
  POPULAR,
  POSTED_BY,
  PREV,
  START_A_DISCUSSION,
  TODAY,
  TOP_COMMENTERS,
  UNANSWERED,
  WEEK,
  WHAT_ARE_YOU_WORKING_ON,
  YOUR_COMMENTS,
  YOUR_POSTS,
  YOUR_TOPICS,
  UPDATED_AT,
  HI,
  POST,
  ADD_PHOTO_FOR_BETTER_ANSWERS,
  ENTER_TEXT_OR_PASTE_MANZILIK_LINK,
  OPTION,
  POLL_OPTIONS,
  YOU_CAN_ENTER_TEXT_LINK_PRODUCT_OR_UPLOAD_AN_IMAGE,
  ADD_OPTION,
  ADD_POLL,
  CHOOSE_TOPIC,
  ADD_POST,
  ADD_LINK,
  LINK,
  TEXT,
  ADD_VIDEO,
  OLDEST,
  RELATED_DISCUSSIONS,
  SORTED_BY,
  FEATURED_ANSWER,
  MORE_DISCUSSIONS,
  VOTE,
  COMMENTS,
  TITLE_AND_DESC_NEEDS_TO_BE_15,
  POLL_MUST_HAVE_TWO,
  REMOVE_POLL,
  WELCOME_TO_MANZILIK_ADVICE,
  SEE_DISCUSSION_FROM_DESIGNERS_AND_EXPERT,
  GET_STARTED,
  FIND_HOME_AND_GARDEN_TOPICS,
  FOLLOW_AT_LEAST_ONE_TOPIC,
  WHAT_ARE_YOUR_INTERESTS,
  FULL_STORY,
  STORIES_FROM_MANZILIK,
  LATEST_FROM_MANZILIK,
  WATCH_NOW,
  MANZILIK_TOURS,
  MANZILIK_TV,
  MOST_POPULAR,
  NEW_THIS_WEEK,
  NEXT_PAGE,
  PREV_PAGE,
  NEW_TO_MANZILIK,
  READ_RELATED_STORIES,
  READ_THE_FULL_STORY,
  SEE_MORE_COMMENTS,
  PEOPLE_WHO_LIKED_THIS_STORY,
  MORE_ON_MANZILIK,
  EXPLORE_RELATED_TOPICS,
  PRINT,
  EMBED,
  READ_MORE,
  SHOW_LESS,
  LIKE,
  SAVE_DISCUSSION,
  SAVED_SUCCESSFULLY,
  SAUDI_ARABIA,
  DISCUSSION,
  PHOTO,
  NO_COMMENTS,
  COMMENT_IS_EMPTY,
  REPORT_SPAM,
  IDEAS,
  MAGAZINE,
  TV,
  IDEA,
  NEW_VIDEO_ON_MANZILIK,
  FIND_PROFESSIONAL,
  FIND_PRODUCTS,
  SEND_MESSAGE,
  PROFESSIONAL_ROOM,
  REVIEW,
  PAGE_404,
  BACK_HOME,
  THERE_ARE_NO_DATA,
  EMBED_A_WIDGET,
  CATEGORIES,
  COMPLETE_PROFILE,
  HOMEOWNER,
  MAX_SIZE_FILE,
  PHONE_NUMBER,
  PROFESSIONAL,
  SELECT_CATEGORY,
  SELECT_LOCATION,
  SELECT_SERVICE,
  SERVICES,
  UNDER_REVIEW,
  HOW_DO_YOU_RATE_THIS_PROFESSIONAL,
  DO_YOU_WANT_TO_SHARE_MORE,
  WRITE_REVIEW,
  REVIEWS,
  CONTACT_THIS_PRO,
  NAME,
  MOBILE_NUMBER,
  PROJECT_ZIP_CODE,
  SAVE_YOUR_MESSAGE,
  TELL_YOUR_PROFESSIONAL,
  FOR,
  ACTIVATE_MY_ACCOUNT,
  ADDRESS,
  PROF_PROFILE_REVIEW_NOTE,
  UPLOAD_PHOTOS,
  PLEASE_FILL_REQUIRED_FIELDS,
  LOAD_NEXT_PHOTOS,
  EDIT_REVIEW,
  PLEASE_ENTER_VALID_PHONE_NUMBER,
  PROFESSIONALS,
  NO_REVIEWS,
  BE_FIRST_TO_COMMENT,
  SPAM_DONE,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO,
  POST_0,
  POST_1,
  POST_2,
  POST_3,
  PHOTO_0,
  PHOTO_1,
  PHOTO_2,
  PHOTO_3,
  DISCUSSION_2,
  DISCUSSION_0,
  DISCUSSION_1,
  DISCUSSION_3,
  IDEA_0,
  IDEA_1,
  IDEA_2,
  IDEA_3,
  POSTS,
  POST_4,
  DISCUSSION_4,
  PHOTO_4,
  IDEA_4,
  REVIEW_0,
  REVIEW_1,
  REVIEW_2,
  REVIEW_3,
  REVIEW_4,
  IDEA_TAG,
  COMMENT,
  COMMENT_0,
  COMMENT_4,
  COMMENT_1,
  COMMENT_2,
  COMMENT_3,
  LIKES,
  LIKE_0,
  LIKE_3,
  LIKE_4,
  LIKE_2,
  LIKE_1,
  CONFIRM_EMAIL,
  USER_DOESNT_EXIST,
  INCORRECT_USERNAME_OR_PASSWORD,
  USER_EXIST,
  INVALID_CODE,
  USER_NOT_FOUND,
  MORE_REVIEWS,
  SAVE_MAGAZINE,
  SAVE_TV,
  MORE_REVIEWS_PLURAL,
  MORE_REVIEWS_0,
  MORE_REVIEWS_1,
  MORE_REVIEWS_2,
  MORE_REVIEWS_3,
  MORE_REVIEWS_4,
  SOMETHING_WENT_WRONG,
  MAGAZINES,
  TVS,
  IN,
  DISCUSSIONS_SEARCH,
  SHOPPING,
  ALL_PRODUCTS,
  PRODUCT,
  PRODUCTS,
  EXPLORE_PRODUCTS,
  BUY_ALL,
  FURNITURE,
  HIGHTEST_PRICE,
  LOWEST_PRICE,
  HIGHEST_RATE,
  LOWEST_RATE,
  EXTRA_COLORS,
  EXTRA_SIZES,
  EXPLORE_MORE_FAV_PRODUCTS,
  SHOW_ALL_RESULTS,
  USER_NOT_CONFIRMED,
  ADD_TO_CART,
  ITEMS_LEFT,
  READY_TO_SHIP_IN,
  DAYS,
  PRODUCT_DESCRIPTION,
  PRODUCT_SPECIFICATION,
  PRODUCT_SHIPPING,
  QUANTITY,
  ADD_QUANTITY,
  ENTER_NEW_QUANTITY,
  BE_FIRST_REVIEWER,
  NO_BODY_REVIEWED_THIS_PRODUCT,
  CUSTOMER_REVIEWS,
  REVIEW_PRODUCT,
  REVIEWED_PRODUCT_SUCCESSFULLY,
  WHAT_IS_YOUR_REVIEW_FOR_PRODUCT,
  TRANSFER_YOUR_EXPERIENCE_OF_USING_THE_PRODUCT,
  REASONABLE_PRICE,
  PRODUCT_MATCHES_DESCRIPTION,
  PRODUCT_QUALITY,
  SHIPPING_SPEED,
  ADD_PRODUCT_IMAGES,
  ADD_PRODUCT_COMMENT,
  ADD_YOUR_COMMENT_HERE,
  PRODUCT_NUMBER,
  MANUFACTORY,
  SOLD_BY,
  SIZE_OR_WEIGHT,
  CATEGORY,
  WHO_WE_ARE,
  DOWNLOAD_APPLICATION,
  FOLLOW_US,
  AVAILABLE_APPLE,
  APP_STORE,
  GET_APPLICATION,
  GOOGLE_PLAY,
  MAIN,
  MOST_RECENT,
  ALL_RIGHTS,
  MANZILIK,
  BOUGHT_THIS_PRODUCT,
  COMMENT_LIKE,
  DID_THIS_COMMENT_HELP_YOU,
  REVIEW_THIS_PRODUCT,
  EDIT_PRODUCT_REVIEW,
  YOU_HAVE_TO_LOGIN_FOR_REVIEW,
  GO_TO_LOGIN,
  SHOW_ALL,
  CONTACT_US,
  FREQUENTLY_ASKED_QUESTIONS,
  HOW_CAN_WE_HELP,
  FULL_NAME,
  EMAIL_HERE,
  WRITE_FULL_NAME,
  MESSAGE_CONTENT,
  MESSAGE_CONTENT_HERE,
  LETTER,
  SEND_NOW,
  HOME_PAGE,
  REQUEST_SUCCESSFULLY_RECEIVED,
  REQUEST_WILL_BE_FOLLOWED_UP,
  MOBILE,
  MAIL,
  HAVE_ACCOUNT,
  SEND_CONFIRMATION_CODE,
  AWARDS,
  WON,
  BADGES,
  GENERAL_IDEA_ABOUT_ME,
  PROFILE_IDEAS,
  UNLIMITED_CREATIVITY_IDEAS,
  QUANTITY_VALIDATION_MESSAGE,
  NO_IDEAS,
  JOINT_US_NOW,
  WITH_MANZILIK,
  INSPIRE_YOURSELF,
  OR_SIGH_UP_WITH,
  EQUIPMENT_YOU_APARTMENT,
  WE_BRING_PRACTICAL_IDEAS,
  MANY_NEW_DECORATING_IDEAS,
  WANT_TO_CHANGE_YOUR_HOME_DECORE,
  WE_PROVIDE_COMPLETE_FEED_OF_IDEAS,
  DO_YOU_WANT_TO_EXPAND_CUSTOMER_INCREASE,
  WE_PROVIDE_PLATFORM_TO_DISPLAY_YOUR_BUSINESS,
  ARE_YOU_AN_INTERIOR_DESIGN,
  YOUR_GOAL_IS_TO_INCREASE_CLIENT_PROJECTS,
  CONFIRMATION_CODE,
  HOME_PAGE_SEO_DESCRIPTION,
  DISCUSSIONS_TITLE,
  MAGAZINES_TITLE,
  ABOUT_DESCRIPTION,
  TERMS_DESCRIPTION,
  PRIVACY_DESCRIPTION,
  HOME,
  ADD_WEB_PAGE_URL,
  UN_FOLLOW,
  FOLLOWING,
  FOLLOWERS,
  RESET_YOUR_PASSWORD,
  SAVE_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  PREVIEW,
  QUESTION_SUBMIT,
  SEE_THIS_PROJECT_ON_MANZILIK,
  SEARCH_IDEAS,
  SEARCH_IDEA,
  SIGN_IN_NOW,
  CONTACT_PROFESSIONAL,
  PROJECT,
  MUST_RESET_PASSWORD,
  PLEASE_ENTER_VALID_PHONE_NUMBER_EX,
  YOU_NEE_TO_LOGIN,
  RECEIVE_ONLY_EMAILS_ABOUT_NEW_FOLLOWERS,
  RECEIVE_EMAILS_ABOUT_MY_FOLLOWERS,
  WEBSITE_NOTIFICATIONS,
  DAILY_NOTIFICATIONS,
  UPDATED_NOTIFICATIONS_SUCCESS,
  ARE_YOY_LOOKING_FOR_IDEAS,
  RELATED_PROFESSIONALS,
  SUBSCRIBE_TO_NEWSLETTER,
  MUST_AGREE_TERMS,
  MY_PROJECTS,
  YOU_DONT_HAVE_EMAIL,
  HOME_PAGE_DESCRIPTION,
  MORE,
  MY_ACCOUNT,
  ABOUT_SHOULD_BE_MORE_THAN_FIFTY_CHARACTER,
  FIELD_SHOULD_BE_URL,
  ALL,
  ENTER_PROJECT_DESCRIPTION,
  ENTER_PROJECT_STATUS,
  ENTER_PROJECT_CATEGORY,
  PUBLISH_STATUS,
  PRIVATE_WORD,
  PUBLIC_WORD,
  CATEGORY_TYPE,
  CAPTCHA_IS_MISSING,
  MANZILIK_DISCUSSIONS,
  PERSONAL_INFORMATION,
  PHOTOS_GALLERY,
  VIDEOS,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VIDEO,
  SEE_MORE_VIDEOS,
  PROFILE_VIDEOS,
  VIDEO_EXAMPLE,
  ADD_YOUTUBE_LINK,
  ADD_VALID_YOUTUBE_LINK,
  MOST_SEARCHED,
  BRANDS,
  CHOOSE_BRAND_TYPE,
  FIND_STORE,
  STYLE_TYPES,
  HOW_DO_YOU_RATE_THIS_STORE,
  WEBSITE,
  THE_TV,
  THE_MAGAZINE,
  THE_DISCUSSIONS,
  ADDRESSES,
  ADD_ADDRESS,
  ADDRESS_TITLE,
  NEIGHBORHOOD,
  ADDRESS_DETAILS,
  UPDATE_ADDRESS_TITLE,
  PROFESSIONAL_SERVICES,
  SHARE,
  ROOM_TYPES,
  SAVE_UPDATES,
  NEW_ADDRESS,
  PROVIDER,
  HOW_DO_YOU_RATE_THIS_PARTNER,
  THE_PRODUCT,
  PRICE,
  OPERATION,
  BASKET,
  DELIVERY,
  PAYMENT,
  ORDER_REVIEW,
  TRACK,
  FAST_DELIVERY,
  SAFE_GATEWAY,
  SUPPORT,
  CHECK,
  DO_YOU_HAVE_COPOUN,
  PRICE_BEFORE_TAX,
  PRICE_AFTER_TAX,
  TOTAL_TAX,
  STEP_CONTINUE,
  SHOPPING_BASKET,
  ENTER_COPON_CODE,
  GO_TO_BASKET,
  CONTINUE_SHOPPING,
  PRODUCT_WAS_ADDED_TO_BASKET,
  CONFIRM_PAYMENT_PROCCESS,
  SHOPPING_DATA_NOT_COMPLETED,
  BASKET_IS_EMPTY,
  START_ADDING_PRODUCT_TO_BASKET,
  MANZILIK_STORE,
  SHIPPING_ADDRESSES,
  NO_ADDED_ADDRESSES,
  START_SAVING_YOUR_ADDRESS_TO_DELIVER_THE_PRODUCTS,
  SAVED_CARDS,
  ADD_NEW_CARD,
  SAVE_CARD_IN_SAFE_WAY_TO_PAY_QUICKLY_IN_FUTURE,
  THE_TOTAL_PRICE_HAS_BEEN_PAID_AND_WILL_WORKING_ON_PREPARING_THE_ORDER,
  TRACK_THE_ORDER,
  REVIEW_PROCESS,
  PREPARE_THE_ORDER,
  DELIVERY_PROCESS,
  THE_ORDER_HAS_BEEN_DELIVERED_SUCCESSFULLY,
  MY_ORDERS,
  CANCEL_THE_ORDER,
  CLOSE,
  THE_ORDER_HAS_BEEN_DONE_SUCCESSFULLY,
  SHOW_CONTACT_INFO,
  SEARCH_BY_ORDER_NUMBER,
  ORDER_DETAILS,
  UNDER_PROCESSING,
  CANCELED,
  DELIVERED,
  ORDER_NUMBER,
  TOTAL_PRICE,
  ORDER_STATUS,
  CANCEL_PRODUCT,
  CONFIRM_CANCEL_PRODUCT,
  REFUND_REQUEST_DESC,
  REFUND_REQUEST_NOTE,
  REFUND_REQUEST_SUCCESS,
  REFUND_REQUEST_SUCCESS_NOTE,
  ORDER_DATE,
  DELIVERY_ADDRESS,
  DETAILS,
  THE_FOLLOWING_INFO_IS_ONLY_FOR_US_IT_WONT_PREVENT_YOU_FROM_CANCELING_YOUR_ORDER,
  SHIPPING_PROBLEM,
  OTHER,
  ORDER_IS_DUPLICATED,
  CHANGE_THE_ORDER,
  REASON_OF_CANCELING_THE_ORDER,
  ADD_DESCRIPTION,
  CONFIRM_ORDER_CANCELATION,
  NEW,
  SHIPPING_COST,
  DISCOUNT_AMOUNT,
  REGION,
  STORE_NAME,
  INESTEGRAM_ACCOUNT,
  SEND_INFORMATION,
  ENTER,
  THANK_FOR_YOUR_INTEREST_TO_JOIN_MANZILIK_PLANTFORM,
  OPERATION_TEAM_WILL_REACH_YOU_SOON,
  COMING_SOON,
  MANZILIK_STORE_SOON,
  WE_HELP_PEOPLE_TO_ACHEVE_THEIR_GOAL,
  JOIN_OUR_VENDORS,
  EXPLORE_MANZILIK,
  SAR,
  DISCOUNT,
  PAYMENT_REVIEW,
  SELECT_PAYMENT_METHOD,
  SECURE_PAYMENT_LINE,
  LAST_STEP_PURCHASE,
  PAY_NOW,
  EDIT_ADDRESS_LINE,
  EDIT_ADDRESS,
  SUCCESSFULL,
  PENDING,
  REJECTED,
  PENDING_PAYMENT,
  ITEM_COLOR,
  ITEM_DIMENSIONS,
  SHIPPING_NO_ADDRESS_SELECTED,
  BASKET_NO_ITEMS_ADDED,
  COMPANY_ACCOUNT_TYPE,
  PERSONAL_ACCOUNT_TYPE,
  COMPANY_NAME,
  PERSONAL_FREELANCE_LICENSE,
  COMPANY_LOGO,
  COMPANY_REGISTERATION_REF,
  SELECT_ACCOUNT_TYPE,
  ATTR_COLOR,
  ATTR_DIMENSIONS,
  VERIFIED,
  UNVERIFIED,
  VERIFICATION,
  ERROR_ACCOUNT_TYPE_NOT_SELECTED,
  UPLOADED_IMAGES_SUCCESSFULLY,
  GENERAL_REVIEW_MANDATORY,
  INVALID_CARD_HOLDER,
  MISSING_FIRST_NAME,
  MISSING_LAST_NAME,
  CARD_NUMBER_MISMATCH_BRAND_TYPE,
  INVALID_REGISTERATION_REFERENCE,
  INVALID_FREELANCE_NUMBER,
  NO_NAME,
  BUDGET_EXAMPLE,
  CONTACT_DETAILS,
  IMAGES,
  DOWNLOAD_MANZILIK_APP,
  DOWNLOAD_MANZILIK_APP_DESC,
  DOWNLOAD,
  ELAPSED,
  HOUR,
  HOURS,
  MARK_ALL_AS_READ,
  MINUTE,
  MINUTES,
  NO_NOTIFICATIONS,
  DAY,
  SEND_REQUEST_SERVICE,
  BADGES_TITLE,
  BADGES_ACHIEVED,
  BADGES_REMINDING,
  SEND_SERVICE_REQUEST,
  SEND_SERVICE_REQUEST_NOW,
  SERVICE_DESCRIPTION,
  SERVICE_INFO,
  SHOP_SEARCH,
  SHOP_SEARCH_HEADING,
  SHOP_SEARCH_SUBHEADING,
  SHOWING_RESULTS_FOR,
  TELEPHONE_NUMBER,
  WHATSAPP_NUMBER,
  YOU_CAN_BROWSE,
  SELECT_IMAGES_TO_UPLOAD,
  SERVICE_REQUESTS_LIST,
  SERVICE_REQUEST_UNDER_REVIEW,
  SERVICE_REQUEST_WAS_SENT,
  RETRY_AGAIN,
  SERVICE_REQUEST_ERROR,
  SERVICE_REQUESTS,
  HOME_OWNER,
  ISSUE_DATE,
  SERVICE_TYPE,
  ADD_PROJECTS_AND_SERVICES_TO_IMPROVE_YOUR_CHANCE,
  RECIEVED_REQUESTS,
  SENT_REQUESTS,
  PROJECT_DESCRIPTION,
  ACCEPT_REQUEST,
  EXTRA_INFO,
  REJECT,
  REQUEST_DETAILS,
  REQUEST_ACCEPTED_CONTACT_OWNER,
  REJECT_SERVICE_REASONS,
  PLEASE_ADD_REJECTION_DETAILS,
  REQUESTED_SERVICE_NOT_AVAILABLE,
  CLIENT_MISBEHAVIOUR,
  COULDNT_REACH_AN_AGREEMENT,
  CONFIRM_SERVICE_REJECTION,
  UNDO,
  REQUIRED_INFO_WILL_NOT_PREVENT_REJECTION,
  ACCEPTED_REQUEST,
  WAITING_RESPONSE_REQUEST,
  DELETED_REQUEST,
  REJECTED_REQUEST,
  M0,
  M1,
  M10,
  M11,
  M2,
  M3,
  M4,
  M5,
  M6,
  M7,
  M8,
  M9,
  FIND_REQUEST_BY_NUMBER,
  REQUEST_STATUS,
  REQUEST_NUMBER,
  YOU_CAN_CONTINUE_VIEWING_SERVICE_REQUEST,
  SERVICE_REQUEST_WAS_REJECTED,
  BROWSE_WEBSITE,
  CAll_NOW,
  ERROR_INVALID_TEL_5XXXXXXXX,
  LANG,
  HIGHER_RATING,
  JOIN_DATE,
  VERIFIED_FIRST,
  SELECT_SORT_METHOD,
  RESULTS,
  VIEW_AS,
  ENTER_FULL_NAME,
  NAME_VERIFICATION_STEP,
  SAVE_AND_CONTINUE,
  PLEASE_ENTER_VALID_FIRST_NAME,
  PLEASE_ENTER_VALID_LAST_NAME,
  PLEASE_SELECT_CITY,
  PLEASE_SELECT_COUNTRY,
  MANAGE_GIGS,
  ADD_NEW_SERVICE,
  NO_FAST_SERVICES,
  START_NOW_GIGS,
  IMAGE,
  SERVICE_PRICE,
  SERVICE_LOCATION,
  SERVICE_STATUS,
  MAIN_SERVICE,
  SERVICE_TITLE,
  ACTIVATED,
  NOT_AVAILABLE,
  SET_AS_MAIN,
  TITLE_INVALID_CHARS,
  ADD_SERVICE,
  EDIT_SERVICE,
  BACK,
  SELECT_SERVICE_TYPE,
  SERVICE_ADDRESS,
  SERVICE_DESC_ONE,
  SERVICE_DESC_TWO,
  SERVICE_CITIES,
  SAVE_SERVICE,
  AVAILABLE,
  SERVICE_DETAILS,
  REQUEST_SERVICE,
  PLEASE_ENTER_VALID_YOUTUBE_LINK,
  KNOW_YOUR_NEEDS,
  KNOW_YOUR_NEEDS2,
  DO_YOU_NEED_HELP,
  PROVIDE_YOU_HELP,
  FIND_A_PRO,
  SELECT_CITY,
  SEARCH_CITY,
  CONFIRM_CITY,
  VIEW_MORE,
  VIEW_LESS,
  RATING,
  SIMILAR_SERVICES,
  AVAILABLE_IN,
  START_CONTACT,
  CONTACT_NOW,
  UNREAD_MESSAGES,
  MORE_INFO,
  CONNECT_WITH_SERVICE_PROVIDER,
  VIEW_CHAT,
  COVERED_CITIES,
  SERVICES_PACKAGE,
  PROVIDE_SERVICES_PACKAGE,
  REQUEST_SERVICE_NOW,
  VIEW,
  DISAPPEAR,
  REQUEST_SERVICE_INFO,
  ADDITIONAL_INFO,
  SELECT_CITY_FIRST,
  PROVIDE_YOU_HELP2,
  NO_GIGS,
  GIGS,
  EXAMPLE_LICENSE,
  REQUEST_COMPLETED,
  CANCEL_REQUEST,
  SERVICE_PROVIDER,
  COMPLETE_BTN,
  COMPLETE_NOTIFICATION,
  RELATED_GIGS,
  COMPLETED_REQUEST,
  CANCELLED_REQUEST,
  THANKS_FOR_COMPLETE,
  MANZILIK_AI_TAB,
  DESIGN_YOUR_HOME_NOW,
  USE_AI_TO_DESIGN_YOUR_HOME,
  START_THE_EXPERIENCE_NOW,
  USE_AI_TO_DESIGN_YOUR_HOME2,
  ADD,
  POINT,
  NEWLY_PUBLISHED,
  MY_DESIGNS,
  WE_DO_THE_WORK,
  STUCK,
  UPLOAD_PHOTO_TO_START,
  START_UPLOAD_PHOTO,
  HOW_AI_WORKS,
  THE_STYLE,
  ROOM_TYPE,
  MORE_DETAILS,
  PRIVACY,
  EXPORT_SPEED,
  START_NOW,
  YOUR_CREDIT_NOT_ENOUGH,
  ADD_CREDITS,
  PRIVACY_TOOLTIP,
  PRIVATE_TEXT,
  PUBLIC_TEXT,
  TWO_POINTS,
  TWO_POINTS_NOR_PAID,
  FAST,
  ONE_POINT,
  FREE,
  SLOW,
  SHARE_AI,
  NEED_DESIGNER,
  NEED_DESIGNER_TEXT,
  SEND_TO_MANZILIK,
  WHY_MANZILIK,
  WHY_MANZILIK_TEXT,
  SAVE_TIME_EFFORT,
  SAVE_TIME_EFFORT_TEXT,
  IMPROVE_DESIGN,
  IMPROVE_DESIGN_TEXT,
  PREDICT_FUTURE,
  PREDICT_FUTURE_TEXT,
  CUSTOMIZE_DESIGN,
  CUSTOMIZE_DESIGN_TEXT,
  ADD_ACCOUNT_POINTS,
  ALL_OPTIONS_AVAILABLE,
  DOWNLOAD_IMAGES,
  CREATE_DESIGNS,
  REMOVE_WATER_MARK,
  A_LOT_OF_DESIGNS,
  MOST_REQUESTED,
  GET_IT_NOW,
  BEFORE,
  AFTER,
  SMART_DESIGN,
  SELECT_IMAGE,
  CONTINUE_BTN,
  ORIGINAL,
  EXPERIMENTAL,
  THANKS_FOR_RATE,
  TOTAL_PAYMENT,
  START_SMART_DESIGN,
  TYR_ANOTHER_TIME,
  PAYMENT_FAILED,
  DESIGN_IN_PROGRESS,
  ALL_PRICES,
  RIGHT_DESCRIPTION,
  SPECIFY_ACCOUNT_TYPE,
  CONTINUE_HOME_OWNER,
  CONTINUE_PROFESSIONAL,
  CLIENT_TYPE_TEXT,
  SERVICE_PROVIDER2,
  INVITE_FRIENDS,
  GET_POINTS,
  COPY_DONE,
  SHARE_LINK,
  REGISTER_FOR_MANZILIK,
  TRY_AI,
  GOT_POINTS,
  SHARE_WITH_FRIENDS,
  WE_NEED_YOUR_PHONE,
  AI_ERROR_B104,
  PROF_TYPE_TEXT,
  DELETE_DESIGN,
  SURE_DELETE,
  CONFIRM_DELETE,
  REQUEST_FOR_QUOTATION,
  REQUEST_FOR_QUOTATION_TEXT,
  POST_A_QUOTE,
  POST_NOW,
  POST_A_QUOTE_TEXT,
  CREATE_NEW_PROJECT,
  POST_IT_NOW,
  PRICE_QUOTE_SEND_SUCCESS,
  PRICE_QUOTE_SEND_SUCCESS_TEXT,
  PRICE_QUOTE_DETAILS,
  CREATE_A_PROJECT,
  CREATE_A_PROJECT_TEXT,
  DETERMINE_THE_REQUIRED_SKILLS,
  DETERMINE_THE_REQUIRED_SKILLS_TEXT,
  DETERMINE_THE_BUDGET,
  DETERMINE_THE_BUDGET_TEXT,
  PUBLISH_THE_PROJECT,
  PUBLISH_THE_PROJECT_TEXT,
  REVIEW_OFFERS,
  REVIEW_OFFERS_TEXT,
  DISCUSS_DETAILS,
  DISCUSS_DETAILS_TEXT,
  CONTRACTING,
  CONTRACTING_TEXT,
  FOLLOW_THE_PROGRESS,
  FOLLOW_THE_PROGRESS_TEXT,
  SUBMIT_A_PRICE_REQUEST,
  ACCURACY,
  WALLS,
  FLOOR,
  CEILING,
  COLOR_PALETS,
  AI_INPUT_PLACEHOLDER,
  DESIGN_DESC,
  ADDITIONAL_OPTIONS,
  NOT_SELECTED,
  QUOTATION_DETAILS,
  APPLY_TO_QUOTATIONS,
  QUOTATIONS,
  OPENED_REQUEST,
  CLOSED_REQUEST,
  DURATION,
  REQUESTS_WORKS_ON,
  QUOTATIONS_REQUESTS,
  CONTRACTED_REQUEST,
  BUSINESS_YOU_LIKE,
  BROWSE_JOBS,
  APPLICANTS,
  PHOTOS_ATTACHED,
  MAKE_AN_OFFER,
  OFFER_PRICE,
  PROJECT_DURATION,
  MESSAGE_TO_SERVICE_REQUESTER,
  ATTACH_YOUR_WORK,
  UPLOAD_YOUR_WORK,
  ATTACH_UP_TO,
  TO_SUBMIT_RFQ,
  UPGRADE_SERVICES,
  THE_TYPE,
  QUOTATION_REQUEST,
  ARE_U_SURE_TO_SUBMIT_QUOTATION,
  QUOTATION_LAST_STEP,
  CONFIRM_QUOTATION,
  QUOTATION_SENT,
  MONTHS,
  YEARS,
  START_TRIP,
  YOU_CAN_APPLY,
  PRESS_MY_ACCOUNT,
  SERVICE_DETAILS_CONT,
  TYR_TO_ADD_SERVICE,
  THEN_CLICK_GIGS_MANAGEMENT,
  THEN_CLICK_ADD_GIG,
  TO_CONTINUE_FOLLOW_DETAILS,
  YOUR_REVIEW,
  REVIEW_SERVICE,
  REVIEW_SERVICE_TEXT,
  START_RATING,
  ADD_RATE,
  PRICE_NOT_ADDED,
  ORDER_ACCEPTED,
  CONTACT_CS,
  DESC_ABOUT_ME,
  NO_BADGES,
  HOW_TO_GET,
  HOW_TO_GET_BAGDES,
  HEADING_HOME_CLIENT1,
  HEADING_HOME_CLIENT2,
  START_SEARCH,
  EXPLORE_YOUR_REQUESTS,
  NO_PROJECTS,
  ADD_NEW_PROJECTS,
  NEXT_STEP,
  SERVICE_FORM,
  PAY_SERVICE_VALUE,
  VAT_INCLUDED,
  WILL_ADDED,
  FIVE_POINTS,
  TO_USE_IN_AI,
  INCLUDED_FEES,
  REFUND2,
  ACTIVE_REPUBLISH,
  YOUR_MANZILIK_ORDER,
  CONGRATS,
  ARE_YOU_SURE_DELETE_ORDER,
  ACCEPTED_OFFER,
  CANCELED_OFFER,
  NOW_CAN_COMPLETE_CONTRACT,
  ORDER_CANCELED,
  REFUND_MESSAGE,
  CONFIRM_CANCEL_ORDER,
  MESSAGES,
  OFFER_ACCEPTED,
  YOU_CAN_PUBLISH,
  RE_PUBLISH,
  NO_QUOTATIONS,
  DRAFT_REQUEST,
  DESCRIBE_SERVICE,
  DESCRIPTION_HELPS_YOU,
  DESCRIPTION_EXAMPLE,
  REGISTER_NOW,
  WE_NEED_YOUR_PHONE_TO_ASSIST,
  CONTINUE_PAYMENT,
  CONTACT_SUPPORT,
  SHOW_CLOSED_COMPLETED_REQUESTS,
  INPROGRESS_REQUESTS,
  NEW_REQUESTS,
  COMPLETED_CANCELED_REQUESTS,
  APPLICANT,
  SOME_GUIDES,
  AI_GUIDE_1,
  AI_GUIDE_2,
  AI_GUIDE_3,
  AI_GUIDE_4,
  AI_GUIDE_5,
  AI_GUIDE_6,
  AI_GUIDE_7,
  ATTACHED_PHOTOS,
  GUIDES,
  SMART_TEXT_AREA,
  SMART_GENERATION,
  RE_GENERATE,
  SELECT_STYLE,
  SELECT_ROOM_TYPE,
  RE_SELECT,
  ADV_GENERATE_TOOLTIP,
  NOT_EXIST,
  CLASSIC_VIEW,
  BACK_HISTORY,
  CLASSIFY_STORES,
  FIND_VENDOR,
  SIMILAR_PRODUCTS,
  ANALYZE_PRODUCTS,
  COUNTRY_CITY,
  MANAGE_IDEAS,
  MANAGE_VIDEOS,
  SHARE_PROFILE,
  SHARE_PROFILE_WITH_PEOPLE,
  MANAGE_REQUESTS,
  TRENDING,
  JOBS,
  APPLIED_FOR_JOB,
  BE_FIRST_ONE,
  APPLY,
  INCOMPLETE_RFQ,
  NO_PRODUCTS_EXIST,
  VIEW_SELLERS,
  VIEW_PRODUCTS,
  PRESS_TO_VIEW_PRODUCTS,
  VIEW_SELLERS_PRODUCTS,
  VIEW_SELLERS_PRODUCTS_WITHIN_SEARCH,
  BEST_SELLING_PRODUCTS,
  WILL_DEDUCT,
  POINTS_FROM_BALANCE,
  BEST_SELLERS_IN_KINGDOM,
} from './strings';

export default {
  [JOKER]: 'منزلك',
  [SAR]: 'ر.س',
  [DISCOUNT]: 'تخفيض',
  [EMAIL]: 'البريد الإلكتروني',
  [PASSWORD]: 'كلمة السر',
  [LOGIN]: 'تسجيل الدخول',
  [MY_ACCOUNT]: 'حسابي',
  [SIGNUP]: 'تسجيل مستخدم جديد',
  [SIGNUP_WITH_EMAIL]: 'إنشاء حساب',
  [CONTINUE_WITH_GOOGLE]: 'جوجل',
  [CONTINUE_WITH_FACEBOOK]: 'فيسبوك',
  [OR]: 'أو',
  [AGREE_TO_TERMS]: ' موافق على جميع',
  [EMAIL_REQUIRED_MESSAGE]: 'الرجاء إدخال الإيميل',
  [PASSWORD_REQUIRED_MESSAGE]: 'الرجاء كتابة كلمة المرور',
  [REGEX_DOESNT_MATCH_MSG]: 'يجب أن تتكون كلمة مرورك من 8 أحرف على الأقل.',
  [HOME_PAGE_HEADING]: ' استمد إلهامك',
  [HOME_PAGE_DESCRIPTION]: 'واستكشف العديد من الأفكار المختلفة في منزلك',
  [CONFIRM]: 'تأكيد',
  [ITEM_COLOR]: 'لون المنتج',
  [ITEM_DIMENSIONS]: 'أبعاد المنتج',
  [CONFIRM_EMAIL]: 'تأكيد الإيميل؟',
  [BACK_TO_LOGIN]: 'عودة إلى تسجيل الدخول',
  [RESEND_CONFIRMATION]: 'إعادة الإرسال مرة أخرى',
  [FIND_IDEAS]: 'ابحث عن افكار',
  [CONFIRM_CODE_TITLE]: 'ادخل رمز التفعيل',
  [SIGNUP_WITH]: ' إنشاء حساب جديد بـ',
  [LOGIN_BY_EMAIL]: ' تسجيل الدخول بالبريد الإلكتروني',
  [USERNAME]: 'البريد الإلكتروني',
  [FORGET_PASSWORD]: 'نسيت كلمة المرور؟',
  [RESET_YOUR_PASSWORD]: 'استرجاع كلمة المرور',
  [KEEP_SIGNED_IN]: 'ابقني مسجلا الدخول',
  [SIGNIN]: 'تسجيل الدخول',
  [SIGN_IN_POLICY]: 'بمجرد إنشاء حساب جديد، أو تسجيل الدخول فأنت توافق على الشروط والخصوصية لموقع منزلك',
  [COMMON_QUESTIONS]: 'أسئلة تسجيل الدخول الشائعة',
  [COMMON_QUESTION_1]: 'إذا لم تستطع تسجيل الدخول بحسابك، حاول قراءة مقالات المساعدة',
  [COMMON_QUESTION_2]: 'أنا لا أستطيع تسجيل الدخول، ماذا يجب أن أفعل؟',
  [COMMON_QUESTION_3]: 'نسيت البريد الإلكتروني أو كلمة السر، ماذا يجب أن أفعل؟',
  [COMMON_QUESTION_4]: 'كيف أستطيع تحديث أو تغيير كلمة المرور الخاص بي؟',
  [COMMON_QUESTION_5]: 'كيف أستطيع تغيير بريدي الإلكتروني؟',
  [COMMON_QUESTION_6]: 'هل أستطيع تغيير اسمي؟',
  [LOGIN_WITH_FACEBOOK]: 'تسجيل الدخول بواسطة فيسبوك',
  [LOGIN_WITH_GOOGLE]: 'تسجيل الدخول بواسطة جوجل',
  [LOGIN_BY_SOCIAL_MEDIA]: ' تسجيل الدخول بواسطة مواقع التواصل الاجتماعي',
  [MANZIL_LOGIN]: 'تسجيل الدخول لمنزل',
  [FORGET_YOUR_PASSWORD]: 'نسيت كلمة المرور؟',
  [FORGET_PASSWORD_DESCRIPTION]: 'ادخل بريدك الإلكتروني وسوف نرسل لك رمز لتغير كلمة المرور',
  [HELLO]: 'مرحبًا',
  [CONTINUE]: 'اكمل',
  [RE_ENTER_PASSWORD]: 'إعادة إدخال كلمة المرور',
  [SUBMIT]: 'تغيير',
  [NEW_PASSWORD_TERMS]: 'بمجرد تغير كلمة المرور فأنت توافق على شروط الخدمات والخصوصية لموقع منزلك',
  [PASSWORD_DONT_MATCH]: 'كلمات المرور غير متطابقة',
  [CREATE_NEW_PASSWORD]: ' كلمة المرور جديدة',
  [CONFIRM_NEW_PASSWORD]: 'تأكيد كلمة المرور الجديدة',
  [SAVE_PASSWORD]: 'حفظ كلمة المرور',
  [CODE_MESSAGE]: 'الرجاء إدخال الرمز',
  [CODE_TEXT]: 'الرمز',
  [MOBILE]: 'الجوال',
  [MAIL]: 'الايميل',
  [HAVE_ACCOUNT]: 'هل يوجد لديك حساب؟ يمكنك',
  [SEND_CONFIRMATION_CODE]: 'ارسل رمز التأكيد',
  //GENERIC//
  [CANCEL]: 'إلغاء',
  [OPTIONAL]: 'اختياري',
  [DELETE]: 'حذف',
  [DISCUSSIONS]: 'نقاشات',
  [PHOTOS]: 'صور',
  [ENGLISH]: 'En',
  [SEND]: 'إرسال',
  // MOVE IDEA MODAL //
  [MOVE_IDEA]: 'نقل فكره',
  [SELECT_PROJECT]: 'اختر مشروعاً',
  [SELECT_ALL]: 'اختر الكل',
  [ORGANIZE]: 'تنظيم',
  // HEADER //
  [GET_IDEAS]: 'افكار',
  [NOTIFICATIONS]: 'تنبيهات',
  [PROJECTS]: 'مشاريع',
  [MORE]: 'المزيد',
  //PROJECT//
  [CREATE_PROJECT]: 'إنشاء مشروع',
  [PROJECT_NAME]: 'اسم المشروع',
  [PRIVATE]: 'خاص (لا يظهر للعامة ويمكن تغييره من الإعدادات بسهولة)',
  [PUBLIC]: 'عام (سيظهر للجميع بعد إضافة صورتان أو أكثر في مشروعك)',
  [ENTER_PROJECT_NAME]: 'ادخل اسم مشروعك',
  [CHOOSE_SPACE]: 'اختر التصنيف الذي يعبر عن مشروعك',
  [CREATE]: 'أَنْشِئْ',
  [LOADING]: 'تحميل...',
  [CHANGE_TO_GALLERY_VIEW]: 'عرض الصور بشكل أكبر',
  [ADD_NEW_IDEA]: 'إضافة فكرة',
  [EXPORT]: 'تصدير',
  // PROJECTS LIST PAGE
  [ACTIVITY]: 'نشاطات',
  // VALIDATION MESSAGES
  [REQUIRED]: 'هذا الحقل مطلوب',
  [RESET_PASSWORD_SUCCESS_MSG]: 'نجحت  استعادة كلمة المرور',
  [INVITE_FRIEND]: 'دعوة صديق',
  [FILTER_BY]: 'تصنيف حسب',
  [MY_IDEAS]: 'افكاري',
  [MORE_IDEAS_FROM_MANZEL]: 'افكار أخرى من منزلك',
  [ADD_NOTE]: 'إضافة ملاحظة',
  [COPY]: 'نسخ',
  [MOVE]: 'نقل',
  [REMOVE]: 'حذف',
  // USERBOX
  [VIEW_PROFILE]: 'الملف الشخصي',
  [VIEW_ALL]: 'اعرض الكل',
  [IDEA]: 'فكره',
  [SEARCH_IDEAS]: 'افكار',
  [SEARCH_IDEA]: 'فكره',
  [IDEA_0]: '{{count}} افكار',
  [IDEA_1]: 'فكره',
  [IDEA_2]: 'فكرتان',
  [IDEA_3]: '{{count}} افكار',
  [IDEA_4]: '{{count}} افكار',
  // SEND MESSAGE FORM
  [ENTER_EMAIL_RECIPIENTS]: ' (أدخل عناوين البريد الإلكتروني للمستلمين، مفصول بينهم بفواصل):',
  [MESSAGE_SUBJECT]: 'موضوع الرسالة',
  [SEND_BY_EMAIL]: 'إرسال بواسطة البريد الإلكتروني',
  [MESSAGE]: 'محتوى الرسالة',
  [MESSAGE_INPUT_PLACE_HOLDER]:
    'ألقِ نظرة على هذه المساحات الرائعة التي وجدتها في موقع منزلك ، واسمح لي أن أعرف ما هو رأيك. ',
  [MESSAGE_IS_SENT]: ' تم إرسال الرسالة',
  [LINK_COPIED]: 'تم نسخ الرابط',
  [TO]: 'إلى',
  [IMPORT_GMAIL_CONTACT]: 'استيراد جهات اتصال البريد الإلكتروني',
  // ADD IDEA FORM
  [UPLOAD_TERMS]:
    'بتحميل الصور ، فإنك توافق على عرضها بواسطة موقع منزلك ، هذا يعني ان حقوق الصورة اما لك أو أن لديك إذنًا من المالك ، وتوافق على الالتزام بشروط استخدام منزلك.',
  [UPLOAD_PHOTO]: 'رفع صورة',
  [UPLOAD_PHOTOS]: 'رفع صور',
  [UPLOADED_IMAGES_SUCCESSFULLY]: 'تم رفع الصور بنجاح',
  [SAVE_FROM_WEB]: 'حفظ من موقع',
  [UPLOAD]: 'إضافة',
  [ADD_PHOTO]: 'إضافة صورة',
  [YOU_CAN_ONLY_UPLOAD_JPG]: 'يمكنك فقط رفع ملف بصيغة png/jpg',
  [EDIT_PROJECT_INFO]: 'تعديل المشروع',
  [TITLE]: 'العنوان',
  [DESCRIPTION]: 'الوصف',
  [EDIT]: 'تعديل',
  [WRITE_DESCRIPTION]: 'كتابة وصف المشروع',
  [ENTER_PROJECT_DESCRIPTION]: 'ادخل وصف للمشروع',
  [ENTER_PROJECT_STATUS]: 'حدد حالة النشر',
  [ENTER_PROJECT_CATEGORY]: 'حدد تصنيف المشروع',
  [PUBLISH_STATUS]: 'حالة النشر',
  [PRIVATE_WORD]: 'خاص',
  [PUBLIC_WORD]: 'عام',
  [CATEGORY_TYPE]: 'نوع التصنيف',

  // -------------------------- Footer ---------------------//

  [FOOTER_CREDITS]: '2021 منزلك',
  [COMPANY]: 'شركة',
  [ABOUT_MANZEL]: 'عن منزلك',
  [PRIVACY_AND_POLICY]: 'سياسة الخصوصية',
  [TERMS_AND_CONDITIONS]: 'الشروط والأحكام',
  [COOKIE_POLICY]: 'سياسة ملفات الارتباط',
  [GET_HELP]: 'الحصول على مساعدة',
  [GET_INSPIRED]: 'الحصول على الالهام',
  [MANZEL_SUPPORT]: 'دعم منزلك',
  [CONTACT]: ' تواصل معنا',
  [FACEBOOK]: 'فيسبوك',
  [TWITTER]: 'تويتر',
  [INSTAGRAM]: 'انستجرام',
  [YOUTUBE]: 'يوتيوب',
  [RSS]: 'RSS',
  [DOWNLOAD_APPLICATION]: 'حمّل التطبيق',
  [CONNECT_WITH_US]: 'اتصل معنا',
  [FOLLOW_US]: 'تابعنا',
  [AVAILABLE_APPLE]: 'متاح على',
  [APP_STORE]: 'متجر أبل',
  [GET_APPLICATION]: 'احصل على التطبيق',
  [GOOGLE_PLAY]: 'جوجل بلاي',
  [ALL_RIGHTS]: 'جميع الحقوق محفوظة لــ',
  [DROP_FILE_HERE]: 'اسحب الملفات هنا أو اضغط للرفع',
  //PopConfirm
  [YES]: 'نعم',
  [NO]: 'لا',
  [ARE_YOU_SURE_DELETE_MSG]: 'هل أنت متأكد أنك تريد حذف مشروع {{projectName}}؟',
  [ARE_YOU_SURE_DELETE_IDEA]: 'هل أنت متأكد أنك تريد الحذف؟',
  [UPDATE]: 'تعديل',
  [LOGOUT]: 'تسجيل خروج',
  // invite friend modal
  [INVITE]: 'أرسل دعوة',
  [SAVE_SETTINGS]: 'حفظ الإعدادات',
  [CAN_EDIT]: 'يمكن التعديل',
  [CAN_VIEW]: 'يمكن المشاهدة',
  [WHO_HAS_ACCESS]: ' من لديه صلاحية',
  [CURRENTLY_SHARE_WITH]: 'يتم مشاركته حالياً مع',
  [INVITE_BY_EMAIL_OR_NAME_PLACEHOLDER]: 'ادخل البريد الإلكتروني لاصدقاءك مفصول بينهم بفاصلة',
  [DELETE_FRIEND]: 'حذف صديق',
  [YOU]: 'أنت',
  //EDIT PROFILE
  [EDIT_PROFILE]: 'تعديل الملف الشخصي',
  [ACCOUNT]: 'الحساب',
  [ACCOUNT_INFORMATION]: 'معلومات الحساب',
  [PROFILE_INFO]: 'معلومات الحساب الشخصي',
  [CONTACT_INFO]: 'معلومات التواصل',
  [SOCIAL_MEDIA_SETTINGS]: 'إدارة الشبكات الإجتماعية',
  [ADVANCE_SETTINGS]: 'إعدادات متقدمة',
  [PRIVACY_SETTINGS]: 'إعدادات الخصوصية',
  [PROFILE_INFORMATION]: 'معلومات الحساب',
  [FIRST_NAME]: 'الاسم الأول',
  [SELECT_ACCOUNT_TYPE]: 'نوع الحساب الخاص بك',
  [ERROR_ACCOUNT_TYPE_NOT_SELECTED]: 'خطأ . يرجى اختيار نوع الحساب',
  [PERSONAL_ACCOUNT_TYPE]: 'حساب شخصي',
  [COMPANY_NAME]: 'إسم الشركة',
  [COMPANY_LOGO]: 'شعار الشركة',
  [COMPANY_REGISTERATION_REF]: 'السجل التجاري',
  [PERSONAL_FREELANCE_LICENSE]: 'وثيقة العمل الحر',
  [COMPANY_ACCOUNT_TYPE]: 'حساب شركة',
  [LAST_NAME]: 'الاسم الأخير',
  [PUBLICLY_DISPLAYED]: 'معروض للجميع',
  [ABOUT_ME]: 'معلومات عنا',
  [MY_FAVORITE_STYLE]: 'أسلوبي المفضل',
  [MY_NEXT_MANZILIK_PROJECT]: 'مشروعي القادم في منزلك',
  [USER_NAME]: 'اسم المستخدم',
  [BY]: 'بواسطة',
  [PROFILE_DETAILS_UPDATED_SUCCESSFULLY]: 'تم تحديث معلوماتك الشخصية بنجاح',
  [PROCEED_TO]: 'يمكنك الذهاب إلى ',
  [YOUR_MANZILIK_PROJECTS]: 'مشاريعك الخاصة',
  [PREVIEW_YOUR]: 'عرض',
  [PUBLIC_PROFILE]: 'الملف الشخصي الخاص بك',
  [OLD_PASSWORD]: 'كلمة المرور القديمة',
  [NEW_PASSWORD]: 'كلمة المرور الجديدة',
  [CONTACT_INFORMATION]: 'معلومات التواصل',
  [COUNTRY]: 'الدولة',
  [CITY]: 'المدينة',
  [STATE]: 'الولاية',
  [ZIP]: 'ZIP',
  [SAVED_TO]: 'تم الحفظ في',
  [UPDATED_NOTIFICATIONS_SUCCESS]: 'تم تعديل الاعدادات بنجاح!',
  [ABOUT_SHOULD_BE_MORE_THAN_FIFTY_CHARACTER]: 'يجب أن يكون عدد الأحرف أكبر من 50 حرف',
  [FIELD_SHOULD_BE_URL]: 'هذا الحقل يجب أن يكون رابط موقع الكتروني',
  [ADDRESSES]: 'عناويني',
  [ADD_ADDRESS]: 'اضافة عنوان',
  [ADDRESS_TITLE]: 'اسم العنوان',
  [NEIGHBORHOOD]: 'الحي',
  [ADDRESS_DETAILS]: 'تفاصيل العنوان',
  [UPDATE_ADDRESS_TITLE]: 'تعديل العنوان',
  [SAVE_UPDATES]: 'حفظ التعديلات',
  [NEW_ADDRESS]: 'عنوان جديد',
  // idea page
  [SHARE]: 'نشر',
  [SAVE]: 'حفظ',
  [OTHER_PHOTO_IN]: 'صور أخرى في',
  [SEE_MORE_PHOTOS]: 'عرض المزيد من الصور',
  [SEE_MORE_VIDEOS]: 'عرض المزيد من الفيديوهات',
  [SAVE_IDEA]: 'حفظ فكره',
  [WHAT_DO_YOU_LIKE_ABOUT_THIS_IDEA]: 'اضف ملاحظة، ماذا يعجبك في هذه الفكره',
  [FOLLOW]: 'متابعة',
  [UN_FOLLOW]: 'إلغاء',
  [WRITE_COMMENT]: 'اكتب تعليقًا',
  [QUESTION_ABOUT_THIS_PHOTO]: 'أسئلة عن هذا المشروع',
  [SHOW]: 'عرض',
  [MORE_QUESTIONS]: 'أسئلة أخرى',
  [MORE_COMMENTS]: 'تعليقات أخرى',
  [ASK_QUESTION]: 'اسأل سؤالاً',
  [I_HAVE_ANOTHER_QUESTION]: 'لدي سؤال آخر',
  [ASK_QUESTION_ABOUT_THIS_PHOTO]: 'اسأل سؤالاً عن هذه الصورة',
  [TELL_US_THE_DETAILS]: 'أخبرنا التفاصيل',
  [YOUR_QUESTION]: 'السؤال',
  [OK]: 'حسناً',
  [PEOPLE_WHO_LIKE_THIS]: 'الأشخاص الذين أعجبهم هذا',
  [ARE_YOU_SURE_DELETE_COMMENT_MSG]: 'هل أنت متأكد أنك تريد حذف هذا التعليق',
  [PREVIEW]: 'عرض',
  [QUESTION_SUBMIT]: 'اسأل',
  [SEE_THIS_PROJECT_ON_MANZILIK]: 'ألقِ نظرة على هذا المشروع في منزلك',
  //edit profile videos
  [PROFILE_VIDEOS]: 'الفيديوهات',
  [VIDEO_EXAMPLE]: 'مثال لرابط فيديو',
  [ADD_YOUTUBE_LINK]: 'اضافة رابط يوتيوب',
  [ADD_VALID_YOUTUBE_LINK]: 'رابط اليوتيوب غير صحيح',
  //edit profile -social media
  [LINKEDIN]: 'لينكد ان',
  [WEBSITE_OR_BLOG]: 'الموقع أو المدونة',
  [YOUR_PROFILE_PHOTO]: 'صورة ملفك الشخصي',
  [DRAG_THE_CORNER_OF_THE_BOX_TO_CHANGE_POSITION_AND_SIZE_OR_UPLOAD_A_NEW_IMAGE]:
    'اسحب زاوية المربع لتغيير الموضع والحجم ، أو تحميل صورة جديدة.',
  [CLICK_OR_DRAG_DROP_FILES_HERE_TO_UPLOAD]: 'انقر أو اسحب وأفلت الملفات هنا للتحميل',
  [YOU_CAN_UPLOAD_A_JPG_GIF_OR_PNG_FILE]: 'يمكنك تحميل ملف JPG أو GIF أو PNG',
  [YOUR_PRIVACY_SETTINGS]: 'إعدادات الخصوصية الخاصة بك',
  [SMS_OFFERS]: 'عروض الرسائل القصيرة',
  [SEND_ME_SMS_FROM_MANZILIK_WITH_TIPS]:
    'أرسل لي رسالة نصية قصيرة من منزلك تحتوي على النصائح والعروض والخدمات المماثلة وفقًا لشروط استخدام منزلك.',
  [IT_MAY_TAKE_UP_TO_3_DAYS_FOR_US_TO_FULLY_PROCESS]:
    'يرجى تفهم أنه قد يستغرق الأمر ما يصل إلى 3 أيام حتى نقوم بمعالجة إلغاء الاشتراك بالكامل.',
  [DISCLOSE_MY_INFO_FOR_COMMERCIAL_PURPOSES]: 'افصح عن معلوماتي للأغراض التجارية',
  [UNDER_CALIFORNIA_LAW_LEARN_MORE_HERE]:
    'بموجب قانون ولاية كاليفورنيا ، يعتبر الكشف عن معلوماتي للأغراض التجارية "بيعًا". تعلم المزيد هنا.',
  [MARKETING_AND_EMAIL_COMMUNICATIONS]: 'التسويق والاتصالات عبر البريد الإلكتروني',
  [MANAGE_YOUR_EMAIL_SETTINGS]: 'إدارة إعدادات البريد الإلكتروني الخاص بك',
  [ADDRESS_BOOK]: 'دليل العناوين',
  [DELETE_MY_ADDRESS_BOOK_CONTACT]: 'حذف دفتر العناوين الخاص بي',
  [DOWNLOAD_A_COPY_OF_YOUR_DATA]: 'قم بتنزيل نسخة من بياناتك',
  [PLEASE_WRITE_VALID_EMAILS]: 'الرجاء إدخال بريد صحيح',
  //-------------------------------- idea search page --------------------------
  [SEARCH]: ' بحث ',
  [IN_ALL_PHOTOS]: 'في كل الصور',
  [ALL_FILTERS]: 'جميع التصنيفات',
  [STYLE]: 'الطريقة',
  [SIZE]: 'الحجم',
  [COLOR]: 'اللون',
  [LIVING_ROOM]: 'أفكار لغرفة الجلوس',
  [KITCHEN_IN_PHOTOS]: 'أفكار للمطبخ في صور',
  [BATHROOM_IN_PHOTOS]: 'أفكار للحمام في صور',
  [SEND_EMAIL]: 'إرسال',
  [REFINE_BY]: 'اختيار ضمن',
  [SORT_BY]: 'ترتيب النتائج',
  [OF]: 'من',
  [LOCATION]: 'المدن',
  [RELEVANCE]: 'ملائم',
  [DONE]: 'تم',
  [CLEAR_ALL]: 'امسح الكل',
  [SELECTED]: 'تم اختيار',
  [BUDGET]: 'الميزانية',
  [TYPE]: 'نوع المستخدم',
  [ADVANCED_SETTINGS]: 'إعدادات متقدمة  ',
  [NEWSLETTER_EDITION]: 'إصدار النشرة الإخبارية  ',
  [EMAIL_PREFERENCES]: 'تنبيهات البريد الالكتروني',
  [RECEIVE_EMAILS_ABOUT_MY_FOLLOWERS]: 'تنبيهات كل ما هو جديد عن المتابعين',
  [RECEIVE_ONLY_EMAILS_ABOUT_NEW_FOLLOWERS]: 'المتابعين الجدد لي ',
  [WEBSITE_NOTIFICATIONS]: 'تنبيهات الموقع',
  [DAILY_NOTIFICATIONS]: 'الاشعارات اليومية',
  [CUSTOMIZE_MY_EMAIL_SUBSCRIPTIONS_BELOW]: 'تخصيص اشتراكات البريد الإلكتروني الخاص بي أدناه  ',
  [EMAIL_SUBSCRIPTIONS]: 'الاشتراكات البريد الإلكتروني  ',
  [MANZILIK_MAGAZINE_HOME_TOURS]: 'مجلة منزلك (جولات منزلية ، إعادة صياغة القصص والنصائح)    ',
  [MANZILIK_SHOP_TRENDING_PRODUCTS_AND_SALES]: ' متجر منزلك (المنتجات الرائجة والمبيعات)',
  [PRODUCT_ALERTS_PRICE_DROPS]: 'تنبيهات المنتج (انخفاض الأسعار ، عودة العناصر في المخزون والمراجعات)    ',
  [PERSONALIZED_RECOMMENDATIONS]: 'توصيات مخصصة (المنتجات والصور والإيجابيات)  ',
  [MANZILIK_TV_SURVEYS_AND_OTHER_UPDATES]: 'تلفاز منزلك ، استطلاعات الرأي والتحديثات الأخرى  ',
  [MANZILIK_REFERRAL_COUPONS]: 'كوبونات إحالة  من أصدقائك أو محترفي المنزل    ',
  [MANZILIK_UPDATES_NEAR_ME]: 'تحديثات منزلك بالقرب مني ',
  [TIPS_GUIDES_AND_NEW_FEATURE_ANNOUNCEMENTS]: 'نصائح وأدلة وإعلانات الميزات الجديدة  ',
  [USER_ACTIVITY_NOTIFICATIONS]: 'إخطارات نشاط المستخدم  ',
  [SEND_ME_AN_EMAIL_IF]: 'أرسل لي بريدًا إلكترونيًا إذا:  ',
  [THERE_IS_AN_ISSUE_WITH_MY_ACCOUNT_OR_LOGIN]: 'هناك مشكلة في حسابي أو تسجيل الدخول  ',
  [SOMEONE_FOLLOWS_ME]: 'شخص ما يتبعني  ',
  [SOMEONE_MESSAGES_ME]: 'شخص ما يرسل لي  ',
  [SOMEONE_COMMENTS_ON_MY_IDEABOOK]: 'يعلق شخص ما على كتاب الافكار الخاص بي  ',
  [SOMEONE_COMMENTS_ON_MY_POST]: 'شخص ما يعلق على رسالتي  ',
  [EVERY_TIME]: 'كل مره  ',
  [NOTIFICATION_CENTER]: 'مركز التنبيهات',
  [SHOW_ACTIVITIES_FROM_USERS_I_AM_FOLLOWING]: 'عرض الأنشطة من المستخدمين الذين أتابعهم في مركز الإشعارات الخاص بي    ',
  [MY_PROFILE_PAGES]: 'صفحات ملف التعريف الخاصة بي  ',
  [THE_FOLLOWING_WILL_BE_VISIBLE_ON_MY_PUBLIC_PROFILE]:
    'سيكون ما يلي مرئيًا على صفحة ملفي الشخصي العام ، وسيتم مشاركته مع متابعيني:    ',
  [MY_PUBLIC_IDEABOOKS]: 'كتب الافكار العامة الخاصة بي  ',
  [MY_UPLOADED_PHOTOS_AND_PROJECTS]: 'صوري ومشاريعي التي تم تحميلها  ',
  [MY_DISCUSSIONS]: 'نقاشاتي',
  [MY_SOCIAL_MEDIA_PROFILES]: 'ملفات التعريف الخاصة بي على وسائل التواصل الاجتماعي  ',
  [PEOPLE_WHO_FOLLOW_ME]: 'الناس الذين يتبعونني  ',
  [PEOPLE_I_FOLLOW]: 'الناس الذين أتابعهم  ',
  [DEFAULT_PHOTO_ORDER_IN_MY_IDEABOOKS]: 'ترتيب الصور الافتراضي في كتب الافكار الخاصة بي  ',
  [CLIMATE_ZONE_FOR_GARDEN_FORUMS]: 'منطقة المناخ لمنتديات الحديقة  ',
  [MY_GARDEN_WEB_TRADING_LIST]: 'قائمة التداول الخاصة بي GardenWeb  ',
  [ALLOW_USERS_TO_FOLLOW_MY_PROFILE]: 'السماح للمستخدمين لمتابعة ملف التعريف الخاص بي',
  [NOTE_IF_THIS_IS_UNCHECKED]: 'ملاحظة: إذا لم يتم تحديد هذا الخيار ، فستتم إزالة جميع المتابعين بشكل دائم.    ',
  [WHO_CAN_MESSAGE_ME]: 'من يمكنه مراسلتي  ',
  [ENABLE_ADD_TO_IDEABOOK_CONFIRMATION]: 'تفعيل الإضافة لتأكيد كتاب الافكار  ',
  [ALLOW_COMMENTS_ON_MY_PUBLIC_PHOTOS]: 'السماح بالتعليقات على صوري العامة  ',
  [PUBLIC_PROFILE_PAGE]: 'صفحة الملف الشخصي العامة  ',
  [DEACTIVATE_ACCOUNT]: 'تعطيل الحساب  ',
  [NEVER]: 'أبداً',
  [LIMIT_1_PER_HOUR_POST]: 'بحدود مرة كل ساعة لكل منشور',
  [LIMIT_3_PER_HOUR_POST]: 'بحدود ٣ مرات كل ساعة لكل منشور',
  [LIMIT_1_PER_DAY]: 'مرة يومياً',
  [LIMIT_2_PER_DAY]: 'مرتين يومياً',
  [LIMIT_5_PER_DAY]: '٥ مرات يومياً',
  [DISPLAY_NEWS_FIRST]: 'عرض أحدث الصورة أولاً',
  [DISPLAY_OLDEST_FIRST]: 'عرض أقدم الصور أولاً',
  [DONT_ALLOW_USERS_TO_MESSAGE_ME]: 'عدم السماح لأحد بمراسلتي',
  [ALLOW_USERS_I_FOLLOW_TO_MESSAGE_ME]: 'السماح للأشخاص الذين أتابعهم بمراسلتي',
  [ALLOW_ALL_USERS_TO_MESSAGE_ME]: 'السماح للجميع بمراسلتي',
  [YOU_NEE_TO_LOGIN]: 'من أجل أمانك ، تحتاج إلى تسجيل الدخول مرة أخرى لتتمكن من تغيير كلمة المرور الخاصة بك',
  [YOU_DONT_HAVE_EMAIL]: 'ليس لديك بريد الكتروني، يمكنك اضافة بريدك الالكتروني من معلومات الحساب الشخصي',
  [MOST_SEARCHED]: 'الأكثر بحثاً',

  // --------------------------------- discussions page ----------------------------

  [WHAT_ARE_YOU_WORKING_ON]: 'ما الذي تعمل عليه؟',
  [GET_HELP_FOR_YOUR_PROJECTS]: 'احصل على المساعدة لمشاريعك ، وشارك اكتشافاتك ، واعرض ما لديك قبل وبعد.',
  [START_A_DISCUSSION]: 'ابدأ نقاشك',
  [LATEST]: 'الأحدث',
  [FEATURED]: 'الأكثر تميزًا',
  [POPULAR]: 'الشائعة',
  [UNANSWERED]: 'غير مردود عليها',
  [POSTED_BY]: 'تم نشره بواسطة',
  [YOUR_POSTS]: 'منشوراتك',
  [YOUR_COMMENTS]: 'تعليقاتك',
  [YOUR_TOPICS]: 'مواضيعك',
  [EXPLORE_DISCUSSIONS]: 'استكشف النقاشات',
  [EXPLORE_ALL_DISCUSSIONS]: 'استكشف جميع النقاشات',
  [FEATURED_HOME_DISCUSSIONS]: 'نقاشات المنازل المميزة',
  [FEATURED_GARDEN_DISCUSSIONS]: 'نقاشات الحدائق المميزة',
  [TOP_COMMENTERS]: 'أهم المعلقين',
  [TODAY]: 'اليوم',
  [WEEK]: 'أسبوع',
  [MONTH]: 'شهر',
  [PREV]: 'السابق',
  [NEXT]: 'التالي',
  [UPDATED_AT]: 'أخر تحديث ',
  [HI]: 'أهلًا،',
  [POST]: 'منشور',
  [POSTS]: 'منشورات',
  [POST_0]: 'منشورات',
  [POST_1]: 'منشور واحد',
  [POST_2]: 'منشوران',
  [POST_3]: '{{count}} منشورات',
  [POST_4]: '{{count}} منشورًا',
  [ADD_PHOTO_FOR_BETTER_ANSWERS]: 'قم بإضافة صورة للحصول على إجابات أفضل',
  [POLL_OPTIONS]: 'خيارات الاستطلاع  ',
  [YOU_CAN_ENTER_TEXT_LINK_PRODUCT_OR_UPLOAD_AN_IMAGE]:
    'يمكنك إدخال نص أو رابط إلى صورة أو منتج من منزلك أو تحميل صورة.  ',
  [ENTER_TEXT_OR_PASTE_MANZILIK_LINK]: 'أدخل نصًا أو رابط من منزلك',
  [OPTION]: 'خيار',
  [ADD_OPTION]: 'أضف خيارًا',
  [ADD_POLL]: 'إضافة استطلاع',
  [CHOOSE_TOPIC]: 'اختر موضوعًا',
  [ADD_POST]: 'انشر',
  [ADD_LINK]: 'إضافة رابط',
  [ADD_VIDEO]: 'إضافة فيديو',
  [TEXT]: 'النص',
  [LINK]: 'الرابط',
  [SORTED_BY]: 'تصنيف حسب:',
  [OLDEST]: 'الأقدم',
  [RELATED_DISCUSSIONS]: 'نقاشات لها علاقة',
  [MORE_DISCUSSIONS]: 'نقاشات أخرى',
  [FEATURED_ANSWER]: 'الجواب الأفضل',
  [VOTE]: 'تصويت',
  [COMMENTS]: 'التعليقات',
  [COMMENT]: 'تعليق',
  [COMMENT_0]: 'تعليقات',
  [COMMENT_1]: 'تعليق',
  [COMMENT_2]: 'تعليقان',
  [COMMENT_3]: 'تعليقات',
  [COMMENT_4]: 'تعليقات',
  [REMOVE_POLL]: 'حذف استطلاع',
  [POLL_MUST_HAVE_TWO]: 'يجب أن يحتوي الاستطلاع على ٢ خيارات كحد أدنى',
  [TITLE_AND_DESC_NEEDS_TO_BE_15]: 'يجب أن يحتوي العنوان والوصف على ١٥ حرف كحد أدنى',
  [TITLE_INVALID_CHARS]: 'يحتوي العنوان على رموز غير صالحة مثال ( )',
  [WELCOME_TO_MANZILIK_ADVICE]: 'أهلاً بك في نصائح منزلك',
  [SEE_DISCUSSION_FROM_DESIGNERS_AND_EXPERT]: 'تابع النقاشات من مصممين وخبراء',
  [GET_STARTED]: 'ابدأ الآن',
  [FOLLOW_AT_LEAST_ONE_TOPIC]: 'اختر موضوعاً واحداً على الأقل',
  [FIND_HOME_AND_GARDEN_TOPICS]: 'ابحث عن مواضيع متعلقة بالمنزل والحديقة',
  [WHAT_ARE_YOUR_INTERESTS]: 'ما هي اهتماماتك؟',
  [NO_COMMENTS]: 'لا يوجد تعليقات',
  [REPORT_SPAM]: 'إبلاغ عن سبام',

  // --------------------------------- magazine page ----------------------------
  [FULL_STORY]: 'القصة كاملة',
  [STORIES_FROM_MANZILIK]: 'قصص من منزلك',
  [LATEST_FROM_MANZILIK]: 'آخر القصص',
  [WATCH_NOW]: 'شاهد الآن',
  [MOST_POPULAR]: 'الأكثر شيوعًا',
  [NEW_THIS_WEEK]: 'جديد هذا الأسبوع',
  [MANZILIK_TOURS]: 'جولات منزلك',
  [MANZILIK_TV]: 'تلفاز منزلك',
  [PREV_PAGE]: 'الصفحة السابقة',
  [NEXT_PAGE]: 'الصفحة التالية',
  [NEW_TO_MANZILIK]: 'جديد على منزلك؟',
  [READ_RELATED_STORIES]: 'المزيد من القصص ذات العلاقة',
  [READ_THE_FULL_STORY]: 'أكمل القراءة',
  [SEE_MORE_COMMENTS]: 'عرض {{number}} تعليق آخر ',
  [MANZILIK_DISCUSSIONS]: 'نقاشات منزلك',

  // --------------------------------- magazine details page ----------------------------
  [PEOPLE_WHO_LIKED_THIS_STORY]: 'أشخاص أعجبتهم هذه القصة أعجبهم  أيضًا',
  [MORE_ON_MANZILIK]: 'المزيد في منزلك',
  [EXPLORE_RELATED_TOPICS]: 'تصفح مواضيع لها علاقة',
  [PRINT]: 'طباعة',
  [EMBED]: 'تضمين',
  [READ_MORE]: 'قراءة المزيد',
  [SHOW_LESS]: 'عرض أقل',
  [LIKE]: 'إعجاب',
  [LIKES]: 'إعجاب',
  [LIKE_0]: 'إعجاب',
  [LIKE_1]: 'إعجاب',
  [LIKE_2]: 'إعجابان',
  [LIKE_3]: 'إعجاب',
  [LIKE_4]: 'إعجاب',
  [SAVE_DISCUSSION]: 'حفظ نقاش',
  [SAVED_SUCCESSFULLY]: 'تم الحفظ بنجاح',
  [SAUDI_ARABIA]: 'السعودية',
  [PHOTO]: 'صورة',
  [PHOTO_0]: '{{count}} صور',
  [PHOTO_1]: 'صورة',
  [PHOTO_2]: 'صورتان',
  [PHOTO_3]: '{{count}} صور',
  [PHOTO_4]: '{{count}} صورة',
  [DISCUSSION]: 'نقاش',
  [DISCUSSION_0]: '{{count}} نقاشات',
  [DISCUSSION_1]: '{{count}} نقاش',
  [DISCUSSION_2]: 'نقاشان',
  [DISCUSSION_3]: '{{count}} نقاشات',
  [DISCUSSION_4]: '{{count}} نقاش',
  [COMMENT_IS_EMPTY]: 'التعليق فارغ',
  [IDEAS]: 'افكار',
  [MAGAZINE]: 'مجلة',
  [TV]: 'تلفاز',
  [IDEA_TAG]: 'فكرة',
  [NEW_VIDEO_ON_MANZILIK]: 'حلقات جديدة على منزلك',
  [FIND_PROFESSIONAL]: 'بحث عن محترف',
  [FIND_PRODUCTS]: 'بحث في منتجات',
  [PROFESSIONAL_ROOM]: 'الأقسام',
  [REVIEW]: 'تقييم',
  [REVIEWS]: 'التقييمات',
  [REVIEW_0]: 'لا تقييمات',
  [REVIEW_1]: '{{count}} تقييم',
  [REVIEW_2]: 'تقييمان',
  [REVIEW_3]: '{{count}} تقييمات',
  [REVIEW_4]: '{{count}} تقييم',
  [NO_REVIEWS]: 'لا يوجد تقييمات',
  [PAGE_404]: 'الصفحة غير موجودة',
  [BACK_HOME]: 'العودة للرئيسية',
  [THERE_ARE_NO_DATA]: 'لا يوجد نتائج',
  [EMBED_A_WIDGET]: 'قم بتضمين الودجت في موقعك الخاص أو مدونتك',
  // Complete profile
  [CATEGORIES]: 'التصنيفات',
  [SERVICES]: 'الخدمات',
  [MAX_SIZE_FILE]: 'يجب ألا يتعدى حجم الملف ٢٠ ميجا كحد أقصى',
  [UNDER_REVIEW]: 'الحساب تحت التقييم',
  [COMPLETE_PROFILE]: 'اكمال الملف الشخصي',
  [PROFESSIONAL]: 'محترف',
  [HOMEOWNER]: 'صاحب منزل',
  [SELECT_LOCATION]: 'اختر مدينة',
  [SELECT_CATEGORY]: 'اختر تصنيفًا',
  [SELECT_SERVICE]: 'اختر خدمة',
  [PHONE_NUMBER]: 'رقم الموبايل',
  [HOW_DO_YOU_RATE_THIS_PROFESSIONAL]: 'كيف تقيم هذا المحترف؟',
  [DO_YOU_WANT_TO_SHARE_MORE]: 'هل تريد اضافة المزيد من التفاصيل؟',
  [WRITE_REVIEW]: 'اكتب تقييمًا',
  [SEND_MESSAGE]: 'أرسل رسالة',
  [CONTACT_THIS_PRO]: 'تواصل مع هذا المحترف',
  [NAME]: 'الاسم',
  [MOBILE_NUMBER]: 'رقم الجوال',
  [PROJECT_ZIP_CODE]: 'الرمز البريدي',
  [SAVE_YOUR_MESSAGE]: 'احفظ رسائلك لحفظ الوقت، تواصل مع محترفين أكثر بسهولة',
  [TELL_YOUR_PROFESSIONAL]: 'أخبر هذا المحترف بما يدور في عقلك عن مشروعك',
  [FOR]: 'لـ',
  [ACTIVATE_MY_ACCOUNT]: 'حفظ وتفعيل الحساب',
  [ADDRESS]: 'العنوان',
  [PROF_PROFILE_REVIEW_NOTE]:
    'من أجل تقديم أفضل خدمة ، يرجى تضمين البريد الإلكتروني المرتبط بحساب Manzilik الخاص بك. نسعى جاهدين للرد على جميع الاستفسارات في غضون 1-3 أيام عمل.',
  [PLEASE_FILL_REQUIRED_FIELDS]: 'الرجاء إكمال الحقول الفارغة',
  [LOAD_NEXT_PHOTOS]: 'صور أخرى',
  [EDIT_REVIEW]: 'تعديل التقييم الخاص بك',
  [PLEASE_ENTER_VALID_PHONE_NUMBER]: 'الرجاء إدخال رقم هاتف صحيح',
  [PLEASE_ENTER_VALID_PHONE_NUMBER_EX]: 'الرجاء إدخال رقم هاتف صحيح, مثال: 12345678+',
  [PROFESSIONALS]: 'محترفين',
  [BE_FIRST_TO_COMMENT]: 'كن أول المعلقين',
  [SPAM_DONE]: 'تم الإبلاغ بنجاح',
  [ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO]: 'هل أنت متأكد من حذف هذه الصورة؟',
  [ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VIDEO]: 'هل أنت متأكد من حذف هذا الفيديو',
  [MORE_REVIEWS]: 'أخرى',
  [MORE_REVIEWS_0]: 'تقييمات أخرى',
  [MORE_REVIEWS_1]: 'َتقييم آخر',
  [MORE_REVIEWS_2]: 'تقييمات أخرى',
  [MORE_REVIEWS_3]: 'تقييم آخر',
  [MORE_REVIEWS_4]: 'تقييم أخر',
  [PERSONAL_INFORMATION]: 'المعلومات الشخصية',
  [PHOTOS_GALLERY]: 'ألبوم الصور',
  [VIDEOS]: 'الفديوهات',
  [REGION]: 'المنطقة',

  // -------------------------- cognito messages ------------------------------------

  [USER_DOESNT_EXIST]: 'المستخدم غير موجود',
  [INCORRECT_USERNAME_OR_PASSWORD]: 'البريد الالكتروني أو كلمة المرور غير صحيحة',
  [USER_EXIST]: 'هذا الحساب موجود بالفعل',
  [INVALID_CODE]: 'رمز التأكيد غير صحيح، يرجى المحاولة مرة أخرى',
  [USER_NOT_FOUND]: 'البريد الالكتروني غير موجود',
  [SOMETHING_WENT_WRONG]: 'حدث خطأ ما',
  [CAPTCHA_IS_MISSING]: 'فشلت عملية التحقق من أنك لست روبوت',

  // ---------------------- auto complete --------------------------------
  [USER_NOT_CONFIRMED]: 'لم يتم تأكيد المستخدم',
  [MUST_RESET_PASSWORD]: 'عليك تغيير كلمة المرور',

  // ------------------------- save modal -----------------------------

  [SAVE_MAGAZINE]: 'حفظ مجلة',
  [SAVE_TV]: 'حفظ تلفاز',
  [MAGAZINES]: 'مجلة',
  [DISCUSSIONS_SEARCH]: 'نقاشات',
  [TVS]: 'تلفاز',
  [IN]: 'في',

  // ----------------------- Departments Page ----------------------
  [PRODUCT]: 'منتج',
  [PRODUCTS]: 'منتجات',
  [EXPLORE_PRODUCTS]: 'استعرض المنتجات',
  [EXPLORE_MORE_FAV_PRODUCTS]: 'استكشف المزيد من المنتجات المفضلة',
  [BUY_ALL]: 'قم بشراء جميع',
  [FURNITURE]: 'أثاث',
  [SHOPPING]: 'تسوق',
  [ALL_PRODUCTS]: 'جميع المنتجات',
  // ----------------------- departments details page
  [HIGHTEST_PRICE]: 'الأعلى سعراً',
  [LOWEST_PRICE]: 'الأقل سعراً',
  [HIGHEST_RATE]: 'الأعلى تقييماً',
  [LOWEST_RATE]: 'الأقل تقييماً',
  [EXTRA_COLORS]: 'المزيد من الألوان',
  [EXTRA_SIZES]: 'المزيد من الأحجام',
  [SHOW_ALL_RESULTS]: 'استعراض جميع النتائج',

  //---------------------- product page --------------------------
  [ADD_TO_CART]: 'أضف إلى السلة',
  [ITEMS_LEFT]: 'قطع متبقية فقط',
  [READY_TO_SHIP_IN]: 'جاهز للشخن خلال',
  [DAYS]: 'أيام',
  [DAY]: 'يوم',
  [PRODUCT_DESCRIPTION]: 'وصف المنتج',
  [PRODUCT_SPECIFICATION]: 'مواصفات المنتج',
  [PRODUCT_SHIPPING]: 'الشحن والإرجاع',
  [QUANTITY]: 'الكمية',
  [ADD_QUANTITY]: 'اضافة كمية',
  [ENTER_NEW_QUANTITY]: 'ادخل كمية جديدة',
  [BE_FIRST_REVIEWER]: 'كن اول المقييمين للخدمات الخاصة بالمحترف وابدا بحجز خدمة الان',
  [NO_BODY_REVIEWED_THIS_PRODUCT]: 'لم يقم أحد بتقييم هذا المنتج بعد!',
  [CUSTOMER_REVIEWS]: 'تقييمات المستخدمين',
  [REVIEW_THIS_PRODUCT]: 'قيم هذا المنتج',
  [EDIT_PRODUCT_REVIEW]: 'تعديل التقييم',
  [GENERAL_REVIEW_MANDATORY]: 'الرجاء إضافة تقييمك',
  [WHAT_IS_YOUR_REVIEW_FOR_PRODUCT]: 'ما هو تقييمك العام للمنتج؟',
  [TRANSFER_YOUR_EXPERIENCE_OF_USING_THE_PRODUCT]: 'اُنْقُلْ تجربتك في استخدام المنتج وساعد الآخرين في اتخاذ القرار',
  [REASONABLE_PRICE]: 'السعر مناسب',
  [PRODUCT_MATCHES_DESCRIPTION]: 'مطابقة المنتج للوصف',
  [PRODUCT_QUALITY]: 'جودة المنتج',
  [SHIPPING_SPEED]: 'سرعة الشحن',
  [ADD_PRODUCT_IMAGES]: 'أضف صورًا للمنتج',
  [ADD_PRODUCT_COMMENT]: 'أكتب تعليقك على المنتج',
  [ADD_YOUR_COMMENT_HERE]: 'أكتب تعليقك هنا...',
  [PRODUCT_NUMBER]: 'رقم المنتج',
  [MANUFACTORY]: 'الشركة المصنعة',
  [SOLD_BY]: 'تُباع بواسطة',
  [SIZE_OR_WEIGHT]: 'الحجم / الوزن',
  [CATEGORY]: 'التصنيف',
  [MANZILIK]: 'منزلك',
  [BOUGHT_THIS_PRODUCT]: 'قام بشراء هذا المنتج',
  [COMMENT_LIKE]: 'أعجبني',
  [DID_THIS_COMMENT_HELP_YOU]: 'هل ساعدك هذا التعليق؟',
  [YOU_HAVE_TO_LOGIN_FOR_REVIEW]: 'يجب عليك تسجيل الدخول لتقييم هذا المنتج!',
  [GO_TO_LOGIN]: 'تسجيل الدخول',
  [CONTACT_US]: 'تواصل معنا',
  [FREQUENTLY_ASKED_QUESTIONS]: 'أسئلة شائعة',
  [HOW_CAN_WE_HELP]: 'كيف يمكننا مساعدتك',
  [FULL_NAME]: 'الاسم كاملاً',
  [EMAIL_HERE]: 'البريد الالكتروني هنا',
  [WRITE_FULL_NAME]: 'كتابة الاسم بالكامل',
  [MESSAGE_CONTENT]: 'نص الرسالة',
  [MESSAGE_CONTENT_HERE]: 'نص الرسالة هنا',
  [LETTER]: 'حرف',
  [SEND_NOW]: 'أرسل الأن',
  [HOME_PAGE]: 'الرئيسية',
  [REQUEST_SUCCESSFULLY_RECEIVED]: 'تم استلام طلبك بنجاح',
  [REQUEST_WILL_BE_FOLLOWED_UP]: 'سيتم متابعة طلبك باقرب وقت ممكن',
  [REVIEW_PRODUCT]: 'تقييم المنتج',
  [REVIEWED_PRODUCT_SUCCESSFULLY]: 'تم تقييم المنتج بنجاح',
  // -------------------------- who we are ------------------------------------
  [WHO_WE_ARE]: 'من نحن',
  //------------------------- Main Page ----------------------------//
  [MAIN]: 'الرئيسية',
  [MOST_RECENT]: 'يتم عرض اخر الحالات في موقع منزلك',
  [SHOW_ALL]: 'استعراض الكل',

  [SIGN_IN_NOW]: 'سجل دخول الآن',
  [SUBSCRIBE_TO_NEWSLETTER]: 'الاشتراك في القائمة البريدية الخاصة بمنزلك',
  [MUST_AGREE_TERMS]: 'يجب الموافقة على الشروط والأحكام',
  [ARE_YOY_LOOKING_FOR_IDEAS]: 'هل تبحث عن افكار',

  //------------------------- Public Profile ----------------------------//
  [AWARDS]: 'الجوائز',
  [WON]: 'الحاصل عليها',
  [BADGES]: 'البادج',
  [GENERAL_IDEA_ABOUT_ME]: 'فكرة عامة عني',
  [PROFILE_IDEAS]: 'الافكار',
  [UNLIMITED_CREATIVITY_IDEAS]: 'ابداع غير محدود من الافكار الفريدة',
  [QUANTITY_VALIDATION_MESSAGE]: 'القيمة يجب أن تكون أكبر من 0 وأقل من ',
  [FOLLOWING]: 'أتابعهم',
  [FOLLOWERS]: 'متابعون',
  //------------------------- Contact Us Page ----------------------------//
  [NO_IDEAS]: 'لا يوجد افكار مضافة هنا',
  //------------------------- Landing Pages ----------------------------//
  [JOINT_US_NOW]: 'انضم إلينا الآن',
  [WITH_MANZILIK]: 'مع منزلك',
  [INSPIRE_YOURSELF]: 'قم بإلهام نفسك بمخططات مصممة مسبقًا للسرير، الحمام، غرفة المعيشة، الخ',
  [OR_SIGH_UP_WITH]: 'أو التسجيل بواسطة',
  [EQUIPMENT_YOU_APARTMENT]: 'تجهز شقتك وتدور على أفكار جديدة للديكور؟',
  [WE_BRING_PRACTICAL_IDEAS]:
    'في منزلك جئنا لك بمجموعة من الأفكار العملية اللي تساعدك على تغيير ديكور منزلك بأقل التكاليف وأقل مجهود، ومو بس كذا كمان نوفر لك العديد من الافكار',
  [MANY_NEW_DECORATING_IDEAS]: 'العديد من أفكار وتصاميم الديكور الجديدة',
  [WANT_TO_CHANGE_YOUR_HOME_DECORE]: 'ناوي تغير ديكور بيتك ذي السنة وتبغى أفكار جديدة ومبتكــــرة؟',
  [WE_PROVIDE_COMPLETE_FEED_OF_IDEAS]: 'نوفر لك تغذية كاملة للعديد من الأفكار التي يمكنــــــــك الإختيار من بينها',
  [DO_YOU_WANT_TO_EXPAND_CUSTOMER_INCREASE]: 'تبغى توسع قاعدة عملائك وتزيد أرباحـــــــــــك؟',
  [WE_PROVIDE_PLATFORM_TO_DISPLAY_YOUR_BUSINESS]: 'نوفر لك منصة تعرض فيها أعمالك و تساعدك تحصل على عملاء جدد',
  [ARE_YOU_AN_INTERIOR_DESIGN]:
    'هل أنت مصمم ديكور، مصمم داخلي، سبّاك، فني بلاط أو أحدى المهن التي تتعلق بتشطيب وتجهيز المنازل؟',
  [YOUR_GOAL_IS_TO_INCREASE_CLIENT_PROJECTS]: 'هدفك تزيد عملائك ومشاريعك؟',
  [CONFIRMATION_CODE]: 'كود التفعيل',

  // ------------------------- SEO -----------------------------------//
  [HOME_PAGE_SEO_DESCRIPTION]:
    'منزلِك هي منصة الكترونيّة متخصصة لمساعدتك في تجديد وتصميم منزلِك. كيف نقوم بذلك؟ عبر مجموعة مميزة من المتخصصين في مجال الديكور، وبائعي منتجات المنزل، وأصحاب المنازل، والمستأجرين، ومهندسي الديكور الذين يعملون جنبًا إلى جنب لمساعدتك على تصميم منزل أحلامك',
  [DISCUSSIONS_TITLE]: 'نقاش',
  [MAGAZINES_TITLE]: 'مجلة',
  [ABOUT_DESCRIPTION]:
    'منزلِك هي منصة الكترونيّة متخصصة لمساعدتك في تجديد وتصميم منزلِك. كيف نقوم بذلك؟ عبر مجموعة مميزة من المتخصصين في مجال الديكور، وبائعي منتجات المنزل، وأصحاب المنازل، والمستأجرين، ومهندسي الديكور الذين يعملون جنبًا إلى جنب لمساعدتك على تصميم منزل أحلامك',
  [TERMS_DESCRIPTION]:
    'أهلًا بكَ في منزلك. إذا واصلت تصفح واستخدام هذا الموقع فإنك توافق على الامتثال والالتزام بشروط وأحكام الاستخدام التالية. تحكم شروط وأحكام الاستخدام هذه جنبًا إلى جنب مع سياسة خصوصيتنا علاقة منزلِك معك فيما يتعلق باستخدامك لهذا الموقع',
  [PRIVACY_DESCRIPTION]:
    'توضح سياسة الخصوصية هذه طريقة استخدام وحماية منصة منزلِك لأي معلومات تقدمها للمنصة عند استخدامك لها',
  [HOME]: 'الرئيسية',

  //------------------------- Add idea from web --------------//
  [ADD_WEB_PAGE_URL]: 'أضف رابط صفحة الموقع:',
  //------------------------------------- Idea page --------------------------------------//
  [CONTACT_PROFESSIONAL]: 'تواصل',
  [PROJECT]: 'مشروع',
  [RELATED_PROFESSIONALS]: 'المحترفين المشابهين',
  [MY_PROJECTS]: 'مشاريعي',
  [ALL]: 'الكل',
  //----------------------------------Stores -----------------------------//
  [BRANDS]: 'العلامات التجارية',
  [CHOOSE_BRAND_TYPE]: 'تصفية حسب الفئة',
  [FIND_STORE]: 'ابحث عن متجرك المناسب',
  [STYLE_TYPES]: 'انواع الاستايل',
  [HOW_DO_YOU_RATE_THIS_STORE]: 'كيف تقيم هذا المتجر؟',
  [WEBSITE]: 'الموقع الالكتروني',
  //-----------------------------------EXTENDED FOOTER -----------------//
  [THE_TV]: 'التلفزيون',
  [THE_MAGAZINE]: 'المجلة',
  [THE_DISCUSSIONS]: 'النقاشات',
  [PROFESSIONAL_SERVICES]: 'خدمات المحترفين ',
  [BrandType.GLOBAL]: 'عالمي',
  [BrandType.LOCAL]: 'محلي',
  [ROOM_TYPES]: 'انواع الغرف',
  //--------------------------- Partner ------------------------//
  [PROVIDER]: 'المزود',
  [HOW_DO_YOU_RATE_THIS_PARTNER]: 'كيف تقيم هذا المزود؟',
  //------------------------checkout------------------------//
  [SHIPPING_NO_ADDRESS_SELECTED]: 'لم يتم إختيار عنوان للتوصيل',
  [BASKET_NO_ITEMS_ADDED]: 'لم يتم إضافة منتجات للسلة',
  [SUCCESSFULL]: 'تمت العملية بنجاح',
  [PENDING_PAYMENT]: 'جاري التحقق من عملية الدفع',
  [REJECTED]: 'لم تتم العملية بنجاح',
  [THE_PRODUCT]: 'المنتج',
  [PRICE]: 'السعر (ر.س)',
  [OPERATION]: 'العمليات',
  [BASKET]: 'السلة',
  [DELIVERY]: 'التوصيل',
  [PAYMENT]: 'الدفع',
  [PAYMENT_REVIEW]: 'الدفع والمراجعة',
  [SELECT_PAYMENT_METHOD]: 'اختر وسيلة الدفع',
  [SECURE_PAYMENT_LINE]: 'دفع آمن 100%',
  [LAST_STEP_PURCHASE]: 'المرحلة الأخيرة لشراء المنتجات. مرحلة الدفع تبدأ هنا',
  [EDIT_ADDRESS_LINE]: 'عند تغييرك للعنوان سيتم ارجاعك خطوة العناوين',
  [PAY_NOW]: 'إدفع الآن',
  [EDIT_ADDRESS]: 'تعديل العنوان',
  [ORDER_REVIEW]: 'مراجعة',
  [TRACK]: 'تتبع الطلب',
  [FAST_DELIVERY]: 'توصيل سريع',
  [SAFE_GATEWAY]: 'بوابة دفع أمنة',
  [SUPPORT]: 'دعم فني 24/7',
  [CHECK]: 'تحقق',
  [DO_YOU_HAVE_COPOUN]: 'هل لديك كوبون خصم؟',
  [PRICE_BEFORE_TAX]: 'السعر قبل الضريبة',
  [PRICE_AFTER_TAX]: 'الاجمالي بعد الضريبة',
  [TOTAL_TAX]: 'الضريبة المضافة',
  [STEP_CONTINUE]: 'متابعة',
  [SHOPPING_BASKET]: 'سلة المشتريات',
  [ATTR_COLOR]: 'اللون',
  [ATTR_DIMENSIONS]: 'الأبعاد',
  [ENTER_COPON_CODE]: 'أدخل رمز الكوبون',
  [GO_TO_BASKET]: 'الذهاب للسلة',
  [CONTINUE_SHOPPING]: 'الاستمرار بالتسوق',
  [PRODUCT_WAS_ADDED_TO_BASKET]: 'تم اضافة المنتج للسلة بنجاح',
  [CONFIRM_PAYMENT_PROCCESS]: ' تاكيد عملية الدفع',
  [SHOPPING_DATA_NOT_COMPLETED]: 'العملية غير مكتملة',
  [BASKET_IS_EMPTY]: 'السلة فارغة',
  [START_ADDING_PRODUCT_TO_BASKET]: 'ابدا باضافة منتجات للسلة والبدء بعملية الشراء',
  [MANZILIK_STORE]: 'متجر منزلك',
  [SHIPPING_ADDRESSES]: 'عناوين الشحن',
  [NO_ADDED_ADDRESSES]: 'لا يوجد عناوين مضافة',
  [START_SAVING_YOUR_ADDRESS_TO_DELIVER_THE_PRODUCTS]: 'ابدا بحفظ عنوانك ليتم توصيل المنتجات عليه',
  [SAVED_CARDS]: 'البطاقات المحفوظة',
  [ADD_NEW_CARD]: 'اضافة بطاقة جديدة',
  [SAVE_CARD_IN_SAFE_WAY_TO_PAY_QUICKLY_IN_FUTURE]: 'احفظ البطاقة بطريقة آمنة للدفع السريع بالمستقبل',
  [THE_TOTAL_PRICE_HAS_BEEN_PAID_AND_WILL_WORKING_ON_PREPARING_THE_ORDER]:
    'تم خصم المبلغ الاجمالي وسيتم العمل علي تجهيز الطلب يمكنك متابعة حالة الطلب',
  [TRACK_THE_ORDER]: 'تتبع الطلب',
  [REVIEW_PROCESS]: 'مرحلة المراجعة',
  [PREPARE_THE_ORDER]: 'تجهيز الطلب',
  [DELIVERY_PROCESS]: 'مرحلة التوصيل',
  [THE_ORDER_HAS_BEEN_DELIVERED_SUCCESSFULLY]: 'تم تسليم الشحنة بنجاح',
  [MY_ORDERS]: 'طلباتي',
  [CANCEL_THE_ORDER]: 'الغاء الطلب',
  [CLOSE]: 'اغلاق',
  [THE_ORDER_HAS_BEEN_DONE_SUCCESSFULLY]: 'تم الطلب بنجاح',
  [SHOW_CONTACT_INFO]: 'اظهار معلومات التواصل',

  //------------------------my orders------------------------//

  [SEARCH_BY_ORDER_NUMBER]: 'ابحث بـ رقم الطلب',
  [ORDER_DETAILS]: 'تفاصيل الطلب',
  [UNDER_PROCESSING]: 'تحت المراجعة',
  [CANCELED]: 'تم الإلغاء',
  [DELIVERED]: 'تم التوصيل',
  [PENDING]: 'مًعلق',
  [ORDER_NUMBER]: 'رقم الطلب',
  [TOTAL_PRICE]: 'السعر الاجمالي',
  [ORDER_STATUS]: 'الحالة',
  [CANCEL_PRODUCT]: 'الغاء المنتج',
  [CONFIRM_CANCEL_PRODUCT]: 'تاكيد الغاء المنتج',
  [REFUND_REQUEST_DESC]: 'اختيارك من المعلومات التالية سيساعدنا كثيراً، ولن يؤثر على إلغاء الطلب',
  [REFUND_REQUEST_NOTE]: 'توضيح سبب الالغاء',
  [REFUND_REQUEST_SUCCESS]: 'تم الغاء المنتج بنجاح',
  [REFUND_REQUEST_SUCCESS_NOTE]: 'سيتم ارجاع قيمة المنتج الى حسابك خلال 72 ساعة القادمة',
  [ORDER_DATE]: 'تاريخ الطلب',
  [DELIVERY_ADDRESS]: 'عنوان التوصيل',
  [DETAILS]: 'تفاصيل',
  [THE_FOLLOWING_INFO_IS_ONLY_FOR_US_IT_WONT_PREVENT_YOU_FROM_CANCELING_YOUR_ORDER]:
    'المعلومات التالية هي فقط لسجلاتنا و لن يمنعك من إلغاء طلبك.',
  [SHIPPING_PROBLEM]: 'مشكلة بالشحن',
  [OTHER]: 'أخرى',
  [ORDER_IS_DUPLICATED]: 'تكرار الطلب',
  [CHANGE_THE_ORDER]: 'تغير التفكير بالمطلوب',
  [REASON_OF_CANCELING_THE_ORDER]: 'اسباب الغاء الطلب',
  [ADD_DESCRIPTION]: 'اضافة وصف للفكرة',
  [CONFIRM_ORDER_CANCELATION]: 'تاكيد الغاء الطلب',
  [NEW]: 'جديد',
  [SHIPPING_COST]: 'تكلفة الشحن',
  [DISCOUNT_AMOUNT]: 'قيمة الخصم',

  // ----------------------- coming soon --------------------- //
  [STORE_NAME]: 'اسم المتجر',
  [INESTEGRAM_ACCOUNT]: 'حساب الانستجرام',
  [SEND_INFORMATION]: 'ارسل البيانات',
  [ENTER]: 'ادخل',
  [THANK_FOR_YOUR_INTEREST_TO_JOIN_MANZILIK_PLANTFORM]: 'شكرا لك لاهتمامك بالانضمام لمزودي المنتجات في منزلك!',
  [OPERATION_TEAM_WILL_REACH_YOU_SOON]: 'سيتواصل فريقنا معك قريبا لمتابعة اجراءات التسجيل',
  [COMING_SOON]: 'Coming Soon',
  [MANZILIK_STORE_SOON]: 'قريبًا متجر منزلِك',
  [WE_HELP_PEOPLE_TO_ACHEVE_THEIR_GOAL]: 'نساعد التجار وأصحاب المنازل على تحقيق أهدافهم',
  [JOIN_OUR_VENDORS]: 'كن تاجرا في منزلك الآن',
  [EXPLORE_MANZILIK]: 'تصفح منزلك',

  // else
  [VERIFICATION]: 'التوثيق',
  [VERIFIED]: 'موثق',
  [UNVERIFIED]: 'غير موثق',
  [INVALID_CARD_HOLDER]: 'يرجى التحقق من إسم صاحب البطاقة',
  [MISSING_FIRST_NAME]: 'يرجى ادخال الإسم الأول',
  [MISSING_LAST_NAME]: 'يرجى ادخال الإسم الأخير',
  [CARD_NUMBER_MISMATCH_BRAND_TYPE]: 'رقم البطاقة غير مطابق لنوع البطاقة',
  [INVALID_REGISTERATION_REFERENCE]: 'يرجى التحقق من رقم الوثيقة التجارية ',
  [INVALID_FREELANCE_NUMBER]: 'يرجى التحقق من رقم المستند التجاري',
  [NO_NAME]: 'لا يوجد اسم',
  [DOWNLOAD_MANZILIK_APP]: 'تحميل تطبيق منزلِك',
  [DOWNLOAD_MANZILIK_APP_DESC]: 'لتجربة استخدام أفضل',
  [DOWNLOAD]: 'تحميل',
  [SHOP_SEARCH_HEADING]: 'متجر متكامل مع الكثير من خيارات التسوق',
  [SHOP_SEARCH_SUBHEADING]: 'كل ما يحتاجه منزلك في عالم الديكور',
  [SHOP_SEARCH]: 'ابحث عن منتجك',
  [SHOWING_RESULTS_FOR]: 'عرض نتائج البحث : ',
  [MINUTE]: 'دقيقة',
  [MINUTES]: 'دقائق',
  [HOUR]: 'ساعة',
  [HOURS]: 'ساعات',
  [ELAPSED]: 'منذ',
  [MARK_ALL_AS_READ]: 'تمييز الكل كمقرؤ',
  [NO_NOTIFICATIONS]: 'لا يوجد إشعارات',
  [YOU_CAN_BROWSE]: 'يمكنك تصفح',
  [SEND_REQUEST_SERVICE]: 'ارسل طلب خدمة',
  [BADGES_TITLE]: 'البادجات الخاصة بمنزلك',
  [BADGES_ACHIEVED]: 'حصلت عليها ',
  [BADGES_REMINDING]: '  متبقي ',
  [SEND_SERVICE_REQUEST]: 'إرسال طلب خدمة',
  [SEND_SERVICE_REQUEST_NOW]: 'ارسل طلب الخدمة الآن',
  [SERVICE_INFO]: 'تفاصيل الخدمة',
  [SERVICE_DESCRIPTION]: 'وصف الخدمة',
  [BUDGET_EXAMPLE]: 'مثال: ميزانية المشروع 400 - 1000 ر.س',
  [IMAGES]: 'الصور',
  [CONTACT_DETAILS]: 'معلومات التواصل',
  [WHATSAPP_NUMBER]: 'رقم الواتس اب',
  [SELECT_IMAGES_TO_UPLOAD]: 'قم باختيار الصور المراد رفعها',
  [SERVICE_REQUEST_WAS_SENT]: 'تم ارسال طلب الخدمة بنجاح',
  [SERVICE_REQUEST_UNDER_REVIEW]:
    'جاري مراجعة الطلب من المحترف وسيتم الرد عليك بأقرب وقت ممكن. يمكنك مراجعة حالة الطلب',
  [SERVICE_REQUESTS_LIST]: 'قائمة طلبات الخدمة',
  [SERVICE_REQUEST_ERROR]: 'حدث خطأ أثناء ارسال الطلب. يمكنك المحاولة مرة اخرى',
  [RETRY_AGAIN]: 'المحاولة مرة أخرى',
  [SERVICE_REQUESTS]: 'الطلبات',
  [SERVICE_TYPE]: 'نوع الخدمة',
  [HOME_OWNER]: 'صاحب المنزل',
  [ISSUE_DATE]: 'تاريخ طلب الخدمة',
  [ADD_PROJECTS_AND_SERVICES_TO_IMPROVE_YOUR_CHANCE]: 'قم بإضافة مشاريع وخدمات لملفك الشخصي لتحسين عملية البحث عنك',
  [RECIEVED_REQUESTS]: 'الطلبات المستلمة',
  [SENT_REQUESTS]: 'الطلبات المرسلة',
  [ACCEPTED_REQUEST]: 'مقبول',
  [REJECTED_REQUEST]: 'رفض',
  [WAITING_RESPONSE_REQUEST]: 'انتظار التأكيد',
  [DELETED_REQUEST]: 'تم الحذف',
  [PROJECT_DESCRIPTION]: 'وصف المشروع',
  [EXTRA_INFO]: 'تفاصيل اخرى',
  [ACCEPT_REQUEST]: 'قبول الطلب',
  [REJECT]: 'رفض',
  [REQUEST_DETAILS]: 'تفاصيل الطلب',
  [REQUEST_ACCEPTED_CONTACT_OWNER]: 'تم الموافقة على الخدمة يمكنك التواصل مع صاحب المنزل',
  [REJECT_SERVICE_REASONS]: 'اسباب الغاء الخدمة',
  [REQUESTED_SERVICE_NOT_AVAILABLE]: 'الخدمة المطلوبة ليست من ضمن اختصاصي',
  [COULDNT_REACH_AN_AGREEMENT]: 'لم يتم التوصل لإتفاق مع العميل',
  [CLIENT_MISBEHAVIOUR]: 'سلوك العميل لم يكن ملائماً',
  [PLEASE_ADD_REJECTION_DETAILS]: 'الرجاء اضافة تفاصيل السبب الخاص بالإلغاء',
  [CONFIRM_SERVICE_REJECTION]: 'تأكيد الغاء الخدمة',
  [UNDO]: 'تراجع',
  [REQUIRED_INFO_WILL_NOT_PREVENT_REJECTION]: 'المعلومات التالية هي فقط لسجلاتنا ولن يمنعك من إلغاء الطلب',
  [M0]: 'يناير',
  [M1]: 'فبراير',
  [M2]: 'مارس',
  [M3]: 'ابريل',
  [M4]: 'مايو',
  [M5]: 'يونيو',
  [M6]: 'يوليو',
  [M7]: 'اغسطس',
  [M8]: 'سبتمبر',
  [M9]: 'اكتوبر',
  [M10]: 'نوفمبر',
  [M11]: 'ديسمبر',
  [REQUEST_STATUS]: 'حالة الطلب',
  [REQUEST_NUMBER]: 'رقم الطلب',
  [FIND_REQUEST_BY_NUMBER]: 'ابحث عن رقم الطلب',
  [SERVICE_REQUEST_WAS_REJECTED]: 'تم رفض طلب الخدمة بنجاح',
  [YOU_CAN_CONTINUE_VIEWING_SERVICE_REQUEST]: 'يمكنك الاطلاع متابعة الاطلاع على تفاصيل الخدمة',
  [BROWSE_WEBSITE]: ' تصفح الموقع الالكتروني',
  [CAll_NOW]: 'اتصل الان',
  [ERROR_INVALID_TEL_5XXXXXXXX]: 'رقم الجوال مطلوب. مثال 5XXXXXXXX',
  [VERIFIED_FIRST]: 'الموثقين أولاً',
  [JOIN_DATE]: 'المنضمين حديثاً',
  [HIGHER_RATING]: 'الأعلى تقييماً',
  [SELECT_SORT_METHOD]: 'اختار نوع الترتيب',
  [RESULTS]: 'النتائج',
  [LANG]: 'اللغة',
  [VIEW_AS]: 'عرض كما يظهر للآخرين',
  [ENTER_FULL_NAME]: 'قم بإدخال الاسم بالكامل',
  [NAME_VERIFICATION_STEP]: 'الخطوة الحالية سيتم من خلالها تأكيد الاسم بالكامل',
  [SAVE_AND_CONTINUE]: 'حفظ واستمرار',
  [PLEASE_ENTER_VALID_FIRST_NAME]: 'يرجى ادخال اﻹسم الأول',
  [PLEASE_ENTER_VALID_LAST_NAME]: 'يرجى ادخال اﻹسم الأخير',
  [PLEASE_SELECT_COUNTRY]: 'يرجى اختيار الدولة',
  [PLEASE_SELECT_CITY]: 'يرجى اختيار المدينة',
  [MANAGE_GIGS]: 'إدارة خدماتي',
  [ADD_NEW_SERVICE]: 'إضافة خدمة جديدة',
  [NO_FAST_SERVICES]: 'لا يوجد خدمات سريعة',
  [START_NOW_GIGS]: 'ابدا الان باضافة خدمة سريعة للبدا في استقبال الطلبات من اصحاب المنازل',
  [IMAGE]: 'صوره',
  [SERVICE_PRICE]: 'سعر الخدمة',
  [SERVICE_LOCATION]: 'موقع الخدمة',
  [SERVICE_STATUS]: 'حالة الخدمة',
  [MAIN_SERVICE]: 'الخدمة الرئيسية',
  [SERVICE_TITLE]: 'عنوان الخدمة',
  [ACTIVATED]: 'مفعله',
  [NOT_AVAILABLE]: 'غير متاحه',
  [SET_AS_MAIN]: 'تعيين كغلاف',
  [SERVICE_DETAILS]: 'تفاصيل الخدمة',
  [REQUEST_SERVICE]: 'طلب خدمه',
  [ADD_SERVICE]: 'اضافة الخدمه',
  [EDIT_SERVICE]: 'تحديث الخدمة',
  [BACK]: 'رجوع',
  [SELECT_SERVICE_TYPE]: 'اختر نوع الخدمة',
  [SERVICE_ADDRESS]: 'عنوان الخدمة',
  [SERVICE_DESC_ONE]: 'معلومات تفصيلية عن الخدمة',
  [SERVICE_DESC_TWO]: 'هنا يتم كتابة التفاصيل الاضافية للخدمة',
  [SERVICE_CITIES]: ' مدن تغطية الخدمة',
  [SAVE_SERVICE]: 'حفظ الخدمة',
  [AVAILABLE]: 'متاح',
  [PLEASE_ENTER_VALID_YOUTUBE_LINK]: 'الرجاء ادخال رابط صحيح لليوتيوب',
  [KNOW_YOUR_NEEDS]: ' تفهم كافة احتياجاتك',
  [KNOW_YOUR_NEEDS2]: 'مهما كان مجال العمل يمكنك الاعتماد على منزلك ودعمها لمختلف التخصصات',
  [DO_YOU_NEED_HELP]: 'هل تريد المساعدة بخدمة اخرى .؟',
  [FIND_A_PRO]: 'ابحث عن محترف',
  [SELECT_CITY]: 'حدد المدينة',
  [SEARCH_CITY]: 'ابحث عن المدينة',
  [CONFIRM_CITY]: 'تأكيد المدينة',
  [PROVIDE_YOU_HELP]: 'نقدم لك خدمة المساعدة الفورية بخدمات اخرى غير المتوفر .. ابدا الان رحلة مختلفة مع منزلك',
  [PROVIDE_YOU_HELP2]: 'نقدم لك خدمة المساعدة الفورية بخدمات اخرى غير المتوفر ..',
  [VIEW_MORE]: 'عرض المزيد',
  [VIEW_LESS]: 'عرض اقل',
  [RATING]: 'التقييم',
  [SIMILAR_SERVICES]: 'الخدمات المشابهة',
  [AVAILABLE_IN]: 'متوفر فقط في',
  [UNREAD_MESSAGES]: 'رسالة غير مقروءة',
  [START_CONTACT]: 'ابدا التواصل',
  [CONTACT_NOW]: 'تواصل الان',
  [VIEW_CHAT]: 'عرض المحادثة',
  [MORE_INFO]: 'تفاصيل اكثر',
  [CONNECT_WITH_SERVICE_PROVIDER]: 'تواصل مع مقدم الخدمة',
  [COVERED_CITIES]: 'مدن تغطية الخدمة',
  [SERVICES_PACKAGE]: 'باقة الخدمات',
  [PROVIDE_SERVICES_PACKAGE]: 'يقدم باقة خدمات ',
  [REQUEST_SERVICE_NOW]: 'اطلب خدمة خاصة',
  [TELEPHONE_NUMBER]: 'رقم الهاتف',
  [DISAPPEAR]: 'اخفاء',
  [VIEW]: 'اظهار',
  [REQUEST_SERVICE_INFO]: 'معلومات طلب الخدمة',
  [ADDITIONAL_INFO]: 'معلومات اضافية',
  [SELECT_CITY_FIRST]: 'حتى تتمكن من تقديم طلب الخدمة يجب تحديد المدينة',
  [NO_GIGS]: 'لا يوجد خدمات',
  [GIGS]: 'خدمات',
  [EXAMPLE_LICENSE]: 'مثال : FL-1234567890',
  [REQUEST_COMPLETED]: 'اكتملت الخدمة',
  [CANCEL_REQUEST]: 'الغاء الخدمه',
  [SERVICE_PROVIDER]: 'مقدم الخدمة',
  [COMPLETE_BTN]: 'استكمال',
  [COMPLETE_NOTIFICATION]:
    'مرحبا بك في منزلك. لتتمكن من استخدام ميزات مقدمي الخدمة يجب عليك اكمال ملفك الاحترافي أولا. يمكنك اكماله من هنا.',
  [RELATED_GIGS]: 'خدمات ذات صلة',
  [COMPLETED_REQUEST]: 'مكتمله',
  [CANCELLED_REQUEST]: 'تم الالغاء',
  [THANKS_FOR_COMPLETE]: 'شكراً لإتمامك الخدمة',
  [MANZILIK_AI_TAB]: 'منزلك AI',
  [DESIGN_YOUR_HOME_NOW]: 'صمم بيتك الآن',
  [USE_AI_TO_DESIGN_YOUR_HOME]: 'استخدام الذكاء الصناعي في انشاء تصميمك الداخلي بعدة خيارات تناسب ذوقك',
  [START_THE_EXPERIENCE_NOW]: 'ابدا تجربة تصميم استثنائية الآن',
  [USE_AI_TO_DESIGN_YOUR_HOME2]: 'استخدام الذكاء الصناعي في انشاء تصميمك الداخلي',
  [ADD]: 'اضافة',
  [POINT]: 'نقطة',
  [NEWLY_PUBLISHED]: 'نشر حديثاً',
  [MY_DESIGNS]: 'تصاميمي',
  [WE_DO_THE_WORK]: 'ابدا رحلتك معنا الان ',
  [STUCK]: 'لا تعرف من اين تبداء؟ ارفع صوره لمساحتك و اختار نوع الغرفه و نمط التصميم و استمتع برحله تصميم داخلي مميزه',
  [UPLOAD_PHOTO_TO_START]: 'قم برفع صورة مساحتك لتبداء تجربة تصميم مميزه',
  [START_UPLOAD_PHOTO]: '  ارفع الصوره هنا',
  [HOW_AI_WORKS]: 'كيف يعمل منزلك AI',
  [THE_STYLE]: 'نمط التصميم',
  [ROOM_TYPE]: 'نوع الغرفة',
  [MORE_DETAILS]: 'المزيد من التفاصيل',
  [PRIVACY]: 'الخصوصية',
  [EXPORT_SPEED]: 'سرعة التصدير',
  [START_NOW]: 'ابدا الآن',
  [YOUR_CREDIT_NOT_ENOUGH]: 'رصيدك غير كافي قم بشراء نقاط اضافية',
  [ADD_CREDITS]: 'اضافة نقاط',
  [PRIVACY_TOOLTIP]: 'يمكنك تحديد حالة الخصوصية للتصميم الخاص بك',
  [PRIVATE_TEXT]: 'خاص',
  [PUBLIC_TEXT]: 'عام',
  [TWO_POINTS]: '(نقطتين)',
  [TWO_POINTS_NOR_PAID]: '(نقطتين- نقاط مدفوعة)',
  [FAST]: 'سريع',
  [SLOW]: 'بطيء',
  [ONE_POINT]: '(1 نقطة)',
  [FREE]: '(مجانا)',
  [SHARE_AI]: 'مشاركة',
  [NEED_DESIGNER]: 'تحتاج مصمم داخلي؟',
  [NEED_DESIGNER_TEXT]: ' اطلب عروض أسعار من مصممينا لتحول تصميمك إلى واقع بأفضل سعر',
  [SEND_TO_MANZILIK]: 'أنشر طلب عرض سعر',
  [WHY_MANZILIK]: 'لماذا منزلك AI',
  [WHY_MANZILIK_TEXT]: 'نسخّر الذكاء الاصطناعي لمساعدتك خلال رحلة تصميم منزلك',
  [SAVE_TIME_EFFORT]: 'توفير الوقت والجهد',
  [SAVE_TIME_EFFORT_TEXT]:
    'كل ما عليك هو رفع صورة للمساحة التي ترغب في تصميمها و الانتظار قليلًا للحصول على تصاميم أنيقة و عصرية',
  [IMPROVE_DESIGN]: 'رحلة تصميم داخلي أكثر متعة ',
  [IMPROVE_DESIGN_TEXT]:
    'مجموعة من الخطوات البسيطة و احصل على تصاميم مذهلة. لست بحاجه إلى بذل أي مجهود لتصميم منزل أحلامك!',
  [PREDICT_FUTURE]: 'تصميم منزلك بنفسك !',
  [PREDICT_FUTURE_TEXT]: 'استمتع بالحصول على تصورات متنوعة لتصميم مساحتك',
  [CUSTOMIZE_DESIGN]: 'تصاميم مخصصة لك',
  [CUSTOMIZE_DESIGN_TEXT]:
    'يقدم لك الذكاء الاصطناعي تصاميم مخصصة لمساحتك بحسب نوع الغرفة و نمط التصميم الذي تختاره و يلائم تفضيلاتك',
  [ADD_ACCOUNT_POINTS]: 'اضافة نقاط لحسابي',
  [ALL_OPTIONS_AVAILABLE]: 'جميع المميزات متاحة لك',
  [DOWNLOAD_IMAGES]: 'تحميل الصور بجودة عالية',
  [CREATE_DESIGNS]: 'انشاء التصميم بسرعة عالية',
  [REMOVE_WATER_MARK]: 'ازالة العلامة المائية من التصميم',
  [A_LOT_OF_DESIGNS]: 'العديد من التصاميم بخصوصية تامة',
  [MOST_REQUESTED]: 'الاكثر طلباً',
  [GET_IT_NOW]: 'احصل عليها الآن',
  [BEFORE]: 'قبل',
  [AFTER]: 'بعد',
  [SMART_DESIGN]: 'هل أعجبك التصميم الذكي؟',
  [SELECT_IMAGE]: 'اختر الصورة المناسبة',
  [CONTINUE_BTN]: 'احفظ اختياري',
  [ORIGINAL]: 'الاصليه',
  [EXPERIMENTAL]: 'تجريبي',
  [THANKS_FOR_RATE]: 'شكرا لك على تقييم التصميم',
  [TOTAL_PAYMENT]: ' الاجمالي/شامل الضريبة المضافة',
  [START_SMART_DESIGN]: 'ابدا تصميم ذكي الآن',
  [TYR_ANOTHER_TIME]: 'حاول مرة اخرى',
  [PAYMENT_FAILED]: 'فشلت عملية الدفع',
  [DESIGN_IN_PROGRESS]: 'جاري تجهيز التصميم الخاص بك ',
  [ALL_PRICES]: 'جميع اسعار الباقات شاملة ضريبة القيمة المضافة',
  [RIGHT_DESCRIPTION]: 'ما هو الوصف المناسب لك؟',
  [SPECIFY_ACCOUNT_TYPE]: 'اختر نوع حسابك',
  [CONTINUE_HOME_OWNER]: 'الاستمرار كصاحب منزل',
  [CONTINUE_PROFESSIONAL]: 'الاستمرار كمقدم خدمة',
  [CLIENT_TYPE_TEXT]: `يمكنك الإطلاع على أفكار متعددة للتصميم الداخلي والخارجي وطلب خدمة من أحد مقدمي الخدمة لتجعل منزلك الأبهى والأجمل
  الاستمرار كصاحب منزل`,
  [PROF_TYPE_TEXT]: `يمكنك الإستفادة من جميع ما يميز صاحب المنزل بالإضافة إلى قدرتك على نشر أعمالك واستقبالك لطلبات خدمة من العملاء والتواصل معهم
  الاستمرار كمقدم خدمة`,
  [SERVICE_PROVIDER2]: 'مقدم خدمة',
  [INVITE_FRIENDS]: 'ادع أصدقاءك لتجربة منزلك AI',
  [GET_POINTS]: 'واحصل على 5 نقاط لكل دعوة',
  [COPY_DONE]: 'تم النسخ',
  [SHARE_LINK]: 'قم بمشاركة الرابط مع اصدقائك',
  [REGISTER_FOR_MANZILIK]: 'سجل في منزلك',
  [TRY_AI]: 'AI جرب منزلك ',
  [GOT_POINTS]: 'حصلت علي 5 نقاط بنجاح',
  [SHARE_WITH_FRIENDS]: 'شارك الرابط الخاص بك مع اصدقائك',
  [WE_NEED_YOUR_PHONE]: 'نحتاج رقم هاتفك للتواصل معك',
  [AI_ERROR_B104]:
    'لا يمكنك جعل تصاميمك "خاصة" إلا بعد شراء نقاط من منزلك AI. تسمح لك النقاط المجانية بالحصول على تصاميم "عامة" يسمح بنشرها على المنصة.',
  [DELETE_DESIGN]: 'حذف التصميم',
  [SURE_DELETE]: 'هل أنت متأكد من حذف التصميم؟',
  [CONFIRM_DELETE]: 'حذففي حال تاكيدك لعملية الحذف سيتم ازالتها من تصاميمك الخاصة ولا يمكن ارجاعها',
  [REQUEST_FOR_QUOTATION]: 'طلب عرض سعر',
  [REQUEST_FOR_QUOTATION_TEXT]: 'مهما كان مجال العمل يمكنك الاعتماد على منزلك ودعمها لمختلف التخصصات',
  [POST_A_QUOTE]: 'انشر عرض سعر',
  [POST_NOW]: 'انشر الآن',
  [POST_A_QUOTE_TEXT]: 'قم بتقديم المعلومات الكافية لعملك للحصول علي عروض اسعار',
  [CREATE_NEW_PROJECT]:
    'قم بإنشاء مشروع جديد. حدد اسم المشروع ووصف مفصل لما تحتاجه. كلما كان وصف المشروع أكثر وضوحًا، كلما كان من الأسهل للمحترفين فهم متطلباتك.',
  [POST_IT_NOW]: 'نشرها الآن',
  [PRICE_QUOTE_SEND_SUCCESS]: 'تم ارسال اضافة عرض السعر بنجاح ',
  [PRICE_QUOTE_SEND_SUCCESS_TEXT]: 'الآن طلبك وصل للمحترفين المرتبطين بالخدمة المطلوبة وسيتم تقديم العروض باقرب وقت',
  [PRICE_QUOTE_DETAILS]: 'تفاصيل عرض السعر',
  [CREATE_A_PROJECT]: 'إنشاء مشروع',
  [CREATE_A_PROJECT_TEXT]:
    'بمجرد إنشاء حسابك، قم بإنشاء مشروع جديد. حدد اسم المشروع ووصف مفصل لما تحتاجه. كلما كان وصف المشروع أكثر وضوحًا، كلما كان من الأسهل المحترفين فهم متطلباتك.',
  [DETERMINE_THE_REQUIRED_SKILLS]: 'تحديد المهارات المطلوبة ',
  [DETERMINE_THE_REQUIRED_SKILLS_TEXT]:
    'في القسم المخصص للمهارات المطلوبة، حدد المهارات التي يجب أن يكون بها المستقل الذي تبحث عنه. هذا سيساعد في تحديد الفئة المناسبة لمشروعك وجلب المستقلين الذين يمتلكون تلك المهارات.',
  [DETERMINE_THE_BUDGET]: 'تحديد الميزانية',
  [DETERMINE_THE_BUDGET_TEXT]:
    'حدد الميزانية المتاحة لمشروعك. يمكنك تحديد ميزانية ثابتة أو تقديم نطاقٍ تقريبي للميزانية إذا لم تكن متأكدًا بالضبط.',
  [PUBLISH_THE_PROJECT]: 'نشر المشروع',
  [PUBLISH_THE_PROJECT_TEXT]:
    ' بمجرد إكمال تفاصيل المشروع، قم بنشره على المنصة. سيكون المحترفين قادرين على رؤية مشروعك وتقديم عروضهم.',
  [REVIEW_OFFERS]: 'مراجعة العروض',
  [REVIEW_OFFERS_TEXT]:
    'بمجرد تقديم المحترفين لعروضهم، قم بمراجعتها وتقييمها بناءً على المهارات والخبرات والأسعار التي قدموها.',
  [DISCUSS_DETAILS]: 'مناقشة التفاصيل',
  [DISCUSS_DETAILS_TEXT]:
    'اختر المحترف الذي يبدو مناسبًا لمشروعك وقم بالتواصل معه لمناقشة التفاصيل بشكل أعمق. يمكنك تحديد متطلباتك بدقة والتوصل إلى اتفاق نهائي قبل بدء التنفيذ.',
  [CONTRACTING]: 'التعاقد',
  [CONTRACTING_TEXT]:
    'بعد مناقشة التفاصيل، قد يكون من الضروري توقيع اتفاقية أو عقد تفاهم يحدد جميع التفاصيل المتعلقة بالمشروع بما في ذلك المهام والميزانية والجداول الزمنية.',
  [FOLLOW_THE_PROGRESS]: 'متابعة التقدم',
  [FOLLOW_THE_PROGRESS_TEXT]:
    'تابع تقدم المحترف في تنفيذ المشروع وتأكد من تلبية توقعاتك. قد تحتاج إلى مناقشة أي تعديلات أو تغييرات أثناء عملية التنفيذ.',
  [SUBMIT_A_PRICE_REQUEST]: 'تقديم علي طلب سعر',
  [ACCURACY]: 'تطابق التصميم السابق',
  [WALLS]: 'الحوائط',
  [FLOOR]: 'الأرضيات',
  [CEILING]: 'الأسقف',
  [COLOR_PALETS]: 'قائمة الالوان',
  [DESIGN_DESC]: 'وصف التصميم باستخدام AI ',
  [AI_INPUT_PLACEHOLDER]: 'تخصيص عملية الوصف اكثر “سرير زوجي مع خزانة خشبية”',
  [ADDITIONAL_OPTIONS]: 'خصائص اضافية لتصميمك',
  [NOT_SELECTED]: 'غير محدد',
  [QUOTATION_DETAILS]: 'تفاصيل العرض',
  [APPLY_TO_QUOTATIONS]: 'المتقدمين لعرض السعر',
  [QUOTATIONS]: 'عروض الاسعار',
  [OPENED_REQUEST]: 'مفتوح',
  [CLOSED_REQUEST]: ' مغلق',
  [DURATION]: 'مدة التنفيذ',
  [REQUESTS_WORKS_ON]: 'خدمات اعمل عليها',
  [QUOTATIONS_REQUESTS]: ' عروض الاسعار',
  [CONTRACTED_REQUEST]: 'تم العاقد',
  [BUSINESS_YOU_LIKE]: 'اعمال قد تعجبك',
  [BROWSE_JOBS]: 'تصفح الوظائف التي تتوافق مع خبرتك وتفضيلات التوظيف الخاصة بالعميل. مرتبة حسب الأكثر صلة.',
  [APPLICANTS]: 'المتقدمين',
  [PHOTOS_ATTACHED]: 'صور مرفقة',
  [MAKE_AN_OFFER]: 'قدم علي العرض  ',
  [OFFER_PRICE]: 'سعر العرض',
  [PROJECT_DURATION]: ' مدة تنفيذ المشروع',
  [MESSAGE_TO_SERVICE_REQUESTER]: 'رسالة لطالب الخدمة',
  [ATTACH_YOUR_WORK]: 'قم بارفاق اعمالك السابقة',
  [UPLOAD_YOUR_WORK]: 'قم برفع اعمالك',
  [ATTACH_UP_TO]:
    'يمكنك إرفاق ما يصل إلى 5 ملفات بحجم أقل من 10 ميغابايت لكل منها. قم بتضمين عينات العمل أو المستندات الأخرى لدعم طلبك.',
  [TO_SUBMIT_RFQ]: 'لا يمكنك التقديم على هذا الطلب لانه ليس من ضمن انواع الخدمات التي تقدمها حاليا',
  [UPGRADE_SERVICES]: 'تحديث الخدمات',
  [THE_TYPE]: 'النوع',
  [QUOTATION_REQUEST]: ' عرض سعر',
  [ARE_U_SURE_TO_SUBMIT_QUOTATION]: 'هل انت متأكد من ارسال عرض السعر',
  [QUOTATION_LAST_STEP]: 'الخطوة الاخيرة لارسال عرض السعر',
  [CONFIRM_QUOTATION]: 'تأكيد  ',
  [QUOTATION_SENT]: 'تم ارسال عرض سعر ',
  [MONTHS]: 'شهر',
  [YEARS]: 'سنه',
  [START_TRIP]: 'ابدا رحلة تقديم الخدمات',
  [YOU_CAN_APPLY]: 'يمكنك الان تقديم خدمات جاهزة من خلال ادارة الخدمات التي تقدمها منزلك',
  [PRESS_MY_ACCOUNT]: 'اضغط على حسابي',
  [SERVICE_DETAILS_CONT]: 'تفاصيل الخدمة .. وتابع طلباتك',
  [TYR_TO_ADD_SERVICE]: 'قم باضافة خدمة من خلال الضغط على حسابي',
  [THEN_CLICK_GIGS_MANAGEMENT]: 'من ثم الضغط علي ادارة الخدمات من هنا',
  [THEN_CLICK_ADD_GIG]: 'من ثم الضغط علي اضافه الخدمات من هنا',
  [TO_CONTINUE_FOLLOW_DETAILS]: 'حتى تتمكن من متابعة تفاصيل الحقل اتبع التعليمات',
  [YOUR_REVIEW]: 'تقييمك للخدمة',
  [REVIEW_SERVICE]: ' قم بتقييم الخدمة',
  [REVIEW_SERVICE_TEXT]: 'تقييم الخدمة الخاصة بـ',
  [START_RATING]: 'ابدا التقييم',
  [ADD_RATE]: 'اضف التقييم',
  [PRICE_NOT_ADDED]: 'السعر غير محدد من العميل',
  [ORDER_ACCEPTED]: 'الطلب الحالي مقبول قم بمتابعة طلبك',
  [CONTACT_CS]: 'تواصل مع خدمة العملاء',
  [DESC_ABOUT_ME]: 'وصف عني',
  [NO_BADGES]: 'لا يوجد بادجات',
  [HOW_TO_GET]: 'كيف تحصل؟',
  [HOW_TO_GET_BAGDES]: 'علي بادج في منصة منزلك',
  [HEADING_HOME_CLIENT1]: 'منزلك .. خدمات منزلية متكاملة',
  [HEADING_HOME_CLIENT2]:
    'استكشف العديد من الخدمات المميزة في منزلك واستمتع بمنزل العمر من خلال العديد من الافكار والمقترحات والخدمات المميزة من محترفينا',
  [START_SEARCH]: 'ابدا البحث في منزلك',
  [EXPLORE_YOUR_REQUESTS]: 'قم بتصفح الاعمال الخاصة بك التي تعمل عليها او قدمت عليها مسبقاً',
  [NO_PROJECTS]: 'لا يوجد مشاريع',
  [ADD_NEW_PROJECTS]: 'قم باضافة اول مشروع خاصة بك يساعدك علي الحصول علي العديد من الاعمال',
  [NEXT_STEP]: 'الخطوة التالية',
  [SERVICE_FORM]: 'نموذج الخدمة',
  [PAY_SERVICE_VALUE]: 'دفع قيمة الخدمة',
  [VAT_INCLUDED]: 'السعر شامل ضريبة القيمة المضافة',
  [WILL_ADDED]: 'سيضاف',
  [FIVE_POINTS]: '5 نقاط مجانية',
  [TO_USE_IN_AI]: ' لتستخدمها للذكاء الاصطناعي',
  [INCLUDED_FEES]: 'تشمل دفع تكلفة الخدمة',
  [REFUND2]: 'في حال عدم حصولك على أكثر من عرض خلال 72 ساعة سوف يتم استرداد المبلغ لحسابك.',
  [ACTIVE_REPUBLISH]: 'تفعيل ونشر الطلب',
  [YOUR_MANZILIK_ORDER]: 'طلبك على منزلك!',
  [CONGRATS]: 'تهانينا لقد حصلت على كوبون خصم بقيمة 100% لتكلفة طلبك مقدم من منزلك!',
  [ARE_YOU_SURE_DELETE_ORDER]: 'هل أنت متأكد من رغبتك بإلغاء الطلب؟',
  [ACCEPTED_OFFER]: 'العرض المقبول',
  [CANCELED_OFFER]: 'العرض لاغي',
  [NOW_CAN_COMPLETE_CONTRACT]: 'الآن يمكنك إتمام عملية التعاقد',
  [ORDER_CANCELED]: 'تم إلغاء التعاقد. بإمكانك إعادة نشر الطلب مرة أخرى من خلال الضغط على زر إعادة نشر الطلب بالأسفل.',
  [REFUND_MESSAGE]: 'سوف يتم إرجاء مبلغ الطلب لحسابك بعد مرور 72 ساعة',
  [CONFIRM_CANCEL_ORDER]: 'تأكيد إلغاء الطلب',
  [MESSAGES]: 'رسائل',
  [OFFER_ACCEPTED]: ' قبول العرض',
  [YOU_CAN_PUBLISH]: 'يمكنك نشر طلب خدمة جديد يتوافق مع ما نُشر في هذه الخدمة، وتحديد بعض التعديلات كما تشاء.',
  [RE_PUBLISH]: '  إعادة نشر الطلب من جديد',
  [NO_QUOTATIONS]: 'لا يوجد متقدمين حتى هذه اللحظة!',
  [DRAFT_REQUEST]: 'غير مكتمل',
  [DESCRIBE_SERVICE]: 'اوصف طلب الخدمة',
  [DESCRIPTION_HELPS_YOU]: 'الوصف يساعد فريق منزلك لايجاد افضل المحترفين و تقدميهم لك',
  [DESCRIPTION_EXAMPLE]: 'مثال: اريد تصميم مشابه لغرفة مساحتها ٥ * ٤ متر طراز حديث',
  [REGISTER_NOW]: 'سجل الآن لتجرب المزيد من مزايا منزلك',
  [WE_NEED_YOUR_PHONE_TO_ASSIST]: 'لنتمكن من مساعدتك في مشكلة الدفع يرجى تزويدنا برقم هاتفك المحمول أدناه',
  [CONTINUE_PAYMENT]: 'استكمال عملية الدفع',
  [CONTACT_SUPPORT]: 'تواصل مع الدعم الفني',
  [SHOW_CLOSED_COMPLETED_REQUESTS]: 'عرض الطلبات المنتهية والملغاه',
  [INPROGRESS_REQUESTS]: 'طلبات قيد التنفيذ',
  [NEW_REQUESTS]: 'طلبات جديدة',
  [COMPLETED_CANCELED_REQUESTS]: 'طلبات منتهية / ملغاه',
  [APPLICANT]: 'متقدم',
  [SOME_GUIDES]: 'بعض الإرشادات لكتابة جمل تأتي بنتائج مبهرة!',
  [AI_GUIDE_1]: 'التفضيل للكتابة باللغة الإنجليزية للحصول على نتائج أكثر دقة.',
  [AI_GUIDE_2]: 'نقدم لك سلسلة من الأسئلة. إجابات هذه الأسئلة ستساعد في صياغة موضوع جيد:',
  [AI_GUIDE_3]: 'نوع الأثاث / أسلوب الأثاث:',
  [AI_GUIDE_4]: 'ما لون الأثاث؟',
  [AI_GUIDE_5]: 'ما لون الجدران؟',
  [AI_GUIDE_6]: 'ما هو نوع الأرضيات (مثلاً: باركيه، سيراميك، إيبوكسي)؟',
  [AI_GUIDE_7]: 'طلب أي عناصر خاصة في الغرفة (مثال: مدفأة، حامل، حامل ضوء، أشجار صناعية، طاولة قهوة).',
  [ATTACHED_PHOTOS]: 'الصور المرفقة',
  [GUIDES]: 'ارشادات',
  [SMART_TEXT_AREA]: 'نص الدعم الذكي (متقدم)',
  [SMART_GENERATION]: 'توليد ذكي',
  [RE_GENERATE]: 'اعادة توليد',
  [SELECT_STYLE]: 'اختر الأسلوب',
  [SELECT_ROOM_TYPE]: 'اختر نوع الغرفة',
  [RE_SELECT]: 'إعادة التخصيص',
  [ADV_GENERATE_TOOLTIP]: 'بإمكانك التوليد بطريقة كتابة فقرة تفصيلية للذكاء الإصطناعي لتحصل على نتائج أكثر دقة',
  [NOT_EXIST]: 'لا يوجد',
  [CLASSIC_VIEW]: 'المظهر الكلاسيكي للحمام مع خزائن الحوض',
  [BACK_HISTORY]: 'عوده',
  [CLASSIFY_STORES]: 'تصنيف المتاجر',
  [FIND_VENDOR]: 'البحث عن بائع',
  [SIMILAR_PRODUCTS]: 'منتجات مشابهة',
  [ANALYZE_PRODUCTS]: 'جاري تحليل المنتجات ومطابقتها مع  منتجات قريبة منك!',
  [COUNTRY_CITY]: 'الدولة و المدينة',
  [MANAGE_IDEAS]: 'ادارة الصور',
  [MANAGE_VIDEOS]: 'ادارة الفيديوهات',
  [MANAGE_REQUESTS]: 'ادارة الطلبات',
  [SHARE_PROFILE]: 'شارك ',
  [SHARE_PROFILE_WITH_PEOPLE]: 'شارك رابط ملفك مع الأصدقاء لزيادة عدد متابعينك',
  [TRENDING]: 'شائع',
  [JOBS]: 'الوظائف',
  [APPLIED_FOR_JOB]: 'لقد تقدمت لهذه الوظيفة',
  [BE_FIRST_ONE]: 'كن أول المتقدمين ',
  [APPLY]: 'تقدم',
  [NO_PRODUCTS_EXIST]: 'لا يوجد منتجات مشابهة حالياً',
  [VIEW_SELLERS]: 'عرض البائعين',
  [VIEW_PRODUCTS]: 'عرض المنتجات',
  [PRESS_TO_VIEW_PRODUCTS]: 'إضغط لعرض منتجات أكثر مبيعاً',
  [VIEW_SELLERS_PRODUCTS]: 'عرض منتجات جميع البائعين',
  [VIEW_SELLERS_PRODUCTS_WITHIN_SEARCH]: 'عرض جميع البائعين ضمن النتائج',
  [BEST_SELLING_PRODUCTS]: 'المنتجات الأكثر مبيعاً',
  [WILL_DEDUCT]: 'سيتم خصم',
  [POINTS_FROM_BALANCE]: ' نقاط من رصيدك',
  [BEST_SELLERS_IN_KINGDOM]: 'بائعين مميزين في المملكة',
  [INCOMPLETE_RFQ]:
    ' لديك طلب عرض سعر غير مكتمل. أنشره الآن لتحصل على أفضل سعر لتنفيذ تصميمك المطلوب وأفضل مقدم للخدمة من اختيارك',
};
