import React, { CSSProperties } from 'react';

interface SvgIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const MessageIcon: React.FC<SvgIconProps> = ({ width = 19, height = 18, color = '#BDB39A', style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 18" fill="none" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 7.10605C0.5 4.86584 0.5 3.74574 0.93597 2.89009C1.31947 2.13744 1.93139 1.52552 2.68404 1.14202C3.53969 0.706055 4.65979 0.706055 6.9 0.706055H12.1C14.3402 0.706055 15.4603 0.706055 16.316 1.14202C17.0686 1.52552 17.6805 2.13744 18.064 2.89009C18.5 3.74574 18.5 4.86584 18.5 7.10605V8.30606C18.5 10.5463 18.5 11.6664 18.064 12.5221C17.6805 13.2747 17.0686 13.8866 16.316 14.2701C15.4603 14.7061 14.3402 14.7061 12.1 14.7061H4.91421C4.649 14.7061 4.39464 14.8115 4.20711 14.999L2.20711 16.999C1.57714 17.629 0.5 17.1828 0.5 16.2919V14.7061V9.70605V7.10605ZM6.5 4.70605C5.94772 4.70605 5.5 5.15377 5.5 5.70605C5.5 6.25833 5.94772 6.70605 6.5 6.70605H12.5C13.0523 6.70605 13.5 6.25833 13.5 5.70605C13.5 5.15377 13.0523 4.70605 12.5 4.70605H6.5ZM6.5 8.70605C5.94772 8.70605 5.5 9.15376 5.5 9.70605C5.5 10.2584 5.94772 10.7061 6.5 10.7061H9.5C10.0523 10.7061 10.5 10.2584 10.5 9.70605C10.5 9.15376 10.0523 8.70605 9.5 8.70605H6.5Z"
        fill={color}
      />
    </svg>
  );
};

export default MessageIcon;
