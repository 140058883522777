import { BrandType } from '../pages/stores/types';
import {
  AGREE_TO_TERMS,
  CANCEL,
  CHOOSE_SPACE,
  CONFIRM,
  CONTINUE_WITH_FACEBOOK,
  CONTINUE_WITH_GOOGLE,
  CREATE,
  CREATE_PROJECT,
  EMAIL,
  EMAIL_REQUIRED_MESSAGE,
  ENTER_PROJECT_NAME,
  FIND_IDEAS,
  HOME_PAGE_HEADING,
  JOKER,
  LOADING,
  LOGIN,
  OPTIONAL,
  OR,
  PASSWORD,
  PASSWORD_REQUIRED_MESSAGE,
  PRIVATE,
  PROJECT_NAME,
  REGEX_DOESNT_MATCH_MSG,
  RESEND_CONFIRMATION,
  SIGNUP,
  SIGNUP_WITH,
  SIGNUP_WITH_EMAIL,
  LOGIN_BY_EMAIL,
  USERNAME,
  FORGET_PASSWORD,
  KEEP_SIGNED_IN,
  SIGNIN,
  SIGN_IN_POLICY,
  COMMON_QUESTIONS,
  COMMON_QUESTION_1,
  COMMON_QUESTION_2,
  COMMON_QUESTION_3,
  COMMON_QUESTION_4,
  COMMON_QUESTION_5,
  COMMON_QUESTION_6,
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_GOOGLE,
  LOGIN_BY_SOCIAL_MEDIA,
  MANZIL_LOGIN,
  FORGET_YOUR_PASSWORD,
  FORGET_PASSWORD_DESCRIPTION,
  HELLO,
  CONTINUE,
  RE_ENTER_PASSWORD,
  SUBMIT,
  NEW_PASSWORD_TERMS,
  PASSWORD_DONT_MATCH,
  CREATE_NEW_PASSWORD,
  CODE_MESSAGE,
  CODE_TEXT,
  RESET_PASSWORD_SUCCESS_MSG,
  INVITE_FRIEND,
  FILTER_BY,
  MY_IDEAS,
  MORE_IDEAS_FROM_MANZEL,
  ADD_NOTE,
  COPY,
  REMOVE,
  MOVE,
  ENTER_EMAIL_RECIPIENTS,
  MESSAGE_SUBJECT,
  MESSAGE,
  MESSAGE_INPUT_PLACE_HOLDER,
  MESSAGE_IS_SENT,
  SEND_BY_EMAIL,
  LINK_COPIED,
  UPLOAD_TERMS,
  UPLOAD_PHOTO,
  SAVE_FROM_WEB,
  UPLOAD,
  ADD_PHOTO,
  EDIT_PROJECT_INFO,
  TITLE,
  DESCRIPTION,
  EDIT,
  WRITE_DESCRIPTION,
  DISCUSSIONS,
  PHOTOS,
  ARABIC,
  GET_IDEAS,
  NOTIFICATIONS,
  PROJECTS,
  FOOTER_CREDITS,
  COMPANY,
  ABOUT_MANZEL,
  PRIVACY_AND_POLICY,
  TERMS_AND_CONDITIONS,
  COOKIE_POLICY,
  GET_HELP,
  GET_INSPIRED,
  MANZEL_SUPPORT,
  CONTACT,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  YOUTUBE,
  RSS,
  CONNECT_WITH_US,
  DROP_FILE_HERE,
  YES,
  NO,
  ARE_YOU_SURE_DELETE_MSG,
  UPDATE,
  VIEW_PROFILE,
  VIEW_ALL,
  LOGOUT,
  TO,
  IMPORT_GMAIL_CONTACT,
  ACTIVITY,
  DELETE,
  BACK_TO_LOGIN,
  SEND,
  MOVE_IDEA,
  ARE_YOU_SURE_DELETE_IDEA,
  INVITE,
  SAVE_SETTINGS,
  CAN_EDIT,
  CAN_VIEW,
  WHO_HAS_ACCESS,
  CURRENTLY_SHARE_WITH,
  INVITE_BY_EMAIL_OR_NAME,
  INVITE_BY_EMAIL_OR_NAME_PLACEHOLDER,
  DELETE_FRIEND,
  YOU,
  SELECT_ALL,
  ORGANIZE,
  CHANGE_TO_GALLERY_VIEW,
  ADD_NEW_IDEA,
  EXPORT,
  EDIT_PROFILE,
  ACCOUNT,
  ACCOUNT_INFORMATION,
  PROFILE_INFO,
  CONTACT_INFO,
  SOCIAL_MEDIA_SETTINGS,
  ADVANCE_SETTINGS,
  PRIVACY_SETTINGS,
  PROFILE_INFORMATION,
  FIRST_NAME,
  LAST_NAME,
  PUBLICLY_DISPLAYED,
  ABOUT_ME,
  MY_FAVORITE_STYLE as MY_FAVORITE_STYLE,
  MY_NEXT_MANZILIK_PROJECT,
  USER_NAME,
  BY,
  PREVIEW_YOUR,
  PROCEED_TO,
  PROFILE_DETAILS_UPDATED_SUCCESSFULLY,
  PUBLIC_PROFILE,
  YOUR_MANZILIK_PROJECTS,
  YOU_CAN_ONLY_UPLOAD_JPG,
  OLD_PASSWORD,
  NEW_PASSWORD,
  CONTACT_INFORMATION,
  COUNTRY,
  CITY,
  STATE,
  ZIP,
  SAVED_TO,
  SAVE,
  OTHER_PHOTO_IN,
  SEE_MORE_PHOTOS,
  SAVE_IDEA,
  SELECT_PROJECT,
  WHAT_DO_YOU_LIKE_ABOUT_THIS_IDEA,
  FOLLOW,
  WRITE_COMMENT,
  LINKEDIN,
  WEBSITE_OR_BLOG,
  CLICK_OR_DRAG_DROP_FILES_HERE_TO_UPLOAD,
  DRAG_THE_CORNER_OF_THE_BOX_TO_CHANGE_POSITION_AND_SIZE_OR_UPLOAD_A_NEW_IMAGE,
  YOU_CAN_UPLOAD_A_JPG_GIF_OR_PNG_FILE,
  YOUR_PROFILE_PHOTO,
  ADDRESS_BOOK,
  DELETE_MY_ADDRESS_BOOK_CONTACT,
  DISCLOSE_MY_INFO_FOR_COMMERCIAL_PURPOSES,
  DOWNLOAD_A_COPY_OF_YOUR_DATA,
  MANAGE_YOUR_EMAIL_SETTINGS,
  MARKETING_AND_EMAIL_COMMUNICATIONS,
  ADVANCED_SETTINGS,
  NEWSLETTER_EDITION,
  ALLOW_COMMENTS_ON_MY_PUBLIC_PHOTOS,
  ALLOW_USERS_TO_FOLLOW_MY_PROFILE,
  CLIMATE_ZONE_FOR_GARDEN_FORUMS,
  CUSTOMIZE_MY_EMAIL_SUBSCRIPTIONS_BELOW,
  DEACTIVATE_ACCOUNT,
  DEFAULT_PHOTO_ORDER_IN_MY_IDEABOOKS,
  EMAIL_PREFERENCES,
  EMAIL_SUBSCRIPTIONS,
  ENABLE_ADD_TO_IDEABOOK_CONFIRMATION,
  EVERY_TIME,
  MANZILIK_MAGAZINE_HOME_TOURS,
  MANZILIK_REFERRAL_COUPONS,
  MANZILIK_SHOP_TRENDING_PRODUCTS_AND_SALES,
  MANZILIK_TV_SURVEYS_AND_OTHER_UPDATES,
  MANZILIK_UPDATES_NEAR_ME,
  MY_DISCUSSIONS,
  MY_GARDEN_WEB_TRADING_LIST,
  MY_PROFILE_PAGES,
  MY_PUBLIC_IDEABOOKS,
  MY_SOCIAL_MEDIA_PROFILES,
  MY_UPLOADED_PHOTOS_AND_PROJECTS,
  NOTE_IF_THIS_IS_UNCHECKED,
  NOTIFICATION_CENTER,
  PEOPLE_I_FOLLOW,
  PEOPLE_WHO_FOLLOW_ME,
  PERSONALIZED_RECOMMENDATIONS,
  PRODUCT_ALERTS_PRICE_DROPS,
  PUBLIC_PROFILE_PAGE,
  RECEIVE_ONLY_EMAILS_ABOUT_NEW_FOLLOWERS,
  SEND_ME_AN_EMAIL_IF,
  SHOW_ACTIVITIES_FROM_USERS_I_AM_FOLLOWING,
  SOMEONE_COMMENTS_ON_MY_IDEABOOK,
  SOMEONE_COMMENTS_ON_MY_POST,
  SOMEONE_FOLLOWS_ME,
  SOMEONE_MESSAGES_ME,
  THERE_IS_AN_ISSUE_WITH_MY_ACCOUNT_OR_LOGIN,
  THE_FOLLOWING_WILL_BE_VISIBLE_ON_MY_PUBLIC_PROFILE,
  TIPS_GUIDES_AND_NEW_FEATURE_ANNOUNCEMENTS,
  USER_ACTIVITY_NOTIFICATIONS,
  WHO_CAN_MESSAGE_ME,
  IT_MAY_TAKE_UP_TO_3_DAYS_FOR_US_TO_FULLY_PROCESS,
  SEND_ME_SMS_FROM_MANZILIK_WITH_TIPS,
  SMS_OFFERS,
  UNDER_CALIFORNIA_LAW_LEARN_MORE_HERE,
  YOUR_PRIVACY_SETTINGS,
  QUESTION_ABOUT_THIS_PHOTO,
  SHOW,
  MORE_QUESTIONS,
  MORE_COMMENTS,
  ASK_QUESTION,
  I_HAVE_ANOTHER_QUESTION,
  ASK_QUESTION_ABOUT_THIS_PHOTO,
  TELL_US_THE_DETAILS,
  YOUR_QUESTION,
  OK,
  PEOPLE_WHO_LIKE_THIS,
  PLEASE_WRITE_VALID_EMAILS,
  ARE_YOU_SURE_DELETE_COMMENT_MSG,
  SEARCH,
  IN_ALL_PHOTOS,
  ALL_FILTERS,
  STYLE,
  SIZE,
  COLOR,
  PREVIOUS_PAGE,
  LIVING_ROOM,
  KITCHEN_IN_PHOTOS,
  BATHROOM_IN_PHOTOS,
  SEND_EMAIL,
  REFINE_BY,
  SORT_BY,
  OF,
  LOCATION,
  RELEVANCE,
  PUBLIC,
  DONE,
  CLEAR_ALL,
  SELECTED,
  BUDGET,
  TYPE,
  LIMIT_1_PER_HOUR_POST,
  LIMIT_1_PER_DAY,
  LIMIT_2_PER_DAY,
  LIMIT_3_PER_HOUR_POST,
  LIMIT_5_PER_DAY,
  NEVER,
  DISPLAY_NEWS_FIRST,
  DISPLAY_OLDEST_FIRST,
  ALLOW_ALL_USERS_TO_MESSAGE_ME,
  ALLOW_USERS_I_FOLLOW_TO_MESSAGE_ME,
  DONT_ALLOW_USERS_TO_MESSAGE_ME,
  EXPLORE_ALL_DISCUSSIONS,
  EXPLORE_DISCUSSIONS,
  FEATURED,
  FEATURED_GARDEN_DISCUSSIONS,
  FEATURED_HOME_DISCUSSIONS,
  GET_HELP_FOR_YOUR_PROJECTS,
  LATEST,
  MONTH,
  NEXT,
  POPULAR,
  POSTED_BY,
  PREV,
  START_A_DISCUSSION,
  TODAY,
  TOP_COMMENTERS,
  UNANSWERED,
  WEEK,
  WHAT_ARE_YOU_WORKING_ON,
  YOUR_COMMENTS,
  YOUR_POSTS,
  YOUR_TOPICS,
  UPDATED_AT,
  HI,
  POST,
  ADD_PHOTO_FOR_BETTER_ANSWERS,
  ENTER_TEXT_OR_PASTE_MANZILIK_LINK,
  OPTION,
  POLL_OPTIONS,
  YOU_CAN_ENTER_TEXT_LINK_PRODUCT_OR_UPLOAD_AN_IMAGE,
  ADD_OPTION,
  ADD_POLL,
  CHOOSE_TOPIC,
  ADD_POST,
  ADD_LINK,
  LINK,
  TEXT,
  ADD_VIDEO,
  RELATED_DISCUSSIONS,
  OLDEST,
  SORTED_BY,
  FEATURED_ANSWER,
  MORE_DISCUSSIONS,
  VOTE,
  COMMENTS,
  POLL_MUST_HAVE_TWO,
  REMOVE_POLL,
  TITLE_AND_DESC_NEEDS_TO_BE_15,
  WELCOME_TO_MANZILIK_ADVICE,
  SEE_DISCUSSION_FROM_DESIGNERS_AND_EXPERT,
  GET_STARTED,
  FIND_HOME_AND_GARDEN_TOPICS,
  FOLLOW_AT_LEAST_ONE_TOPIC,
  WHAT_ARE_YOUR_INTERESTS,
  FULL_STORY,
  LATEST_FROM_MANZILIK,
  MANZILIK_TOURS,
  MANZILIK_TV,
  MOST_POPULAR,
  NEW_THIS_WEEK,
  STORIES_FROM_MANZILIK,
  WATCH_NOW,
  NEXT_PAGE,
  PREV_PAGE,
  NEW_TO_MANZILIK,
  READ_RELATED_STORIES,
  READ_THE_FULL_STORY,
  SEE_MORE_COMMENTS,
  PEOPLE_WHO_LIKED_THIS_STORY,
  MORE_ON_MANZILIK,
  EXPLORE_RELATED_TOPICS,
  PRINT,
  EMBED,
  READ_MORE,
  SHOW_LESS,
  REQUIRED,
  LIKE,
  SAVE_DISCUSSION,
  SAVED_SUCCESSFULLY,
  SAUDI_ARABIA,
  DISCUSSION,
  PHOTO,
  NO_COMMENTS,
  COMMENT_IS_EMPTY,
  REPORT_SPAM,
  IDEAS,
  MAGAZINE,
  TV,
  IDEA,
  NEW_VIDEO_ON_MANZILIK,
  FIND_PROFESSIONAL,
  FIND_PRODUCTS,
  SEND_MESSAGE,
  PROFESSIONAL_ROOM,
  REVIEW,
  PAGE_404,
  BACK_HOME,
  THERE_ARE_NO_DATA,
  EMBED_A_WIDGET,
  CATEGORIES,
  COMPLETE_PROFILE,
  HOMEOWNER,
  MAX_SIZE_FILE,
  PHONE_NUMBER,
  PROFESSIONAL,
  SELECT_CATEGORY,
  SELECT_LOCATION,
  SELECT_SERVICE,
  SERVICES,
  UNDER_REVIEW,
  HOW_DO_YOU_RATE_THIS_PROFESSIONAL,
  DO_YOU_WANT_TO_SHARE_MORE,
  WRITE_REVIEW,
  REVIEWS,
  CONTACT_THIS_PRO,
  NAME,
  MOBILE_NUMBER,
  PROJECT_ZIP_CODE,
  SAVE_YOUR_MESSAGE,
  TELL_YOUR_PROFESSIONAL,
  FOR,
  ACTIVATE_MY_ACCOUNT,
  ADDRESS,
  PROF_PROFILE_REVIEW_NOTE,
  UPLOAD_PHOTOS,
  PLEASE_FILL_REQUIRED_FIELDS,
  LOAD_NEXT_PHOTOS,
  EDIT_REVIEW,
  PLEASE_ENTER_VALID_PHONE_NUMBER,
  PROFESSIONALS,
  NO_REVIEWS,
  BE_FIRST_TO_COMMENT,
  SPAM_DONE,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO,
  POSTS,
  IDEA_TAG,
  LIKES,
  COMMENT,
  CONFIRM_EMAIL,
  CONFIRM_CODE_TITLE,
  USER_DOESNT_EXIST,
  INCORRECT_USERNAME_OR_PASSWORD,
  USER_EXIST,
  INVALID_CODE,
  USER_NOT_FOUND,
  MORE_REVIEWS,
  SAVE_MAGAZINE,
  SAVE_TV,
  MORE_REVIEWS_PLURAL,
  SOMETHING_WENT_WRONG,
  MAGAZINES,
  TVS,
  IN,
  DISCUSSIONS_SEARCH,
  SHOPPING,
  ALL_PRODUCTS,
  PRODUCT,
  PRODUCTS,
  EXPLORE_PRODUCTS,
  BUY_ALL,
  FURNITURE,
  HIGHTEST_PRICE,
  HIGHEST_RATE,
  LOWEST_RATE,
  LOWEST_PRICE,
  EXTRA_COLORS,
  EXTRA_SIZES,
  EXPLORE_MORE_FAV_PRODUCTS,
  SHOW_ALL_RESULTS,
  USER_NOT_CONFIRMED,
  ADD_TO_CART,
  ITEMS_LEFT,
  READY_TO_SHIP_IN,
  DAYS,
  PRODUCT_DESCRIPTION,
  PRODUCT_SPECIFICATION,
  PRODUCT_SHIPPING,
  QUANTITY,
  ADD_QUANTITY,
  ENTER_NEW_QUANTITY,
  BE_FIRST_REVIEWER,
  NO_BODY_REVIEWED_THIS_PRODUCT,
  CUSTOMER_REVIEWS,
  REVIEW_PRODUCT,
  REVIEWED_PRODUCT_SUCCESSFULLY,
  WHAT_IS_YOUR_REVIEW_FOR_PRODUCT,
  TRANSFER_YOUR_EXPERIENCE_OF_USING_THE_PRODUCT,
  REASONABLE_PRICE,
  PRODUCT_MATCHES_DESCRIPTION,
  PRODUCT_QUALITY,
  SHIPPING_SPEED,
  ADD_PRODUCT_IMAGES,
  ADD_PRODUCT_COMMENT,
  ADD_YOUR_COMMENT_HERE,
  PRODUCT_NUMBER,
  MANUFACTORY,
  SOLD_BY,
  SIZE_OR_WEIGHT,
  CATEGORY,
  WHO_WE_ARE,
  DOWNLOAD_APPLICATION,
  FOLLOW_US,
  AVAILABLE_APPLE,
  APP_STORE,
  GET_APPLICATION,
  GOOGLE_PLAY,
  MAIN,
  MOST_RECENT,
  ALL_RIGHTS,
  MANZILIK,
  BOUGHT_THIS_PRODUCT,
  COMMENT_LIKE,
  DID_THIS_COMMENT_HELP_YOU,
  PREVIEW,
  REVIEW_THIS_PRODUCT,
  EDIT_PRODUCT_REVIEW,
  YOU_HAVE_TO_LOGIN_FOR_REVIEW,
  GO_TO_LOGIN,
  SHOW_ALL,
  CONTACT_US,
  FREQUENTLY_ASKED_QUESTIONS,
  HOW_CAN_WE_HELP,
  FULL_NAME,
  EMAIL_HERE,
  WRITE_FULL_NAME,
  MESSAGE_CONTENT,
  MESSAGE_CONTENT_HERE,
  LETTER,
  SEND_NOW,
  HOME_PAGE,
  REQUEST_SUCCESSFULLY_RECEIVED,
  REQUEST_WILL_BE_FOLLOWED_UP,
  AWARDS,
  WON,
  BADGES,
  GENERAL_IDEA_ABOUT_ME,
  PROFILE_IDEAS,
  UNLIMITED_CREATIVITY_IDEAS,
  QUANTITY_VALIDATION_MESSAGE,
  NO_IDEAS,
  MOBILE,
  MAIL,
  HAVE_ACCOUNT,
  SEND_CONFIRMATION_CODE,
  JOINT_US_NOW,
  WITH_MANZILIK,
  INSPIRE_YOURSELF,
  OR_SIGH_UP_WITH,
  EQUIPMENT_YOU_APARTMENT,
  WE_BRING_PRACTICAL_IDEAS,
  MANY_NEW_DECORATING_IDEAS,
  WANT_TO_CHANGE_YOUR_HOME_DECORE,
  WE_PROVIDE_COMPLETE_FEED_OF_IDEAS,
  DO_YOU_WANT_TO_EXPAND_CUSTOMER_INCREASE,
  WE_PROVIDE_PLATFORM_TO_DISPLAY_YOUR_BUSINESS,
  ARE_YOU_AN_INTERIOR_DESIGN,
  YOUR_GOAL_IS_TO_INCREASE_CLIENT_PROJECTS,
  CONFIRMATION_CODE,
  HOME_PAGE_SEO_DESCRIPTION,
  DISCUSSIONS_TITLE,
  MAGAZINES_TITLE,
  ABOUT_DESCRIPTION,
  TERMS_DESCRIPTION,
  PRIVACY_DESCRIPTION,
  HOME,
  ADD_WEB_PAGE_URL,
  UN_FOLLOW,
  FOLLOWING,
  FOLLOWERS,
  RESET_YOUR_PASSWORD,
  SAVE_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  SIGN_IN_NOW,
  QUESTION_SUBMIT,
  SEE_THIS_PROJECT_ON_MANZILIK,
  RECEIVE_EMAILS_ABOUT_MY_FOLLOWERS,
  WEBSITE_NOTIFICATIONS,
  DAILY_NOTIFICATIONS,
  UPDATED_NOTIFICATIONS_SUCCESS,
  SEARCH_IDEAS,
  SEARCH_IDEA,
  CONTACT_PROFESSIONAL,
  PROJECT,
  MUST_RESET_PASSWORD,
  PLEASE_ENTER_VALID_PHONE_NUMBER_EX,
  YOU_NEE_TO_LOGIN,
  RELATED_PROFESSIONALS,
  ARE_YOY_LOOKING_FOR_IDEAS,
  SUBSCRIBE_TO_NEWSLETTER,
  MUST_AGREE_TERMS,
  MY_PROJECTS,
  YOU_DONT_HAVE_EMAIL,
  HOME_PAGE_DESCRIPTION,
  MORE,
  MY_ACCOUNT,
  ABOUT_SHOULD_BE_MORE_THAN_FIFTY_CHARACTER,
  FIELD_SHOULD_BE_URL,
  ALL,
  ENTER_PROJECT_DESCRIPTION,
  ENTER_PROJECT_STATUS,
  ENTER_PROJECT_CATEGORY,
  PUBLISH_STATUS,
  PUBLIC_WORD,
  PRIVATE_WORD,
  CATEGORY_TYPE,
  CAPTCHA_IS_MISSING,
  MANZILIK_DISCUSSIONS,
  PERSONAL_INFORMATION,
  PHOTOS_GALLERY,
  VIDEOS,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VIDEO,
  SEE_MORE_VIDEOS,
  PROFILE_VIDEOS,
  VIDEO_EXAMPLE,
  ADD_YOUTUBE_LINK,
  ADD_VALID_YOUTUBE_LINK,
  MOST_SEARCHED,
  BRANDS,
  CHOOSE_BRAND_TYPE,
  FIND_STORE,
  STYLE_TYPES,
  HOW_DO_YOU_RATE_THIS_STORE,
  WEBSITE,
  THE_TV,
  THE_MAGAZINE,
  THE_DISCUSSIONS,
  ADDRESSES,
  ADD_ADDRESS,
  ADDRESS_TITLE,
  NEIGHBORHOOD,
  ADDRESS_DETAILS,
  UPDATE_ADDRESS_TITLE,
  PROFESSIONAL_SERVICES,
  SHARE,
  ROOM_TYPES,
  SAVE_UPDATES,
  NEW_ADDRESS,
  PROVIDER,
  HOW_DO_YOU_RATE_THIS_PARTNER,
  THE_PRODUCT,
  PRICE,
  OPERATION,
  BASKET,
  DELIVERY,
  PAYMENT,
  ORDER_REVIEW,
  TRACK,
  FAST_DELIVERY,
  SAFE_GATEWAY,
  SUPPORT,
  CHECK,
  DO_YOU_HAVE_COPOUN,
  PRICE_BEFORE_TAX,
  PRICE_AFTER_TAX,
  TOTAL_TAX,
  STEP_CONTINUE,
  SHOPPING_BASKET,
  ENTER_COPON_CODE,
  GO_TO_BASKET,
  CONTINUE_SHOPPING,
  PRODUCT_WAS_ADDED_TO_BASKET,
  CONFIRM_PAYMENT_PROCCESS,
  SHOPPING_DATA_NOT_COMPLETED,
  BASKET_IS_EMPTY,
  START_ADDING_PRODUCT_TO_BASKET,
  MANZILIK_STORE,
  SHIPPING_ADDRESSES,
  NO_ADDED_ADDRESSES,
  START_SAVING_YOUR_ADDRESS_TO_DELIVER_THE_PRODUCTS,
  SAVED_CARDS,
  ADD_NEW_CARD,
  SAVE_CARD_IN_SAFE_WAY_TO_PAY_QUICKLY_IN_FUTURE,
  THE_TOTAL_PRICE_HAS_BEEN_PAID_AND_WILL_WORKING_ON_PREPARING_THE_ORDER,
  TRACK_THE_ORDER,
  REVIEW_PROCESS,
  PREPARE_THE_ORDER,
  DELIVERY_PROCESS,
  THE_ORDER_HAS_BEEN_DELIVERED_SUCCESSFULLY,
  MY_ORDERS,
  CANCEL_THE_ORDER,
  CLOSE,
  SEARCH_BY_ORDER_NUMBER,
  ORDER_DETAILS,
  UNDER_PROCESSING,
  CANCELED,
  DELIVERED,
  ORDER_NUMBER,
  TOTAL_PRICE,
  ORDER_STATUS,
  CANCEL_PRODUCT,
  CONFIRM_CANCEL_PRODUCT,
  REFUND_REQUEST_DESC,
  REFUND_REQUEST_NOTE,
  REFUND_REQUEST_SUCCESS,
  REFUND_REQUEST_SUCCESS_NOTE,
  ORDER_DATE,
  DELIVERY_ADDRESS,
  DETAILS,
  THE_FOLLOWING_INFO_IS_ONLY_FOR_US_IT_WONT_PREVENT_YOU_FROM_CANCELING_YOUR_ORDER,
  SHIPPING_PROBLEM,
  OTHER,
  ORDER_IS_DUPLICATED,
  CHANGE_THE_ORDER,
  REASON_OF_CANCELING_THE_ORDER,
  ADD_DESCRIPTION,
  CONFIRM_ORDER_CANCELATION,
  NEW,
  SHIPPING_COST,
  DISCOUNT_AMOUNT,
  REGION,
  STORE_NAME,
  INESTEGRAM_ACCOUNT,
  SEND_INFORMATION,
  ENTER,
  THANK_FOR_YOUR_INTEREST_TO_JOIN_MANZILIK_PLANTFORM,
  OPERATION_TEAM_WILL_REACH_YOU_SOON,
  COMING_SOON,
  MANZILIK_STORE_SOON,
  WE_HELP_PEOPLE_TO_ACHEVE_THEIR_GOAL,
  JOIN_OUR_VENDORS,
  EXPLORE_MANZILIK,
  SHOW_CONTACT_INFO,
  SAR,
  DISCOUNT,
  PAYMENT_REVIEW,
  SELECT_PAYMENT_METHOD,
  SECURE_PAYMENT_LINE,
  LAST_STEP_PURCHASE,
  PAY_NOW,
  EDIT_ADDRESS_LINE,
  EDIT_ADDRESS,
  PENDING,
  REJECTED,
  SUCCESSFULL,
  PENDING_PAYMENT,
  ITEM_COLOR,
  ITEM_DIMENSIONS,
  SHIPPING_NO_ADDRESS_SELECTED,
  BASKET_NO_ITEMS_ADDED,
  ATTR_COLOR,
  ATTR_DIMENSIONS,
  COMPANY_ACCOUNT_TYPE,
  PERSONAL_ACCOUNT_TYPE,
  COMPANY_NAME,
  COMPANY_LOGO,
  COMPANY_REGISTERATION_REF,
  PERSONAL_FREELANCE_LICENSE,
  SELECT_ACCOUNT_TYPE,
  ERROR_ACCOUNT_TYPE_NOT_SELECTED,
  VERIFIED,
  UNVERIFIED,
  VERIFICATION,
  UPLOADED_IMAGES_SUCCESSFULLY,
  GENERAL_REVIEW_MANDATORY,
  INVALID_CARD_HOLDER,
  MISSING_FIRST_NAME,
  MISSING_LAST_NAME,
  CARD_NUMBER_MISMATCH_BRAND_TYPE,
  INVALID_REGISTERATION_REFERENCE,
  INVALID_FREELANCE_NUMBER,
  NO_NAME,
  SHOP_SEARCH_HEADING,
  SHOP_SEARCH,
  SHOP_SEARCH_SUBHEADING,
  SHOWING_RESULTS_FOR,
  ELAPSED,
  HOUR,
  HOURS,
  MARK_ALL_AS_READ,
  MINUTE,
  MINUTES,
  NO_NOTIFICATIONS,
  YOU_CAN_BROWSE,
  DAY,
  SEND_SERVICE_REQUEST,
  BUDGET_EXAMPLE,
  CONTACT_DETAILS,
  IMAGES,
  SEND_SERVICE_REQUEST_NOW,
  SERVICE_DESCRIPTION,
  SERVICE_INFO,
  TELEPHONE_NUMBER,
  WHATSAPP_NUMBER,
  SELECT_IMAGES_TO_UPLOAD,
  SERVICE_REQUESTS_LIST,
  SERVICE_REQUEST_UNDER_REVIEW,
  SERVICE_REQUEST_WAS_SENT,
  SERVICE_REQUEST_ERROR,
  RETRY_AGAIN,
  SERVICE_REQUESTS,
  NO_SERVICE_REQUESTS,
  REVIEW_PROFESSIONAlS_TO_REQUEST_SERVICE,
  HOME_OWNER,
  ISSUE_DATE,
  SERVICE_TYPE,
  ADD_PROJECTS_AND_SERVICES_TO_IMPROVE_YOUR_CHANCE,
  RECIEVED_REQUESTS,
  SENT_REQUESTS,
  PROJECT_DESCRIPTION,
  ACCEPT_REQUEST,
  EXTRA_INFO,
  REJECT,
  REQUEST_DETAILS,
  REQUEST_ACCEPTED_CONTACT_OWNER,
  REJECT_SERVICE_REASONS,
  CLIENT_MISBEHAVIOUR,
  COULDNT_REACH_AN_AGREEMENT,
  PLEASE_ADD_REJECTION_DETAILS,
  REQUESTED_SERVICE_NOT_AVAILABLE,
  CONFIRM_SERVICE_REJECTION,
  UNDO,
  REQUIRED_INFO_WILL_NOT_PREVENT_REJECTION,
  ACCEPTED_REQUEST,
  WAITING_RESPONSE_REQUEST,
  DELETED_REQUEST,
  REJECTED_REQUEST,
  M0,
  M1,
  M10,
  M11,
  M2,
  M3,
  M4,
  M5,
  M6,
  M7,
  M8,
  M9,
  FIND_REQUEST_BY_NUMBER,
  REQUEST_STATUS,
  REQUEST_NUMBER,
  YOU_CAN_CONTINUE_VIEWING_SERVICE_REQUEST,
  SERVICE_REQUEST_WAS_REJECTED,
  DOWNLOAD_MANZILIK_APP,
  DOWNLOAD_MANZILIK_APP_DESC,
  DOWNLOAD,
  ERROR_INVALID_TEL_5XXXXXXXX,
  SEND_REQUEST_SERVICE,
  BADGES_TITLE,
  BADGES_ACHIEVED,
  BADGES_REMINDING,
  BROWSE_WEBSITE,
  CAll_NOW,
  LANG,
  HIGHER_RATING,
  JOIN_DATE,
  VERIFIED_FIRST,
  SELECT_SORT_METHOD,
  RESULTS,
  VIEW_AS,
  ENTER_FULL_NAME,
  NAME_VERIFICATION_STEP,
  SAVE_AND_CONTINUE,
  PLEASE_ENTER_VALID_FIRST_NAME,
  PLEASE_ENTER_VALID_LAST_NAME,
  PLEASE_SELECT_CITY,
  PLEASE_SELECT_COUNTRY,
  MANAGE_GIGS,
  ADD_NEW_SERVICE,
  NO_FAST_SERVICES,
  START_NOW_GIGS,
  SERVICE_PRICE,
  SERVICE_LOCATION,
  SERVICE_STATUS,
  MAIN_SERVICE,
  IMAGE,
  SERVICE_TITLE,
  ACTIVATED,
  NOT_AVAILABLE,
  SET_AS_MAIN,
  SERVICE_DETAILS,
  REQUEST_SERVICE,
  TITLE_INVALID_CHARS,
  ADD_SERVICE,
  EDIT_SERVICE,
  BACK,
  SELECT_SERVICE_TYPE,
  SERVICE_ADDRESS,
  SERVICE_DESC_ONE,
  SERVICE_DESC_TWO,
  SERVICE_CITIES,
  SAVE_SERVICE,
  AVAILABLE,
  PLEASE_ENTER_VALID_YOUTUBE_LINK,
  KNOW_YOUR_NEEDS,
  KNOW_YOUR_NEEDS2,
  DO_YOU_NEED_HELP,
  PROVIDE_YOU_HELP,
  FIND_A_PRO,
  SELECT_CITY,
  SEARCH_CITY,
  CONFIRM_CITY,
  VIEW_MORE,
  VIEW_LESS,
  SIMILAR_SERVICES,
  AVAILABLE_IN,
  START_CONTACT,
  CONTACT_NOW,
  THE_ORDER_HAS_BEEN_DONE_SUCCESSFULLY,
  RATING,
  UNREAD_MESSAGES,
  MORE_INFO,
  CONNECT_WITH_SERVICE_PROVIDER,
  VIEW_CHAT,
  COVERED_CITIES,
  SERVICES_PACKAGE,
  PROVIDE_SERVICES_PACKAGE,
  REQUEST_SERVICE_NOW,
  VIEW,
  DISAPPEAR,
  REQUEST_SERVICE_INFO,
  ADDITIONAL_INFO,
  SELECT_CITY_FIRST,
  PROVIDE_YOU_HELP2,
  NO_GIGS,
  GIGS,
  EXAMPLE_LICENSE,
  REQUEST_COMPLETED,
  CANCEL_REQUEST,
  SERVICE_PROVIDER,
  COMPLETE_BTN,
  COMPLETE_NOTIFICATION,
  RELATED_GIGS,
  COMPLETED_REQUEST,
  CANCELLED_REQUEST,
  THANKS_FOR_COMPLETE,
  MANZILIK_AI_TAB,
  DESIGN_YOUR_HOME_NOW,
  USE_AI_TO_DESIGN_YOUR_HOME,
  START_THE_EXPERIENCE_NOW,
  USE_AI_TO_DESIGN_YOUR_HOME2,
  ADD,
  POINT,
  NEWLY_PUBLISHED,
  MY_DESIGNS,
  WE_DO_THE_WORK,
  STUCK,
  UPLOAD_PHOTO_TO_START,
  START_UPLOAD_PHOTO,
  HOW_AI_WORKS,
  THE_STYLE,
  ROOM_TYPE,
  MORE_DETAILS,
  PRIVACY,
  EXPORT_SPEED,
  YOUR_CREDIT_NOT_ENOUGH,
  ADD_CREDITS,
  START_NOW,
  PRIVACY_TOOLTIP,
  PRIVATE_TEXT,
  PUBLIC_TEXT,
  TWO_POINTS,
  TWO_POINTS_NOR_PAID,
  SLOW,
  FAST,
  ONE_POINT,
  FREE,
  SHARE_AI,
  NEED_DESIGNER,
  NEED_DESIGNER_TEXT,
  SEND_TO_MANZILIK,
  WHY_MANZILIK,
  WHY_MANZILIK_TEXT,
  SAVE_TIME_EFFORT,
  SAVE_TIME_EFFORT_TEXT,
  IMPROVE_DESIGN,
  IMPROVE_DESIGN_TEXT,
  PREDICT_FUTURE,
  PREDICT_FUTURE_TEXT,
  CUSTOMIZE_DESIGN,
  CUSTOMIZE_DESIGN_TEXT,
  ADD_ACCOUNT_POINTS,
  ALL_OPTIONS_AVAILABLE,
  DOWNLOAD_IMAGES,
  CREATE_DESIGNS,
  REMOVE_WATER_MARK,
  A_LOT_OF_DESIGNS,
  MOST_REQUESTED,
  GET_IT_NOW,
  BEFORE,
  AFTER,
  SMART_DESIGN,
  SELECT_IMAGE,
  CONTINUE_BTN,
  ORIGINAL,
  EXPERIMENTAL,
  THANKS_FOR_RATE,
  TOTAL_PAYMENT,
  START_SMART_DESIGN,
  TYR_ANOTHER_TIME,
  PAYMENT_FAILED,
  DESIGN_IN_PROGRESS,
  ALL_PRICES,
  RIGHT_DESCRIPTION,
  SPECIFY_ACCOUNT_TYPE,
  CONTINUE_HOME_OWNER,
  CONTINUE_PROFESSIONAL,
  CLIENT_TYPE_TEXT,
  SERVICE_PROVIDER2,
  INVITE_FRIENDS,
  GET_POINTS,
  COPY_DONE,
  SHARE_LINK,
  REGISTER_FOR_MANZILIK,
  TRY_AI,
  GOT_POINTS,
  SHARE_WITH_FRIENDS,
  WE_NEED_YOUR_PHONE,
  AI_ERROR_B104,
  PROF_TYPE_TEXT,
  DELETE_DESIGN,
  SURE_DELETE,
  CONFIRM_DELETE,
  REQUEST_FOR_QUOTATION,
  REQUEST_FOR_QUOTATION_TEXT,
  POST_A_QUOTE,
  POST_NOW,
  POST_A_QUOTE_TEXT,
  CREATE_NEW_PROJECT,
  POST_IT_NOW,
  PRICE_QUOTE_SEND_SUCCESS,
  PRICE_QUOTE_SEND_SUCCESS_TEXT,
  PRICE_QUOTE_DETAILS,
  CREATE_A_PROJECT,
  CREATE_A_PROJECT_TEXT,
  DETERMINE_THE_REQUIRED_SKILLS,
  DETERMINE_THE_REQUIRED_SKILLS_TEXT,
  DETERMINE_THE_BUDGET,
  DETERMINE_THE_BUDGET_TEXT,
  PUBLISH_THE_PROJECT,
  PUBLISH_THE_PROJECT_TEXT,
  REVIEW_OFFERS,
  REVIEW_OFFERS_TEXT,
  DISCUSS_DETAILS,
  DISCUSS_DETAILS_TEXT,
  CONTRACTING,
  CONTRACTING_TEXT,
  FOLLOW_THE_PROGRESS,
  FOLLOW_THE_PROGRESS_TEXT,
  SUBMIT_A_PRICE_REQUEST,
  ACCURACY,
  WALLS,
  FLOOR,
  CEILING,
  COLOR_PALETS,
  DESIGN_DESC,
  AI_INPUT_PLACEHOLDER,
  ADDITIONAL_OPTIONS,
  NOT_SELECTED,
  QUOTATION_DETAILS,
  APPLY_TO_QUOTATIONS,
  QUOTATIONS,
  OPENED_REQUEST,
  CLOSED_REQUEST,
  DURATION,
  REQUESTS_WORKS_ON,
  QUOTATIONS_REQUESTS,
  CONTRACTED_REQUEST,
  BUSINESS_YOU_LIKE,
  BROWSE_JOBS,
  APPLICANTS,
  PHOTOS_ATTACHED,
  MAKE_AN_OFFER,
  OFFER_PRICE,
  PROJECT_DURATION,
  MESSAGE_TO_SERVICE_REQUESTER,
  ATTACH_YOUR_WORK,
  UPLOAD_YOUR_WORK,
  ATTACH_UP_TO,
  TO_SUBMIT_RFQ,
  UPGRADE_SERVICES,
  THE_TYPE,
  QUOTATION_REQUEST,
  ARE_U_SURE_TO_SUBMIT_QUOTATION,
  QUOTATION_LAST_STEP,
  CONFIRM_QUOTATION,
  QUOTATION_SENT,
  MONTHS,
  YEARS,
  START_TRIP,
  YOU_CAN_APPLY,
  PRESS_MY_ACCOUNT,
  SERVICE_DETAILS_CONT,
  TYR_TO_ADD_SERVICE,
  THEN_CLICK_GIGS_MANAGEMENT,
  THEN_CLICK_ADD_GIG,
  TO_CONTINUE_FOLLOW_DETAILS,
  YOUR_REVIEW,
  REVIEW_SERVICE,
  REVIEW_SERVICE_TEXT,
  START_RATING,
  ADD_RATE,
  PRICE_NOT_ADDED,
  ORDER_ACCEPTED,
  CONTACT_CS,
  DESC_ABOUT_ME,
  NO_BADGES,
  HOW_TO_GET,
  HOW_TO_GET_BAGDES,
  HEADING_HOME_CLIENT1,
  HEADING_HOME_CLIENT2,
  START_SEARCH,
  EXPLORE_YOUR_REQUESTS,
  NO_PROJECTS,
  ADD_NEW_PROJECTS,
  NEXT_STEP,
  SERVICE_FORM,
  PAY_SERVICE_VALUE,
  VAT_INCLUDED,
  WILL_ADDED,
  FIVE_POINTS,
  TO_USE_IN_AI,
  INCLUDED_FEES,
  REFUND2,
  ACTIVE_REPUBLISH,
  YOUR_MANZILIK_ORDER,
  CONGRATS,
  ARE_YOU_SURE_DELETE_ORDER,
  ACCEPTED_OFFER,
  CANCELED_OFFER,
  NOW_CAN_COMPLETE_CONTRACT,
  ORDER_CANCELED,
  REFUND_MESSAGE,
  CONFIRM_CANCEL_ORDER,
  MESSAGES,
  OFFER_ACCEPTED,
  YOU_CAN_PUBLISH,
  RE_PUBLISH,
  NO_QUOTATIONS,
  DRAFT_REQUEST,
  DESCRIBE_SERVICE,
  DESCRIPTION_HELPS_YOU,
  DESCRIPTION_EXAMPLE,
  REGISTER_NOW,
  WE_NEED_YOUR_PHONE_TO_ASSIST,
  CONTINUE_PAYMENT,
  CONTACT_SUPPORT,
  SHOW_CLOSED_COMPLETED_REQUESTS,
  INPROGRESS_REQUESTS,
  NEW_REQUESTS,
  COMPLETED_CANCELED_REQUESTS,
  APPLICANT,
  SOME_GUIDES,
  AI_GUIDE_1,
  AI_GUIDE_2,
  AI_GUIDE_3,
  AI_GUIDE_4,
  AI_GUIDE_5,
  AI_GUIDE_6,
  AI_GUIDE_7,
  ATTACHED_PHOTOS,
  GUIDES,
  SMART_TEXT_AREA,
  SMART_GENERATION,
  RE_GENERATE,
  SELECT_STYLE,
  SELECT_ROOM_TYPE,
  RE_SELECT,
  ADV_GENERATE_TOOLTIP,
  NOT_EXIST,
  CLASSIC_VIEW,
  BACK_HISTORY,
  CLASSIFY_STORES,
  FIND_VENDOR,
  SIMILAR_PRODUCTS,
  ANALYZE_PRODUCTS,
  COUNTRY_CITY,
  MANAGE_IDEAS,
  MANAGE_VIDEOS,
  SHARE_PROFILE,
  SHARE_PROFILE_WITH_PEOPLE,
  MANAGE_REQUESTS,
  TRENDING,
  JOBS,
  APPLIED_FOR_JOB,
  BE_FIRST_ONE,
  APPLY,
  NO_PRODUCTS_EXIST,
  VIEW_SELLERS,
  VIEW_PRODUCTS,
  PRESS_TO_VIEW_PRODUCTS,
  VIEW_SELLERS_PRODUCTS,
  VIEW_SELLERS_PRODUCTS_WITHIN_SEARCH,
  BEST_SELLING_PRODUCTS,
  WILL_DEDUCT,
  POINTS_FROM_BALANCE,
  BEST_SELLERS_IN_KINGDOM,
  INCOMPLETE_RFQ,
} from './strings';

export default {
  [JOKER]: 'Manzilik',
  [EMAIL]: 'Email',
  [PASSWORD]: 'Password',
  [LOGIN]: 'Login',
  [MY_ACCOUNT]: 'My account',
  [SIGNUP]: 'Sign up',
  [SIGNUP_WITH_EMAIL]: 'Signup',
  [CONTINUE_WITH_GOOGLE]: 'Google',
  [CONTINUE_WITH_FACEBOOK]: 'Facebook',
  [OR]: 'Or',
  [AGREE_TO_TERMS]: 'Agree to all',
  [EMAIL_REQUIRED_MESSAGE]: 'Please input your email.',
  [PASSWORD_REQUIRED_MESSAGE]: 'Please input your password.',
  [REQUIRED]: 'This field is required',
  [REGEX_DOESNT_MATCH_MSG]: 'Your password should have at least 8 characters',
  [HOME_PAGE_HEADING]: 'Get Inspired',
  [HOME_PAGE_DESCRIPTION]: 'and find many different ideas',
  [ITEM_COLOR]: 'Product color',
  [ITEM_DIMENSIONS]: 'Product dimensions',
  [CONFIRM]: 'Confirm',
  [CONFIRM_EMAIL]: 'Confirm email?',
  [CONFIRM_CODE_TITLE]: 'Enter Confirmation Code',
  [BACK_TO_LOGIN]: 'Back to login',
  [RESEND_CONFIRMATION]: 'Resend code',
  [FIND_IDEAS]: 'Find Ideas',
  [SIGNUP_WITH]: 'Signup with',
  [LOGIN_BY_EMAIL]: 'Login by Email',
  [USERNAME]: 'Email',
  [FORGET_PASSWORD]: 'Forgot your password?',
  [RESET_YOUR_PASSWORD]: 'Reset your password',
  [KEEP_SIGNED_IN]: 'Keep me signed in',
  [SIGNIN]: 'Signin',
  [RESET_PASSWORD_SUCCESS_MSG]: 'You have successfully changed your password.',
  [SIGN_IN_POLICY]: 'By signing up, signing in or continuing, I agree to our Terms of Use and Privacy Policy.',
  [COMMON_QUESTIONS]: 'Common Login Questions:',
  [COMMON_QUESTION_1]: 'If you can not log in to your Manzilik account, try visiting the Manzilik  Helpmagazines:',
  [COMMON_QUESTION_2]: 'I’m unable to sign in. What should I do?',
  [COMMON_QUESTION_3]: 'I forgot my username or password. What should I do?',
  [COMMON_QUESTION_4]: 'How do I update or reset my password?',
  [COMMON_QUESTION_5]: 'How do I update my email address?',
  [COMMON_QUESTION_6]: 'Can I change my username?',
  [LOGIN_WITH_FACEBOOK]: 'Continue with Facebook',
  [LOGIN_WITH_GOOGLE]: 'Continue with Google',
  [LOGIN_BY_SOCIAL_MEDIA]: 'Login by Social Account',
  [LOGOUT]: 'Logout',
  [MANZIL_LOGIN]: 'Manzilik Login',
  [FORGET_YOUR_PASSWORD]: 'Forgot Your Password?',
  [FORGET_PASSWORD_DESCRIPTION]: 'Enter your email address and we’ll send you a link to reset your password.',
  [HELLO]: 'Hello',
  [CONTINUE]: 'Continue',
  [RE_ENTER_PASSWORD]: 'Re-enter Password',
  [SUBMIT]: 'Submit',
  [NEW_PASSWORD_TERMS]: 'By setting a password, you agree to Manzilik`s Terms of Use, Privacy Policy.',
  [PASSWORD_DONT_MATCH]: 'The two passwords don`t match',
  [CREATE_NEW_PASSWORD]: 'New Password',
  [CONFIRM_NEW_PASSWORD]: 'Confirm new password',
  [SAVE_PASSWORD]: 'Save Password',
  [CODE_MESSAGE]: 'Please enter the code',
  [CODE_TEXT]: 'Code',
  [LOADING]: 'Loading...',
  [PRIVATE]: 'Private (It`s not visible and can be changed from the settings)',
  [PUBLIC]: 'Public (It will be visible to everyone after adding 2 or more pictures)',
  [PROJECT_NAME]: 'Project Name',
  [CREATE_PROJECT]: 'Create Project',
  [ENTER_PROJECT_NAME]: 'Enter your project name',
  [CHOOSE_SPACE]: 'Choose space',
  [MOBILE]: 'Mobile',
  [MAIL]: 'Email',
  [HAVE_ACCOUNT]: 'have an account? you can',
  [SEND_CONFIRMATION_CODE]: 'Send confirmation code',
  // GENERIC
  [DISCUSSIONS]: '{{count}} Discussions',
  [PHOTOS]: 'Photos',
  [OPTIONAL]: 'Optional',
  [CREATE]: 'Create',
  [CANCEL]: 'Cancel',
  [ARABIC]: 'Ar',
  [SEND]: 'Send',
  // MOVE IDEA MODAL
  [MOVE_IDEA]: 'Move Idea',
  [SELECT_LOCATION]: 'Select Location',
  // HEADER //
  [GET_IDEAS]: 'Get ideas',
  [NOTIFICATIONS]: 'Notifications',
  [PROJECTS]: 'Projects',
  [MORE]: 'More',
  // IDEA COMPONENT
  [INVITE_FRIEND]: 'Invite Friends',
  [FILTER_BY]: 'Filter by',
  [MY_IDEAS]: 'My Ideas',
  [MORE_IDEAS_FROM_MANZEL]: 'More ideas from Manzilik',
  [ADD_NOTE]: 'Add a note',
  [COPY]: 'Copy',
  [MOVE]: 'Move',
  [REMOVE]: 'Remove',
  [SELECT_ALL]: 'Select All',
  [ORGANIZE]: 'Organize',
  [CHANGE_TO_GALLERY_VIEW]: 'Change to gallery view',
  [ADD_NEW_IDEA]: 'Add new picture',
  [EXPORT]: 'Export',
  // USERBOX
  [VIEW_PROFILE]: 'View profile',
  [VIEW_ALL]: 'View all',
  [IDEA]: '{{count}} item',
  [IDEAS]: '{{count}} items',
  // PROJECTS LIST PAGE
  [ACTIVITY]: 'Activities',
  [SEARCH_IDEAS]: 'Ideas',
  [SEARCH_IDEA]: 'Idea',
  // SEND MESSAGE FORM
  [TO]: 'To',
  [IMPORT_GMAIL_CONTACT]: 'Import my Gmail contact list (why)',
  [ENTER_EMAIL_RECIPIENTS]: '(enter recipient emails, separated by commas):  ',
  [MESSAGE_SUBJECT]: 'Subject',
  [SEND_BY_EMAIL]: 'Send By Email',
  [MESSAGE]: 'Message',
  [MESSAGE_INPUT_PLACE_HOLDER]:
    'Take a look at these great spaces that I found on Manzilik, and let me know what you think.',
  [MESSAGE_IS_SENT]: 'Message is sent',
  [LINK_COPIED]: 'Copied',
  // ADD IDEA FORM
  [UPLOAD_TERMS]:
    'By uploading photos, you are approving their display by Manzilik, saying that you either own the rights to the image or that you have permission or a license to do so from the copyright holder, and agreeing to abide by manzilik` terms of use.',
  [UPLOAD_PHOTO]: 'Upload Photo',
  [UPLOAD_PHOTOS]: 'Upload Photos',
  [UPLOADED_IMAGES_SUCCESSFULLY]: 'Uploaded photos successfully',
  [SAVE_FROM_WEB]: 'Save From Web',
  [UPLOAD]: 'Upload',
  [ADD_PHOTO]: 'Add Photo',
  [EDIT_PROJECT_INFO]: 'Edit Project',
  [TITLE]: 'Title',
  [DESCRIPTION]: 'Description',
  [EDIT]: 'Edit',
  [WRITE_DESCRIPTION]: 'Write a description',
  [ENTER_PROJECT_DESCRIPTION]: 'Enter project description',
  [ENTER_PROJECT_STATUS]: 'Enter project status',
  [ENTER_PROJECT_CATEGORY]: 'Enter project category',
  [PUBLISH_STATUS]: 'Publish status',
  [PRIVATE_WORD]: 'Private',
  [PUBLIC_WORD]: 'Public',
  [CATEGORY_TYPE]: 'Category type',

  //------------------ Footer ---------------------------//

  [FOOTER_CREDITS]: 'Manzilik Co. 2021',
  [COMPANY]: 'Company',
  [ABOUT_MANZEL]: 'About Manzilik',
  [PRIVACY_AND_POLICY]: 'Privacy Policy',
  [TERMS_AND_CONDITIONS]: 'Terms &  Conditions',
  [COOKIE_POLICY]: 'Cookie Policy',
  [GET_HELP]: 'Get Help',
  [GET_INSPIRED]: 'Get Inspired',
  [MANZEL_SUPPORT]: 'Manzilik Support',
  [CONTACT]: 'Contact Us',
  [FACEBOOK]: 'Facebook',
  [TWITTER]: 'Twitter',
  [INSTAGRAM]: 'Instagram',
  [YOUTUBE]: 'Youtube',
  [RSS]: 'RSS',
  [DOWNLOAD_APPLICATION]: 'Download Application',
  [CONNECT_WITH_US]: 'Connect With Us',
  [FOLLOW_US]: 'Follow Us',
  [AVAILABLE_APPLE]: 'Available on the',
  [APP_STORE]: 'App Store',
  [GET_APPLICATION]: 'GET IT ON',
  [GOOGLE_PLAY]: 'Google Play',
  [ALL_RIGHTS]: 'All rights reserved to',
  [DROP_FILE_HERE]: 'Drop files here or click to upload',

  //PopConfirm
  [YES]: 'Yes',
  [NO]: 'No',
  [ARE_YOU_SURE_DELETE_MSG]: 'Are you sure you want to delete this project?',
  [ARE_YOU_SURE_DELETE_IDEA]: 'Are you sure you want to delete this?',
  [UPDATE]: 'Update',

  // invite friend modal
  [INVITE]: 'Invite',
  [SAVE_SETTINGS]: 'Save Settings',
  [CAN_EDIT]: 'Can Edit',
  [CAN_VIEW]: 'Can View',
  [WHO_HAS_ACCESS]: 'Who has access',
  [CURRENTLY_SHARE_WITH]: ' Currently share with',
  [INVITE_BY_EMAIL_OR_NAME_PLACEHOLDER]: 'Enter your friend emails separated by commas',
  [DELETE_FRIEND]: 'Delete Friend',
  [YOU]: 'You',
  //EDIT PROFILE
  [EDIT_PROFILE]: 'Edit Profile',
  [ACCOUNT]: 'Account',
  [ACCOUNT_INFORMATION]: 'Account Information',
  [PROFILE_INFO]: 'Profile Info',
  [CONTACT_INFO]: 'Contact Info',
  [SOCIAL_MEDIA_SETTINGS]: 'Social Media Settings',
  [ADVANCE_SETTINGS]: 'Advanced Settings',
  [PRIVACY_SETTINGS]: 'Privacy Settings',
  [PROFILE_INFORMATION]: 'Profile Information',
  [FIRST_NAME]: 'First Name',
  [SELECT_ACCOUNT_TYPE]: 'Select account type',
  [PERSONAL_ACCOUNT_TYPE]: 'Personal Account',
  [COMPANY_ACCOUNT_TYPE]: 'Company Account',
  [ERROR_ACCOUNT_TYPE_NOT_SELECTED]: 'Please select account type',
  [LAST_NAME]: 'Last Name',
  [COMPANY_NAME]: 'Company name',
  [COMPANY_LOGO]: 'Company logo',
  [COMPANY_REGISTERATION_REF]: 'Company regestiration reference',
  [PERSONAL_FREELANCE_LICENSE]: 'Business certificate',
  [PUBLICLY_DISPLAYED]: 'Publicly Displayed',
  [ABOUT_ME]: 'About Me',
  [MY_FAVORITE_STYLE]: 'My Favorite Style',
  [MY_NEXT_MANZILIK_PROJECT]: 'My Next Manzilik Project',
  [USER_NAME]: 'User Name',
  [BY]: 'By',
  [PROFILE_DETAILS_UPDATED_SUCCESSFULLY]: 'Your profile is updated successfully',
  [PROCEED_TO]: 'Proceed to',
  [YOUR_MANZILIK_PROJECTS]: 'your Manzilik projects',
  [PREVIEW_YOUR]: 'preview your',
  [PUBLIC_PROFILE]: 'Public Profile',
  [YOU_CAN_ONLY_UPLOAD_JPG]: 'You can only upload JPG/PNG file!',
  [OLD_PASSWORD]: 'Old Password',
  [NEW_PASSWORD]: 'New Password',
  [CONTACT_INFORMATION]: 'Contact Information',
  [COUNTRY]: 'Country',
  [CITY]: 'City',
  [STATE]: 'State',
  [ZIP]: 'ZIP',
  [SAVED_TO]: 'Saved to',
  [UPDATED_NOTIFICATIONS_SUCCESS]: 'Notifications Updated Successfully!',
  [ABOUT_SHOULD_BE_MORE_THAN_FIFTY_CHARACTER]: 'The field should have more than 50 character',
  [FIELD_SHOULD_BE_URL]: 'This field should be a url',
  [ADDRESSES]: 'Addresses',
  [ADD_ADDRESS]: 'Add address',
  [ADDRESS_TITLE]: 'Address title',
  [NEIGHBORHOOD]: 'Neighborhood',
  [ADDRESS_DETAILS]: 'Address details',
  [UPDATE_ADDRESS_TITLE]: 'update Address',
  [SAVE_UPDATES]: 'save updates',
  [NEW_ADDRESS]: 'New Address',
  // idea page
  [SHARE]: 'Share',
  [SAVE]: 'Save',
  [QUESTION_SUBMIT]: 'Ask',
  [OTHER_PHOTO_IN]: 'Other photos in',
  [SEE_MORE_PHOTOS]: 'See more photos',
  [SEE_MORE_VIDEOS]: 'See more videos',
  [SAVE_IDEA]: ' Save Idea',
  [WHAT_DO_YOU_LIKE_ABOUT_THIS_IDEA]: 'Add notes, What do you like about this idea?',
  [FOLLOW]: 'Follow',
  [UN_FOLLOW]: 'Un Follow',
  [WRITE_COMMENT]: 'Write a comment',
  [QUESTION_ABOUT_THIS_PHOTO]: 'Questions About This Project',
  [SHOW]: 'Show',
  [MORE_QUESTIONS]: 'more questions',
  [MORE_COMMENTS]: 'more comments',
  [ASK_QUESTION]: 'Ask Question',
  [I_HAVE_ANOTHER_QUESTION]: 'I have another question',
  [ASK_QUESTION_ABOUT_THIS_PHOTO]: 'Ask Question about this photo',
  [TELL_US_THE_DETAILS]: 'Tell us the details',
  [YOUR_QUESTION]: 'Your question',
  [OK]: 'Ok',
  [PEOPLE_WHO_LIKE_THIS]: 'People who like this',
  [ARE_YOU_SURE_DELETE_COMMENT_MSG]: 'Are you sure you want to delete this comment',
  [PREVIEW]: 'Preview',
  [SEE_THIS_PROJECT_ON_MANZILIK]: 'Look at this project on Manzilik',
  //edit profile videos
  [PROFILE_VIDEOS]: 'videos',
  [VIDEO_EXAMPLE]: 'Video Example',
  [ADD_YOUTUBE_LINK]: 'Add youtube link',
  [ADD_VALID_YOUTUBE_LINK]: 'Youtube link is wrong, try again',
  //edit profile -social media
  [LINKEDIN]: 'LinkedIn',
  [WEBSITE_OR_BLOG]: 'Website Or Blog',
  [YOUR_PROFILE_PHOTO]: 'Your Profile Photo  ',
  [DRAG_THE_CORNER_OF_THE_BOX_TO_CHANGE_POSITION_AND_SIZE_OR_UPLOAD_A_NEW_IMAGE]:
    'Drag the corner of the box to change position and size, or upload a new image.',
  [CLICK_OR_DRAG_DROP_FILES_HERE_TO_UPLOAD]: 'Click or drag & drop files here to upload',
  [YOU_CAN_UPLOAD_A_JPG_GIF_OR_PNG_FILE]: 'You can upload a JPG, GIF, or PNG file',
  [YOUR_PRIVACY_SETTINGS]: 'Your Privacy Settings',
  [SMS_OFFERS]: 'SMS Offers',
  [SEND_ME_SMS_FROM_MANZILIK_WITH_TIPS]:
    'Send me SMS from MANZILIK with tips, offers, and similar services pursuant to the MANZILIK Terms of Use.',
  [IT_MAY_TAKE_UP_TO_3_DAYS_FOR_US_TO_FULLY_PROCESS]:
    'Please understand that it may take up to 3 days for us to fully process your opt-out.',
  [DISCLOSE_MY_INFO_FOR_COMMERCIAL_PURPOSES]: 'Disclose My Info for Commercial Purposes',
  [UNDER_CALIFORNIA_LAW_LEARN_MORE_HERE]:
    'Under California law, the disclosure of my info for commercial purposes is considered a “sale.” Learn more here.',
  [MARKETING_AND_EMAIL_COMMUNICATIONS]: 'Marketing and Email Communications',
  [MANAGE_YOUR_EMAIL_SETTINGS]: 'Manage your email settings',
  [ADDRESS_BOOK]: 'Address Book',
  [DELETE_MY_ADDRESS_BOOK_CONTACT]: 'Delete my address book contact',
  [DOWNLOAD_A_COPY_OF_YOUR_DATA]: 'Download a copy of your data',
  // idea questions
  [DELETE]: 'Delete',
  [PLEASE_WRITE_VALID_EMAILS]: 'Please write valid emails',

  //-------------------------------- idea search page --------------------------
  [SEARCH]: 'Search',
  [IN_ALL_PHOTOS]: 'in All Photos',
  [ALL_FILTERS]: 'All Filters',
  [STYLE]: 'Style',
  [SIZE]: 'Size',
  [COLOR]: 'Color',
  [NEXT_PAGE]: 'Next Page',
  [PREVIOUS_PAGE]: 'Previous Page',
  [LIVING_ROOM]: 'Living Room',
  [KITCHEN_IN_PHOTOS]: 'Kitchen Pictures In Pictures',
  [BATHROOM_IN_PHOTOS]: 'Bathroom Pictures in Pictures',
  [SEND_EMAIL]: 'Email',
  [REFINE_BY]: 'Refine by',
  [SORT_BY]: 'Sort by',
  [OF]: 'Of',
  [LOCATION]: 'Location',
  [RELEVANCE]: 'Relevance',
  [DONE]: 'Done',
  [CLEAR_ALL]: 'Clear All',
  [SELECTED]: 'Selected',
  [BUDGET]: 'Budget',
  [TYPE]: 'User Type',
  [MOST_SEARCHED]: 'Most searched',
  // --------------------------------- advanced settings page ----------------------------

  [ADVANCED_SETTINGS]: 'Advanced Settings',
  [NEWSLETTER_EDITION]: 'Newsletter Edition',
  [EMAIL_PREFERENCES]: 'Email Notifications',
  [RECEIVE_EMAILS_ABOUT_MY_FOLLOWERS]: 'Receive everything new about my followers ',
  [RECEIVE_ONLY_EMAILS_ABOUT_NEW_FOLLOWERS]: 'Receive only emails about my new followers',
  [CUSTOMIZE_MY_EMAIL_SUBSCRIPTIONS_BELOW]: 'Customize my email subscriptions below',
  [WEBSITE_NOTIFICATIONS]: 'Website Notifications',
  [DAILY_NOTIFICATIONS]: 'Daily Notifications',
  [EMAIL_SUBSCRIPTIONS]: 'Email Subscriptions',
  [MANZILIK_MAGAZINE_HOME_TOURS]: 'Manzilik Magazine (home tours, remodeling stories and tips) ',
  [MANZILIK_SHOP_TRENDING_PRODUCTS_AND_SALES]: 'Manzilik Shop (trending products and sales)',
  [PRODUCT_ALERTS_PRICE_DROPS]: 'Product alerts (price drops, items back in stock and reviews)  ',
  [PERSONALIZED_RECOMMENDATIONS]: 'Personalized recommendations (products, photos and pros)  ',
  [MANZILIK_TV_SURVEYS_AND_OTHER_UPDATES]: 'Manzilik TV, surveys and other updates',
  [MANZILIK_REFERRAL_COUPONS]: 'Manzilik referral coupons from your friends or home professionals  ',
  [MANZILIK_UPDATES_NEAR_ME]: 'Manzilik updates near me',
  [TIPS_GUIDES_AND_NEW_FEATURE_ANNOUNCEMENTS]: 'Tips, guides, and new feature announcements',
  [USER_ACTIVITY_NOTIFICATIONS]: 'User Activity Notifications',
  [SEND_ME_AN_EMAIL_IF]: 'Send me an email if:',
  [THERE_IS_AN_ISSUE_WITH_MY_ACCOUNT_OR_LOGIN]: 'there is an issue with my account or login',
  [SOMEONE_FOLLOWS_ME]: 'someone follows me',
  [SOMEONE_MESSAGES_ME]: 'someone messages me',
  [SOMEONE_COMMENTS_ON_MY_IDEABOOK]: 'someone comments on my ideabook',
  [SOMEONE_COMMENTS_ON_MY_POST]: 'someone comments on my post',
  [EVERY_TIME]: 'Every time',
  [NOTIFICATION_CENTER]: 'Notification Center',
  [SHOW_ACTIVITIES_FROM_USERS_I_AM_FOLLOWING]: 'Show activities from users I am following at my notification center  ',
  [MY_PROFILE_PAGES]: 'My Profile Pages',
  [THE_FOLLOWING_WILL_BE_VISIBLE_ON_MY_PUBLIC_PROFILE]:
    'The following will be visible on my public profile page, and shared with my followers:  ',
  [MY_PUBLIC_IDEABOOKS]: 'My public ideabooks',
  [MY_UPLOADED_PHOTOS_AND_PROJECTS]: 'My uploaded photos and projects',
  [MY_DISCUSSIONS]: 'My discussions',
  [MY_SOCIAL_MEDIA_PROFILES]: 'My social media profiles',
  [PEOPLE_WHO_FOLLOW_ME]: 'People who follow me',
  [PEOPLE_I_FOLLOW]: 'People I follow',
  [DEFAULT_PHOTO_ORDER_IN_MY_IDEABOOKS]: 'Default photo order in my ideabooks',
  [CLIMATE_ZONE_FOR_GARDEN_FORUMS]: 'Climate Zone for Garden Forums',
  [MY_GARDEN_WEB_TRADING_LIST]: 'My GardenWeb Trading List',
  [ALLOW_USERS_TO_FOLLOW_MY_PROFILE]: 'Allow users to follow my profile',
  [NOTE_IF_THIS_IS_UNCHECKED]: 'Note: If this is unchecked, all followers will be removed permanently.  ',
  [WHO_CAN_MESSAGE_ME]: 'Who can message me',
  [ENABLE_ADD_TO_IDEABOOK_CONFIRMATION]: 'Enable add to ideabook confirmation',
  [ALLOW_COMMENTS_ON_MY_PUBLIC_PHOTOS]: 'Allow comments on my public photos',
  [PUBLIC_PROFILE_PAGE]: 'Public profile page',
  [DEACTIVATE_ACCOUNT]: 'Deactivate Account',
  [NEVER]: 'Never',
  [LIMIT_1_PER_HOUR_POST]: 'Limit 1 per hour per post',
  [LIMIT_3_PER_HOUR_POST]: 'Limit 3 per hour per post',
  [LIMIT_1_PER_DAY]: 'Limit 1 per day',
  [LIMIT_2_PER_DAY]: 'Limit 2 per day',
  [LIMIT_5_PER_DAY]: 'Limit 5 per day',
  [DISPLAY_NEWS_FIRST]: 'Display newest photos first',
  [DISPLAY_OLDEST_FIRST]: 'Display oldest photos first',
  [DONT_ALLOW_USERS_TO_MESSAGE_ME]: 'Don`t allow users to message me',
  [ALLOW_USERS_I_FOLLOW_TO_MESSAGE_ME]: 'Allow users I follow to message me',
  [ALLOW_ALL_USERS_TO_MESSAGE_ME]: 'Allow all users to message me',
  [YOU_NEE_TO_LOGIN]: 'For your security you need to login again to be able to change your password',
  [YOU_DONT_HAVE_EMAIL]: 'You don`t have email, please update your email in the profile',
  // --------------------------------- discussions page ----------------------------

  [WHAT_ARE_YOU_WORKING_ON]: 'What are you working on?',
  [GET_HELP_FOR_YOUR_PROJECTS]: 'Get help for your projects, share your finds, and show off your Before & After.',
  [START_A_DISCUSSION]: 'Start a discussion',
  [LATEST]: 'Latest',
  [FEATURED]: 'Featured',
  [POPULAR]: 'Popular',
  [UNANSWERED]: 'Unanswered',
  [POSTED_BY]: 'Posted by',
  [YOUR_POSTS]: 'Your Posts',
  [YOUR_COMMENTS]: 'Your Comments',
  [YOUR_TOPICS]: 'Your Topics',
  [EXPLORE_DISCUSSIONS]: 'Explore Discussions',
  [EXPLORE_ALL_DISCUSSIONS]: 'Explore All Discussions',
  [FEATURED_HOME_DISCUSSIONS]: 'Featured Home Discussions',
  [FEATURED_GARDEN_DISCUSSIONS]: 'Featured Garden Discussions',
  [TOP_COMMENTERS]: 'Top Commenters',
  [TODAY]: 'Today',
  [WEEK]: 'Week',
  [MONTH]: 'Month',
  [PREV]: 'Previous',
  [NEXT]: 'Next',
  [UPDATED_AT]: 'Updated',
  [HI]: 'Hi,',
  [POST]: '{{count}} Post',
  [POSTS]: '{{count}} Posts',
  [ADD_PHOTO_FOR_BETTER_ANSWERS]: 'Tip: Add photos for better answers',
  [POLL_OPTIONS]: 'Poll Options',
  [YOU_CAN_ENTER_TEXT_LINK_PRODUCT_OR_UPLOAD_AN_IMAGE]:
    'You can enter text, link to a Manzilik photo or product, or upload an image.',
  [ENTER_TEXT_OR_PASTE_MANZILIK_LINK]: 'Enter text or paste Manzilik link',
  [OPTION]: 'Option',
  [ADD_OPTION]: 'Add an option',
  [ADD_POLL]: 'Add Poll',
  [CHOOSE_TOPIC]: 'Choose topic',
  [ADD_POST]: 'Post',
  [ADD_LINK]: 'Add Link',
  [ADD_VIDEO]: 'Add Video',
  [TEXT]: 'Text',
  [LINK]: 'Link',
  [SORTED_BY]: 'Sorted by:',
  [OLDEST]: 'Oldest',
  [RELATED_DISCUSSIONS]: 'Related Discussions',
  [MORE_DISCUSSIONS]: 'More Discussions',
  [FEATURED_ANSWER]: 'Featured Answer',
  [VOTE]: 'Vote',
  [COMMENT]: 'Comment',
  [COMMENTS]: 'Comments',
  [REMOVE_POLL]: 'Remove poll',
  [POLL_MUST_HAVE_TWO]: 'Poll must have at least 2 options',
  [TITLE_AND_DESC_NEEDS_TO_BE_15]: 'Title and description must be at 15 letters length',
  [TITLE_INVALID_CHARS]: 'Title has invalid chars i.e. ( )',
  [WELCOME_TO_MANZILIK_ADVICE]: 'Welcome to Manzilik Advices',
  [SEE_DISCUSSION_FROM_DESIGNERS_AND_EXPERT]: 'See discussions from designers and experts',
  [GET_STARTED]: 'Get started',
  [FOLLOW_AT_LEAST_ONE_TOPIC]: 'Follow at least 1 topic',
  [FIND_HOME_AND_GARDEN_TOPICS]: 'Find Home and Garden Topics',
  [WHAT_ARE_YOUR_INTERESTS]: 'What are your interests?',
  [NO_COMMENTS]: 'No Comments',
  [REPORT_SPAM]: 'Report Spam',

  // --------------------------------- magazine page ----------------------------

  [FULL_STORY]: 'Full Story',
  [STORIES_FROM_MANZILIK]: 'Stories from Manzilik',
  [LATEST_FROM_MANZILIK]: 'Latest from Manzilik',
  [WATCH_NOW]: 'Watch Now',
  [MOST_POPULAR]: 'Most Popular',
  [NEW_THIS_WEEK]: 'New This Week',
  [MANZILIK_TOURS]: 'Manzilik Tours',
  [MANZILIK_TV]: 'Manzilik TV',
  [PREV_PAGE]: 'Previous Page',
  [NEW_TO_MANZILIK]: 'New to Manzilik',
  [READ_RELATED_STORIES]: 'Read Related Stories',
  [READ_THE_FULL_STORY]: 'Read The Full Story',
  [SEE_MORE_COMMENTS]: 'See more {{number}} comments',
  [MANZILIK_DISCUSSIONS]: 'Manzilik Discussions',

  // --------------------------------- magazine details page ----------------------------
  [PEOPLE_WHO_LIKED_THIS_STORY]: 'People who liked this story also liked',
  [MORE_ON_MANZILIK]: 'More on Manzilik',
  [EXPLORE_RELATED_TOPICS]: 'Explore related topics',
  [PRINT]: 'Print',
  [EMBED]: 'Embed',
  [READ_MORE]: 'Read More',
  [SHOW_LESS]: 'Show Less',
  [LIKE]: 'Like',
  [LIKES]: 'Likes',
  [SAVE_DISCUSSION]: 'Save Discussion',
  [SAVED_SUCCESSFULLY]: 'Saved Successfully',
  [SAUDI_ARABIA]: 'Saudi Arabia',
  [PHOTO]: '{{count}} Photo',
  [DISCUSSION]: '{{count}} Discussion',
  [COMMENT_IS_EMPTY]: 'Comment is empty',
  [MAGAZINE]: 'Magazine',
  [TV]: 'TV',
  [IDEA_TAG]: 'PICTURE',
  [NEW_VIDEO_ON_MANZILIK]: 'New Episodes From Manzilik TV Live',
  [FIND_PROFESSIONAL]: 'Find Professional',
  [FIND_PRODUCTS]: 'Find Products',
  [SEND_MESSAGE]: 'Send a Message',
  [PROFESSIONAL_ROOM]: 'Room Type',
  [REVIEW]: '{{count}} Review',
  [REVIEWS]: '{{count}} Reviews',
  [NO_REVIEWS]: 'There are no reviews',
  [PAGE_404]: 'Page Not Found',
  [BACK_HOME]: 'Back Home',
  [THERE_ARE_NO_DATA]: 'There are no results',
  [EMBED_A_WIDGET]: 'Embed a Widget on Your Website or Blog ',
  // Complete profile
  [CATEGORIES]: 'Categories',
  [SERVICES]: 'Services',
  [MAX_SIZE_FILE]: 'Max file size is 20 M',
  [UNDER_REVIEW]: 'Your Account is under review',
  [COMPLETE_PROFILE]: 'Complete Your Profile',
  [PROFESSIONAL]: 'Professional',
  [HOMEOWNER]: 'Home Owner',
  [SELECT_CATEGORY]: 'Select Category',
  [SELECT_SERVICE]: 'Select Services',
  [PHONE_NUMBER]: 'Mobile Number',
  [HOW_DO_YOU_RATE_THIS_PROFESSIONAL]: 'How do you rate this professional?',
  [DO_YOU_WANT_TO_SHARE_MORE]: 'Do you want to share more about it?',
  [WRITE_REVIEW]: 'Write a review',
  [FOR]: 'for',
  [CONTACT_THIS_PRO]: 'Contact this pro',
  [NAME]: 'Name',
  [MOBILE_NUMBER]: 'Mobile Number',
  [PROJECT_ZIP_CODE]: 'Project Zip Code',
  [SAVE_YOUR_MESSAGE]: 'Save your message to save time - easily contact more pros',
  [TELL_YOUR_PROFESSIONAL]: 'Tell this pro what you have in mind for your project…',
  [ACTIVATE_MY_ACCOUNT]: 'Save & Activate My Account',
  [ADDRESS]: 'Address',
  [PROF_PROFILE_REVIEW_NOTE]:
    'In order to provide the best service, please include the email associated with your Manzilik account. We strive to respond to all inquiries within 1-3 business days.',
  [PLEASE_FILL_REQUIRED_FIELDS]: 'Please fill the required fields',
  [LOAD_NEXT_PHOTOS]: 'More Photos',
  [EDIT_REVIEW]: 'Edit your review',
  [PLEASE_ENTER_VALID_PHONE_NUMBER]: 'Please enter valid Mobile Number',
  [PLEASE_ENTER_VALID_PHONE_NUMBER_EX]: 'Please enter valid Mobile Number, EX: +12345678',
  [PROFESSIONALS]: 'Professionals',
  [BE_FIRST_TO_COMMENT]: 'Be the first to comment',
  [SPAM_DONE]: 'Spam has been reported successfully',
  [ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO]: 'Are you sure you want to delete this photo?',
  [ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_VIDEO]: 'Are you sure you want to delete this video?',
  [MORE_REVIEWS]: 'more review',
  [MORE_REVIEWS_PLURAL]: 'more reviews',
  [PERSONAL_INFORMATION]: 'Personal Info',
  [PHOTOS_GALLERY]: 'Photo Gallery',
  [VIDEOS]: 'Videos',
  [REGION]: 'Region',

  // -------------------------- cognito messages ------------------------------------

  [USER_DOESNT_EXIST]: 'User does not exist',
  [INCORRECT_USERNAME_OR_PASSWORD]: 'Incorrect username or password',
  [USER_EXIST]: 'this account already exists',
  [INVALID_CODE]: 'Invalid verification code provided, please try again',
  [USER_NOT_FOUND]: 'Username not found',
  [SOMETHING_WENT_WRONG]: 'Something went wrong',
  [CAPTCHA_IS_MISSING]: 'Captcha is missing',

  // ---------------------- auto complete --------------------------------
  [USER_NOT_CONFIRMED]: 'User is not confirmed',
  [MUST_RESET_PASSWORD]: 'You have to reset your password',

  // ------------------------- save modal -----------------------------
  [SAVE_MAGAZINE]: 'Save Magazine',
  [SAVE_TV]: 'Save TV',
  [MAGAZINES]: 'Magazines',
  [DISCUSSIONS_SEARCH]: 'Discussions',
  [TVS]: 'Tvs',
  [IN]: 'in',

  // ----------------------- Departments Page ----------------------
  [PRODUCT]: 'Product',
  [PRODUCTS]: 'Products',
  [EXPLORE_PRODUCTS]: 'Explore Products',
  [EXPLORE_MORE_FAV_PRODUCTS]: 'Explore More Favorite Products',
  [BUY_ALL]: 'Buy All',
  [FURNITURE]: 'Furniture',
  [SHOPPING]: 'Shop',
  [ALL_PRODUCTS]: 'All Products',
  // ----------------------- departments details page
  [HIGHTEST_PRICE]: 'Highest Price',
  [LOWEST_PRICE]: 'Lowest Price',
  [HIGHEST_RATE]: 'Highest Rate',
  [LOWEST_RATE]: 'Lowest Rate',
  [EXTRA_COLORS]: 'Extra colors',
  [EXTRA_SIZES]: 'Extra sizes',
  [SHOW_ALL_RESULTS]: 'Show all results',
  [ADD_TO_CART]: 'Add to cart',
  [ITEMS_LEFT]: 'only items left',
  [READY_TO_SHIP_IN]: 'ready to shipping in',
  [DAYS]: 'days',
  [DAY]: 'day',
  [PRODUCT_DESCRIPTION]: 'Product Description',
  [PRODUCT_SPECIFICATION]: 'Product Specification',
  [PRODUCT_SHIPPING]: 'Shipping & Returns',
  [QUANTITY]: 'Quantity',
  [ADD_QUANTITY]: 'Add Quantity',
  [ENTER_NEW_QUANTITY]: 'Enter new quantity',
  [BE_FIRST_REVIEWER]: 'Be the first reviewer',
  [NO_BODY_REVIEWED_THIS_PRODUCT]: 'No body reviewed this before!',
  [CUSTOMER_REVIEWS]: 'Customer Reviews',
  [REVIEW_PRODUCT]: 'Review Product',
  [REVIEWED_PRODUCT_SUCCESSFULLY]: 'Reviewed product successfully',
  [WHAT_IS_YOUR_REVIEW_FOR_PRODUCT]: 'What is your review for the product?',
  [TRANSFER_YOUR_EXPERIENCE_OF_USING_THE_PRODUCT]:
    'Transfer your experience of using the product and help others make the decision',
  [REASONABLE_PRICE]: 'Price',
  [PRODUCT_MATCHES_DESCRIPTION]: 'Matches description',
  [PRODUCT_QUALITY]: 'Quality',
  [SHIPPING_SPEED]: 'Shipping speed',
  [ADD_PRODUCT_IMAGES]: 'Add product images',
  [ADD_PRODUCT_COMMENT]: 'Write your review on the product',
  [ADD_YOUR_COMMENT_HERE]: 'Write your comment here...',
  [PRODUCT_NUMBER]: 'Product Number',
  [MANUFACTORY]: 'Manufacture Company',
  [SOLD_BY]: 'Sold By',
  [SIZE_OR_WEIGHT]: 'Size/Weight',
  [CATEGORY]: 'Category',
  [MANZILIK]: 'Manzilik',
  [BOUGHT_THIS_PRODUCT]: 'Has bought this product',
  [COMMENT_LIKE]: 'Like',
  [DID_THIS_COMMENT_HELP_YOU]: 'Did this comment help you?',
  [REVIEW_THIS_PRODUCT]: 'Review this product',
  [EDIT_PRODUCT_REVIEW]: 'Edit product review',
  [GENERAL_REVIEW_MANDATORY]: 'Please add your review',
  [YOU_HAVE_TO_LOGIN_FOR_REVIEW]: 'You have to login in order to review this product!',
  [GO_TO_LOGIN]: 'Go to login',
  [CONTACT_US]: 'Contact Us',
  [FREQUENTLY_ASKED_QUESTIONS]: 'Frequently Asked Questions',
  [HOW_CAN_WE_HELP]: 'How can we help you',
  [FULL_NAME]: 'Full name',
  [EMAIL_HERE]: 'Your email here',
  [WRITE_FULL_NAME]: 'Write email here',
  [MESSAGE_CONTENT]: 'Message',
  [MESSAGE_CONTENT_HERE]: 'Message here',
  [LETTER]: 'Letter',
  [SEND_NOW]: 'Send now',
  [HOME_PAGE]: 'Home page',
  [REQUEST_SUCCESSFULLY_RECEIVED]: 'Your request has been successfully received',
  [REQUEST_WILL_BE_FOLLOWED_UP]: 'Your request will be followed up as soon as possible',
  // -------------------------- who we are ------------------------------------
  [WHO_WE_ARE]: 'Who We Are',
  //------------------------- Main Page ----------------------------//
  [MAIN]: 'Home',
  [MOST_RECENT]: 'Latest News On Manzilik',
  [SHOW_ALL]: 'Show All',
  [SIGN_IN_NOW]: 'Sign in now',
  [SUBSCRIBE_TO_NEWSLETTER]: 'Subscribe to Manzilik newsletter',
  [MUST_AGREE_TERMS]: 'You must agree to the terms and conditions',
  [ARE_YOY_LOOKING_FOR_IDEAS]: 'Are you looking for Ideas?',

  //------------------------- Public Profile ----------------------------//
  [AWARDS]: 'Awards',
  [WON]: 'Won',
  [BADGES]: 'Badges',
  [GENERAL_IDEA_ABOUT_ME]: 'General idea',
  [PROFILE_IDEAS]: 'Ideas',
  [UNLIMITED_CREATIVITY_IDEAS]: 'Unlimited creativity of unique Ideas',
  [QUANTITY_VALIDATION_MESSAGE]: 'value should be greater than 0 and less than',
  [FOLLOWING]: 'Following',
  [FOLLOWERS]: 'Followers',
  //------------------------- Contact Us Page ----------------------------//
  [NO_IDEAS]: 'No Ideas',
  //------------------------- Landing Pages ----------------------------//
  [JOINT_US_NOW]: 'Join us now',
  [WITH_MANZILIK]: 'With Manzilik',
  [INSPIRE_YOURSELF]: 'Inspire yourself with pre-designed layouts for bed, bathroom, living room, etc',
  [OR_SIGH_UP_WITH]: 'Or sighup with',
  [EQUIPMENT_YOU_APARTMENT]: 'Equipping your apartment and looking for new pictures for decoration?',
  [WE_BRING_PRACTICAL_IDEAS]:
    'In your home, we have brought you a set of practical Ideas that will help you to change the decor of your home with the least costs and the least effort, and not only that, we also provide you with many pictures',
  [MANY_NEW_DECORATING_IDEAS]: 'Many new decorating Ideas and designs',
  [WANT_TO_CHANGE_YOUR_HOME_DECORE]:
    'Do you want to change your home decor this year and want new and innovative pictures?',
  [WE_PROVIDE_COMPLETE_FEED_OF_IDEAS]: 'We provide you with a complete feed of many ideas that you can choose from',
  [DO_YOU_WANT_TO_EXPAND_CUSTOMER_INCREASE]: 'Do you want to expand your customer base and increase your profits?',
  [WE_PROVIDE_PLATFORM_TO_DISPLAY_YOUR_BUSINESS]:
    'We provide you with a platform to display your business and help you get new clients',
  [ARE_YOU_AN_INTERIOR_DESIGN]:
    'Are you an interior designer, interior designer, plumber, tile technician or one of the professions related to home finishing and furnishing?',
  [YOUR_GOAL_IS_TO_INCREASE_CLIENT_PROJECTS]: 'Your goal is to increase your clients and projects?',
  [CONFIRMATION_CODE]: 'Confirmation Code',
  [HOME_PAGE_SEO_DESCRIPTION]:
    'Interior design platform provides variety of inspiration ideas for home, office and villa interior design including all it`s parts like salon, kitchen, bedrooms and others',
  [DISCUSSIONS_TITLE]: 'Interior design discussions',
  [MAGAZINES_TITLE]: 'Interior Design Magazine',
  [ABOUT_DESCRIPTION]:
    'Your ultimate interior design platform. Here you can get pictures about bedroom design, living room design, children room or any part of your property',
  [TERMS_DESCRIPTION]: 'Terms & Conditions of Manzilik , your ultimate interior design platform',
  [PRIVACY_DESCRIPTION]:
    'Manzilik, this page represent all the privacy policy rules you should follow while using our platform',
  [HOME]: 'Home',

  //------------------------- Add idea from web --------------//
  [ADD_WEB_PAGE_URL]: 'Add web page URL:',
  //------------------------------------- Idea page --------------------------------------//
  [CONTACT_PROFESSIONAL]: 'Contact',
  [PROJECT]: 'Project',
  [RELATED_PROFESSIONALS]: 'Related Professionals',
  [MY_PROJECTS]: 'My Projects',
  [ALL]: 'All',
  //----------------------------------Stores -----------------------------//
  [BRANDS]: 'Brands',
  [CHOOSE_BRAND_TYPE]: 'Choose Brand Type',
  [FIND_STORE]: 'Find Store',
  [STYLE_TYPES]: 'Style Types',
  [HOW_DO_YOU_RATE_THIS_STORE]: 'How Do You Rate This Store ?',
  [WEBSITE]: 'Website',
  //-----------------------------------EXTENDED FOOTER -----------------//
  [THE_TV]: 'TV',
  [THE_MAGAZINE]: 'Magazine',
  [THE_DISCUSSIONS]: 'Discussions',
  [PROFESSIONAL_SERVICES]: 'Professionals Services',
  [BrandType.GLOBAL]: 'Global',
  [BrandType.LOCAL]: 'Local',
  [ROOM_TYPES]: 'Room Types',

  //--------------------------- Partner ------------------------//
  [PROVIDER]: 'Partner',
  [HOW_DO_YOU_RATE_THIS_PARTNER]: 'How do you rate this partner?',

  //------------------------checkout------------------------//
  [THE_PRODUCT]: 'Product',
  [SAR]: 'SAR',
  [PRICE]: 'Price (S.R)',
  [DISCOUNT]: 'Discount',
  [OPERATION]: 'Action',
  [BASKET]: 'Basket',
  [DELIVERY]: 'Delivery',
  [PAYMENT]: 'Payment',
  [SUCCESSFULL]: 'Payment Success',
  [SHIPPING_NO_ADDRESS_SELECTED]: 'Shipping address not selected, Please select address',
  [BASKET_NO_ITEMS_ADDED]: 'No items were added to basket, Please add items to proceed',
  [PENDING_PAYMENT]: 'Checking Payment Status',
  [REJECTED]: 'Payment Rejected',
  [PAYMENT_REVIEW]: 'Payment & Review',
  [SELECT_PAYMENT_METHOD]: 'Select Payment Method',
  [SECURE_PAYMENT_LINE]: '100% Secure Payment',
  [EDIT_ADDRESS_LINE]: 'Editing Delivery Address Will Move You to the Previous Step',
  [LAST_STEP_PURCHASE]: 'Last Step to Purchase Your Product, Payment Setp Starts Here',
  [PAY_NOW]: 'Pay Now',
  [EDIT_ADDRESS]: 'Edit Delivery Address',
  [ORDER_REVIEW]: 'Review',
  [TRACK]: 'Track',
  [FAST_DELIVERY]: 'Fast Delivery',
  [SAFE_GATEWAY]: 'Safe Gateway',
  [SUPPORT]: 'Support 24/7',
  [CHECK]: 'check',
  [DO_YOU_HAVE_COPOUN]: 'Do you have coupon',
  [PRICE_BEFORE_TAX]: 'Price before tax',
  [PRICE_AFTER_TAX]: 'total after tax',
  [TOTAL_TAX]: 'tax',
  [STEP_CONTINUE]: 'continue',
  [SHOPPING_BASKET]: 'Shopping basket',
  [ATTR_COLOR]: 'Color',
  [ATTR_DIMENSIONS]: 'Dimensions',
  [ENTER_COPON_CODE]: 'Enter copon code',
  [GO_TO_BASKET]: 'Go to basket',
  [CONTINUE_SHOPPING]: 'Continue shopping',
  [PRODUCT_WAS_ADDED_TO_BASKET]: 'Product was added to the basket successfully',
  [CONFIRM_PAYMENT_PROCCESS]: 'Confirm payment',
  [SHOPPING_DATA_NOT_COMPLETED]: 'The process is not completed',
  [BASKET_IS_EMPTY]: 'The basket is empty',
  [START_ADDING_PRODUCT_TO_BASKET]: 'Start adding products to the pasket',
  [MANZILIK_STORE]: 'MANZILIK store',
  [SHIPPING_ADDRESSES]: 'shipping address',
  [NO_ADDED_ADDRESSES]: 'No added addresses',
  [START_SAVING_YOUR_ADDRESS_TO_DELIVER_THE_PRODUCTS]: 'Start saving your address to deliver the product to',
  [SAVED_CARDS]: 'Saved cards',
  [ADD_NEW_CARD]: 'Add new card',
  [SAVE_CARD_IN_SAFE_WAY_TO_PAY_QUICKLY_IN_FUTURE]: 'Save card in a safe way to pay quickly in the future',
  [THE_TOTAL_PRICE_HAS_BEEN_PAID_AND_WILL_WORKING_ON_PREPARING_THE_ORDER]:
    'The total price has been paid and we will start working on preparing your order',
  [TRACK_THE_ORDER]: 'Track the order',
  [REVIEW_PROCESS]: 'Review process',
  [PREPARE_THE_ORDER]: 'Prepare the order',
  [DELIVERY_PROCESS]: 'Delivery process',
  [THE_ORDER_HAS_BEEN_DELIVERED_SUCCESSFULLY]: 'The order has been delivered successfully',
  [MY_ORDERS]: 'My orders',
  [CANCEL_THE_ORDER]: 'cancel order',
  [CLOSE]: 'close',
  [SHOW_CONTACT_INFO]: 'show contact information',

  //------------------------my orders------------------------//

  [SEARCH_BY_ORDER_NUMBER]: 'search by order number',
  [ORDER_DETAILS]: 'Order details',
  [UNDER_PROCESSING]: 'under processiong',
  [CANCELED]: 'canceled',
  [DELIVERED]: 'delivered',
  [PENDING]: 'pending',
  [ORDER_NUMBER]: 'Order number',
  [TOTAL_PRICE]: 'Total price',
  [ORDER_STATUS]: 'Order status',
  [CANCEL_PRODUCT]: 'Cancel Product',
  [CONFIRM_CANCEL_PRODUCT]: 'Confirm canceling product',
  [REFUND_REQUEST_DESC]: 'Your following selection will help us a lot. It will not affect the order cancellation',
  [REFUND_REQUEST_NOTE]: 'Explain the reason for the refund request',
  [REFUND_REQUEST_SUCCESS]: 'Refund request sent successfully',
  [REFUND_REQUEST_SUCCESS_NOTE]: 'The money will be deposited back to your account within the next 72 hours',
  [ORDER_DATE]: 'Order date',
  [DELIVERY_ADDRESS]: 'delivery address',
  [DETAILS]: 'Details',
  [THE_FOLLOWING_INFO_IS_ONLY_FOR_US_IT_WONT_PREVENT_YOU_FROM_CANCELING_YOUR_ORDER]:
    'The following information is only for us and it won`t prevent you from canceling your order',
  [SHIPPING_PROBLEM]: 'Shipping problem',
  [OTHER]: 'Other',
  [ORDER_IS_DUPLICATED]: 'Order is dublicated',
  [CHANGE_THE_ORDER]: 'Change the order',
  [REASON_OF_CANCELING_THE_ORDER]: 'Reason of canceling the order',
  [ADD_DESCRIPTION]: 'add description',
  [CONFIRM_ORDER_CANCELATION]: 'Confirm order cancelation',
  [NEW]: 'New',
  [SHIPPING_COST]: 'Shipping cost',
  [DISCOUNT_AMOUNT]: 'Discount amount',

  // ----------------------- coming soon --------------------- //
  [STORE_NAME]: 'store name',
  [INESTEGRAM_ACCOUNT]: 'intagram account',
  [SEND_INFORMATION]: 'send information',
  [ENTER]: 'enter',
  [THANK_FOR_YOUR_INTEREST_TO_JOIN_MANZILIK_PLANTFORM]: 'Thank You for your interest to join our vendors!',
  [OPERATION_TEAM_WILL_REACH_YOU_SOON]: 'Our team will contact you soon to proceed with the remaining process',
  [COMING_SOON]: 'Coming Soon',
  [MANZILIK_STORE_SOON]: 'Manzilik store soon',
  [WE_HELP_PEOPLE_TO_ACHEVE_THEIR_GOAL]: 'we help people to achieve their goal ',
  [JOIN_OUR_VENDORS]: 'Join our vendors',
  [EXPLORE_MANZILIK]: 'تصفح منزلك',

  // else
  [VERIFICATION]: 'Verification',
  [VERIFIED]: 'Verified',
  [UNVERIFIED]: 'Unverified',
  [INVALID_CARD_HOLDER]: 'Invalid Card Holder',
  [MISSING_FIRST_NAME]: 'Missing First Name',
  [MISSING_LAST_NAME]: 'Missing Last Name',
  [CARD_NUMBER_MISMATCH_BRAND_TYPE]: "Card number doesn't match selected card type",
  [INVALID_REGISTERATION_REFERENCE]: 'Invalid registeration reference',
  [INVALID_FREELANCE_NUMBER]: 'Invalid freelance document number',
  [NO_NAME]: 'No Name',
  [SHOP_SEARCH_HEADING]: 'A complete shop with including a large set of market options',
  [SHOP_SEARCH_SUBHEADING]: 'Everything You Need At Your Fingertip',
  [SHOP_SEARCH]: 'Search for your product',
  [SHOWING_RESULTS_FOR]: 'Showing results for: ',
  [MINUTE]: 'minute',
  [MINUTES]: 'minutes',
  [HOUR]: 'hour',
  [HOURS]: 'hours',
  [ELAPSED]: 'ago',
  [MARK_ALL_AS_READ]: 'Mark All as Read',
  [NO_NOTIFICATIONS]: 'No Notifications',
  [YOU_CAN_BROWSE]: 'You can browse',
  [SEND_SERVICE_REQUEST]: 'Send Service Request',
  [SEND_SERVICE_REQUEST_NOW]: 'Request Service Now',
  [SERVICE_INFO]: 'Service Info.',
  [SERVICE_DESCRIPTION]: 'Service Description',
  [BUDGET_EXAMPLE]: 'i.e. budget 400 - 1000 SAR',
  [IMAGES]: 'Images',
  [CONTACT_DETAILS]: 'Contact Details',
  [TELEPHONE_NUMBER]: 'Telephone Number',
  [WHATSAPP_NUMBER]: 'Whatsapp Number',
  [SELECT_IMAGES_TO_UPLOAD]: 'select images to upload',
  [SERVICE_REQUEST_WAS_SENT]: 'service request was sent',
  [SERVICE_REQUEST_UNDER_REVIEW]:
    'Service request is under review, You will recieve a response shortly. In the meantime you can review your requests status',
  [SERVICE_REQUESTS_LIST]: 'Service requests list',
  [SERVICE_REQUEST_ERROR]: 'An error occured sending your request, You can try sending again',
  [RETRY_AGAIN]: 'Retry Again',
  [SERVICE_REQUESTS]: 'Service Requests',
  [NO_SERVICE_REQUESTS]: 'No Service Requests',
  [REVIEW_PROFESSIONAlS_TO_REQUEST_SERVICE]: 'You can review professionals and request a service',
  [SERVICE_TYPE]: 'Service Type',
  [HOME_OWNER]: 'Homeowner',
  [ISSUE_DATE]: 'Issue Date',
  [ADD_PROJECTS_AND_SERVICES_TO_IMPROVE_YOUR_CHANCE]:
    'Add projects and services to improve your chance in getting hired',
  [RECIEVED_REQUESTS]: 'Recieved Requests',
  [SENT_REQUESTS]: 'Sent Requests',
  [ACCEPTED_REQUEST]: 'Accepted',
  [REJECTED_REQUEST]: 'Rejected',
  [WAITING_RESPONSE_REQUEST]: 'Waiting Response',
  [DELETED_REQUEST]: 'Deleted',
  [PROJECT_DESCRIPTION]: 'Project Description',
  [EXTRA_INFO]: 'Extra Info.',
  [ACCEPT_REQUEST]: 'Accept Request',
  [REJECT]: 'Reject',
  [REQUEST_DETAILS]: 'Request Details',
  [REQUEST_ACCEPTED_CONTACT_OWNER]: 'Request accepted, You can contact homeowner',
  [REJECT_SERVICE_REASONS]: 'Reject service reasons',
  [REQUESTED_SERVICE_NOT_AVAILABLE]: 'Requested service is not available',
  [COULDNT_REACH_AN_AGREEMENT]: "We couldn't reach an agreement",
  [CLIENT_MISBEHAVIOUR]: 'Client misbehaviour',
  [PLEASE_ADD_REJECTION_DETAILS]: 'Please add rejection details',
  [CONFIRM_SERVICE_REJECTION]: 'Confirm Service Rejection',
  [UNDO]: 'Undo',
  [REQUIRED_INFO_WILL_NOT_PREVENT_REJECTION]: "Required information won't prevent you from rejecting service request",
  [M0]: 'January',
  [M1]: 'February',
  [M2]: 'March',
  [M3]: 'April',
  [M4]: 'May',
  [M5]: 'June',
  [M6]: 'July',
  [M7]: 'August',
  [M8]: 'September',
  [M9]: 'October',
  [M10]: 'November',
  [M11]: 'December',
  [REQUEST_STATUS]: 'Request Status',
  [REQUEST_NUMBER]: 'Request Number',
  [FIND_REQUEST_BY_NUMBER]: 'Find request by number',
  [SERVICE_REQUEST_WAS_REJECTED]: 'Service was rejected successfully',
  [YOU_CAN_CONTINUE_VIEWING_SERVICE_REQUEST]: 'You can continue viewing service request',
  [SEND_REQUEST_SERVICE]: 'Send Request Service',
  [DOWNLOAD_MANZILIK_APP]: 'Download Manzilik App',
  [DOWNLOAD_MANZILIK_APP_DESC]: 'For betters experience',
  [DOWNLOAD]: 'Download',
  [BADGES_TITLE]: 'Badges for manzilik',
  [BADGES_ACHIEVED]: 'Achieved',
  [BADGES_REMINDING]: 'Reminding',
  [BROWSE_WEBSITE]: 'Browse Website',
  [CAll_NOW]: 'Call Now',
  [LANG]: 'English',
  [VERIFIED_FIRST]: 'Verified First',
  [JOIN_DATE]: 'Join Date',
  [HIGHER_RATING]: 'Higher Rating',
  [SELECT_SORT_METHOD]: 'Select Sort Method',
  [VIEW_AS]: 'View As Others See You',
  [ENTER_FULL_NAME]: 'Enter Full Name',
  [NAME_VERIFICATION_STEP]: 'Your name will be verified in this step',
  [SAVE_AND_CONTINUE]: 'Save and Continue',
  [PLEASE_ENTER_VALID_FIRST_NAME]: 'Please enter your first name',
  [PLEASE_ENTER_VALID_LAST_NAME]: 'Please enter your last name',
  [PLEASE_SELECT_COUNTRY]: 'Please select a country',
  [PLEASE_SELECT_CITY]: 'Please select a city',
  [MANAGE_GIGS]: 'Manage Services',
  [ADD_NEW_SERVICE]: 'Add New Service',
  [NO_FAST_SERVICES]: 'No Fast Services',
  [START_NOW_GIGS]: ' Now start adding a quick service to start receiving requests from homeowners',
  [IMAGE]: 'Image',
  [SERVICE_PRICE]: 'Service Price',
  [SERVICE_LOCATION]: 'Service Location',
  [SERVICE_STATUS]: 'Service Status',
  [MAIN_SERVICE]: 'Main Service',
  [SERVICE_TITLE]: 'Service Title',
  [ACTIVATED]: 'Activated',
  [NOT_AVAILABLE]: 'Not Available',
  [SET_AS_MAIN]: 'Set As Main',
  [SERVICE_DETAILS]: 'Service Details',
  [REQUEST_SERVICE]: 'Request Service',
  [ADD_SERVICE]: 'Add Service',
  [EDIT_SERVICE]: 'Edit Service',
  [BACK]: 'Back',
  [SELECT_SERVICE_TYPE]: 'Select Service Type',
  [SERVICE_ADDRESS]: 'Service Address',
  [SERVICE_DESC_ONE]: 'Service Description',
  [SERVICE_DESC_TWO]: 'Here you can type the extra desecration ',
  [SERVICE_CITIES]: 'Service Cities',
  [SAVE_SERVICE]: 'Save Service',
  [AVAILABLE]: 'Available',
  [PLEASE_ENTER_VALID_YOUTUBE_LINK]: 'Please enter valid youtube link',
  [KNOW_YOUR_NEEDS]: 'Know All Your Needs',
  [KNOW_YOUR_NEEDS2]: 'Whatever the field of work, you can count on your home and support for various disciplines',
  [DO_YOU_NEED_HELP]: 'Do you need help for another service?',
  [FIND_A_PRO]: 'Find a Pro',
  [SELECT_CITY]: 'Select City',
  [SEARCH_CITY]: 'Search City',
  [CONFIRM_CITY]: 'Confirm City',
  [PROVIDE_YOU_HELP]:
    'We offer you immediate assistance with other services that are not available. Start now a different journey with your home',
  [PROVIDE_YOU_HELP2]: 'We offer you immediate assistance with other services that are not available.',
  [VIEW_MORE]: 'View More',
  [VIEW_LESS]: 'View Less',
  [RATING]: 'Rating',
  [THE_ORDER_HAS_BEEN_DONE_SUCCESSFULLY]: 'The order has been done successfully',
  [ERROR_INVALID_TEL_5XXXXXXXX]: 'Invalid tel format 5XXXXXXXX',
  [RESULTS]: 'result',
  [SIMILAR_SERVICES]: 'Similar Services',
  [AVAILABLE_IN]: 'Available In',
  [UNREAD_MESSAGES]: 'Unread Messages',
  [START_CONTACT]: 'Start Contacting',
  [CONTACT_NOW]: 'Contact Now',
  [VIEW_CHAT]: 'View Chat',
  [MORE_INFO]: 'More Details',
  [CONNECT_WITH_SERVICE_PROVIDER]: 'Connect with service provider',
  [COVERED_CITIES]: 'Coverage cities',
  [SERVICES_PACKAGE]: 'Services Package',
  [PROVIDE_SERVICES_PACKAGE]: 'Provide Services Package',
  [REQUEST_SERVICE_NOW]: 'Request inquiry',
  [VIEW]: 'View',
  [DISAPPEAR]: 'Hide',
  [REQUEST_SERVICE_INFO]: 'Request Service Info',
  [ADDITIONAL_INFO]: 'Additional Info',
  [SELECT_CITY_FIRST]: 'In order to request service, you have to select city',
  [NO_GIGS]: 'No Services',
  [GIGS]: 'Services',
  [EXAMPLE_LICENSE]: 'Example: FL-12345678',
  [REQUEST_COMPLETED]: 'Request Completed',
  [CANCEL_REQUEST]: 'Cancel Request',
  [SERVICE_PROVIDER]: 'Service Provider',
  [COMPLETE_BTN]: 'Complete',
  [COMPLETE_NOTIFICATION]:
    ' Welcome to Manzilik. your professional profile need to be completed to be able to use pro features. You can complete it here.',
  [RELATED_GIGS]: 'Related Gigs',
  [COMPLETED_REQUEST]: 'Completed',
  [CANCELLED_REQUEST]: 'Canceled',
  [THANKS_FOR_COMPLETE]: 'Thanks for completing the service',
  [MANZILIK_AI_TAB]: 'Manzilik AI',
  [DESIGN_YOUR_HOME_NOW]: 'Design your home now',
  [USE_AI_TO_DESIGN_YOUR_HOME]:
    'Using artificial intelligence to create your interior design with several options that suit your taste',
  [START_THE_EXPERIENCE_NOW]: 'Start the experience now',
  [USE_AI_TO_DESIGN_YOUR_HOME2]: 'Using artificial intelligence to create your interior design',
  [ADD]: 'Add',
  [POINT]: 'Point',
  [NEWLY_PUBLISHED]: 'Newly Published',
  [MY_DESIGNS]: 'My Designs',
  [WE_DO_THE_WORK]: 'We do the work',
  [STUCK]:
    '"Stuck","Not sure where to start", Every day people wake up in rooms they do not like and say they do not know what to do.',
  [UPLOAD_PHOTO_TO_START]: 'Upload Photo to Start',
  [START_UPLOAD_PHOTO]: 'Start Upload Photo',
  [HOW_AI_WORKS]: 'How Manzilik AI Works',
  [THE_STYLE]: 'Style',
  [ROOM_TYPE]: 'Room Type',
  [MORE_DETAILS]: 'More Details',
  [PRIVACY]: 'Privacy',
  [EXPORT_SPEED]: 'Export Speed',
  [START_NOW]: 'Start Now',
  [YOUR_CREDIT_NOT_ENOUGH]: 'Your credit is not enough',
  [ADD_CREDITS]: 'Add Credits',
  [PRIVACY_TOOLTIP]: 'Your design will be saved in your account and will not be shared with anyone',
  [PRIVATE_TEXT]: 'Private',
  [PUBLIC_TEXT]: 'Public',
  [TWO_POINTS]: '(2 points)',
  [TWO_POINTS_NOR_PAID]: '(2 points - paid points)',
  [SLOW]: 'Slow',
  [FAST]: 'Fast',
  [ONE_POINT]: '1 point',
  [FREE]: 'Free',
  [SHARE_AI]: 'Share',
  [NEED_DESIGNER]: 'You need a real designer',
  [NEED_DESIGNER_TEXT]: 'Request quotations from our professional to get the best price for your design',
  [SEND_TO_MANZILIK]: 'Request quotations',
  [WHY_MANZILIK]: 'Why manzilik AI',
  [WHY_MANZILIK_TEXT]: 'Here a one-line text is added to describe the section',
  [SAVE_TIME_EFFORT]: 'Save time and effort',
  [SAVE_TIME_EFFORT_TEXT]:
    'Artificial intelligence can reduce the research and assembly time required to select the right interior design elements',
  [IMPROVE_DESIGN]: 'Improve planning and design',
  [IMPROVE_DESIGN_TEXT]:
    'Artificial intelligence can improve planning and design processes by analyzing data and providing advice',
  [PREDICT_FUTURE]: 'Predicting future trends',
  [PREDICT_FUTURE_TEXT]: 'Artificial intelligence can analyze current data and trends for fashion and interior design',
  [CUSTOMIZE_DESIGN]: 'customize designs',
  [CUSTOMIZE_DESIGN_TEXT]:
    'Using artificial intelligence, higher customization of interior designs can be achieved according to preferences',
  [ADD_ACCOUNT_POINTS]: 'Add points to my account',
  [ALL_OPTIONS_AVAILABLE]: 'All features are available to you',
  [DOWNLOAD_IMAGES]: 'Download images in high quality',
  [CREATE_DESIGNS]: 'Design creation at high speed',
  [REMOVE_WATER_MARK]: 'Remove the watermark from the design',
  [A_LOT_OF_DESIGNS]: 'Many designs with complete privacy',
  [MOST_REQUESTED]: 'Most requested',
  [GET_IT_NOW]: 'Get it now',
  [BEFORE]: 'Before',
  [AFTER]: 'After',
  [SMART_DESIGN]: 'Do you like the smart design?',
  [SELECT_IMAGE]: 'Select Suitable Image',
  [CONTINUE_BTN]: 'Continue',
  [ORIGINAL]: 'Original',
  [EXPERIMENTAL]: 'Experimental',
  [THANKS_FOR_RATE]: 'Thanks for rating the design',
  [TOTAL_PAYMENT]: 'Total / including VAT',
  [START_SMART_DESIGN]: 'Start Smart Design Now',
  [TYR_ANOTHER_TIME]: 'Try another time',
  [PAYMENT_FAILED]: 'Payment Failed',
  [DESIGN_IN_PROGRESS]: 'Design in progress',
  [ALL_PRICES]: 'All prices include VAT',
  [RIGHT_DESCRIPTION]: 'What is the right description for you?',
  [SPECIFY_ACCOUNT_TYPE]: 'Select your account type at manzilik',
  [CONTINUE_HOME_OWNER]: 'Continuing as a homeowner',
  [CONTINUE_PROFESSIONAL]: 'Continuing as a professional',
  [CLIENT_TYPE_TEXT]:
    'To see the different ideas and designs for interior and exterior decoration and design of homes, gardens and other spaces.',
  [PROF_TYPE_TEXT]: `You can benefit from all that distinguishes a homeowner, in addition to your ability to publish your work, receive service requests from customers, and communicate with them.
    Continue as a service provider`,
  [SERVICE_PROVIDER2]: 'Service Provider',
  [INVITE_FRIENDS]: 'Invite Friends to try Manzilik AI',
  [GET_POINTS]: 'And Get 5 points for each inivte',
  [COPY_DONE]: 'Copied',
  [SHARE_LINK]: 'Share Link with your friends',
  [REGISTER_FOR_MANZILIK]: 'Register for Manzilik',
  [TRY_AI]: 'Try Manzilik AI',
  [GOT_POINTS]: 'You got 5 points successfully',
  [SHARE_WITH_FRIENDS]: 'Share the link  with your friends',
  [WE_NEED_YOUR_PHONE]: 'We need your phone number',
  [AI_ERROR_B104]:
    'You cannot set your designs to “Private” unless you buy credits from Manzilik AI. Free credits only allow you to obtain “Public” designs that can be displayed on the platform.',
  [DELETE_DESIGN]: 'Delete Design',
  [SURE_DELETE]: 'Are you sure you want to delete this design?',
  [CONFIRM_DELETE]: 'If you confirm the deletion, it will be removed from your designs and cannot be returned',
  [REQUEST_FOR_QUOTATION]: 'Request for quotation',
  [REQUEST_FOR_QUOTATION_TEXT]:
    'Whatever the field of work, you can rely on your home and its support for various specialties',
  [POST_A_QUOTE]: 'Post a Quote',
  [POST_NOW]: 'Post Now',
  [POST_A_QUOTE_TEXT]: 'Provide sufficient information about your business to obtain quotes',
  [CREATE_NEW_PROJECT]:
    'Create a new project. Specify a project name and a detailed description of what you need. The clearer the project description, the easier it will be for professionals to understand your requirements.',
  [POST_IT_NOW]: 'Post it now',
  [PRICE_QUOTE_SEND_SUCCESS]: 'Your price quote has been sent successfully',
  [PRICE_QUOTE_SEND_SUCCESS_TEXT]:
    'Now your request has reached the professionals associated with the required service, and offers will be submitted as soon as possible',
  [PRICE_QUOTE_DETAILS]: 'Price quote details',
  [CREATE_A_PROJECT]: 'Create a project',
  [CREATE_A_PROJECT_TEXT]: `Once you've created your account, create a new project. Specify a project name and a detailed description of what you need. The clearer the project description, the easier it will be for professionals to understand your requirements.`,
  [DETERMINE_THE_REQUIRED_SKILLS]: 'Determine the required skills',
  [DETERMINE_THE_REQUIRED_SKILLS_TEXT]: `In the section dedicated to the required skills, specify the skills that the freelancer you are looking for must have. This will help determine the right category for your project and bring in freelancers who possess those skills.`,
  [DETERMINE_THE_BUDGET]: 'Determine the budget',
  [DETERMINE_THE_BUDGET_TEXT]: `Determine the budget available for your project. You can set a fixed budget or provide a rough budget range if you're not sure exactly.`,
  [PUBLISH_THE_PROJECT]: 'Publish the project',
  [PUBLISH_THE_PROJECT_TEXT]: `Once you have completed the project details, publish it on the platform. Professionals will be able to see your project and make their offers.`,
  [REVIEW_OFFERS]: 'Review offers',
  [REVIEW_OFFERS_TEXT]: `Once professionals submit their offers, review and evaluate them based on the skills, experience, and prices they provided.`,
  [DISCUSS_DETAILS]: 'Discuss the details',
  [DISCUSS_DETAILS_TEXT]: `Choose a professional who seems right for your project and reach out to discuss the details in more detail. You can precisely define your requirements and reach a final agreement before implementation begins.`,
  [CONTRACTING]: 'Contracting',
  [CONTRACTING_TEXT]: `After discussing the details, it may be necessary to sign an agreement or contract of understanding that specifies all details related to the project including tasks, budget, and timelines.`,
  [FOLLOW_THE_PROGRESS]: 'Follow the progress',
  [FOLLOW_THE_PROGRESS_TEXT]: `Follow the professional's progress in implementing the project and ensure that your expectations are met. You may need to discuss any modifications or changes during the implementation process.`,
  [SUBMIT_A_PRICE_REQUEST]: 'Submit a price request',
  [ACCURACY]: 'Match previous design',
  [WALLS]: 'Walls',
  [FLOOR]: 'Flooring',
  [CEILING]: 'Ceilings',
  [COLOR_PALETS]: 'Color list',
  [DESIGN_DESC]: 'Describe design using AI',
  [AI_INPUT_PLACEHOLDER]: 'Customize Process Description More “Double Bed with Wooden Wardrobe”',
  [ADDITIONAL_OPTIONS]: 'Additional features for your design',
  [NOT_SELECTED]: 'Not Selected',
  [QUOTATION_DETAILS]: 'Quotation Details',
  [APPLY_TO_QUOTATIONS]: 'Applicants to quote',
  [QUOTATIONS]: 'Quotations',
  [OPENED_REQUEST]: 'Opened',
  [CLOSED_REQUEST]: 'Not Completed',
  [DURATION]: 'Duration',
  [REQUESTS_WORKS_ON]: 'Requests works on',
  [QUOTATIONS_REQUESTS]: 'Quotations Requests',
  [CONTRACTED_REQUEST]: 'Contracted ',
  [BUSINESS_YOU_LIKE]: 'Business you may like',
  [BROWSE_JOBS]: `Browse jobs that match your experience and client's hiring preferences. Sorted by most relevant.`,
  [APPLICANTS]: 'Applicants',
  [PHOTOS_ATTACHED]: 'Photos attached',
  [MAKE_AN_OFFER]: 'Make an offer',
  [OFFER_PRICE]: 'Offer Price',
  [PROJECT_DURATION]: 'Project Duration',
  [MESSAGE_TO_SERVICE_REQUESTER]: 'Message to the service requester',
  [ATTACH_YOUR_WORK]: 'Attach your previous work',
  [UPLOAD_YOUR_WORK]: 'Upload your works',
  [ATTACH_UP_TO]:
    'You can attach up to 5 files less than 10MB each. Include work samples or other documents to support your application.',
  [TO_SUBMIT_RFQ]: 'In order to be able to follow offer information you must update your services',
  [UPGRADE_SERVICES]: 'Upgrade Services',
  [THE_TYPE]: 'type',
  [QUOTATION_REQUEST]: 'Quotation Request',
  [ARE_U_SURE_TO_SUBMIT_QUOTATION]: 'Are you sure you want to submit this quotation?',
  [QUOTATION_LAST_STEP]: 'This is the last step to submit your quotation',
  [CONFIRM_QUOTATION]: 'Confirm',
  [QUOTATION_SENT]: 'Quotation sent ',
  [MONTHS]: 'month',
  [YEARS]: 'year',
  [START_TRIP]: 'Start the journey of providing services',
  [YOU_CAN_APPLY]: 'You can now provide ready-made services by managing the services provided by your home',
  [PRESS_MY_ACCOUNT]: 'Press my account',
  [SERVICE_DETAILS_CONT]: 'Service details ... continue',
  [TYR_TO_ADD_SERVICE]: 'Add a service by clicking on My Account',
  [THEN_CLICK_GIGS_MANAGEMENT]: 'Then click on Manage Services from here',
  [THEN_CLICK_ADD_GIG]: 'Then click on add Services from here',
  [TO_CONTINUE_FOLLOW_DETAILS]: 'To be able to follow the field details follow the instructions',
  [YOUR_REVIEW]: 'Your review',
  [REVIEW_SERVICE]: 'Review Service',
  [REVIEW_SERVICE_TEXT]: 'Rate the service with',
  [START_RATING]: 'Start Rating',
  [ADD_RATE]: 'Add Rate',
  [PRICE_NOT_ADDED]: 'Price not added from client',
  [ORDER_ACCEPTED]: 'The current order accepted',
  [CONTACT_CS]: 'Contact Customer Service',
  [DESC_ABOUT_ME]: 'Description about me',
  [NO_BADGES]: 'There are no badges',
  [HOW_TO_GET]: 'How do you get?',
  [HOW_TO_GET_BAGDES]: 'a badge on manzilik platform',
  [HEADING_HOME_CLIENT1]: 'Manzilik,  integrated home services',
  [HEADING_HOME_CLIENT2]:
    'Explore many distinctive services in your home and enjoy the home of a lifetime through many unique ideas, suggestions, and services from our professionals',
  [START_SEARCH]: 'Start search at manzilik',
  [EXPLORE_YOUR_REQUESTS]: 'Browse your works that you are working on or have previously submitted',
  [NO_PROJECTS]: 'There are no projects',
  [ADD_NEW_PROJECTS]: 'Add your first project that will help you get many jobs',
  [NEXT_STEP]: 'Next step',
  [SERVICE_FORM]: 'Service model',
  [PAY_SERVICE_VALUE]: 'Pay service value',
  [VAT_INCLUDED]: 'The price includes value added tax',
  [WILL_ADDED]: 'Will add',
  [FIVE_POINTS]: '5 points',
  [TO_USE_IN_AI]: 'to be used for artificial intelligence',
  [INCLUDED_FEES]: 'Includes payment of the cost of the service',
  [REFUND2]: 'If you do not receive more than one offer within 72 hours, the amount will be refunded to your account.',
  [ACTIVE_REPUBLISH]: 'Activate and publish the request',
  [YOUR_MANZILIK_ORDER]: 'Your order at manzilik!',
  [CONGRATS]:
    'Congratulations, you have received a 100% discount coupon for the cost of your order submitted from your home!',

  [ARE_YOU_SURE_DELETE_ORDER]: 'Are you sure you want to delete this order?',
  [ACCEPTED_OFFER]: 'Accepted offer',
  [CANCELED_OFFER]: 'Canceled offer',
  [NOW_CAN_COMPLETE_CONTRACT]: 'Now you can complete the contract',
  [ORDER_CANCELED]:
    'The contract has been cancelled. You can republish the request again by clicking on the republish the request button below.',
  [REFUND_MESSAGE]: 'The order amount will be credited to your account after 72 hours',
  [CONFIRM_CANCEL_ORDER]: 'Confirm cancel',
  [MESSAGES]: 'Messages',
  [OFFER_ACCEPTED]: 'Offer accepted',
  [YOU_CAN_PUBLISH]:
    'You can publish a new service request that is consistent with what was published in this service, and specify some modifications as you wish.',
  [RE_PUBLISH]: 'Republish the request again',
  [NO_QUOTATIONS]: 'There are no quotations',
  [DRAFT_REQUEST]: 'Draft request',
  [DESCRIBE_SERVICE]: 'Describe the service request',
  [DESCRIPTION_HELPS_YOU]: 'Description Helps your home team to find the best professionals and present them to you.',
  [DESCRIPTION_EXAMPLE]: 'Example: You want to make a similar decision for the area of ​​5 x 4 meters in modern style',
  [REGISTER_NOW]: 'Register now to enjoy Manzilik features and More',
  [WE_NEED_YOUR_PHONE_TO_ASSIST]: 'To be able to assist you in this issue, please provide your phone number below',
  [CONTINUE_PAYMENT]: 'Continue payment',
  [CONTACT_SUPPORT]: 'Contact technical support ',
  [SHOW_CLOSED_COMPLETED_REQUESTS]: 'View completed and canceled requests',
  [INPROGRESS_REQUESTS]: 'In progress requests',
  [NEW_REQUESTS]: 'New requests',
  [COMPLETED_CANCELED_REQUESTS]: 'Completed / Canceled requests',
  [APPLICANT]: 'Applicant',
  [SOME_GUIDES]: 'Some tips for writing sentences that produce amazing results!',
  [AI_GUIDE_1]: 'Prefer to write in English for more accurate results.',
  [AI_GUIDE_2]: 'We present to you a series of questions. Answers to these questions will help formulate a good topic:',
  [AI_GUIDE_3]: 'Furniture type/furniture style:',
  [AI_GUIDE_4]: 'What color is the furniture?',
  [AI_GUIDE_5]: 'What color are the walls?',
  [AI_GUIDE_6]: 'What type of flooring (eg: parquet, ceramic, epoxy)?',
  [AI_GUIDE_7]:
    'Request any special items in the room (example: fireplace, stand, light stand, artificial trees, coffee table).',
  [ATTACHED_PHOTOS]: 'Attached photos',
  [GUIDES]: 'Guides',
  [SMART_TEXT_AREA]: 'Smart support text(Advance)',
  [SMART_GENERATION]: 'Smart generation',
  [RE_GENERATE]: 'Regeneration',
  [SELECT_STYLE]: 'Select style',
  [SELECT_ROOM_TYPE]: 'Select room type',
  [RE_SELECT]: 'Reallocation',
  [ADV_GENERATE_TOOLTIP]:
    'You can generate by writing a detailed paragraph for artificial intelligence to obtain more accurate results',
  [NOT_EXIST]: 'Not exist',
  [CLASSIC_VIEW]: 'Classic look of the bathroom with sink cabinets',
  [BACK_HISTORY]: 'Back',
  [CLASSIFY_STORES]: 'Store classification',
  [FIND_VENDOR]: 'Find a vendor',
  [SIMILAR_PRODUCTS]: 'Similar Products',
  [ANALYZE_PRODUCTS]: 'Products are being analyzed and matched with products near you!',
  [COUNTRY_CITY]: 'Country and City',
  [MANAGE_IDEAS]: 'Manage My Ideas',
  [MANAGE_VIDEOS]: 'Manage My Videos',
  [SHARE_PROFILE]: 'Share',
  [SHARE_PROFILE_WITH_PEOPLE]: 'Share your file link with friends to increase your number of followers',
  [MANAGE_REQUESTS]: 'Manage Requests',
  [TRENDING]: 'Trending',
  [JOBS]: 'Jobs',
  [APPLIED_FOR_JOB]: 'Applied for This job',
  [BE_FIRST_ONE]: 'Be the first one to apply',
  [APPLY]: 'Apply',
  [NO_PRODUCTS_EXIST]: 'There are no similar products currently',
  [VIEW_SELLERS]: 'View Sellers',
  [VIEW_PRODUCTS]: 'View Products',
  [PRESS_TO_VIEW_PRODUCTS]: 'Press to view best-selling products',
  [VIEW_SELLERS_PRODUCTS]: 'View Sellers Products',
  [VIEW_SELLERS_PRODUCTS_WITHIN_SEARCH]: 'Show all sellers in the results',
  [BEST_SELLING_PRODUCTS]: 'Best Selling Products',
  [WILL_DEDUCT]: 'Will Deduct',
  [POINTS_FROM_BALANCE]: 'Points from your balance',
  [BEST_SELLERS_IN_KINGDOM]: 'Best Sellers in Kingdom',
  [INCOMPLETE_RFQ]: 'INCOMPLETE_RFQ',
};
